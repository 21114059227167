// import i18n from '@/plugins/i18n';
import { defineComponent } from 'vue';
import { RoutesNames } from '@/router';
import { logRocketService } from '@/services/log-rocket.service';
import { loggerService } from '@/services/logger.service';
import { generalService } from '@/services/general.service';
import { config } from '@/config/config';
import { eventBus } from '@/services/event-bus.service';
import { Decision } from '../types/types';
import { WeekDayOptions } from '@/types/consts';
import { utilService } from '@/services/util.service';
import { alertService } from '@/services/alert.service';
import dayjs from 'dayjs';
import appHeader from '@/components/app-header.vue';
import appUnreadConversations from '@/components/app-unread-conversations.vue';
import sideNav from '@/components/app-side-nav/app-side-nav.vue';
import contactSupportPopup from '@/components/contact-support-popup.vue';
import appGenesys from '@/components/app-genesys/app-genesys.vue';
export default defineComponent({
    components: {
        'app-header': appHeader,
        'app-side-nav': sideNav,
        'contact-support-popup': contactSupportPopup,
        'app-unread-conversations': appUnreadConversations,
        appGenesys
    },
    data() {
        return {
            nationalities: [],
            env: config.env.name,
            selected: '',
            signalRSendMsg: '',
            pingHub: null,
            hubInitCBFunc: null,
            unreadConversations: [],
            showUnreadConversations: false,
            userNotices: [],
            isNoticesExist: false,
            userDecision: false,
        };
    },
    async created() {
        eventBus.$on('messageSent', this.sendMsg);
        eventBus.$on('checkForProfileInfoSabre', this.checkForProfileInfo);
        eventBus.$on('reciveChatMessages', this.handleMessageReceived);
        eventBus.$on('loadUnreadConversation', this.loadUnreadConversation);
        this.hubInitCBFunc = this.hubInit.bind(this); //keep context for signalR callback
        const user = await this.$store.dispatch({ type: 'authStore/getLoggedInUser' });
        this.$store.dispatch({ type: 'authStore/getUserNotices' }).then((userNotices) => {
            this.userNotices = userNotices;
            if (this.userNotices && this.userNotices.length > 0) {
                this.isNoticesExist = true;
            }
        });
        await this.$store.dispatch({ type: 'authStore/loadUserIp' });
        await this.$store.dispatch({ type: 'authStore/loadUserBrowser' });
        // set user system language
        await this.setUserSystemLanguage(user);
        // set user first day option
        const { firstDayOptions } = user;
        const firstDayOfWeek = WeekDayOptions[firstDayOptions] || 0;
        dayjs.Ls.en.weekStart = firstDayOfWeek;
        if (user) {
            logRocketService.logRocketInit(user);
            this.signalrInit();
            this.$store.commit({ type: 'authStore/setIsLoadedData', loaded: true });
        }
        else {
            this.$router.push({ name: RoutesNames.signin });
        }
        await this.loadData();
    },
    methods: {
        async loadData() {
            await this.loadUnreadConversation();
        },
        async loadUnreadConversation() {
            this.unreadConversations = await generalService.getUnreadConversations(this.loggedinUser.id);
        },
        async noticeApprovedClick() {
            const updateUserNoticeRequest = {
                notices: this.userNotices,
                decision: Decision.Accept,
            };
            //Close dialog.
            this.isNoticesExist = false;
            //send HTTP to BE to update userNotices
            await this.$store.dispatch({ type: 'authStore/updateUserNotices', updateUserNoticeRequest });
        },
        noticeDeclineClick() {
            //Signout the user
            this.$router.push({ name: RoutesNames.signout });
        },
        toggleConversationsVisible() {
            this.showUnreadConversations = !this.showUnreadConversations;
            if (this.showUnreadConversations) {
                eventBus.$emit('toggleChatVisible', true);
            }
        },
        async signalrInit() {
            this.hubInit();
            await this.$signalr.start();
        },
        hubInit() {
            this.pingHub = this.$signalr.getHub('pingHub');
            this.pingHub.on('sendConversationReceived', this.handleMessageReceived);
            this.pingHub.on('moveProfileToPnrSabre', this.handleMoveProfileToPnrSabre);
        },
        async handleMoveProfileToPnrSabre(profileInfo) {
            const passengerStore = this.trip.passengers.find((pax) => pax.id === profileInfo.PassengerId);
            if (passengerStore) {
                let isUnusedAdded = (!passengerStore.unusedTickets || passengerStore.unusedTickets.length === 0) &&
                    profileInfo.UnusedTickets?.length > 0;
                if (isUnusedAdded) {
                    let passenger = utilService.deepClone(passengerStore);
                    passenger.unusedTickets = profileInfo.UnusedTickets;
                    passenger.gdsFullProfileResp = profileInfo.GDSLines;
                    this.$store.commit({ type: 'tripStore/updateTripTravellerProfileInfo', passenger });
                    if (this.loggedinUser.id === passenger.corporateUserId) {
                        alertService.success('alert.traveler.pleaseBeAdvisedThatYouHaveUnusedTicketConnectedToHisProfile');
                    }
                    else {
                        alertService.success('alert.traveler.pleaseBeAdvisedThatTravelerHasUnusedTicketConnectedToHisProfile');
                    }
                }
            }
        },
        async handleMessageReceived(msg) {
            //update chat icon with new message when user is not in the current chat
            if (!this.activeChatId && msg.Id !== this.activeChatId) {
                await this.$store.dispatch({ type: 'chatStore/getTripUnreadChats', tripId: msg.TripId });
            }
            //check first if its new chat
            if (this.tripChats[msg.TripId]) {
                const chatIdx = this.tripChats[msg.TripId]?.findIndex((c) => c.id === msg.Id);
                if (chatIdx === -1) {
                    //new chat!!!
                    eventBus.$emit('newIncomingChat');
                    await this.$store.dispatch({ type: 'chatStore/loadChats', tripId: msg.TripId });
                }
                else {
                    const chatRes = await this.$store.dispatch({
                        type: 'chatStore/updateConversationMessages',
                        message: msg,
                    });
                    //check if messages received while user was in the chat in order to mark them as read
                    if (this.activeChatId && msg.Id === this.activeChatId) {
                        await this.$store.dispatch({ type: 'chatStore/resetTripUnreadChats', conversationId: this.activeChatId });
                    }
                }
            }
            this.loadUnreadConversation(); // user level
        },
        sendMsg(msg) {
            msg.UserId = this.loggedinUser.id;
            this.pingHub.invoke('sendConversation', msg);
        },
        checkForProfileInfo(profileInfo) {
            this.pingHub.invoke('initGetMovePnrToProfileInfo', profileInfo);
        },
        async setUserSystemLanguage(user) {
            try {
                //set user system language
                const language = user?.preferences?.language;
                this.$i18n.locale = language.split('-')[0];
                this.$store.commit({ type: 'userStore/setSystemLanguage', language });
            }
            catch (error) {
                loggerService.error(error);
                this.$store.commit({ type: 'userStore/setSystemLanguage', language: 'en-GB' });
                this.$i18n.locale = 'en';
            }
        },
    },
    computed: {
        dataLoaded() {
            return this.$store.getters['authStore/isDataLoaded'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isReportPage() {
            return this.$route.name.toLowerCase() === RoutesNames.powerbiReport.toLowerCase();
        },
        tripChats() {
            return this.$store.getters['chatStore/chats'];
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        activeChatId() {
            return this.$store.getters['chatStore/activeChatId'];
        },
        canUseGenesysChat() {
            return (this.loggedinUser?.permissions?.includes('AgencyCanUseGenesysChat')
                && this.loggedinUser?.permissions?.includes('UserCanUseGenesysChat'));
        },
    },
    unmounted() {
        eventBus.$off('messageSent', this.sendMsg);
        eventBus.$off('checkForProfileInfoSabre', this.checkForProfileInfo);
        eventBus.$off('reciveChatMessages', this.messageReceived);
        this.pingHub.off('sendConversationReceived', this.sendConversationReceived);
        eventBus.$off('loadUnreadConversation', this.loadUnreadConversation);
    },
});
