import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6c0270ab"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "flight-stepper" };
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
    key: 0,
    class: "material-symbols-outlined check-icon"
};
const _hoisted_4 = { key: 1 };
const _hoisted_5 = {
    key: 0,
    class: "is-flex is-align-items-center"
};
const _hoisted_6 = { class: "page-title" };
const _hoisted_7 = {
    key: 1,
    class: "is-flex is-align-items-center"
};
const _hoisted_8 = { class: "page-title" };
const _hoisted_9 = {
    key: 2,
    class: "modify-btn-wrapper is-flex is-align-items-baseline"
};
const _hoisted_10 = { class: "mr-2" };
const _hoisted_11 = { key: 3 };
const _hoisted_12 = { class: "time-range" };
const _hoisted_13 = {
    key: 0,
    class: "line m-2"
};
const _hoisted_14 = {
    key: 0,
    class: "mt-4"
};
const _hoisted_15 = { class: "field control" };
const _hoisted_16 = {
    key: 0,
    class: "help"
};
const _hoisted_17 = { class: "field control" };
const _hoisted_18 = {
    key: 0,
    class: "help"
};
const _hoisted_19 = { class: "field control" };
const _hoisted_20 = { class: "field app-column" };
const _hoisted_21 = {
    key: 0,
    class: "help"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_btn = _resolveComponent("app-btn");
    const _component_app_carousel = _resolveComponent("app-carousel");
    const _component_airport_autocomplete = _resolveComponent("airport-autocomplete");
    const _component_form_validation_msg = _resolveComponent("form-validation-msg");
    const _component_app_datepicker = _resolveComponent("app-datepicker");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", null, [
            _createVNode(_component_app_carousel, {
                options: _ctx.isMobile ? undefined : _ctx.stepperOptions,
                class: _normalizeClass(["flight-carousel", { 'no-pointer-events': _ctx.isOnBySchedulePricingPage }])
            }, {
                default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flightsRes, (_, idx) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: idx,
                            class: "page-container mr-2",
                            onClick: ($event) => (_ctx.$emit('goToSegment', idx))
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(["page-number", {
                                        isActive: _ctx.resultsIndex === idx && !_ctx.selectedSegments[idx]?.segments,
                                        isSelected: _ctx.selectedSegments[idx]?.segments,
                                    }])
                            }, [
                                (_ctx.selectedSegments[idx]?.segments)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, "check"))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(idx + 1), 1 /* TEXT */))
                            ], 2 /* CLASS */),
                            _createElementVNode("div", null, [
                                (_ctx.searchOptions.tripType === _ctx.tripType.multiCity)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.searchOptions.flightRows[idx].origin.Code) + " to " + _toDisplayString(_ctx.searchOptions.flightRows[idx].destination.Code), 1 /* TEXT */)
                                    ]))
                                    : (_ctx.searchOptions.tripType === _ctx.tripType.roundTrip)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                            _createElementVNode("p", _hoisted_8, _toDisplayString(idx === 0 ? _ctx.searchOptions.flightRows[0].origin.Code : _ctx.searchOptions.flightRows[0].destination.Code) + " to " + _toDisplayString(idx === 0 ? _ctx.searchOptions.flightRows[0].destination.Code : _ctx.searchOptions.flightRows[0].origin.Code), 1 /* TEXT */)
                                        ]))
                                        : _createCommentVNode("v-if", true),
                                _createCommentVNode(" as for bug 50633 - modify is currently going to be commented out "),
                                (_ctx.isShowModify && _ctx.resultsIndex === idx && !_ctx.selectedSegments[idx]?.segments)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('flight.selectFlight')), 1 /* TEXT */),
                                        _createVNode(_component_app_btn, {
                                            class: "modify-btn",
                                            onClick: _withModifiers(($event) => (_ctx.modifySegment(idx)), ["stop"]),
                                            type: "text",
                                            isLinkStyle: "",
                                            hasPadding: false
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t('btn.modify')), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                    ]))
                                    : (_ctx.selectedSegments[idx]?.segments)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                            _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.getFormattedTime(_ctx.selectedSegments[idx].segments[0].flights[0].depDate)) + " - " + _toDisplayString(_ctx.getFormattedTime(_ctx.selectedSegments[idx].segments[0].flights[_ctx.selectedSegments[idx].segments[0].flights.length - 1]
                                                .arrDate)), 1 /* TEXT */)
                                        ]))
                                        : _createCommentVNode("v-if", true)
                            ]),
                            (idx + 1 !== _ctx.flightsRes.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13))
                                : _createCommentVNode("v-if", true)
                        ], 8 /* PROPS */, _hoisted_2));
                    }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["options", "class"])
        ]),
        (_ctx.isModifyingSegment)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("form", {
                    class: "is-flex",
                    onSubmit: _cache[4] || (_cache[4] = _withModifiers(
                    //@ts-ignore
                    (...args) => (_ctx.handleSearchSegment && _ctx.handleSearchSegment(...args)), ["prevent"]))
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(["row is-flex", { 'is-flex-direction-column': _ctx.isMobile, 'is-align-items-start': !_ctx.isMobile }])
                    }, [
                        _createElementVNode("div", _hoisted_15, [
                            _createVNode(_component_airport_autocomplete, {
                                isAutoPopulate: "",
                                class: _normalizeClass(["app-search-input", { 'is-danger': _ctx.markFields && _ctx.v$.segmentToEdit.options.origin.$invalid }]),
                                placeholder: _ctx.$t('autocomplete.fromFlightDeparture'),
                                iconName: "flight_takeoff",
                                modelValue: _ctx.v$.segmentToEdit.options.origin.$model,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.v$.segmentToEdit.options.origin.$model) = $event)),
                                onItemSelected: _ctx.selectedOrigin,
                                onChange: _ctx.handleChangeOrigin
                            }, null, 8 /* PROPS */, ["class", "placeholder", "modelValue", "onItemSelected", "onChange"]),
                            ((_ctx.v$.segmentToEdit.options.origin.$invalid && _ctx.v$.segmentToEdit.options.origin.$dirty) ||
                                (_ctx.markFields && _ctx.v$.segmentToEdit.options.origin.$invalid))
                                ? (_openBlock(), _createBlock(_component_form_validation_msg, {
                                    key: 0,
                                    type: "error"
                                }, {
                                    default: _withCtx(() => [
                                        (_ctx.v$.segmentToEdit.options.origin.required.$invalid)
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.$t('validation.required')), 1 /* TEXT */))
                                            : _createCommentVNode("v-if", true)
                                    ]),
                                    _: 1 /* STABLE */
                                }))
                                : _createCommentVNode("v-if", true)
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                            _createVNode(_component_airport_autocomplete, {
                                class: _normalizeClass(["app-search-input", { 'is-danger': _ctx.markFields && _ctx.v$.segmentToEdit.options.destination.$invalid }]),
                                placeholder: _ctx.$t('autocomplete.toFlightDestination'),
                                iconName: "flight_land",
                                modelValue: _ctx.v$.segmentToEdit.options.destination.$model,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.v$.segmentToEdit.options.destination.$model) = $event)),
                                onItemSelected: _ctx.selectedDestination,
                                onChange: _ctx.handleChangeDestination
                            }, null, 8 /* PROPS */, ["placeholder", "class", "modelValue", "onItemSelected", "onChange"]),
                            ((_ctx.v$.segmentToEdit.options.destination.$invalid && _ctx.v$.segmentToEdit.options.destination.$dirty) ||
                                (_ctx.markFields && _ctx.v$.segmentToEdit.options.destination.$invalid))
                                ? (_openBlock(), _createBlock(_component_form_validation_msg, {
                                    key: 0,
                                    type: "error"
                                }, {
                                    default: _withCtx(() => [
                                        (_ctx.v$.segmentToEdit.options.destination.required.$invalid)
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.$t('validation.required')), 1 /* TEXT */))
                                            : _createCommentVNode("v-if", true)
                                    ]),
                                    _: 1 /* STABLE */
                                }))
                                : _createCommentVNode("v-if", true)
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("div", _hoisted_20, [
                                _createVNode(_component_app_datepicker, {
                                    class: _normalizeClass(["flight-date-picker", { 'is-danger': _ctx.markFields && _ctx.v$.segmentToEdit.options.departureDate.$invalid }]),
                                    modelValue: _ctx.v$.segmentToEdit.options.departureDate.$model,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.v$.segmentToEdit.options.departureDate.$model) = $event)),
                                    placeholder: _ctx.$t('flight.departureDate')
                                }, null, 8 /* PROPS */, ["modelValue", "placeholder", "class"]),
                                ((_ctx.v$.segmentToEdit.options.departureDate.$invalid && _ctx.v$.segmentToEdit.options.departureDate.$dirty) ||
                                    (_ctx.markFields && _ctx.v$.segmentToEdit.options.departureDate.$invalid))
                                    ? (_openBlock(), _createBlock(_component_form_validation_msg, {
                                        key: 0,
                                        type: "error"
                                    }, {
                                        default: _withCtx(() => [
                                            (_ctx.v$.segmentToEdit.options.departureDate.required.$invalid)
                                                ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.$t('validation.required')), 1 /* TEXT */))
                                                : _createCommentVNode("v-if", true)
                                        ]),
                                        _: 1 /* STABLE */
                                    }))
                                    : _createCommentVNode("v-if", true)
                            ])
                        ]),
                        _createVNode(_component_app_btn, {
                            class: "desktop-btn muliBold",
                            type: "primary",
                            isLoading: false
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('common.search')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                        }),
                        _createElementVNode("span", {
                            class: "material-symbols-outlined close-modify mt-4",
                            onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.isModifyingSegment = false))
                        }, " close ")
                    ], 2 /* CLASS */)
                ], 32 /* NEED_HYDRATION */)
            ]))
            : _createCommentVNode("v-if", true)
    ]));
}
