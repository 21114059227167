import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-304f39ea"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "flight-by-schedule-pricing" };
const _hoisted_2 = { class: "mb-4" };
const _hoisted_3 = { class: "best-combination-wrapper is-flex is-align-items-stretch" };
const _hoisted_4 = {
    key: 1,
    class: "loader-container is-flex is-justify-content-center is-align-items-center"
};
const _hoisted_5 = { class: "is-flex is-flex-direction-column is-align-items-center" };
const _hoisted_6 = { class: "loader-gif" };
const _hoisted_7 = { class: "muliSemiBold" };
const _hoisted_8 = {
    key: 2,
    class: "width-100 has-text-centered is-align-self-center"
};
const _hoisted_9 = {
    key: 3,
    class: "pricing-options-container is-flex is-align-items-center"
};
const _hoisted_10 = { key: 0 };
const _hoisted_11 = { class: "mr-2" };
const _hoisted_12 = { key: 0 };
const _hoisted_13 = { class: "mb-4" };
const _hoisted_14 = { class: "additional-pricing-options-wrapper" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_flights_by_schedule_selected_segments = _resolveComponent("flights-by-schedule-selected-segments");
    const _component_flight_by_schedule_pricing_option = _resolveComponent("flight-by-schedule-pricing-option");
    const _component_app_btn = _resolveComponent("app-btn");
    const _component_app_pagination = _resolveComponent("app-pagination");
    const _directive_loading = _resolveDirective("loading");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('flight.selectBestCombination')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_3, [
            _createCommentVNode(" flights selected-segments "),
            (!_ctx.isMobile)
                ? (_openBlock(), _createBlock(_component_flights_by_schedule_selected_segments, {
                    key: 0,
                    class: "mr-4",
                    selectedSegments: _ctx.selectedSegments,
                    onFlightSelected: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('flightSelected', { flightResult: _ctx.pricingResults.originalPackage, drawerType: 'details' })))
                }, null, 8 /* PROPS */, ["selectedSegments"]))
                : _createCommentVNode("v-if", true),
            _createCommentVNode(" flights results "),
            (_ctx.pricingResults === null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _withDirectives(_createElementVNode("span", _hoisted_6, null, 512 /* NEED_PATCH */), [
                            [_directive_loading, true]
                        ]),
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('flight.weAreLookingForTheBestFlightCombination')), 1 /* TEXT */)
                    ])
                ]))
                : (_ctx.pricingResults !== null && !_ctx.pricingResults?.results?.length && !_ctx.pricingResults?.originalPackage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("h3", null, _toDisplayString(_ctx.$t('common.noResults')), 1 /* TEXT */)
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (_ctx.pricingResults?.originalPackage)
                            ? (_openBlock(), _createBlock(_component_flight_by_schedule_pricing_option, {
                                key: 0,
                                selectedSegments: _ctx.selectedSegments,
                                pricingOption: _ctx.pricingResults.originalPackage,
                                title: _ctx.$t('flight.yourSelection'),
                                subTitle: _ctx.$t('flight.combinedFlightSelected'),
                                isRibbon: _ctx.isCheapestEqualsOriginal,
                                onFlightSelected: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('flightSelected', { flightResult: _ctx.pricingResults.originalPackage, drawerType: 'rules' }))),
                                onAddToCart: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('addToCart', { selectedFlightResult: _ctx.pricingResults.originalPackage, isPricing: true }))),
                                onCancelCartBtnLoadingBySchedule: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('cancelCartBtnLoading')))
                            }, null, 8 /* PROPS */, ["selectedSegments", "pricingOption", "title", "subTitle", "isRibbon"]))
                            : _createCommentVNode("v-if", true),
                        (_ctx.pricingResults?.results?.length && !_ctx.isCheapestEqualsOriginal)
                            ? (_openBlock(), _createBlock(_component_flight_by_schedule_pricing_option, {
                                key: 1,
                                class: _normalizeClass({ 'ml-4': !_ctx.isMobile }),
                                pricingOption: _ctx.cheapestPackage,
                                title: _ctx.$t('flight.cheapestPackage'),
                                subTitle: _ctx.$t('flight.cheapestCombinationForFlight'),
                                isRibbon: !_ctx.isCheapestEqualsOriginal,
                                onFlightSelected: _cache[4] || (_cache[4] = ($event) => (_ctx.$emit('flightSelected', { flightResult: _ctx.cheapestPackage, drawerType: 'rules' }))),
                                onAddToCart: _cache[5] || (_cache[5] = ($event) => (_ctx.$emit('addToCart', { selectedFlightResult: _ctx.cheapestPackage, isPricing: true }))),
                                onCancelCartBtnLoadingBySchedule: _cache[6] || (_cache[6] = ($event) => (_ctx.$emit('cancelCartBtnLoading')))
                            }, null, 8 /* PROPS */, ["class", "pricingOption", "title", "subTitle", "isRibbon"]))
                            : _createCommentVNode("v-if", true)
                    ]))
        ]),
        (_ctx.pricingResults?.results?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_app_btn, {
                    class: "is-flex is-align-items-center mb-4",
                    onClick: _cache[7] || (_cache[7] = ($event) => (_ctx.isShowAdditionalPricing = !_ctx.isShowAdditionalPricing)),
                    isFitWidth: _ctx.isMobile
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('flight.additionalPricingOptions')), 1 /* TEXT */),
                        _createElementVNode("span", {
                            class: _normalizeClass(["material-symbols-outlined", { 'rotate-180': _ctx.isShowAdditionalPricing }])
                        }, " expand_more ", 2 /* CLASS */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["isFitWidth"]),
                (_ctx.isShowAdditionalPricing)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.$t('flight.additionalPrices')), 1 /* TEXT */),
                        _createElementVNode("div", _hoisted_14, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pricingResultsToShow, (result, index) => {
                                return (_openBlock(), _createBlock(_component_flight_by_schedule_pricing_option, {
                                    key: index,
                                    class: "pricing-results-section",
                                    onFlightSelected: ($event) => (_ctx.$emit('flightSelected', { flightResult: result, drawerType: 'rules' })),
                                    onAddToCart: ($event) => (_ctx.$emit('addToCart', { selectedFlightResult: result, isPricing: true })),
                                    onCancelCartBtnLoadingBySchedule: _cache[8] || (_cache[8] = ($event) => (_ctx.$emit('cancelCartBtnLoading'))),
                                    pricingOption: result,
                                    isShowSegmentsRoute: ""
                                }, null, 8 /* PROPS */, ["onFlightSelected", "onAddToCart", "pricingOption"]));
                            }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        (!_ctx.isMobile && _ctx.pricingResults?.results.length)
                            ? (_openBlock(), _createBlock(_component_app_pagination, {
                                key: 0,
                                class: "my-4",
                                total: _ctx.pricingResults.results.length,
                                currPage: _ctx.page,
                                onSwitchPage: _ctx.setPage
                            }, null, 8 /* PROPS */, ["total", "currPage", "onSwitchPage"]))
                            : _createCommentVNode("v-if", true)
                    ]))
                    : _createCommentVNode("v-if", true)
            ]))
            : _createCommentVNode("v-if", true)
    ]));
}
