import { defineComponent } from 'vue';
import { PRICING_RESULTS_COUNT_SIZE } from '@/modules/products/flight/models/consts';
import appPagination from '@/components/app-pagination.vue';
import flightsByScheduleSelectedSegments from '@/modules/products/flight/components/flight-by-schedule-pricing/flights-by-schedule-selected-segments/flights-by-schedule-selected-segments.vue';
import FlightBySchedulePricingOption from '@/modules/products/flight/components/flight-by-schedule-pricing/flight-by-schedule-pricing-option/flight-by-schedule-pricing-option.vue';
export default defineComponent({
    name: 'FlightBySchedulePricing',
    components: {
        appPagination,
        flightsByScheduleSelectedSegments,
        FlightBySchedulePricingOption,
    },
    props: {
        pricingResults: {
            type: Object,
        },
        selectedSegments: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isShowAdditionalPricing: false,
            page: 1,
        };
    },
    methods: {
        setPage(page) {
            this.page = page;
        },
    },
    computed: {
        cheapestPackage() {
            if (!this.pricingResults?.results) {
                return {};
            }
            return this.pricingResults.results.reduce((acc, pricingResult) => {
                if (!acc.avgPricePerPersonDisplayCurrency ||
                    acc.avgPricePerPersonDisplayCurrency > pricingResult.avgPricePerPersonDisplayCurrency) {
                    acc = pricingResult;
                }
                return acc;
            }, {});
        },
        isCheapestEqualsOriginal() {
            return this.pricingResults.originalPackage.packageKey === this.cheapestPackage.packageKey;
        },
        pricingResultsToShow() {
            // remove the cheapest package from additional pricing options because it's already displayed in the UI
            const pricingResults = this.pricingResults.results.filter((result) => result.packageKey !== this.cheapestPackage.packageKey);
            const count = PRICING_RESULTS_COUNT_SIZE;
            const startIdx = (this.page - 1) * count;
            if (this.isMobile) {
                return pricingResults.slice(0, startIdx + count);
            }
            return pricingResults.slice(startIdx, startIdx + count);
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
