import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Co2EmissionsLabel',
    props: {
        carbonQuantity: {
            type: Number,
            default: 0,
        },
        emissionsAverage: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            isTooltipOpen: false,
            highEmissionCap: 5,
            lowEmissionCap: -5,
        };
    },
    computed: {
        emissionsClass() {
            const diff = this.percentageDiff;
            if (diff >= this.highEmissionCap) {
                return 'has-text-danger';
            }
            else if (diff <= this.lowEmissionCap) {
                return 'has-text-success';
            }
            else {
                return 'gray';
            }
        },
        emissionsText() {
            const diff = this.percentageDiff;
            if (diff >= this.highEmissionCap) {
                return this.$t('common.higher');
            }
            else if (diff <= this.lowEmissionCap) {
                return this.$t('common.lower');
            }
            else {
                return this.$t('common.average');
            }
        },
        percentageDiff() {
            const percentageDiff = ((this.carbonQuantity - this.emissionsAverage) / this.emissionsAverage) * 100;
            return Math.round(percentageDiff);
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
