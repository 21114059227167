import { httpService } from '@/services/http.service';
import { utilService } from '@/services/util.service';
export const amtrakService = {
    searchAmtrak,
    autocompleteSearch,
    filterAmtrak,
    amtrakPricing,
    amtrakSelect,
    quoteFareRules,
    loadFrequentFlyer,
    loadContactInformation,
    saveAmtrakContactInfo,
    saveAmtrakFrequentFlyer,
    amtrakFareRules,
    isMultiCityQuote,
    loadCancelQuoteInfo,
    amtrakLoyaltyCards,
};
// GET
async function autocompleteSearch(queryString) {
    return httpService.get(`/AmtrakAutoComplete?str=${queryString}`, null);
}
async function quoteFareRules(quoteId) {
    return httpService.get(`/AmtrakFareRules?quoteId=${quoteId}`, null);
}
async function loadFrequentFlyer(quoteId, paxId) {
    return httpService.get(`/flightFrequentFlyer?quoteId=${quoteId}&paxId=${paxId}`, null);
}
async function loadContactInformation(tripId, quoteId) {
    return httpService.get(`/AmtrakContactInfo?tripId=${tripId}&quoteId=${quoteId}`, null);
}
async function loadCancelQuoteInfo(quoteId) {
    return httpService.get(`/AmtrakCancelQuoteInformation?quoteId=${quoteId}`, null);
}
// POST
async function searchAmtrak(request) {
    return httpService.post('/AmtrakSearch', request, {
        caching: {
            cachingMsTime: 1000 * 60 * 15,
            hardCached: false,
            cachingCategory: 'amtraksearch',
        },
    });
}
async function amtrakPricing(amtrakPricingRequest) {
    return httpService.post('/AmtrakPricing', amtrakPricingRequest);
}
async function amtrakLoyaltyCards() {
    return httpService.get('/AmtrakLoyaltyCards', { caching: {
            cachingMsTime: 1000 * 60 * 60,
            hardCached: false,
            cachingCategory: 'amtraksearch',
        },
    });
}
async function amtrakSelect(amtrakSelectOptions) {
    return httpService.post('/SelectAmtrak', amtrakSelectOptions);
}
async function saveAmtrakContactInfo(contactInformation) {
    return httpService.post('/AmtrakContactInfo', contactInformation);
}
async function saveAmtrakFrequentFlyer(frequentFlyerReq) {
    return httpService.post('/flightFrequentFlyer', frequentFlyerReq);
}
async function amtrakFareRules(req) {
    return httpService.post('/AmtrakFareRules', req);
}
// PUT
// LOGIC & SORT
function filterAmtrak(amtrakRails, filterType) {
    const rails = utilService.deepClone(amtrakRails);
    if (filterType.includes('Price')) {
        const sortDirection = filterType.includes('to Lowest') ? -1 : 1;
        rails.sort((a, b) => sortDirection * (a.basePrice - b.basePrice));
    }
    if (filterType.includes('Depart')) {
        rails.sort((a, b) => new Date(a.journeySegments[0].departureDateTime).getTime() -
            new Date(b.journeySegments[0].departureDateTime).getTime());
        if (filterType.includes('Descending')) {
            rails.reverse();
        }
    }
    if (filterType.includes('Arrive')) {
        rails.sort((a, b) => new Date(a.journeySegments[a.journeySegments.length - 1].arrivalDateTime).getTime() -
            new Date(b.journeySegments[b.journeySegments.length - 1].arrivalDateTime).getTime());
        if (filterType.includes('Descending')) {
            rails.reverse();
        }
    }
    if (filterType.includes('Duration')) {
        rails.sort((a, b) => {
            const durationA = getDurationInMinutes(a.journeyDuration);
            const durationB = getDurationInMinutes(b.journeyDuration);
            return (filterType.includes('Ascending') ? 1 : -1) * (durationA - durationB);
        });
    }
    if (filterType.includes('Number of Stops')) {
        rails.sort((a, b) => a.journeySegments.length - 1 - (b.journeySegments.length - 1));
        if (filterType.includes('Descending')) {
            rails.reverse();
        }
    }
    return rails;
}
function getDurationInMinutes(durationString) {
    // Check if the string is in the "HH:MM" format
    if (durationString.includes(':')) {
        const [hours, minutes] = durationString.split(':').map(Number);
        return hours * 60 + minutes;
    }
    // Check if the string is in the "XXh YYm" format
    const hoursMatch = durationString.match(/(\d+)h/);
    const minutesMatch = durationString.match(/(\d+)m/);
    const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;
    return hours * 60 + minutes;
}
// MISC
function isMultiCityQuote(quote) {
    if (quote.journeySegments.length < 2) {
        return false;
    }
    const { arrivalStationFullName: firstSegDestination } = quote.journeySegments[0];
    const { departureStationFullName: secondSegDeparture } = quote.journeySegments[1];
    if (firstSegDestination !== secondSegDeparture ||
        (firstSegDestination === secondSegDeparture && quote.journeySegments.length > 2)) {
        return true;
    }
    return false;
}
