<template>
  <section class="card-img-skeleton">
    <div v-for="(numOfCard, idx) in numOfCards" :key="idx" class="card-img-skeleton-individual">
      <el-skeleton animated>
        <template #template>
          <ticket-layout>
            <template #ticket-img>
              <section class="hotel-img-container">
                <!-- Hotel picture -->
                <el-skeleton-item variant="image" class="filter-skeleton-map-img" />
              </section>
            </template>

            <template #ticket-details>
              <!-- Hotel content -->
              <section class="hotel-main-content flex is-flex-direction-column space-between">
                <div class="hotel-info flex is-flex-direction-column space-between">
                  <div class="hotel-name flex is-flex-direction-column">
                    <el-skeleton-item variant="h1" class="skeleton-h1" />
                    <el-skeleton-item variant="text" style="width: 30%" />
                  </div>

                  <el-skeleton-item variant="text" style="width: 30%" />
                </div>
                <div class="flex is-flex-direction-column">
                  <hr />
                  <div class="supplier-img flex">
                    <el-skeleton-item variant="text" style="width: 32%" />
                  </div>
                </div>
              </section>
            </template>

            <template #ticket-extra-info>
              <!-- Hotel price, payment methods and more  -->
              <section class="hotel-extra-details flex is-flex-direction-column align-center">
                <el-skeleton-item variant="h3" style="width: 30%" />
                <el-skeleton-item variant="text" style="width: 30%" />
                <el-skeleton-item variant="h1" class="skeleton-h1" style="width: 80%" />
                <el-skeleton-item variant="text" style="width: 30%" />
              </section>
            </template>
          </ticket-layout>
        </template>
      </el-skeleton>
    </div>
  </section>
</template>
<script>
import { defineComponent } from 'vue';
import TicketLayout from '@/components/ticket-layout.vue';

export default defineComponent({
  name: 'CardImgSkeleton',
  props: {
    numOfCards: {
      type: Number,
      default: 3,
    },
  },
  components: { TicketLayout },
  data() {
    return {};
  },
  methods: {},
});
</script>
<style lang="scss" scoped>
@import '@/assets/style/abstracts';
.card-img-skeleton {
  .card-img-skeleton-individual {
    margin-bottom: 1rem;
  }

  .hotel-img-container {
    padding: 1rem;
    @include for-mobile-layout {
      padding: 0;
    }
  }
  .filter-skeleton-map-img {
    width: rem(272px);
    height: rem(160px);
    @include for-mobile-layout {
      width: 100%;
      border-radius: rem(4px) rem(4px) 0 0;
    }
  }
  .hotel-main-content {
    padding: 1rem;
    height: 100%;

    & > :last-child {
      padding-bottom: rem(10px);
    }
    div {
      gap: rem(8px);
    }
    gap: rem(8px);
    .hotel-info {
      height: 100%;
    }
    .hotel-name {
      flex: 1;
    }
  }
  .skeleton-h1 {
    height: rem(32px);
    width: 67%;
  }
  .hotel-extra-details {
    padding: 1rem;
    min-width: rem(175px);
    padding-top: rem(24px);

    & > :first-child {
      margin-bottom: rem(8px);
    }
    & > :nth-child(2) {
      margin-bottom: rem(24px);
    }
    & > :nth-child(3) {
      margin-bottom: rem(16px);
    }
  }
}
</style>
