import { defineComponent } from 'vue';
import { MAX_TRAVELERS_AMOUNT, TripState, Products } from '@/types/consts';
import tripTravelerTag from '@/components/trip/trip-traveler-tag.vue';
import tripEditTraveler from '@/components/trip/trip-edit-traveler/trip-edit-traveler.vue';
import { eventBus } from '@/services/event-bus.service';
import { loggerService } from '@/services/logger.service';
import { utilService } from '@/services/util.service.ts';
import { flightService } from '@/modules/products/flight/services/flight.service';
export default defineComponent({
    components: { tripTravelerTag, tripEditTraveler },
    props: {
        showTooltip: {
            type: Boolean,
            default: false,
        },
        isLockNeeded: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            travelerToEdit: null,
            showEditTravelerModal: false,
            isDialogGuest: false,
            errorTooltipVisible: false,
            isTravelerLoading: false,
            travelerIdx: -1,
        };
    },
    created() {
        eventBus.$on('showTravelersErrorTooltip', this.showErrorTooltip);
    },
    methods: {
        async addEmptyTraveler() {
            if ((this.isPreTrip && this.preTripItems.length) || !this.canBookMultiPax) {
                return;
            }
            await this.updateNumberOfPassengers(1);
        },
        hideRemoveBtn(traveler) {
            return ((traveler.isEmpty && this.travelers.length === 1) || //only one "Placeholder"
                (this.isPreTrip && this.preTripItems.length > 0) ||
                ((!traveler.id || traveler.isPrimary) && this.trip.quotes.length >= 1) //If we have a quote, do not allow remove Placeholder or Primary traveler
            );
        },
        async removeTraveler(id) {
            if (this.isPreTrip && this.preTripItems.length) {
                return;
            }
            try {
                if (id) {
                    await this.$store.dispatch({ type: 'tripStore/removeTraveler', travelerId: id });
                    await this.$store.dispatch({ type: 'tripStore/loadTrip', tripId: this.trip.id });
                }
                else {
                    if (this.numberOfPassengers !== 1 && this.numberOfPassengers > this.passengers.length) {
                        await this.updateNumberOfPassengers(-1);
                    }
                }
                if (this.passengers.length === 0) {
                    this.$store.dispatch({ type: 'authStore/getLoggedInUser', ignoreCaching: true }); // we load updated user again after adding the first traveler, to load userInfo with the compensation details object
                }
            }
            catch (err) {
                loggerService.error(err);
                throw err;
            }
        },
        async updateNumberOfPassengers(diff) {
            const numberOfPassengers = this.numberOfPassengers + diff;
            this.$emit('updateNumberOfPassengers', numberOfPassengers);
            await this.$store.dispatch({ type: 'tripStore/updateTripNumberOfPassengers', numberOfPassengers });
        },
        editTraveler(id, traveler, idx) {
            this.travelerToEdit = this.travelers.find((t) => {
                return t.id === traveler.id || traveler.tempId;
            });
            this.travelerIdx = idx;
            this.showEditTravelerModal = true;
        },
        switchDialogToGuestMode(isOpen) {
            this.isDialogGuest = isOpen;
        },
        closeDialog() {
            this.showEditTravelerModal = false;
            this.isDialogGuest = false;
            this.travelerIdx = -1;
        },
        showErrorTooltip() {
            this.errorTooltipVisible = true;
            eventBus.$emit('showTravelersError');
            setTimeout(() => {
                this.errorTooltipVisible = false;
            }, 5000);
        },
        getEmptyTravelers() {
            return {
                isEmpty: true,
                isPrimary: false,
                tempId: utilService.makeId(),
            };
        },
        async calculateTravelViolation() {
            const policyPax = this.trip.passengers?.find((p) => p.isPrimary && p.hasFlightCapacityPolicy);
            if (this.trip.passengers?.length && policyPax) {
                const quotes = this.quotes.filter(q => q.product === Products.Flight);
                for (const quote of quotes) {
                    const flightTravelPolicyRequest = {
                        packageCacheKey: '',
                        searchCacheKey: '',
                        quoteId: quote.quoteId,
                        tripId: this.trip.id
                    };
                    try {
                        const quoteViolations = await flightService.flightTravelPolicy(flightTravelPolicyRequest);
                        if (quoteViolations?.length > 0) {
                            const flightQuote = utilService.deepClone(quote);
                            flightQuote.travelPolicyViolations = quoteViolations;
                            this.$store.commit({ type: 'tripStore/updateQuote', flightQuote });
                            eventBus.$emit('reloadQuotes');
                        }
                    }
                    catch (error) {
                        loggerService.error(error);
                    }
                }
            }
        },
        async selectUser(passengerInfo) {
            this.isTravelerLoading = true;
            try {
                if (passengerInfo.passenger.CorporateUserId !== this.loggedinUser.id) {
                    if ((!passengerInfo.passenger.Gender) || (!this.loggedinUser.permissions.includes('AllowCreationPaxWithoutTitles') && !passengerInfo.passenger.Title)) {
                        if ((!this.loggedinUser.permissions.includes('CanEditAndViewProfile')) &&
                            !this.loggedinUser.permissions.includes('CanViewProfile') && !this.loggedinUser.permissions.includes('PreventUpdatingOthersAndOwnProfile')) {
                            //in case on of them is true, don't stop him from proceeding in the process (user can't fix the issue) so we should not enforce the profile update 
                            eventBus.$emit('openWarningProfileAddPopup');
                            return;
                        }
                    }
                }
                await this.$store.dispatch({ type: 'tripStore/addTraveler', passengerInfo });
                if (this.passengers.length === 1) {
                    this.$store.dispatch({ type: 'authStore/getLoggedInUser', ignoreCaching: true }); // we load updated user again after adding the first traveler, to load userInfo with the compensation details object
                }
                // Re-evaluate policies
                await this.calculateTravelViolation();
                eventBus.$emit('reloadFormOfPayment');
                eventBus.$emit('validateRequiredInfo');
            }
            catch (err) {
                loggerService.error(err);
                throw err;
            }
            finally {
                this.isTravelerLoading = false;
            }
        },
    },
    computed: {
        quotes() {
            return this.$store.getters['tripStore/quotes'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isFullTrip() {
            return this.travelers.length === MAX_TRAVELERS_AMOUNT;
        },
        isUserIncluded() {
            const user = this.passengers.find((p) => {
                return p.corporateUserId === this.loggedinUser.id;
            });
            return !!user;
        },
        isTravelersNumberLocked() {
            return this.trip.quotes.length >= 1 || (this.isAssistanceRequested && this.isLockNeeded);
        },
        showTravelerTooltip() {
            return this.isTravelersNumberLocked && (this.canBookForGuest || this.canBookForAll || this.canBookForGroups);
        },
        passengers() {
            return this.trip.passengers;
        },
        numberOfPassengers() {
            return this.trip.numberOfPassengers;
        },
        dialogTitle() {
            if (this.isDialogGuest) {
                return `${this.$t('trip.addGuestForm.addGuestToTrip')}`;
            }
            return `${this.$t('trip.addTraveller')}`;
        },
        canBookForGuest() {
            return this.loggedinUser.permissions.includes('CanBookForGuest');
        },
        canBookForAll() {
            return this.loggedinUser.permissions.includes('IsBooker');
        },
        canBookForGroups() {
            return (this.loggedinUser.permissions.includes('CanBookOnBehalfOfAssociatedUsersGroups') ||
                this.loggedinUser.permissions.includes('CanBookForSelfAndForUserGroup'));
        },
        showAddTravelersBtn() {
            if (this.isTravelersNumberLocked || this.isFullTrip || !this.canBookMultiPax) {
                return false;
            }
            return this.canBookForGuest || this.canBookForAll || this.canBookForGroups;
        },
        isCannotRemoveTraveler() {
            return !this.canBookForGuest && !this.canBookForAll && !this.canBookForGroups;
        },
        travelers() {
            const travelers = utilService.deepClone(this.passengers);
            const primaryTravelerIdx = travelers.findIndex((t) => {
                return t.isPrimary;
            });
            //if found - put it as first traveler
            if (primaryTravelerIdx !== -1) {
                const primaryTraveler = travelers[primaryTravelerIdx];
                travelers.splice(primaryTravelerIdx, 1);
                travelers.unshift(primaryTraveler);
            }
            const emptyTravelersAmount = this.numberOfPassengers - travelers.length;
            if (emptyTravelersAmount) {
                for (let i = 0; i < emptyTravelersAmount; i++) {
                    travelers.push(this.getEmptyTravelers());
                }
            }
            return travelers;
        },
        firstEmptyTravelerIdx() {
            return this.travelers.findIndex((traveler) => traveler.isEmpty);
        },
        isPreTrip() {
            return this.trip.state === TripState.PreTripRequest;
        },
        preTripItems() {
            return this.$store.getters['tripStore/preTripItems'];
        },
        isAssistanceRequested() {
            return this.trip.state === TripState.AssistanceRequest;
        },
        canBookMultiPax() {
            return !this.loggedinUser.permissions.includes('DisableBookForMultiPax');
        },
    },
    unmounted() {
        eventBus.$off('showTravelersErrorTooltip', this.showErrorTooltip);
    },
});
