<template>
  <section class="forms-page">
    <!--  -->
    <div class="app-columns mb-3">
      v-model="textarea"
      <el-button round size="large">Round</el-button>
      <div class="field app-column">
        <div class="control full">
          <el-input class="is-danger" v-model="textarea" :rows="2" type="textarea" placeholder="Please input" />
        </div>
      </div>
      <div class="field app-column">
        <div class="control full">
          <airport-autocomplete
            class="app-search-input is-danger"
            :placeholder="$t('autocomplete.toFlightDestination')"
            iconName="flight_land"
            v-model="autocomplete"
            @item-selected="selectedDestination($event, 0)"
            defaultStyle />
        </div>
      </div>
      <div class="field app-column">
        <div class="control full">
          <airport-autocomplete
            class="app-search-input is-danger"
            :placeholder="$t('autocomplete.toFlightDestination')"
            v-model="autocomplete"
            @item-selected="selectedDestination($event, 0)" />
        </div>
      </div>
    </div>
    <!--  -->
    <div class="app-columns mb-3">
      <div class="field app-column mb-0">
        <label class="label">{{ $t('trip.addGuestForm.middleName') }}</label>
        <app-datepicker
          class="ground-tansport-date-picker is-danger"
          v-model="meDate"
          placeholder="DD/MM/YYYY"
          format="DD/MM/YYYY"
          showYearArrows
          hideCurrDay
          editable
          defaultStyle />
      </div>
      <div class="field app-column mb-0">
        <label class="label">{{ $t('trip.addGuestForm.middleName') }}</label>
        <app-datepicker
          class="is-danger"
          v-model="meDate"
          placeholder="DD/MM/YYYY"
          format="DD/MM/YYYY"
          showYearArrows
          hideCurrDay
          editable
          defaultStyle />
      </div>
      <div class="field app-column mb-0">
        <label class="label">{{ $t('trip.addGuestForm.middleName') }}</label>
        <app-datepicker
          class="ground-tansport-date-picker is-danger"
          v-model="meDate"
          placeholder="DD/MM/YYYY"
          format="DD/MM/YYYY"
          showYearArrows
          hideCurrDay
          editable />
      </div>
      <div class="field app-column mb-0">
        <label class="label mandatory">{{ $t('trip.addGuestForm.lastName') }}</label>
        <div class="control">
          <app-daterange class="is-danger" v-model="dateRange" :placeholder="$t('flight.departureDate')" />
        </div>
      </div>
    </div>

    <!--  -->
    <el-radio v-model="aba" :value="'full'">
      <span class="muliSemiBold is-size-6">{{ $t('common.fullCharge') }} ({{ 1 }}{{ 2 }})</span>
    </el-radio>
    <pre>{{ aba }}</pre>
    <h2>Welcome to Forms!</h2>
    <h5>See form stuff here :)</h5>
    <app-daterange
      class="flight-date-picker search-input"
      v-model="dateRange"
      @update:modelValue="onDateSelected"
      :placeholder="$t('flight.departureDate')" />
    <el-button type="primary">Primary</el-button>
    <el-input v-model="name" />
    <el-select v-model="sortBy">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>
    <hr />
    <app-price class="price is-flex-grow-1" :displayCurrencyCode="'USD'" :value="10" />
    <app-btn class="remove-btn" type="text">
      {{ $t('btn.remove') }}
    </app-btn>
    <el-checkbox v-model="isTrue" label="isTrue ?" size="large" />
    <input v-model="isTrue" type="checkbox" />
    <pre>{{ name }}</pre>
    <pre>{{ sortBy }}</pre>
    <pre>{{ isTrue }}</pre>
    <!-- <pre>{{ v$ }}</pre> -->
    <form>
      <div class="field" v-for="(item, idx) in formData" :key="idx">
        <label class="label has-text-weight-normal">
          Name
          <!-- <span class="has-text-danger" v-if="v$.formData.name.$params.required">*</span> -->
        </label>
        <div class="control has-icons-right">
          <!-- :class="{ 'is-danger': v$.formData.name.$error, 'is-success': !v$.formData.name.$invalid }" -->
          <!-- <input v-model="item.name" class="input" type="text" placeholder="Please enter name , longer than 2" /> -->
          <input
            v-model="v$.formData.$model[idx].name"
            @input.once="touch"
            class="input"
            :class="{
              'is-danger': v$.formData.$each.$response.$data[idx].name.$error,
              'is-success': !v$.formData.$each.$response.$data[idx].name.$invalid,
            }"
            type="text"
            placeholder="Please enter name , longer than 2" />
          <form-validation-msg v-if="v$.formData.$each.$response.$data[idx].name.$error" type="error">
            <p v-if="!v$.formData.$each.$response.$data[idx].name.required" class="help is-danger">Name is required</p>
            <p v-else-if="!v$.formData.$each.$response.$data[idx].name.minLength" class="help is-danger">
              Name must be longer then 2
            </p>
          </form-validation-msg>
          <form-validation-msg v-else-if="!v$.formData.$each.$response.$data[idx].name.$invalid" type="success">
            <p class="help is-success">Nice name!</p>
          </form-validation-msg>
          <!-- <p v-else class="help">Please enter name , longer than 2</p> -->
        </div>
        <div class="control has-icons-right">
          <!-- :class="{ 'is-danger': v$.formData.name.$error, 'is-success': !v$.formData.name.$invalid }" -->
          <!-- <input v-model="item.name" class="input" type="text" placeholder="Please enter name , longer than 2" /> -->
          <input
            v-model="v$.formData.$model[idx].address"
            @input.once="touch"
            class="input"
            :class="{
              'is-danger': v$.formData.$each.$response.$data[idx].address.$error,
              'is-success': !v$.formData.$each.$response.$data[idx].address.$invalid,
            }"
            type="text"
            placeholder="Please enter address , longer than 2" />
          <form-validation-msg v-if="v$.formData.$each.$response.$data[idx].address.$error" type="error">
            <p v-if="!v$.formData.$each.$response.$data[idx].address.required" class="help is-danger">
              Address is required
            </p>
            <p v-else-if="!v$.formData.$each.$response.$data[idx].address.minLength" class="help is-danger">
              Address must be longer then 2
            </p>
          </form-validation-msg>
          <form-validation-msg v-else-if="!v$.formData.$each.$response.$data[idx].address.$invalid" type="success">
            <p class="help is-success">Nice address!</p>
          </form-validation-msg>
          <!-- <p v-else class="help">Please enter name , longer than 2</p> -->
        </div>
      </div>
    </form>
    <p>Is valid? {{ !v$.$invalid }}</p>
    <p>Is dirty? {{ v$.formData.$dirty }}</p>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { required, minLength, between, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import appDaterange from '@/components/form/app-daterange.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import airportAutocomplete from '@/components/airport-autocomplete.vue';

export default defineComponent({
  name: 'FormsPage',
  components: {
    formValidationMsg,
    appDaterange,
    airportAutocomplete,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      dateRange: { start: null, end: null },
      autocomplete: {},
      name: '',
      city: '',
      address: '',
      free: '',
      meDate: '',
      sortBy: 3,
      aba: false,
      formData: [
        { name: '', address: '' },
        { name: '', address: '' },
      ],
      // formData: {
      //   name: '',
      //   age: 0,
      //   address: '',
      // },
    };
  },
  methods: {
    touch() {
      this.v$.formData.$touch();
    },
    selectedDestination(dest) {
      //console.log('dest', dest);
    },
  },
  computed: {
    titleOpts() {
      return [
        {
          value: 'Mr',
          label: `${this.$t('trip.addGuestForm.titles.mr')}`,
        },
        {
          value: 'Mrs',
          label: `${this.$t('trip.addGuestForm.titles.mrs')}`,
        },
        {
          value: 'Ms',
          label: `${this.$t('trip.addGuestForm.titles.ms')}`,
        },
        {
          value: 'Miss',
          label: `${this.$t('trip.addGuestForm.titles.miss')}`,
        },
        {
          value: 'Dr',
          label: `${this.$t('trip.addGuestForm.titles.dr')}`,
        },
        {
          value: 'Prof',
          label: `${this.$t('trip.addGuestForm.titles.prof')}`,
        },
      ];
    },
    options() {
      return [
        {
          value: 1,
          label: this.$t('trip.sortType.startDateAsc'),
        },
        {
          value: 2,
          label: this.$t('trip.sortType.startDateDesc'),
        },
        {
          value: 3,
          label: this.$t('trip.sortType.endDateAsc'),
        },
        {
          value: 4,
          label: this.$t('trip.sortType.endDateDesc'),
        },
      ];
    },
  },
  validations() {
    return {
      // formData: {
      //   name: {
      //     required,
      //     minLength: minLength(2),
      //   },
      // },
      formData: {
        $each: helpers.forEach({
          name: {
            required,
            minLength: minLength(2),
          },
          address: {
            required,
            minLength: minLength(2),
          },
        }),
      },
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';
.forms-page {
  width: 100%;
  padding: rem(30px);
}
</style>
