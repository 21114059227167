import { defineComponent } from 'vue';
import { Products, ResponseEmailType, PriceOfferDetailsType, ShareTypes, QuoteStatus, Languages } from '@/types/consts';
import { required, email } from '@vuelidate/validators';
import { tripService } from '@/services/trip.service';
import { alertService } from '@/services/alert.service';
import { loggerService } from '@/services/logger.service';
import { useVuelidate } from '@vuelidate/core';
import { hotelService } from '@/modules/products/hotels/services/hotel.service';
import { eventBus } from '@/services/event-bus.service';
import { SuppliersId } from '@/modules/products/hotels/models/consts';
import { utilService } from '@/services/util.service';
import userAutocomplete from '@/components/user-autocomplete/user-autocomplete.vue';
import tripTravelerTag from '@/components/trip/trip-traveler-tag.vue';
import appShareSkeleton from '@/components/app-share-quotes/app-share-quotes-skeleton.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import groupBy from 'lodash/groupBy';
// public string ProductCacheKey { get; set; } - > Hotels, Parking, Cars
// public int GroundServicePricingId { get; set; }
// public string[] EuRailCacheKeys { get; set; } - > product name === rail
// Heathrow does not have PO
export default defineComponent({
    props: {
        shareType: {
            type: String,
            default: ShareTypes.Products,
        },
    },
    components: { userAutocomplete, tripTravelerTag, appShareSkeleton, formValidationMsg },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            markFields: false,
            isSendBtnLoading: false,
            isLoading: false,
            isCopiedToClipboard: false,
            SuppliersId: SuppliersId,
            SupportedLangs: Languages,
            brandLanguages: [],
            shareForm: {
                recipients: [],
                language: 'en-GB',
                subject: '',
                msg: '',
                mailType: 0,
                removeHeaders: false,
                includePdf: false,
                booker: null,
            },
            emailRes: null,
        };
    },
    async created() {
        await this.loadData();
        this.addExistingPassengers();
    },
    methods: {
        async loadData() {
            this.isLoading = true;
            if (!this.languages) {
                if (this.loggedinUser.isCorporateUser) {
                    let brandingLanguages = (await this.$store.dispatch('getCorporateBrandingLanguage'));
                    if (brandingLanguages) {
                        brandingLanguages = brandingLanguages.map((x) => x.toLowerCase());
                        const languages = this.SupportedLangs.filter((l) => brandingLanguages.includes(l.code.toLowerCase()) || brandingLanguages.includes(l.isoCode.toLowerCase()));
                        this.brandLanguages = languages.map((l) => ({ value: l.isoCode, label: l.name }));
                        if (this.brandLanguages.length > 0) {
                            this.shareForm.language = this.brandLanguages[0].label;
                        }
                    }
                }
                if (!this.languages && !this.brandLanguages) {
                    await this.$store.dispatch('loadLanguages');
                }
            }
            await this.setShareFormData();
        },
        async setShareFormData() {
            this.isLoading = true;
            const opts = this.getProductsOpts();
            try {
                if (this.shareType === ShareTypes.Itinerary) {
                    this.emailRes = await tripService.getTripItineraryMail(opts);
                }
                else {
                    this.emailRes = await tripService.getTripPriceOfferMail(opts);
                }
                this.shareForm.subject = this.emailRes.subject;
                this.shareForm.booker = this.emailRes.booker;
            }
            catch (err) {
                loggerService.error(err);
                alertService.error(this.$t('trip.cancelError').toString(), err);
                this.close();
            }
            finally {
                this.isLoading = false;
            }
        },
        getProductsOpts() {
            const shareDataFuncMap = {
                [ShareTypes.Itinerary]: this.getItineraryOpts,
                [ShareTypes.Products]: this.getProductResultsOpts,
                [ShareTypes.Quotes]: this.getQuotesOpts,
            };
            return shareDataFuncMap[this.shareType]();
        },
        getProductResultsOpts() {
            // determine searchCacheKey according to data
            const searchCacheKey = this.getSearchCacheKey();
            const returnCacheKey = this.getReturnSearchKey();
            const productType = this.productsToShare.type || null;
            let products = [];
            // special case for EU RAIL :(
            if (productType === Products.EuRail) {
                //Group by search key
                const workingStep = groupBy(this.productsToShare.products, 'searchCacheKey');
                Object.keys(workingStep).forEach((key) => {
                    products.push({
                        Product: Products.Rail,
                        QuoteId: null,
                        CacheKey: key,
                        EuRailCacheKeys: workingStep[key].map((p) => p.cacheKey),
                    });
                });
                // normal case for all else :
            }
            else {
                products = this.productsToShare.products.map((p) => {
                    const currProductType = productType; // allow the basis to share different product types
                    switch (currProductType) {
                        case Products.Hotel:
                        case Products.Parking:
                        case Products.Car:
                        case Products.Flight:
                            return {
                                Product: currProductType,
                                QuoteId: null,
                                CacheKey: currProductType === Products.Hotel ? p.room.cacheKey : searchCacheKey,
                                ProductCacheKey: currProductType === Products.Hotel ? p.hotel.cacheKey : p.cacheKey,
                            };
                            break;
                        case Products.Amtrak:
                            return {
                                Product: currProductType,
                                QuoteId: null,
                                CacheKey: searchCacheKey,
                                returnCacheKey: returnCacheKey,
                                ProductCacheKey: p.cacheKey,
                                AmtrakClassCodes: p.classCodes,
                                AmtrakOfferId: p.rail.pricings.find((pr) => pr.selected)?.offerIdReference,
                            };
                            break;
                    }
                });
            }
            return {
                TripId: this.trip.id,
                SelectedLanguage: this.shareForm.language,
                SearchCacheKey: searchCacheKey,
                returnCacheKey: returnCacheKey,
                PriceOfferCacheKey: null,
                changes: null,
                Products: products,
            };
        },
        getItineraryOpts() {
            const quoteIds = this.relevantQuotes?.map((q) => q.quoteId);
            const opts = {
                TripId: this.trip.id,
                SelectedLanguage: this.shareForm.language,
                QuotesIds: quoteIds,
            };
            const compensationQuotes = this.relevantQuotes.filter((q) => q.product === Products.Compensation);
            if (compensationQuotes?.length) {
                const displayProjectUnit = compensationQuotes[0]?.compensationDetails.DisplayProjectUnit || 'Tonne';
                opts.quotes = this.relevantQuotes;
                opts.displayProjectUnit = displayProjectUnit;
            }
            return opts;
        },
        getQuotesOpts() {
            const products = this.relevantQuotes?.map((q) => {
                const currProductType = q.product;
                return {
                    Product: currProductType,
                    QuoteId: q.quoteId,
                    CacheKey: null,
                    ProductCacheKey: null,
                };
            });
            return {
                TripId: this.trip.id,
                SelectedLanguage: this.shareForm.language,
                SearchCacheKey: null,
                PriceOfferCacheKey: null,
                changes: null,
                Products: products,
            };
        },
        getReturnSearchKey() {
            const productType = this.productsToShare.type || null;
            let cacheKey = null;
            switch (productType) {
                case Products.Amtrak:
                    cacheKey = this.$store.getters['amtrakStore/amtrakAnswer']?.returnCacheKey || null;
                    break;
            }
            return cacheKey;
        },
        getSearchCacheKey() {
            const productType = this.productsToShare.type || null;
            let cacheKey = null;
            switch (productType) {
                case Products.Hotel:
                    cacheKey = this.$route.params.searchCacheKey || null;
                    break;
                case Products.Parking:
                    cacheKey = this.$store.getters['parkingStore/parkingRes']?.cacheKey || null;
                    break;
                case Products.Car:
                    cacheKey = this.$store.getters['carStore/carRes']?.cacheKey || null;
                    break;
                case Products.EuRail:
                    cacheKey = this.$store.getters['euRailStore/railsAnswer']?.cacheKey || null;
                    break;
                case Products.Amtrak:
                    cacheKey = this.$store.getters['amtrakStore/amtrakAnswer']?.cacheKey || null;
                    break;
                case Products.Flight:
                    // since "by Schedule", flight results format changed from object with cacheKey to array of objects- each of the objects with it's own cacheKey.
                    // Thus, in order to receive the cacheKey we need to know the index of the flight results.
                    // In "complete trip" it will always be "0"; in "by schedule" it'll be the index of the current segment that we're on.
                    cacheKey = this.$store.getters['flightStore/cacheKey'](this.productsToShare.products[0].resultsIndex) || null;
                    break;
            }
            return cacheKey;
        },
        async share() {
            if (!this.canSendPriceOffer && this.shareType !== ShareTypes.Itinerary) {
                return;
            }
            this.markFields = true;
            if (this.v$.shareForm.$invalid) {
                return;
            }
            this.isSendBtnLoading = true;
            const addresses = this.shareForm.recipients.map((r) => {
                return r.email;
            });
            const opts = {
                TripId: this.trip.id,
                IsAttachPdf: this.shareForm.includePdf,
                SelectedLanguage: this.shareForm.language,
                Subject: this.shareForm.subject,
                Message: this.shareForm.msg,
                ToAdresses: addresses,
            };
            try {
                if (this.shareType !== ShareTypes.Itinerary) {
                    opts.IsRemoveHeaders = this.shareForm.removeHeaders;
                    opts.PriceOfferDetailsType = this.shareForm.mailType;
                    opts.PriceOfferCacheKey = this.emailRes.cacheKey;
                    await tripService.sendPriceOfferMail(opts);
                }
                else {
                    opts.ItineraryCacheKey = this.emailRes.cacheKey;
                    await tripService.sendTripItineraryMail(opts);
                }
                if (this.canShareQuoteCombinedWithAddToCart) {
                    this.addToCart();
                }
                this.close();
                alertService.success('common.sentSuccessfully');
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isSendBtnLoading = false;
            }
        },
        async addToCart() {
            if (this.productsToShare && this.productsToShare.products?.length > 0) {
                if (this.productsToShare.products.filter((p) => p.rail && !p.rail.isRestricTravelPolicyViolation).length > 0) {
                    //will be implemented as part US
                }
                else {
                    try {
                        // flights is exceptional case when we send several flights at once
                        const flightProducts = this.productsToShare.products.filter((item) => item.flight);
                        if (flightProducts && flightProducts.length > 0) {
                            await this.addFlight(flightProducts);
                        }
                        // other products are sent one item per request
                        const prms = this.productsToShare.products
                            .map((item) => {
                            if (item.hotel && !item.hotel.isRestricTravelPolicyViolation) {
                                return this.addHotel(item);
                            }
                            if (item.car && !item.car.isRestricTravelPolicyViolation) {
                                return this.addCar(item);
                            }
                            if (item.parking && !item.parking.isRestricTravelPolicyViolation) {
                                return this.addParking(item);
                            }
                        })
                            .filter((pr) => pr !== undefined);
                        await Promise.all(prms);
                    }
                    catch (err) {
                        loggerService.error(err);
                    }
                }
            }
        },
        async addHotel(item) {
            const hotelCacheKey = item.hotel.cacheKey;
            let room = utilService.deepClone(item.room);
            try {
                if (room.supplierId.startsWith(this.SuppliersId[this.SuppliersId.AMADEUS]) && !room.termsChecked) {
                    room = await hotelService.checkIfRoomRestrictedRefundRule(room); //this function can modify the room
                    await this.$store.dispatch({
                        type: 'roomStore/roomChanged',
                        updatedRoom: await hotelService.checkIfRoomRestrictedRefundRule(room),
                    });
                }
                if (!room.isRestricTravelPolicyViolation) {
                    await this.$store.dispatch({
                        type: 'roomStore/selectRoom',
                        roomCacheKey: room.cacheKey,
                        hotelCacheKey,
                        isSharedMode: true,
                    });
                    item.hotel.wasAddedToCard = true;
                }
                else {
                    eventBus.$emit('showHotelRoomRestrictedSelectionPopup');
                }
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async addFlight(items) {
            try {
                await this.$store.dispatch({
                    type: 'flightStore/selectMultiFlights',
                    packageCacheKeys: items.map((i) => i.flight.packageKey),
                    resultsIndex: items[0].resultsIndex,
                });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async addCar(item) {
            try {
                await this.$store.dispatch({ type: 'carStore/selectCar', car: item.car, isSharedMode: true });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async addParking(item) {
            try {
                await this.$store.dispatch({ type: 'parkingStore/selectParking', parking: item.parking, isSharedMode: true });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        addExistingPassengers() {
            //add existing passengers to share form
            const passengers = this.trip.passengers;
            if (passengers.length) {
                const isEmail = email.$validator;
                // validate every passenger has a valid email
                const recipients = passengers.filter((p) => {
                    return isEmail(p.email);
                });
                if (this.shareForm.booker?.email) {
                    const isValidBooker = isEmail(this.shareForm.booker.email);
                    if (isValidBooker) {
                        this.shareForm.recipients = [this.shareForm.booker];
                    }
                }
                else {
                    this.shareForm.recipients = recipients;
                }
            }
        },
        selectUser(user) {
            const existingUser = this.shareForm.recipients.find((r) => {
                return r.id === user.id || r.email === user.email;
            });
            if (!existingUser) {
                this.shareForm.recipients.push(user);
            }
        },
        removeRecipient(id) {
            const idx = this.shareForm.recipients.findIndex((user) => {
                return user.id === id;
            });
            if (idx !== -1) {
                this.shareForm.recipients.splice(idx, 1);
            }
        },
        languageChanged(lang) {
            this.loadData();
        },
        async copyToClipboard() {
            let html = this.emailContent;
            html = html.replace(/<head>[\s\S]*?<\/head>/i, ''); // remove the <head> section when copying the html (other wise will cause error) - this wont affect the styling of the html when pasting into email client (such as gmail).
            const type = 'text/html';
            const blob = new Blob([html], { type });
            const data = [new ClipboardItem({ [type]: blob })];
            await navigator.clipboard.write(data);
            this.isCopiedToClipboard = true;
            alertService.success(this.$t('common.copiedToClipboard').toString());
            if (this.canShareQuoteCombinedWithAddToCart) {
                this.addToCart();
            }
        },
        close() {
            this.$emit('close');
        },
    },
    computed: {
        //  languageOpts : L
        languageOpts() {
            return this.languages?.map((l) => ({ value: l.code, label: l.name }));
        },
        languages() {
            return this.$store.getters.languages;
        },
        emailContent() {
            if (!this.emailRes) {
                return '';
            }
            if (this.shareType === ShareTypes.Itinerary) {
                return this.emailRes.content;
            }
            return this.emailRes.emailContentList.find((e) => {
                return e.ResponseEmailType === this.ResponseEmailType;
            }).content;
        },
        ResponseEmailType() {
            const mailType = this.shareForm.mailType;
            const withHeaders = !this.shareForm.removeHeaders;
            let emailType = 0;
            if (mailType === PriceOfferDetailsType.Summary) {
                emailType = withHeaders ? ResponseEmailType.WithHeadersSummary : ResponseEmailType.NoHeadersSummary;
            }
            else if (mailType === PriceOfferDetailsType.Details) {
                emailType = withHeaders ? ResponseEmailType.WithHeadersDetails : ResponseEmailType.NoHeadersDetails;
            }
            else if (mailType === PriceOfferDetailsType.Both) {
                emailType = withHeaders ? ResponseEmailType.WithHeadersBoth : ResponseEmailType.NoHeadersBoth;
            }
            return emailType;
        },
        priceOfferDetailsType() {
            return PriceOfferDetailsType;
        },
        quotes() {
            return this.$store.getters['tripStore/quotes'];
        },
        relevantQuotes() {
            return this.quotes?.filter((q) => {
                return (q.status !== QuoteStatus.Cancelled &&
                    q.status !== QuoteStatus.Expired &&
                    q.status !== QuoteStatus.Deleted &&
                    q.isChosen);
            });
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        productsToShare() {
            return this.$store.getters['tripStore/productsToShare'];
        },
        canSendPriceOffer() {
            const user = this.$store.getters['authStore/loggedinUser'];
            return user.permissions.includes('CanViewPriceOffer');
        },
        canShareQuoteCombinedWithAddToCart() {
            const user = this.$store.getters['authStore/loggedinUser'];
            return user.permissions.includes('ShareQuoteCombinedWithAddToCart');
        },
        shareTypes() {
            return ShareTypes;
        },
        isFirefox() {
            return navigator.userAgent.indexOf('Firefox') !== -1;
        },
        hotelSearchOptions() {
            return this.$store.getters['hotelStore/searchOptions'];
        },
    },
    validations() {
        return {
            shareForm: {
                recipients: { required },
            },
        };
    },
});
