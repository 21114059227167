import { format, parse, getTime, parseISO, compareDesc } from 'date-fns';
import { httpService } from '@/services/http.service';
import { utilService } from '@/services/util.service';
import { airportService } from '@/services/airport.service';
import { flightStore } from '@/modules/products/flight/store/flight.store';
import * as flightConsts from '@/modules/products/flight/models/consts';
import { SortByTypes } from '@/modules/products/flight/models/consts';
import { $t } from '@/plugins/i18n';
import { loggerService } from '@/services/logger.service';
import { SuppliersId } from '../models/consts';
export const flightService = {
    searchFlight,
    filterFlights,
    selectFlight,
    selectMultiFlight,
    searchFlightDetails,
    searchFlightDetailsFromCart,
    searchFareRules,
    searchPricingOptions,
    getFlightFilterCheckboxes,
    formatFlightFareRules,
    getSeatMap,
    searchFareRulesFromCart,
    searchEuRailOptions,
    getFlightSearchDefault,
    getBaggage,
    saveBaggage,
    getCngCxlFees,
    getFormOfPayment,
    getFrequentFlyerSavedData,
    getMeals,
    saveMeals,
    getOptionalServices,
    saveOptionalServices,
    getContactDetails,
    saveContactDetails,
    getPassengerInfo,
    savePassengerInfo,
    getSeating,
    saveSeatsFromCart,
    getFlightCarriers,
    saveFrequentFlyer,
    getFlightAncillaryOptions,
    saveFlightAncillaryOptions,
    getFlightAncillaryServices,
    saveAncillaryServicesRequest,
    getSpeedyBoarding,
    saveSpeedyBoarding,
    saveLowCostTerms,
    holdFlight,
    getHandLuggage,
    saveHandLuggage,
    getFrequentFlyerCodes,
    getFlightSeats,
    getFlightParallelSearch,
    getFlightSuppliers,
    getResultMap,
    searchSingleSegmentForBySchedule,
    getBySchedulePricing,
    getHideComparisonTableStatus,
    setHideComparisonTableStatus,
    getBrandedFares,
    getQuoteBrandedFares,
    getAllAirports,
    syncFlightQuote,
    searchFlightExchange,
    searchFlightExchangeNDC,
    selectFlightExchange,
    cancelFlightExchange,
    getSortOptions,
    searchFareRulesFromUnusedTicket,
    calculateUnusedTicketPriceBreakdown,
    flightTravelPolicy,
    getRefundInfo,
    checkPrice,
    getFlightTimeOption,
    isQuoteNDC,
};
async function getAllAirports() {
    const allAirports = await airportService.getAllAirports(false);
    return allAirports;
}
async function getFlightCarriers() {
    return httpService.get('/flightCarriers', null);
}
async function saveFrequentFlyer(opts) {
    return httpService.post('/FlightFrequentFlyerSaveMulti', opts);
}
async function searchFlightDetails(searchOptions) {
    return httpService.post('/flightDetails', searchOptions);
}
async function searchFlightDetailsFromCart(quoteId) {
    return httpService.get(`/flightDetails?quoteId=${quoteId}`, null);
}
async function searchFareRules(searchOptions) {
    return httpService.post('/flightFareRules', searchOptions);
}
async function getBrandedFares(searchOptions) {
    return httpService.post('/flightBrandingDetails', searchOptions);
}
async function checkPrice(dataForComparison, searchData) {
    // CHANGE THE ANY IN THE RETURN TYPE
    const queryString = encodeURIComponent(JSON.stringify(dataForComparison));
    return httpService.post(`/flightCheckPrice?query=${queryString}`, searchData, { timeout: 480000 });
}
async function getQuoteBrandedFares(tripId, quoteId, brandId, carrier, brandName) {
    return httpService.get(`/flightBrandingDetails?tripId=${tripId}&quoteId=${quoteId}&brandId=${brandId}&carrier=${carrier}&brandName=${brandName}`, null);
}
/// -------------[ FlightSeats ]------------
async function getSeatMap(options) {
    return httpService.post('/FlightSeats?isMap=true', options);
}
async function getFlightSeats(quoteId) {
    return httpService.get(`/FlightSeats?quoteId=${quoteId}`, null);
}
async function saveSeatsFromCart(flightSelectSeatsCart) {
    return httpService.put('/FlightSeats', flightSelectSeatsCart);
}
/// -------------[ FlightSeats end ]------------
async function searchFareRulesFromCart(quoteId) {
    return httpService.get(`/flightFareRules?quoteId=${quoteId}`, null);
}
async function searchFareRulesFromUnusedTicket(unusedTicket) {
    return httpService.post('/flightFareRules?fromUnusedTicket=true', unusedTicket);
}
async function calculateUnusedTicketPriceBreakdown(unusedTicketPriceBreakdownRequest) {
    return httpService.post('/ticketAutomatedExchangeQuote', unusedTicketPriceBreakdownRequest, { timeout: 120000 });
}
async function saveSpeedyBoarding(quoteId, selected) {
    httpService.post('/flightSpeedyBoarding', { quoteId, selected });
}
async function flightTravelPolicy(request) {
    return httpService.post('/calculateTravelPolicy', request, { timeout: 1200000 });
}
async function getRefundInfo(quoteId) {
    return httpService.get(`/flightRefundInfo?quoteId=${quoteId}`, { timeout: 1200000 });
}
async function getFlightParallelSearch(destinationCodes, productName) {
    return httpService.post('/HotelMultiCity', { Codes: destinationCodes, Product: productName });
}
async function getSpeedyBoarding(quoteId) {
    //const temp : ISpeedyBoardingResponse  = require('@/temp/JSONs/flightSpeedyBoarding.json');
    //return temp;
    return httpService.get(`/flightSpeedyBoarding?quoteId=${quoteId}`, null);
}
async function getFlightAncillaryServices(quoteId) {
    //const hotelSearchResponse : IFusionBaggageResponse | IBaggageResponse = require('@/temp/JSONs/flightBaggage.json');
    //return hotelSearchResponse;
    return httpService.get(`/flightAncillaryServices?quoteId=${quoteId}`, null);
}
async function getFlightSuppliers() {
    const suppliers = await httpService.get('/flightsuppliers', null);
    return suppliers || [];
}
async function getFlightAncillaryOptions(quoteId) {
    //const ancillaryOptionsResponse : AncillaryOptionsResponse  = require('@/temp/JSONs/flightAncillaryOptions.json');
    //return ancillaryOptionsResponse;
    return httpService.get(`/flightAncillaryOptions?quoteId=${quoteId}`, null);
}
async function saveAncillaryServicesRequest(saveRequest) {
    return httpService.post('/flightAncillaryServices', saveRequest);
}
async function saveFlightAncillaryOptions(saveRequest) {
    return httpService.post('/flightAncillaryOptions', saveRequest);
}
async function saveHandLuggage(saveRequest) {
    return httpService.post('/flightHandLuggage', saveRequest);
}
async function getHandLuggage(quoteId) {
    return httpService.get(`/flightHandLuggage?quoteId=${quoteId}`, null);
}
async function getBaggage(quoteId) {
    return httpService.get(`/flightBaggage?quoteId=${quoteId}`, null);
}
async function saveBaggage(saveRequest) {
    return httpService.post('/flightBaggage', saveRequest);
}
async function getMeals(quoteId) {
    return httpService.get(`/FlightMeals?quoteId=${quoteId}`, null);
}
async function saveMeals(opts) {
    return httpService.post('/FlightMeals', opts);
}
async function getOptionalServices(quoteId) {
    return httpService.get(`/flightOptionalServices?quoteId=${quoteId}`, null);
}
async function saveOptionalServices(opts) {
    return httpService.post('/flightOptionalServices', opts);
}
async function getPassengerInfo(quoteId, paxId) {
    return httpService.get(`/FlightPassengerInfo?quoteId=${quoteId}&paxId=${paxId}`, null);
}
async function savePassengerInfo(opts) {
    return httpService.post('/FlightPassengerInfo', opts);
}
async function getContactDetails(quoteId) {
    return httpService.get(`/flightContactDetails?quoteId=${quoteId}`, null);
}
async function saveContactDetails(opts) {
    return httpService.post('/flightContactDetails', opts);
}
async function getFrequentFlyerSavedData(quoteId, paxId) {
    return httpService.get(`/flightFrequentFlyer?quoteId=${quoteId}&paxId=${paxId}`, null);
}
async function saveLowCostTerms(opts) {
    return httpService.post('/lowCostTerms', opts);
}
async function holdFlight(opts) {
    return httpService.post('/hold', opts, { timeout: 120000 });
}
async function syncFlightQuote(opts) {
    return httpService.post('/flightquotesync', opts, { timeout: 120000 });
}
async function searchFlightExchange(opts) {
    return httpService.post('/flightTicketExchangeSearch', opts, { timeout: 120000 });
}
async function searchFlightExchangeNDC(opts) {
    return httpService.post('/flightSearchAvailability', opts, { timeout: 120000 });
}
async function selectFlightExchange(opts) {
    return httpService.put('/flightTicketExchangeSearch', opts, { timeout: 120000 });
}
async function cancelFlightExchange(quoteId) {
    return httpService.delete(`/flightTicketExchangeSearch?quoteId=${quoteId}`, null, { timeout: 120000 });
}
async function getFrequentFlyerCodes(tripId, paxId, type = 'flight') {
    return httpService.get(`/flightFrequentFlyerCodes?tripId=${tripId}&corporateUserId=${paxId}&type=${type}`, null);
}
async function getHideComparisonTableStatus(tripId) {
    return httpService.get(`/hideComparisonTable?tripId=${tripId}`, null);
}
async function setHideComparisonTableStatus(tripId, hideComparisonTable) {
    return httpService.post(`/hideComparisonTable?tripId=${tripId}&hideComparisonTable=${hideComparisonTable}`, null);
}
async function getSeating(quoteId) {
    return {}; //TODO: ???????
}
async function getFormOfPayment(quoteId) {
    return {}; //TODO: ???????
}
async function getCngCxlFees(quoteId) {
    return {}; //TODO: ???????
}
async function searchEuRailOptions(searchOptions) {
    // TODO: any is temp
    const euRailOptionsRes = await httpService.post('/flightEURailSearchParallel', searchOptions);
    return euRailOptionsRes || [];
}
async function getBySchedulePricing(searchOptions) {
    const pricingOptionsRes = await httpService.post('/flightPricing', searchOptions, {
        timeout: 240000, // TODO - this should be temp until backend fixes the long request time issue in more complex cases(i.e 5 segments in multiCity search)
    });
    return pricingOptionsRes;
}
async function searchPricingOptions(searchOptions) {
    const pricingOptionsRes = await httpService.post('/flightPricing', searchOptions, {
        timeout: 120000,
    });
    if (!pricingOptionsRes?.results) {
        return {};
    }
    const pricingOptionsResults = pricingOptionsRes.results;
    // mapping cabin class to name
    const mapCabin = {
        ECONOMY: 'Economy',
        Economy: 'Economy',
        EconomyPremium: 'Economy',
        ECONOMYPREMIUM: 'Economy',
        EconomyWithRestrictions: 'Economy',
        ECONOMYWITHRESTRICTIONS: 'Economy',
        EconomyWithoutRestrictions: 'Economy',
        ECONOMYWITHOUTRESTRICTIONS: 'Economy',
        PremiumEconomy: 'Premium',
        PREMIUMECONOMY: 'Premium',
        PREMIUM: 'Premium',
        Premium: 'Premium',
        Business: 'Business',
        BUSINESS: 'Business',
        First: 'First',
        FIRST: 'First',
    };
    const mapCabinOrder = {
        Economy: 0,
        ECONOMY: 0,
        EconomyPremium: 0,
        ECONOMYPREMIUM: 0,
        PREMIUM: 0,
        Premium: 0,
        EconomyWithRestrictions: 0,
        ECONOMYWITHRESTRICTIONS: 0,
        EconomyWithoutRestrictions: 0,
        ECONOMYWITHOUTRESTRICTIONS: 0,
        PremiumEconomy: 1,
        PREMIUMECONOMY: 1,
        Business: 2,
        BUSINESS: 2,
        First: 3,
        FIRST: 3,
    };
    const cleanOptions = []; // clean the results, keep only relevant data
    let tabsData = [];
    // build results array for only the attributes we need
    pricingOptionsResults.forEach((option) => {
        if (!option) {
            return;
        }
        const workingOption = {};
        if (option.LastTktDate && option.LastTktDate.indexOf('Z') === -1) {
            option.LastTktDate = option.LastTktDate + 'Z';
        }
        workingOption.isRestricTravelPolicyViolation = option.isRestricTravelPolicyViolation;
        workingOption.packageKey = option.packageKey;
        workingOption.totalPriceDisplayCurrency = +option.totalPriceDisplayCurrency.toFixed(2); //this is not accurate
        workingOption.avgPricePerPersonDisplayCurrency = +option.avgPricePerPersonDisplayCurrency.toFixed(2); //this is not accurate
        workingOption.agreementType = option.agreementType || 0; // 0 None, 1 TMC, 2 Corp
        workingOption.agreementToolTip = option.agreementToolTip;
        workingOption.agreementIcon = option.agreementIcon;
        workingOption.isMarine = option.isMarine;
        workingOption.brandId = option.brandId;
        workingOption.isHasMarineAlternative = option.isHasMarineAlternative;
        workingOption.displayCurrency = option.displayCurrency;
        workingOption.currency = option.currency;
        workingOption.avgPricePerPerson = +option.avgPricePerPerson.toFixed(2);
        workingOption.totalPrice = +option.totalPrice.toFixed(2);
        workingOption.travelPolicyViolations = option.travelPolicyViolations;
        workingOption.isOneWayPackage = option.isOneWayPackage;
        workingOption.lastTktDate = option.lastTktDate;
        workingOption.nextOfficeHoursExpiration = option.nextOfficeHoursExpiration;
        workingOption.isLowCost = option.isLowCost;
        workingOption.isHideBrandRule = option.isHideBrandRule;
        workingOption.isNDC = option.isNDC;
        workingOption.segments = [];
        option.segments.forEach((segment) => {
            // Find the first flight with a defined brandName
            const firstDefinedBrandName = segment.flights.find((flight) => flight.brandName)?.brandName;
            // Update each flight's brandName if undefined
            segment.flights.forEach((flight) => {
                if (!flight.brandName) {
                    flight.brandName = firstDefinedBrandName;
                }
            });
            // lets see if all flights have same brand (if not.. we will delete it and error in console)
            const isSegmentRelevant = segment.flights.every((flight) => flight.brandName === segment.flights[0].brandName);
            if (isSegmentRelevant) {
                tabsData.push({
                    origin: segment.flights[0].origin,
                    destCode: segment.flights[segment.flights.length - 1].destCode,
                    carrierName: segment.flights[0].carrierName,
                    carrier: segment.flights[0].carrier,
                });
                const workingSegment = {};
                workingSegment.flights = [];
                // lets find the longest flight and it's cabin class to place this segment in
                const longest = segment.flights.reduce((previous, current) => {
                    return current.flightDuration.totalMinutes > previous.flightDuration.totalMinutes ? current : previous;
                });
                workingSegment.cabinName = mapCabin[`${longest.flightClassDesc.replaceAll(' ', '')}`]; // this is the longest duration flight's cabin
                workingSegment.cabinClassOrder = mapCabinOrder[`${longest.flightClassDesc.replaceAll(' ', '')}`]; // this is the longest duration flight's cabin
                workingSegment.baggage = longest?.baggage?.replace(' ', '');
                workingSegment.brandName = longest?.brandName || `${workingSegment.cabinName} (${longest.flightClass})`; // if missing brandName, create one from cabin and class - "Economy (Q)""
                workingSegment.brandName = workingSegment.brandName.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase()); // lower case and uppercase the first letter, but wont touch the class letter
                workingSegment.brandId = workingOption.brandId;
                workingSegment.isHideBrandRule = option.isHideBrandRule;
                workingSegment.brandDesc = `${workingSegment.cabinName || longest.flightClassDesc || ''} (${longest.flightClass})`;
                workingSegment.totalPriceDisplayCurrency = workingOption.totalPriceDisplayCurrency;
                workingSegment.totalPrice = workingOption.totalPrice;
                workingSegment.avgPricePerPerson = +workingOption.avgPricePerPerson;
                workingSegment.avgPricePerPersonDisplayCurrency = +workingOption.avgPricePerPersonDisplayCurrency;
                workingSegment.isOneWayPackage = workingOption.isOneWayPackage;
                workingSegment.isLowCost = workingOption.isLowCost;
                workingSegment.isNDC = workingOption.isNDC;
                workingSegment.lastTktDate = workingOption.lastTktDate;
                workingSegment.nextOfficeHoursExpiration = workingOption.nextOfficeHoursExpiration;
                workingSegment.currency = workingOption.currency;
                workingSegment.displayCurrency = workingOption.displayCurrency;
                workingSegment.travelPolicyViolations = workingOption.travelPolicyViolations;
                workingSegment.packageKey = workingOption.packageKey;
                if (option.segments.some((segment) => segment.agreementName)) {
                    workingOption.isAgreementBySegment = true;
                    if (segment.agreementName) {
                        workingSegment.agreementType = workingOption.agreementType;
                        workingSegment.agreementToolTip = workingOption.agreementToolTip;
                        workingSegment.agreementIcon = workingOption.agreementIcon;
                    }
                    else {
                        workingSegment.agreementType = 0;
                    }
                }
                else {
                    workingSegment.agreementType = workingOption.agreementType;
                    workingSegment.agreementToolTip = workingOption.agreementToolTip;
                    workingSegment.agreementIcon = workingOption.agreementIcon;
                }
                workingSegment.isMarine = workingOption.isMarine;
                workingSegment.isHasMarineAlternative = workingOption.isHasMarineAlternative;
                workingSegment.destinationCityName = segment.destinationCityName;
                workingSegment.isGreenBrand = segment.isGreenBrand;
                // done prepare segment shit
                segment.flights.forEach((flight) => {
                    const workingFlight = {};
                    workingFlight.cabin = flight.cabin;
                    workingFlight.cabinName = mapCabin[`${flight.flightClassDesc.replaceAll(' ', '')}`];
                    workingFlight.flightClass = flight.flightClass;
                    workingFlight.brandName = flight?.brandName || `${workingFlight.cabinName} (${workingFlight.flightClass})`; // if missing brandName, create one from cabin and class - "Economy (Q)""
                    workingFlight.brandName = workingFlight.brandName.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase()); // lower case and uppercase the first letter, but wont touch the class letter
                    workingFlight.brandDesc = `${workingFlight.cabinName} (${workingFlight.flightClass})`;
                    workingFlight.origin = flight.origin;
                    workingFlight.destCode = flight.destCode;
                    workingFlight.baggage = flight.baggage?.replace(' ', '');
                    workingSegment.flights.push(workingFlight);
                });
                workingOption.segments.push(workingSegment);
            }
            else {
                workingOption.delete = true;
                loggerService.error('brandName is not equal in all flights in this option: ', JSON.stringify(option));
            }
        });
        if (!workingOption.delete) {
            cleanOptions.push(workingOption);
        }
    });
    tabsData = tabsData.filter((value, index, self) => index === self.findIndex((t) => t.origin === value.origin && t.destCode === value.destCode));
    return { pricingOptions: cleanOptions, tabs: tabsData, originalPricingOptions: pricingOptionsResults };
}
async function getFlightSearchDefault(searchOptions) {
    return httpService.post('/flightSearchDefault', searchOptions);
}
async function searchFlight(searchRequest, cacheKey) {
    // TODO: any is temp
    const flightsRes = await httpService.post(`/flightSearch?query=${cacheKey}`, searchRequest, {
        timeout: 120000,
    });
    const flightResults = flightsRes.results ? [flightsRes] : flightsRes;
    const { resultMap } = getResultMap(flightResults[0].results);
    return { flightsRes: flightResults, resultMap };
}
async function searchSingleSegmentForBySchedule(searchOptions) {
    // TODO: any is temp
    const singleSegmentFlightsRes = await httpService.post('/flightSearch', searchOptions);
    const { resultMap } = getResultMap(singleSegmentFlightsRes.results);
    return { singleSegmentFlightsRes, resultMap };
}
async function selectFlight(flightSelectParams) {
    return httpService.post('/FlightSelect', flightSelectParams, { timeout: 120000 });
}
async function selectMultiFlight(flightSelectParams) {
    return httpService.put('/FlightSelect', flightSelectParams, { timeout: 120000 });
}
function formatFlightFareRules(fareRules) {
    // maybe in the future we will format fare rules received from backend
    return fareRules;
}
function isQuoteNDC(quote) {
    let isNDC = false;
    isNDC =
        ([SuppliersId.UAPIGALILEO, SuppliersId.AMADEUS, SuppliersId.SABRE].includes(quote.sourceSystem) &&
            quote.isNDCGDSOffer) ||
            [
                SuppliersId.NDCBA,
                SuppliersId.AFKL,
                SuppliersId.IBERIA,
                SuppliersId.LUFTHANSA,
                SuppliersId.AMERICANAIRLINE,
                SuppliersId.UNITED,
            ].includes(quote.sourceSystem);
    return isNDC;
}
/*eslint complexity: ["error", 40]*/
function filterFlights(flights, filterBy) {
    let filteredFlights = utilService.deepClone(flights);
    const cityAirportMap = flightStore.state.cityAirportMap
        ? utilService.deepClone(flightStore.state.cityAirportMap)
        : null;
    // filter carrier and flight number by text
    if (filterBy.q) {
        let searchArr = filterBy.q.split(',');
        searchArr = searchArr.filter((searchTerm) => searchTerm);
        filteredFlights = filteredFlights.filter((flightResult) => {
            let isSearchTextMatch = false;
            for (let i = 0; i < searchArr.length; i++) {
                const searchRex = new RegExp(searchArr[i].trim(), 'i');
                isSearchTextMatch = flightResult.segments.some((segment) => segment.flights.some((flight) => searchRex.test(flight.carrierName) || searchRex.test(flight.carrier + flight.flightNumber)));
                if (!isSearchTextMatch) {
                    return false;
                }
            }
            return isSearchTextMatch;
        });
    }
    // filter by policy
    if (filterBy.inPolicy) {
        filteredFlights = filteredFlights.filter((flightResult) => !flightResult.travelPolicyViolations.length);
    }
    // Filter by unused tickets
    if (filterBy.hasUnusedTickets?.length > 0) {
        filteredFlights = filteredFlights.filter((flightResult) => flightResult.hasUnusedTicket);
    }
    // filter by number of stops
    if (filterBy.stops?.length) {
        // TODO: change this condition because we need the option to enter with an empty stops array
        let allFilteredFlights = [];
        filterBy.stops.forEach((stopsChoice) => {
            const relevantFlights = filteredFlights.filter((flightResult) => {
                if (stopsChoice === 'No stops') {
                    return flightResult.segments.every((flightSegment) => !flightSegment.stops.length);
                }
                else {
                    return (flightResult.segments.some((flightSegment) => flightSegment.stops.length === +stopsChoice.substring(0, 1)) &&
                        flightResult.segments.every((flightSegment) => flightSegment.stops.length <= +stopsChoice.substring(0, 1)));
                }
            });
            allFilteredFlights = allFilteredFlights.concat(relevantFlights);
        });
        filteredFlights = allFilteredFlights.filter((v, i, a) => a.findIndex((v2) => v.packageKey === v2.packageKey) === i);
    }
    if (filterBy.stopsDurationRange?.length) {
        filteredFlights = filteredFlights.filter((flightResult) => {
            return ((flightResult.layoverDuration[0] >= filterBy.stopsDurationRange[0] &&
                flightResult.layoverDuration[0] > 0 &&
                flightResult.layoverDuration[1] < filterBy.stopsDurationRange[1] + 1) ||
                (flightResult.layoverDuration[0] === 0 &&
                    flightResult.layoverDuration[1] >= filterBy.stopsDurationRange[0] &&
                    flightResult.layoverDuration[1] <= filterBy.stopsDurationRange[1]) ||
                ((flightResult.layoverDuration[0] === 0 || flightResult.layoverDuration[1] === 0) &&
                    filterBy.stopsDurationRange[0] === filterBy.stopDurationMinMax[0] &&
                    filterBy.stopsDurationRange[1] === filterBy.stopDurationMinMax[1]));
        });
    }
    // filter by advisories
    if (filterBy?.advisories && Object.values(filterBy?.advisories)?.flat()?.length) {
        filteredFlights = filteredFlights.filter((flightResult) => {
            for (const [index, [cityName, advisoryData]] of Object.entries(Object.entries(filterBy.advisories))) {
                const relevantSegment = flightResult.segments.find((segment) => cityAirportMap[segment.flights[0].origin] === cityName);
                if (!relevantSegment && +index < Object.keys(filterBy.advisories).length - 1) {
                    continue;
                }
                else if (!relevantSegment) {
                    return true;
                }
                const checkedAdvisories = advisoryData.map((advisory) => Object.keys(flightConsts.FlightAdvisoriesDisplayNames).find((key) => advisory.includes(flightConsts.FlightAdvisoriesDisplayNames[key])));
                const isFlightSegmentRelevant = relevantSegment.advisories.some((advisory) => checkedAdvisories.includes(advisory.imageClass));
                if (isFlightSegmentRelevant) {
                    return true;
                }
                else if (!isFlightSegmentRelevant && +index !== flightResult.segments.length - 1) {
                    continue;
                }
                else {
                    return false;
                }
            }
        });
    }
    // filter by airports
    if (filterBy?.airports &&
        Object.values(filterBy.airports)?.some((filterSegment) => filterSegment && Object.values(filterSegment)?.some((airportArray) => airportArray?.length))) {
        filteredFlights = filteredFlights.filter((flightResult) => {
            return Object.entries(filterBy.airports).every(([segmentIndex, segmentFilter]) => {
                const currSegment = flightResult.segments[segmentIndex];
                if (!currSegment) {
                    return;
                }
                const segmentOrigin = currSegment.flights[0]?.origin || '';
                const segmentDestination = currSegment.flights[currSegment.flights.length - 1]?.destCode || '';
                return Object.values(segmentFilter).every((airports) => !airports?.length || airports.includes(segmentOrigin) || airports.includes(segmentDestination));
            });
        });
    }
    // filter by carrier
    if (filterBy.carriers?.length) {
        const isFilterMultiCarrier = filterBy.carriers.includes('Multi carrier') ? true : false;
        if (isFilterMultiCarrier) {
            filteredFlights = filteredFlights.filter((flightResult) => {
                const firstFlightResultCarrier = flightResult.segments[0].flights[0].carrierName;
                const isFlightResultMultiCarrier = !flightResult.segments.every((segment) => segment.flights.every((flight) => flight.carrierName === firstFlightResultCarrier));
                return (isFlightResultMultiCarrier ||
                    flightResult.segments.some((flightSegment) => flightSegment.flights.every((flight) => filterBy.carriers.includes(flight.carrierName))));
            });
        }
        else {
            let allFilteredFlights = [];
            for (let i = 0; i < filterBy.carriers.length; i++) {
                const tempFilteredFlights = filteredFlights.filter((flightResult) => {
                    return flightResult.segments.every((flightSegment) => flightSegment.flights.every((flight) => flight.carrierName === filterBy.carriers[i]));
                });
                allFilteredFlights = [...allFilteredFlights, ...tempFilteredFlights];
            }
            filteredFlights = allFilteredFlights;
        }
    }
    // filter by price range
    if (filterBy.priceRange?.length) {
        filteredFlights = filteredFlights.filter((flightResult) => {
            return (flightResult.totalPriceDisplayCurrency >= filterBy.priceRange[0] &&
                flightResult.totalPriceDisplayCurrency < filterBy.priceRange[1] + 1);
        });
    }
    // filter by Agreement type
    if (filterBy.agreementTypes?.length) {
        filteredFlights = filteredFlights.filter((flightResult) => filterBy.agreementTypes.includes(flightConsts.AgreementType[flightResult.agreementType]) ||
            (filterBy.agreementTypes.includes('Marine fare') && flightResult.isMarine) ||
            flightResult.isHasMarineAlternative);
    }
    // filter by departure time range
    if (filterBy?.departTimeRange && Object.values(filterBy?.departTimeRange)?.length) {
        filteredFlights = filteredFlights.filter((flightResult) => {
            for (const [index, [cityName, minMaxRange]] of Object.entries(Object.entries(filterBy.departTimeRange))) {
                const relevantSegment = flightResult.segments.find((segment) => cityAirportMap[segment.flights[0].origin] === cityName || segment.flights[0].origin === cityName);
                if (!relevantSegment && +index < Object.keys(filterBy.departTimeRange).length - 1) {
                    continue;
                }
                else if (!relevantSegment) {
                    return true;
                }
                const currMainFlight = relevantSegment.flights[0];
                let isSegmentRelevant = false;
                const selectedMinMaxRange = minMaxRange;
                const originalMinMaxRange = filterBy.departTimeMinMax[cityName];
                if (JSON.stringify(selectedMinMaxRange) !== JSON.stringify(originalMinMaxRange)) {
                    const depTime = format(new Date(currMainFlight.depDate), 'HH:mm');
                    const depTimeStamp = getTime(parse(depTime, 'HH:mm', new Date(currMainFlight.depDate))); // formatting the '00:00' string into timestamp
                    if (depTimeStamp >= selectedMinMaxRange[0] && depTimeStamp <= selectedMinMaxRange[1]) {
                        isSegmentRelevant = true;
                    }
                }
                else {
                    isSegmentRelevant = true;
                }
                if (!isSegmentRelevant) {
                    return false;
                }
                else if (+index < Object.keys(filterBy.departTimeRange).length - 1) {
                    continue;
                }
                else {
                    return true;
                }
            }
        });
    }
    // filter by arrival time range
    if (filterBy?.arriveTimeRange && Object.values(filterBy?.arriveTimeRange)?.length) {
        filteredFlights = filteredFlights.filter((flightResult) => {
            for (const [index, [cityName, minMaxRange]] of Object.entries(Object.entries(filterBy.arriveTimeRange))) {
                const relevantSegment = flightResult.segments.find((segment) => cityAirportMap[segment.flights[segment.flights.length - 1].destCode] === cityName ||
                    segment.flights[segment.flights.length - 1].destCode === cityName);
                if (!relevantSegment && +index < Object.keys(filterBy.arriveTimeRange).length - 1) {
                    continue;
                }
                else if (!relevantSegment) {
                    return true;
                }
                const currMainFlight = relevantSegment.flights[relevantSegment.flights.length - 1];
                let isSegmentRelevant = false;
                const selectedMinMaxRange = minMaxRange;
                const originalMinMaxRange = filterBy.arriveTimeMinMax[cityName];
                if (JSON.stringify(selectedMinMaxRange) !== JSON.stringify(originalMinMaxRange)) {
                    const arrTime = format(new Date(currMainFlight.arrDate), 'HH:mm');
                    const arrTimeStamp = getTime(parse(arrTime, 'HH:mm', new Date(currMainFlight.arrDate))); // formatting the '00:00' string into timestamp
                    if (arrTimeStamp >= selectedMinMaxRange[0] && arrTimeStamp <= selectedMinMaxRange[1]) {
                        isSegmentRelevant = true;
                    }
                }
                else {
                    isSegmentRelevant = true;
                }
                if (!isSegmentRelevant) {
                    return false;
                }
                else if (+index < Object.keys(filterBy.arriveTimeRange).length - 1) {
                    continue;
                }
                else {
                    return true;
                }
            }
        });
    }
    // filter by segment duration range
    if (filterBy?.durationRange && Object.values(filterBy?.durationRange)?.length) {
        filteredFlights = filteredFlights.filter((flightResult) => {
            for (const [index, [key, value]] of Object.entries(Object.entries(filterBy.durationRange))) {
                const relevantSegment = flightResult.segments.find((segment) => segment.flights[0].originCityName === key);
                if (!relevantSegment && +index < Object.keys(filterBy.durationRange).length - 1) {
                    continue;
                }
                else if (!relevantSegment) {
                    return true;
                }
                const relevantSegmentDuration = relevantSegment.segmentDuration.totalMinutes;
                let isSegmentRelevant = false;
                const selectedMinMaxRange = value;
                const originalMinMaxRange = filterBy.durationMinMax[key];
                if (JSON.stringify(selectedMinMaxRange) !== JSON.stringify(originalMinMaxRange)) {
                    if (relevantSegmentDuration >= selectedMinMaxRange[0] && relevantSegmentDuration <= selectedMinMaxRange[1]) {
                        isSegmentRelevant = true;
                    }
                }
                else {
                    isSegmentRelevant = true;
                }
                if (!isSegmentRelevant) {
                    return false;
                }
                else if (+index < Object.keys(filterBy.durationRange).length - 1) {
                    continue;
                }
                else {
                    return true;
                }
            }
        });
    }
    // SORTS
    if (filterBy.sortBy) {
        if (filterBy.sortBy === 'best') {
            filteredFlights = filteredFlights.sort((a, b) => (b.joy || 0) - (a.joy || 0));
        }
        else if (filterBy.sortBy === 'cheapest' || filterBy.sortBy === 'priceDescending') {
            filteredFlights = filteredFlights.sort((a, b) => {
                if (filterBy.sortBy === 'cheapest') {
                    return a.totalPriceDisplayCurrency - b.totalPriceDisplayCurrency;
                }
                else {
                    return b.totalPriceDisplayCurrency - a.totalPriceDisplayCurrency;
                }
            });
        }
        else if (filterBy.sortBy === 'quickest' || filterBy.sortBy === 'durationDescending') {
            filteredFlights = filteredFlights.sort((a, b) => {
                const aTotalFlightDuration = a.segments.reduce((acc, flightSegment) => {
                    acc += flightSegment.segmentDuration.totalMinutes;
                    return acc;
                }, 0);
                const bTotalFlightDuration = b.segments.reduce((acc, flightSegment) => {
                    acc += flightSegment.segmentDuration.totalMinutes;
                    return acc;
                }, 0);
                if (filterBy.sortBy === 'quickest') {
                    return aTotalFlightDuration - bTotalFlightDuration;
                }
                else {
                    return bTotalFlightDuration - aTotalFlightDuration;
                }
            });
        }
        else if (filterBy.sortBy === SortByTypes.DepartureAscending ||
            filterBy.sortBy === SortByTypes.DepartureDescending) {
            filteredFlights.sort((a, b) => {
                const aFlights = a.segments[0].flights;
                const bFlights = b.segments[0].flights;
                return compareDesc(parseISO(aFlights[0].depDate), parseISO(bFlights[0].depDate));
            });
            if (filterBy.sortBy === SortByTypes.DepartureAscending) {
                filteredFlights.reverse();
            }
        }
        else if (filterBy.sortBy === SortByTypes.ArrivalAscending || filterBy.sortBy === SortByTypes.ArrivalDescending) {
            filteredFlights.sort((a, b) => {
                const aFlights = a.segments[0].flights;
                const bFlights = b.segments[0].flights;
                return compareDesc(parseISO(aFlights[aFlights.length - 1].arrDate), parseISO(bFlights[bFlights.length - 1].arrDate));
            });
            if (filterBy.sortBy === SortByTypes.ArrivalDescending) {
                filteredFlights.reverse();
            }
        }
        else if (filterBy.sortBy === 'stops') {
            filteredFlights.sort((a, b) => {
                let allStopsA = 0;
                let allStopsB = 0;
                for (let i = 0; i < a.segments.length; i++) {
                    allStopsA += a.segments[i].stops.length;
                    allStopsB += b.segments[i].stops.length;
                }
                return allStopsA - allStopsB;
            });
        }
        else if (filterBy.sortBy === 'lowestCarbon') {
            filteredFlights = filteredFlights.sort((a, b) => {
                return a.carbonQuantity - b.carbonQuantity;
            });
        }
        // else if (filterBy.sortBy === 'airline') {
        //   filteredFlights = filteredFlights.sort((a: IFlightResult, b: IFlightResult) => {
        //     const aCarrierName = a.segments[0].flights[0].carrierName;
        //     const bCarrierName = b.segments[0].flights[0].carrierName;
        //     if (aCarrierName < bCarrierName) {
        //       return -1;
        //     } else if (aCarrierName > bCarrierName) {
        //       return 1;
        //     } else {
        //       return 0;
        //     }
        //   });
        // }
    }
    return filteredFlights;
}
function getSortOptions(isFullOptions = false) {
    const opts = [
        {
            value: SortByTypes.DepartureAscending,
            label: $t('filter.departureAscending'),
        },
        {
            value: SortByTypes.DepartureDescending,
            label: $t('filter.departureDescending'),
        },
        {
            value: SortByTypes.ArrivalAscending,
            label: $t('filter.arrivalAscending'),
        },
        {
            value: SortByTypes.ArrivalDescending,
            label: $t('filter.arrivalDescending'),
        },
        {
            value: SortByTypes.Stops,
            label: $t('filter.stops'),
        },
        {
            value: SortByTypes.LowestCarbon,
            label: $t('filter.lowestCarbon'),
        },
    ];
    // incase we want full opts - add the basic options to the array
    if (isFullOptions) {
        const baseOpts = [
            {
                value: SortByTypes.Best,
                label: $t('filter.best'),
            },
            {
                value: SortByTypes.Cheapest,
                label: $t('filter.cheapest'),
            },
            {
                value: SortByTypes.Quickest,
                label: $t('filter.quickest'),
            },
        ];
        opts.unshift(...baseOpts);
    }
    return opts;
}
function getResultMap(flightResults) {
    const filterData = _getLowestPriceMapData();
    const resultMap = filterData.reduce((acc, data) => {
        if (data.countFunction) {
            acc[data.key] = data.countFunction(flightResults);
        }
        return acc;
    }, {});
    return { resultMap };
}
function getFlightTimeOption(departDateAt) {
    const time = new Date(departDateAt).toTimeString().slice(0, 5); // Format as HH:mm
    for (const [key, option] of Object.entries(flightConsts.FlightTimeOptions)) {
        if (option.minTimeSpan && option.maxTimeSpan && time >= option.minTimeSpan && time < option.maxTimeSpan) {
            return option;
        }
    }
    return flightConsts.FlightTimeOptions.Anytime;
}
function getFlightFilterCheckboxes() {
    return [
        {
            key: 'stops',
            transKey: 'flight.FlightStops',
            txt: 'stops',
            items: [
                Object.fromEntries(
                //convert FlightStops to an object, and then translate all the values
                Object.entries(utilService.getEnumToDictionary(flightConsts.FlightStops)).map(([key, value]) => [
                    key,
                    $t('flight.FlightStops.' + value),
                ])),
            ],
        },
        {
            key: 'hasUnusedTickets',
            txt: 'unusedTickets',
            transkey: 'flight.unusedTicketsFilter',
            items: [
                Object.fromEntries(
                //convert FlightStops to an object, and then translate all the values
                Object.entries(utilService.getEnumToDictionary(flightConsts.UnusedTicketsFilter)).map(([key, value]) => [
                    key,
                    $t('flight.unusedTicketsFilter.' + value),
                ])),
            ],
        },
    ];
}
function _getLowestPriceMapData() {
    return [
        {
            key: 'agreementTypes',
            transKey: 'flight.AgreementType',
            countFunction: (flightsResults) => {
                const agreementPriceMap = flightsResults.reduce((acc, flightResult) => {
                    const currAgreementType = flightResult.isMarine || flightResult.isHasMarineAlternative
                        ? 'Marine fare'
                        : flightConsts.AgreementType[flightResult.agreementType];
                    if (!acc[currAgreementType] ||
                        +acc[currAgreementType].substring(1) > flightResult.totalPriceDisplayCurrency) {
                        acc[currAgreementType] =
                            utilService.getCurrencyCode(flightResult.displayCurrency) +
                                flightResult.totalPriceDisplayCurrency.toFixed(2);
                    }
                    return acc;
                }, {});
                return agreementPriceMap;
            },
        },
        {
            key: 'hasUnusedTickets',
            transKey: 'flight.unusedTicketsFilter',
            countFunction: (flightsResults) => {
                let counter = 0;
                const unusedTicketsCountMap = flightsResults.reduce((acc, flightResult) => {
                    if (flightResult.hasUnusedTicket) {
                        counter++;
                        acc[flightConsts.UnusedTicketsFilter[0]] = counter;
                    }
                    return acc;
                }, {});
                return unusedTicketsCountMap;
            },
        },
        {
            key: 'stops',
            transKey: 'flight.FlightStops',
            countFunction: (flightsResults) => {
                const stopPriceMap = flightsResults.reduce((acc, flightResult) => {
                    const maxStopsInFlightResult = flightResult.segments.reduce((acc, segment) => {
                        if (segment.stops.length > acc) {
                            acc = segment.stops.length;
                        }
                        return acc;
                    }, 0);
                    const stopsLabel = flightConsts.FlightStops[maxStopsInFlightResult];
                    if (!acc[stopsLabel] || +acc[stopsLabel].substring(1) > flightResult.totalPriceDisplayCurrency) {
                        acc[stopsLabel] =
                            utilService.getCurrencyCode(flightResult.displayCurrency) +
                                flightResult.totalPriceDisplayCurrency.toFixed(2);
                    }
                    return acc;
                }, {});
                //Reorder the object and create 'No stops' to be the first one
                const orderedStopMap = {
                    'No stops': stopPriceMap['No stops'],
                    ...stopPriceMap, // Spread the rest of the properties
                };
                return orderedStopMap;
            },
        },
        {
            key: 'advisories',
            transKey: 'flight.Advisories',
            countFunction: (flightsResults) => {
                const advisoryPriceMap = flightsResults.reduce((acc, flightResult) => {
                    for (let i = 0; i < flightResult.segments.length; i++) {
                        const currFlightSegment = flightResult.segments[i];
                        for (let j = 0; j < currFlightSegment.advisories.length; j++) {
                            const currAdvisoryName = currFlightSegment.advisories[j].imageClass;
                            const advisoryDisplayName = flightConsts.FlightAdvisoriesDisplayNames[currAdvisoryName];
                            if (!acc[advisoryDisplayName] ||
                                +acc[advisoryDisplayName].substring(1) > flightResult.totalPriceDisplayCurrency) {
                                acc[advisoryDisplayName] =
                                    utilService.getCurrencyCode(flightResult.displayCurrency) +
                                        flightResult.totalPriceDisplayCurrency.toFixed(2);
                            }
                        }
                    }
                    return acc;
                }, {});
                return advisoryPriceMap;
            },
        },
        {
            key: 'carriers',
            countFunction: (flightsResults) => {
                const carriersPriceMap = flightsResults.reduce((acc, flightResult) => {
                    const firstFlightResultCarrier = flightResult.segments[0].flights[0].carrierName;
                    const isMultiCarrierFlightResult = !flightResult.segments.every((segment) => segment.flights[0].carrierName === firstFlightResultCarrier);
                    if (isMultiCarrierFlightResult &&
                        (!acc['Multi carrier'] || +acc['Multi carrier'].substring(1) > flightResult.totalPriceDisplayCurrency)) {
                        acc['Multi carrier'] =
                            utilService.getCurrencyCode(flightResult.displayCurrency) +
                                flightResult.totalPriceDisplayCurrency.toFixed(2);
                    }
                    for (let i = 0; i < flightResult.segments.length; i++) {
                        const currFlightSegment = flightResult.segments[i];
                        for (let j = 0; j < currFlightSegment.flights.length; j++) {
                            const currCarrier = currFlightSegment.flights[j].carrierName;
                            if (!acc[currCarrier] || +acc[currCarrier].substring(1) > flightResult.totalPriceDisplayCurrency) {
                                acc[currCarrier] =
                                    utilService.getCurrencyCode(flightResult.displayCurrency) +
                                        flightResult.totalPriceDisplayCurrency.toFixed(2);
                            }
                        }
                    }
                    return acc;
                }, {});
                return carriersPriceMap;
            },
        },
        {
            key: 'airports',
            countFunction: (flightResults) => {
                const airportsPriceMap = flightResults.reduce((acc, flightResult) => {
                    flightResult.segments.forEach((segment, segmentIndex) => {
                        const segmentKey = segmentIndex.toString();
                        if (!acc[segmentKey]) {
                            acc[segmentKey] = {};
                        }
                        const originAirport = segment.flights[0].origin;
                        const destinationAirport = segment.flights[segment.flights.length - 1].destCode;
                        if (!acc[segmentKey][originAirport] ||
                            +acc[segmentKey][originAirport].substring(1) > flightResult.totalPriceDisplayCurrency) {
                            acc[segmentKey][originAirport] =
                                utilService.getCurrencyCode(flightResult.displayCurrency) +
                                    flightResult.totalPriceDisplayCurrency.toFixed(2);
                        }
                        if (!acc[segmentKey][destinationAirport] ||
                            +acc[segmentKey][destinationAirport].substring(1) > flightResult.totalPriceDisplayCurrency) {
                            acc[segmentKey][destinationAirport] =
                                utilService.getCurrencyCode(flightResult.displayCurrency) +
                                    flightResult.totalPriceDisplayCurrency.toFixed(2);
                        }
                    });
                    return acc;
                }, {});
                return airportsPriceMap;
            },
        },
    ];
}
