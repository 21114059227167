import { ActionTypes } from '@/types/consts';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'AppToolTipFlightCapacityRestricted',
    props: {
        violation: {
            required: true,
        },
        customClass: {
            // add a class to the tooltip
            type: String,
            default: 'hours-limitation-description',
        },
    },
    methods: {
        htmlListToStringArray(htmlString) {
            // Saved in the DB as HTML <UL>
            // Create a new DOM parser
            const parser = new DOMParser();
            // Parse the HTML string into a Document
            const doc = parser.parseFromString(htmlString, 'text/html');
            // Select all the <li> elements within the <ul> or <ol>
            const listItems = doc.querySelectorAll('ul li, ol li');
            // Map the text content of each <li> element to an array
            const resultArray = Array.from(listItems).map(item => item.textContent?.trim() || '');
            return resultArray;
        },
        generateHTMLDescription(flightList) {
            let listHTML = '';
            if (flightList?.length > 0) {
                listHTML = '<p class="flight-list-title">' + this.$t('trip.capacityViolation.violationWithFlights') + '</p><ul class="violating-flights-list">';
                for (let flightIndex = 0; flightIndex < flightList.length; flightIndex++) {
                    listHTML += '<li><span class="flight-number-list">' + flightList[flightIndex] + '</span></li>';
                }
                listHTML += '</ul>' + '<p class="flight-list-title">' + this.$t('trip.capacityViolation.searchForAlternative') + ' ' + (this.violation.ActionType === ActionTypes.Justification ? this.$t('trip.capacityViolation.proceedWithJustification') : '') + '</p>';
            }
            else {
                listHTML = '<p class="flight-list-title">' + this.$t('trip.capacityViolation.violationWithoutFlights') + '</p>';
            }
            return listHTML;
        },
    },
    computed: {
        generateFlightsList() {
            if (this.violation?.TravelPolicyViolationExplanation?.indexOf('<li>') > -1) {
                return this.htmlListToStringArray((this.violation?.TravelPolicyViolationExplanation).split(';;')[1]);
            }
            return null;
        },
    },
});
