import { defineComponent } from 'vue';
import { MAX_SHARE_COUNT } from '@/types/consts';
export default defineComponent({
    props: {
        isFitWidth: {
            type: Boolean,
            default: false,
        },
        isProductInShare: {
            type: Boolean,
            default: false,
        },
        btnClass: {
            type: String,
            default: '',
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        productsToShare() {
            return this.$store.getters['tripStore/productsToShare'];
        },
        maxShareCount() {
            return MAX_SHARE_COUNT;
        },
        isShareFull() {
            return this.productsToShare.products.length === this.maxShareCount;
        },
    },
});
