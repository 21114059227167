import { defineComponent } from 'vue';
import { format } from 'date-fns';
import { airportMarkingsService } from '@/modules/products/flight/services/airport-marking.service';
import ticketLayout from '@/components/ticket-layout.vue';
export default defineComponent({
    name: 'FlightsByScheduleSelectedSegments',
    components: {
        ticketLayout,
    },
    props: {
        selectedSegments: {
            type: Array,
            required: true,
        },
    },
    methods: {
        carrierIcon(carrier) {
            let res = '';
            try {
                res = require(`@/assets/img/flights/airlinesIcons/${carrier}.gif`);
            }
            catch (e) {
                res = require('@/assets/icon/flight.svg');
            }
            return res;
        },
        formattedTime(dateStr) {
            return format(new Date(dateStr), 'HH:mm');
        },
        getFlightConnectionMarking(segmentIdx, flightIdx, target) {
            const markings = this.flightMarkings;
            const selectedSegments = this.selectedSegments;
            const lastSegIdx = selectedSegments.length - 1;
            const lastFlightIdx = selectedSegments[segmentIdx].segments[selectedSegments[lastSegIdx].segments.length - 1].flights.length - 1;
            const isConnectionOrigin = target === 'origin' && segmentIdx > 0 && flightIdx === 0;
            if (isConnectionOrigin) {
                return markings[segmentIdx].originMarked;
            }
            const isConnectionDestination = target === 'destination' && segmentIdx < lastSegIdx && flightIdx === lastFlightIdx;
            if (isConnectionDestination) {
                return markings[segmentIdx].destinationMarked;
            }
            return false;
        },
        getFlightMarking(segmentIdx, flightIdx, direction) {
            const markings = this.flightMarkings;
            const selectedSegments = this.selectedSegments;
            const isDepature = direction === 'origin' && segmentIdx === 0 && flightIdx === 0;
            if (isDepature) {
                return markings[segmentIdx].departureMarked;
            }
            const lastSegIdx = this.selectedSegments.length - 1;
            const lastFlightIdx = selectedSegments[segmentIdx].segments[selectedSegments[lastSegIdx].segments.length - 1].flights.length - 1;
            const isArrival = direction === 'destination' && segmentIdx === lastSegIdx && flightIdx === lastFlightIdx;
            if (isArrival) {
                return markings[segmentIdx].arrivalMarked;
            }
            return false;
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        flightMarkings() {
            const selectedSegments = this.selectedSegments;
            const lastSelectedIdx = selectedSegments.length - 1;
            return selectedSegments.map((cur, idx) => {
                const marking = {
                    originMarked: false,
                    destinationMarked: false,
                    departureMarked: false,
                    arrivalMarked: false,
                };
                //on edge segments we're always checking wether you want to arrive and depart at same
                const isFirstSegment = idx === 0;
                const isLastSegment = idx === lastSelectedIdx;
                if (isFirstSegment || isLastSegment) {
                    const isMarked = airportMarkingsService.markIfConnectingToSameCityDiffAirport(selectedSegments[lastSelectedIdx], selectedSegments[0]);
                    marking.departureMarked = isFirstSegment && isMarked;
                    marking.arrivalMarked = isLastSegment && isMarked;
                }
                const previousFres = selectedSegments[idx - 1];
                if (previousFres) {
                    marking.originMarked = airportMarkingsService.markIfConnectingToSameCityDiffAirport(previousFres, cur);
                }
                const nextFres = selectedSegments[idx + 1];
                if (nextFres) {
                    marking.destinationMarked = airportMarkingsService.markIfConnectingToSameCityDiffAirport(cur, nextFres);
                }
                return marking;
            });
        },
    },
});
