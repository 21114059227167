import app from './app.vue';
import store from './store/store';
import { createApp } from 'vue';
import { router } from './router';
import { config } from './config/config';
import { authService } from '@/services/auth.service';
import { alertService } from '@/services/alert.service';
import { filterService } from '@/services/filter.service';
import { cachingService } from '@/services/storages/caching.service';
import { elementPlus, lang } from '@/plugins/element';
import { registerComponents } from '@/components/components';
import { signalrService } from './services/signalr.service';
import { registerDirectives } from './directives';
import { ElNotification } from 'element-plus';
import { i18n } from '@/plugins';
import { TempStorageService } from '@/services/storages/temp-storage.service';
const tempStorageService = new TempStorageService();
// global scss
import './assets/style/main.scss';
const App = createApp(app);
App.use(elementPlus, { locale: lang });
App.use(router);
App.use(store);
App.use(i18n);
App.config.globalProperties.$auth = authService;
App.config.globalProperties.$alert = alertService;
App.config.globalProperties.$tempStorage = tempStorageService;
App.config.globalProperties.$filters = filterService;
App.config.globalProperties.$notify = ElNotification;
App.config.globalProperties.$signalr = signalrService;
// Only suppress warnings in production or based on the environment variable
if (process.env.VUE_APP_ENV !== 'development') {
    App.config.warnHandler = function (msg, vm, trace) {
        // Ignore all warnings or add conditions to selectively ignore certain warnings
        return;
    };
}
// register global components
registerComponents(App);
// register global directives
registerDirectives(App);
App.mount('#app');
export default App;
//google-analytics
if (config.env.isProd) {
    let newScript = document.createElement('script');
    newScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-NBE09TWTRZ';
    newScript.setAttribute('async', '');
    document.head.appendChild(newScript);
    newScript = document.createElement('script');
    //newScript.src = '/assets/google-analytics.js';
    newScript.textContent = `
      console.log('Add google-analytics');
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-NBE09TWTRZ');
  `;
    document.head.appendChild(newScript);
}
// caching
cachingService.init();
