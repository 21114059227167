import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3953dc0b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "flight-selected-segments"
};
const _hoisted_2 = {
    key: 0,
    class: "change-flight-btn-wrapper"
};
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_flight_preview_segment = _resolveComponent("flight-preview-segment");
    const _component_app_btn = _resolveComponent("app-btn");
    return (_ctx.isAnySegmentSelected)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedSegments, (segment, index) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(["segment-preview-wrapper", {
                            bordered: !_ctx.isLastShownSegment(index) && segment?.segments,
                            padded: !_ctx.isFirstShownSegment(index) && segment?.segments,
                        }])
                }, [
                    _createVNode(_Transition, { name: "slide-ttb" }, {
                        default: _withCtx(() => [
                            (segment?.segments)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(["segment-preview", { 'is-flex is-justify-content-space-between is-align-content-center': !_ctx.isOnBySchedulePricingPage }])
                                }, [
                                    _createVNode(_component_flight_preview_segment, {
                                        flightSegment: segment.segments[0],
                                        isMobile: _ctx.isMobile,
                                        isOnBySchedulePricingPage: _ctx.isOnBySchedulePricingPage,
                                        markedSegmentDirection: _ctx.flightMarkings[index]
                                    }, null, 8 /* PROPS */, ["flightSegment", "isMobile", "isOnBySchedulePricingPage", "markedSegmentDirection"]),
                                    (!_ctx.isOnBySchedulePricingPage)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                            (!_ctx.isMobile)
                                                ? (_openBlock(), _createBlock(_component_app_btn, {
                                                    key: 0,
                                                    class: "change-flight-btn",
                                                    onClick: ($event) => (_ctx.$emit('changeFlight', index))
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.$t('flight.changeFlight')), 1 /* TEXT */)
                                                    ]),
                                                    _: 2 /* DYNAMIC */
                                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                                                : (_openBlock(), _createElementBlock("span", {
                                                    key: 1,
                                                    class: "material-symbols-outlined change-flight-icon",
                                                    onClick: ($event) => (_ctx.$emit('changeFlight', index))
                                                }, "edit ", 8 /* PROPS */, _hoisted_3))
                                        ]))
                                        : _createCommentVNode("v-if", true)
                                ], 2 /* CLASS */))
                                : _createCommentVNode("v-if", true)
                        ]),
                        _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                ], 2 /* CLASS */));
            }), 128 /* KEYED_FRAGMENT */))
        ]))
        : _createCommentVNode("v-if", true);
}
