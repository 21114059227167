import { defineComponent } from 'vue';
import { referenceFieldService } from '@/services/reference-fields.service';
import { utilService } from '@/services/util.service';
import { QuoteStatus } from '@/types/consts';
import { ReferenceFieldEditableOn } from '@/types/ReferenceField/consts';
import { eventBus } from '@/services/event-bus.service';
import GeneralField from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/general-field/general-reference-field.vue';
import QuoteReferenceField from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/quate-field/quote-reference-field.vue';
import InputBasic from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/QuestionInputTypes/InputBasic/input-basic.vue';
import TravelerField from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/traveler-field/traveler-reference-field.vue';
export default defineComponent({
    components: {
        'input-basic': InputBasic,
        'quote-reference-field': QuoteReferenceField,
        'traveler-reference-field': TravelerField,
        'general-reference-field': GeneralField,
    },
    name: 'AppReferenceFieldQuestionnaire',
    props: {
        tripId: {
            type: Number,
            default: () => 0,
        },
        questions: {
            type: Array,
            default: () => [],
        },
        stage: {
            type: Number,
            default: () => 0,
        },
        quotes: {
            type: Array,
            default: () => [],
        },
        isShowTripReferenceForm: {
            type: Boolean,
            default: false,
        },
        passengers: {
            type: Array,
            default: () => [],
        },
        hideSaveBtn: {
            type: Boolean,
            default: false,
        },
        disableAllFields: {
            type: Boolean,
            default: false,
        },
        deleteAllAnswers: {
            type: Boolean,
            default: false,
        },
        errorMsg: {
            type: String,
            default: '',
        },
        isSelfRegistration: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            questionsLocal: [],
            questionTypes: {
                generalQuestions: [],
                passengerQuestions: [],
                productQuestions: [],
                profileQuestions: [],
                productPassengerQuestions: [],
            },
            allDependentQuestionsIds: new Set(),
            activeQuestionsIds: [],
            expandedQuotasIds: [],
            validationDictionary: {},
            markFields: false,
        };
    },
    created() {
        this.questionsLocal = utilService.deepClone(this.questions);
        this.recalculateDependedQuestions();
        this.relevantQuestions?.forEach((question) => {
            this.sortQuestionByType(question);
        });
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        relevantQuotes() {
            //TODO: for MVP we should filter the quoted by status Selected only
            if (this.isShowTripReferenceForm) {
                return this.quotes;
            }
            if (this.stage === ReferenceFieldEditableOn.CheckOut) {
                return this.quotes?.filter((q) => q.status === QuoteStatus.Selected || q.status === QuoteStatus.Booked);
            }
            else {
                return this.quotes?.filter((q) => q.status === QuoteStatus.Selected);
            }
            //return this.quotes;
        },
        isPrimaryCorporateUser() {
            return this.trip?.passengers?.some((p) => p.isPrimary && p.corporateUserId) || false;
        },
        relevantQuestions() {
            return this.questionsLocal?.filter((q) => q.refAnswers && q.refAnswers.length);
        },
        isToShowProductQuestions() {
            return ((this.questionTypes.productQuestions && this.questionTypes.productQuestions.length > 0) ||
                (this.questionTypes.productPassengerQuestions && this.questionTypes.productPassengerQuestions.length > 0));
        },
        isToShowProfileQuestions() {
            return this.questionTypes.profileQuestions && this.questionTypes.profileQuestions.length > 0;
        },
        isToShowGeneralQuestions() {
            return this.questionTypes.generalQuestions && this.questionTypes.generalQuestions.length > 0;
        },
        isToShowPassengerQuestions() {
            return this.questionTypes.passengerQuestions && this.questionTypes.passengerQuestions.length > 0;
        },
    },
    methods: {
        async sendQuestionaire(stage) {
            stage = stage || this.stage;
            this.markFields = true;
            // validate
            if (!this.isFormValid()) {
                return Promise.reject('Invalid reference fields');
            }
            // creating an array containing the modified questions from "this.questionsLocal" +
            // + original questions that were not modified from "this.questions"
            const questionsToSend = utilService
                .deepClone(this.questionsLocal)
                ?.map((q, index) => q.refAnswers?.[0]?.value?.[0]?.value !== undefined ? q : utilService.deepClone(this.questions[index]));
            //Need to be saved in any case, empty 'questionsToSend' or not
            //questionsLocal() includes saving some "non displayed" questions, so questionsToSend can be empty
            //and yet, some questions will be saved.
            await referenceFieldService.saveReferenceField(stage, this.tripId, questionsToSend);
            await referenceFieldService.calculateTravelPolicy(this.tripId, this.loggedinUser.corporationId);
        },
        getProductStatus(status) {
            return QuoteStatus[status];
        },
        isGeneralQuestion(question, fieldData) {
            return !question.isProfileSelectionQ && fieldData.passengerId === 0 && fieldData.quoteId === 0;
        },
        async recalculateApprovalFlow() {
            const customRemarks = this.questionsLocal
                .filter((question) => {
                return question.refAnswers[0]?.value?.length > 0;
            })
                .map((question) => {
                return {
                    Value: question.refAnswers[0]?.value[0]?.value,
                    QuestionId: question.questionId,
                };
            });
            eventBus.$emit('reloadApprovers', customRemarks);
        },
        isGuestPassenger(id) {
            const passengers = this.passengers;
            const passenger = passengers.find((p) => p.id === id);
            const isGuest = !passenger.corporateUserId;
            return isGuest;
        },
        isPassengerRelatedQuestion(question, fieldData) {
            const isProfileQuestion = question.isProfileSelectionQ;
            const conditions = [
                () => fieldData.passengerId !== 0 && fieldData.quoteId === 0,
                //issue 44509
                () => this.isGuestPassenger(fieldData.passengerId),
            ];
            return !isProfileQuestion && conditions.some((c) => c());
        },
        isProfileSelectionQuestion(question, fieldData) {
            return question.isProfileSelectionQ;
        },
        isQuoteRelatedQuestion(question, fieldData) {
            return !question.isProfileSelectionQ && fieldData.passengerId === 0 && fieldData.quoteId !== 0;
        },
        isDependentQuestion(question, fieldData) {
            return !question.isProfileSelectionQ && this.allDependentQuestionsIds.has(question.questionId);
        },
        isPassengerQuoteRelatedQuestion(question, fieldData) {
            return !question.isProfileSelectionQ && fieldData.passengerId !== 0 && fieldData.quoteId !== 0;
        },
        dataChangedHandler(fieldDataGroups, fieldData) {
            //Update question
            const question = this.questionsLocal.find((q) => q.questionId === fieldData.id);
            const answer = question?.refAnswers.find((ans) => ans.passengerId === fieldData.passengerId && ans.quoteId === fieldData.quoteId);
            answer.value = [];
            fieldData.values?.forEach((x) => answer.value.push(x));
            //check any group to update
            fieldDataGroups?.forEach((group) => {
                group?.forEach((q) => {
                    checkToUpdateQuestion(q); //check question
                });
            });
            //Recalculate Form validation
            this.validationDictionary = { ...this.validationDictionary };
            this.validationDictionary[`${fieldData.id}-${fieldData.quoteId}-${fieldData.passengerId}`] = fieldData.isValid;
            function checkToUpdateQuestion(q) {
                const isToUpdate = q.id === fieldData.id && q.passengerId === fieldData.passengerId && q.quoteId === fieldData.quoteId;
                if (isToUpdate) {
                    q.dependedFields = fieldData.dependedFields;
                    q.values = utilService.deepClone(fieldData.values);
                    q.isValid = fieldData.isValid;
                }
                if (q.dependedFields) {
                    q.dependedFields?.forEach((dq) => checkToUpdateQuestion(dq));
                }
            }
            this.$emit('data-changed');
        },
        isFormValid() {
            return (this.questionTypes.productQuestions.every((ans) => this.isAnswerValid(ans)) &&
                this.questionTypes.productPassengerQuestions.every((ans) => this.isAnswerValid(ans)) &&
                this.questionTypes.generalQuestions.every((ans) => this.isAnswerValid(ans)) &&
                this.questionTypes.passengerQuestions.every((ans) => this.isAnswerValid(ans)) &&
                this.questionTypes.profileQuestions.every((ans) => this.isAnswerValid(ans)));
        },
        getQuestionTypes() {
            return this.questionTypes;
        },
        isAnswerValid(answer) {
            let isValid = answer.isValid;
            if (answer.dependedFields) {
                isValid = isValid && answer.dependedFields.every((ans) => this.isAnswerValid(ans));
            }
            return isValid;
        },
        filterQuestionAnswerData(fieldDataList, passengerId, quoteId) {
            return fieldDataList?.filter((q) => (!passengerId || q.passengerId === passengerId) && (!quoteId || q.quoteId === quoteId));
        },
        passengerFullname(passenger) {
            return `${passenger.firstName} ${passenger.lastName}`;
        },
        recalculateDependedQuestions() {
            this.questionsLocal?.forEach((question) => {
                const dependedQuestionsIds = question.optionList?.flatMap((option) => option.dependentQuestionsIds);
                dependedQuestionsIds?.forEach((id) => {
                    this.allDependentQuestionsIds.add(id);
                });
            });
        },
        sortQuestionByType(question) {
            const fieldData = {
                id: question.questionId,
            };
            this.fillRefAnswers(question, fieldData);
        },
        fillRefAnswers(question, field) {
            if (!question.refAnswers || !question.refAnswers.length) {
                //if no any answer exist
                field.quoteId = 0;
                field.passengerId = 0;
                field.values = [];
                this.fillQuestions(question, field);
            }
            else {
                //if  any answer exist
                //for each answer create representation as "fieldData"
                for (const answer of question.refAnswers) {
                    const fieldData = {
                        id: question.questionId,
                        quoteId: answer.quoteId,
                        passengerId: answer.passengerId,
                        values: answer.value ? answer.value : [],
                        isValid: false,
                    };
                    //ignore not relevant answers
                    if (fieldData.quoteId === 0 ||
                        (fieldData.quoteId !== 0 && this.relevantQuotes.some((q) => q.id === fieldData.quoteId))) {
                        this.fillQuestions(question, fieldData);
                    }
                }
            }
        },
        fillQuestions(question, fieldData) {
            if (!this.isDependentQuestion(question, fieldData)) {
                if (this.isGeneralQuestion(question, fieldData)) {
                    this.questionTypes.generalQuestions.push(fieldData);
                }
                else if (this.isQuoteRelatedQuestion(question, fieldData)) {
                    this.questionTypes.productQuestions.push(fieldData);
                }
                else if (this.isPassengerRelatedQuestion(question, fieldData)) {
                    this.questionTypes.passengerQuestions.push(fieldData);
                }
                else if (this.isPassengerQuoteRelatedQuestion(question, fieldData)) {
                    this.questionTypes.productPassengerQuestions.push(fieldData);
                }
                else if (this.isProfileSelectionQuestion(question, fieldData)) {
                    this.questionTypes.profileQuestions.push(fieldData);
                }
            }
        },
    },
});
