export var PaymentMethods;
(function (PaymentMethods) {
    PaymentMethods[PaymentMethods["payAtHotel"] = 0] = "payAtHotel";
    PaymentMethods[PaymentMethods["prePaid"] = 1] = "prePaid";
    PaymentMethods[PaymentMethods["onlinePayment"] = 2] = "onlinePayment";
    PaymentMethods[PaymentMethods["refundable"] = 3] = "refundable";
    PaymentMethods[PaymentMethods["depositNotRequired"] = 4] = "depositNotRequired";
})(PaymentMethods || (PaymentMethods = {}));
export var BookingComPaymentMethods;
(function (BookingComPaymentMethods) {
    BookingComPaymentMethods["PrePaid"] = "pre paid";
    BookingComPaymentMethods["PayOnDeparture"] = "pay on departure";
    BookingComPaymentMethods["OnlinePayment"] = "online payment";
})(BookingComPaymentMethods || (BookingComPaymentMethods = {}));
export var TermsType;
(function (TermsType) {
    // "freeCancellation",
    TermsType[TermsType["nonRefundable"] = 0] = "nonRefundable";
})(TermsType || (TermsType = {}));
export var DirectBillingType;
(function (DirectBillingType) {
    DirectBillingType[DirectBillingType["NoCorporateBilling"] = 0] = "NoCorporateBilling";
    DirectBillingType[DirectBillingType["CorporateBilling"] = 1] = "CorporateBilling";
    DirectBillingType[DirectBillingType["CorporateBillingWithCreditCard"] = 2] = "CorporateBillingWithCreditCard";
})(DirectBillingType || (DirectBillingType = {}));
export var RoomRefundableStatus;
(function (RoomRefundableStatus) {
    RoomRefundableStatus[RoomRefundableStatus["None"] = 0] = "None";
    RoomRefundableStatus[RoomRefundableStatus["NonRefundable"] = 1] = "NonRefundable";
    RoomRefundableStatus[RoomRefundableStatus["Refundable"] = 2] = "Refundable";
})(RoomRefundableStatus || (RoomRefundableStatus = {}));
// export enum CancellationType {
//     NonRefundable = "Non-Refundable",
//     Refundable = "Refundable"
// }
export var SortByTypes;
(function (SortByTypes) {
    SortByTypes["Default"] = "default";
    SortByTypes["Price"] = "price";
    SortByTypes["Supplier"] = "supplier";
    SortByTypes["RoomName"] = "roomName";
})(SortByTypes || (SortByTypes = {}));
export var JoyDefaultSortTypes;
(function (JoyDefaultSortTypes) {
    JoyDefaultSortTypes[JoyDefaultSortTypes["Default"] = 0] = "Default";
    JoyDefaultSortTypes[JoyDefaultSortTypes["Price"] = 1] = "Price";
})(JoyDefaultSortTypes || (JoyDefaultSortTypes = {}));
export var AmenitiesHotel;
(function (AmenitiesHotel) {
    AmenitiesHotel[AmenitiesHotel["AirCondition"] = 0] = "AirCondition";
    AmenitiesHotel[AmenitiesHotel["Bar"] = 1] = "Bar";
    AmenitiesHotel[AmenitiesHotel["Casino"] = 2] = "Casino";
    AmenitiesHotel[AmenitiesHotel["Concierge"] = 3] = "Concierge";
    AmenitiesHotel[AmenitiesHotel["DisabledAccess"] = 4] = "DisabledAccess";
    AmenitiesHotel[AmenitiesHotel["Exchange"] = 5] = "Exchange";
    AmenitiesHotel[AmenitiesHotel["Gym"] = 6] = "Gym";
    AmenitiesHotel[AmenitiesHotel["Internet"] = 7] = "Internet";
    AmenitiesHotel[AmenitiesHotel["Laundry"] = 8] = "Laundry";
    AmenitiesHotel[AmenitiesHotel["Lift"] = 9] = "Lift";
    AmenitiesHotel[AmenitiesHotel["Lounge"] = 10] = "Lounge";
    AmenitiesHotel[AmenitiesHotel["LuggageStorage"] = 11] = "LuggageStorage";
    AmenitiesHotel[AmenitiesHotel["MeetingFacilities"] = 12] = "MeetingFacilities";
    AmenitiesHotel[AmenitiesHotel["Minibar"] = 13] = "Minibar";
    AmenitiesHotel[AmenitiesHotel["Parking"] = 14] = "Parking";
    AmenitiesHotel[AmenitiesHotel["Restaurant"] = 15] = "Restaurant";
    AmenitiesHotel[AmenitiesHotel["Safe"] = 16] = "Safe";
    AmenitiesHotel[AmenitiesHotel["Spa"] = 17] = "Spa";
    AmenitiesHotel[AmenitiesHotel["SwimmingPool"] = 18] = "SwimmingPool";
    AmenitiesHotel[AmenitiesHotel["TV"] = 19] = "TV";
})(AmenitiesHotel || (AmenitiesHotel = {}));
export const SupplierQuoteExpirationTimeOut = {
    travelodgeCountdown: 15,
    premierInnCountdown: 10,
};
export var SurchargeType;
(function (SurchargeType) {
    SurchargeType[SurchargeType["Unknown"] = 0] = "Unknown";
    SurchargeType[SurchargeType["Tax"] = 1] = "Tax";
    SurchargeType[SurchargeType["ServiceFee"] = 2] = "ServiceFee";
    SurchargeType[SurchargeType["ExtraPersonFee"] = 3] = "ExtraPersonFee";
    SurchargeType[SurchargeType["SalesTax"] = 4] = "SalesTax";
    SurchargeType[SurchargeType["HotelOccupancyTax"] = 5] = "HotelOccupancyTax";
    SurchargeType[SurchargeType["TaxAndServiceFee"] = 6] = "TaxAndServiceFee";
    SurchargeType[SurchargeType["PropertyFee"] = 7] = "PropertyFee";
})(SurchargeType || (SurchargeType = {}));
export var RoomAmenityType;
(function (RoomAmenityType) {
    RoomAmenityType[RoomAmenityType["BedType"] = 0] = "BedType";
    RoomAmenityType[RoomAmenityType["Breakfast"] = 1] = "Breakfast";
    RoomAmenityType[RoomAmenityType["Dinner"] = 2] = "Dinner";
    RoomAmenityType[RoomAmenityType["Wifi"] = 3] = "Wifi";
})(RoomAmenityType || (RoomAmenityType = {}));
export var PaidBy;
(function (PaidBy) {
    PaidBy[PaidBy["None"] = 0] = "None";
    PaidBy[PaidBy["TMC"] = 1] = "TMC";
    PaidBy[PaidBy["Corporate"] = 2] = "Corporate";
})(PaidBy || (PaidBy = {}));
export var SuppliersId;
(function (SuppliersId) {
    SuppliersId["BOOKINGCOM"] = "BOOKINGCOM";
    SuppliersId["GOGLOBAL"] = "GOGLOBAL";
    SuppliersId["EHOTEL"] = "EHOTEL";
    SuppliersId["AMADEUS"] = "AMADEUS";
    SuppliersId["AMADEUS-AD"] = "AMADEUS-AD";
    SuppliersId["AMADEUS-AE"] = "Alba Travel";
    SuppliersId["AMADEUS-EY"] = "TotalStay";
    SuppliersId["AMADEUS-GU"] = "GTA";
    SuppliersId["AMADEUS-HB"] = "Beds";
    SuppliersId["AMADEUS-HD"] = "HCorpo";
    SuppliersId["AMADEUS-RI"] = "Restel\t";
    SuppliersId["AMADEUS-SJ"] = "Sun Hotels";
    SuppliersId["AMADEUS-HV"] = "HRS";
    SuppliersId["EXPEDIARAPID"] = "EXPEDIARAPID";
    SuppliersId["GTA"] = "GTA";
    SuppliersId["LATEROOMS"] = "LATEROOMS";
    SuppliersId["PREMIERINN"] = "PREMIERINN";
    SuppliersId["RATEHAWK"] = "RATEHAWK";
    SuppliersId["SABRE"] = "SABRE";
    SuppliersId["TRAVELODGE"] = "TRAVELODGE";
    SuppliersId["UAPIAPOLLO"] = "UAPIAPOLLO";
    SuppliersId["UAPIGALILEO"] = "UAPIGALILEO";
    SuppliersId["UAPIWORLDSPAN"] = "UAPIWORLDSPAN";
    SuppliersId["FUSION"] = "FUSION";
    SuppliersId["YPSILON"] = "YPSILON";
    SuppliersId["TRANSFERZ"] = "TRANSFERZ";
    SuppliersId["BENERAILMOOVE"] = "BENERAILMOOVE";
    SuppliersId["DEUTSCHEBAHN"] = "DEUTSCHEBAHN";
})(SuppliersId || (SuppliersId = {}));
export var AgreementType;
(function (AgreementType) {
    AgreementType[AgreementType["none"] = 0] = "none";
    AgreementType[AgreementType["agency"] = 1] = "agency";
    AgreementType[AgreementType["corporate"] = 2] = "corporate";
    AgreementType[AgreementType["agencyAndCorporate"] = 3] = "agencyAndCorporate";
    AgreementType[AgreementType["package"] = 4] = "package";
})(AgreementType || (AgreementType = {}));
export var LocationType;
(function (LocationType) {
    LocationType[LocationType["CITY"] = 0] = "CITY";
    LocationType[LocationType["AIRPORT"] = 1] = "AIRPORT";
    LocationType[LocationType["REFERENCE_POINT"] = 2] = "REFERENCE_POINT";
    LocationType[LocationType["POINT_OF_INTEREST"] = 3] = "POINT_OF_INTEREST";
    LocationType[LocationType["GOOGLE"] = 4] = "GOOGLE";
    LocationType[LocationType["GEOCODE"] = 5] = "GEOCODE";
    LocationType[LocationType["LOCALITY"] = 6] = "LOCALITY";
    LocationType[LocationType["ESTABLISHMENT"] = 7] = "ESTABLISHMENT";
    LocationType[LocationType["LODGING"] = 8] = "LODGING";
    LocationType[LocationType["POSTAL_CODE"] = 9] = "POSTAL_CODE";
})(LocationType || (LocationType = {}));
export var CorporateLocationType;
(function (CorporateLocationType) {
    CorporateLocationType[CorporateLocationType["OfficeLocation"] = 0] = "OfficeLocation";
    CorporateLocationType[CorporateLocationType["ClientLocation"] = 1] = "ClientLocation";
    CorporateLocationType[CorporateLocationType["GeneralLocation"] = 2] = "GeneralLocation";
})(CorporateLocationType || (CorporateLocationType = {}));
export const searchResultIcon = {
    [CorporateLocationType.OfficeLocation]: 'star',
    [CorporateLocationType.ClientLocation]: 'domain',
    [CorporateLocationType.GeneralLocation]: 'business_center',
};
export const ExpediaTaxTypes = [
    {
        key: 6,
        label: 'taxAndFees',
        tooltip: 'cart.ExpediaTaxInfo',
    },
    { key: 0, label: 'unknown' },
    {
        key: 1,
        label: 'tax',
    },
    {
        key: 2,
        label: 'serviceFee',
    },
    {
        key: 3,
        label: 'extraPersonFee',
    },
    {
        key: 4,
        label: 'salesTax',
    },
    {
        key: 5,
        label: 'hotelOccupancyTax',
    },
    {
        key: 7,
        label: 'propertyFee',
    },
];
export const maxSearchRadius = 32000; // meters
export const DEFAULT_SEARCH_RADIUS = 10;
export const DEFAULT_SEARCH_UNIT = 'km';
export const ITEM_COUNT_SIZE = 20;
