import { defineComponent } from 'vue';
import { zonedTimeToUtc } from 'date-fns-tz';
import { addMinutes, differenceInMilliseconds, isAfter, format } from 'date-fns';
export default defineComponent({
    name: 'FlightTimedQuote',
    props: {
        quote: {
            type: Object,
            required: true,
        },
        countdownDuration: {
            type: Number,
            default: 0,
        },
        initExpiryDate: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            countdownInterval: null,
            countdownRemaining: null,
            quoteExpiryDate: null,
            isTooltipShown: false,
        };
    },
    created() {
        const parsedCreationDate = zonedTimeToUtc(this.quote.creationDate, 'UTC');
        if (this.initExpiryDate) {
            this.quoteExpiryDate = new Date(this.initExpiryDate);
        }
        else if (!this.quoteExpiryDate) {
            this.quoteExpiryDate = !this.countdownDuration
                ? new Date(this.quote.timeLimit)
                : addMinutes(parsedCreationDate, this.countdownDuration);
        }
        this.countdownInterval = setInterval(() => this.timedQuoteCountdown(), 1000);
    },
    methods: {
        timedQuoteCountdown() {
            const currDate = new Date();
            if (isAfter(currDate, new Date(this.quoteExpiryDate))) {
                clearInterval(this.countdownInterval);
                this.$emit('expired');
                this.isTooltipShown = false;
                this.countdownRemaining = this.$t('product.status.Expired');
                return;
            }
            const difference = differenceInMilliseconds(this.quoteExpiryDate, currDate);
            const remainingSeconds = Math.floor(difference / 1000);
            if (remainingSeconds < 3600) {
                // Less than an hour
                this.countdownRemaining = format(difference, 'mm:ss');
            }
            else {
                // More than 1 hour
                const remainingMinutes = Math.floor(remainingSeconds / 60);
                const remainingHours = Math.floor(remainingMinutes / 60);
                const remainingDays = Math.floor(remainingHours / 24);
                const hoursInDay = remainingHours % 24;
                const minutesInHour = remainingMinutes % 60;
                this.isTooltipShown = true;
                this.countdownRemaining = `${remainingDays ? remainingDays + 'D' : ''} ${hoursInDay.toString()}H ${minutesInHour.toString()}M`;
            }
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
