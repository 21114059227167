import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2492f60b"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "new-seat-label is-flex is-align-items-center" };
const _hoisted_2 = { class: "mr-1" };
const _hoisted_3 = { class: "app-label colorLabel" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, [
                (_ctx.isAllContainsNewSeat)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('common.flightWithNewSeatTypes')), 1 /* TEXT */))
                    : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('common.newSeatTypes')), 1 /* TEXT */))
            ])
        ])
    ]));
}
