//TODO: There are some duplicates!
// TODO: use consistent convention for all products (camelCase preferred)
export var Products;
(function (Products) {
    Products["Hotel"] = "hotel";
    Products["Flight"] = "flight";
    Products["Car"] = "car";
    Products["GroundTransport"] = "ground-transport";
    Products["GroundService"] = "groundService";
    Products["Parking"] = "parking";
    Products["HeathrowExpress"] = "heathrowExpress";
    Products["Evolvi"] = "evolvi";
    Products["UkRail"] = "uk-rail";
    Products["Manual"] = "manual";
    Products["Amtrak"] = "amtrak";
    Products["Deal"] = "deal";
    Products["Profile"] = "profile";
    Products["StaySafe"] = "staySafe";
    Products["Compensation"] = "compensation";
    Products["DBrail"] = "DBrail";
    //they are all 'eu-rail'
    Products["Rail"] = "rail";
    Products["EuRail"] = "eu-rail";
    Products["Benerail"] = "Benerail";
    Products["BenerailMoove"] = "BenerailMoove";
    //---
})(Products || (Products = {}));
export var PolicyViolationCodes;
(function (PolicyViolationCodes) {
    PolicyViolationCodes[PolicyViolationCodes["FlightCapacityPolicy"] = 51] = "FlightCapacityPolicy";
})(PolicyViolationCodes || (PolicyViolationCodes = {}));
export var NewUiPreference;
(function (NewUiPreference) {
    NewUiPreference[NewUiPreference["Both"] = 0] = "Both";
    NewUiPreference[NewUiPreference["GtpOnly"] = 1] = "GtpOnly";
    NewUiPreference[NewUiPreference["NewUiOnly"] = 2] = "NewUiOnly";
})(NewUiPreference || (NewUiPreference = {}));
export var ExpenseManagers;
(function (ExpenseManagers) {
    ExpenseManagers[ExpenseManagers["Nexonia"] = 0] = "Nexonia";
    ExpenseManagers[ExpenseManagers["Certify"] = 1] = "Certify";
    ExpenseManagers[ExpenseManagers["Expensify"] = 2] = "Expensify";
    ExpenseManagers[ExpenseManagers["DellBoomi"] = 3] = "DellBoomi";
    ExpenseManagers[ExpenseManagers["Refundly"] = 4] = "Refundly";
})(ExpenseManagers || (ExpenseManagers = {}));
export var PhoneFormat;
(function (PhoneFormat) {
    PhoneFormat[PhoneFormat["Regular"] = 0] = "Regular";
    PhoneFormat[PhoneFormat["USFormat"] = 1] = "USFormat";
})(PhoneFormat || (PhoneFormat = {}));
export var TravelerType;
(function (TravelerType) {
    TravelerType[TravelerType["Adult"] = 0] = "Adult";
    TravelerType[TravelerType["Senior"] = 1] = "Senior";
    TravelerType[TravelerType["Youth"] = 2] = "Youth";
    TravelerType[TravelerType["Child"] = 3] = "Child";
    TravelerType[TravelerType["LapInfant"] = 4] = "LapInfant";
    TravelerType[TravelerType["SeatInfant"] = 5] = "SeatInfant";
})(TravelerType || (TravelerType = {}));
export var AddTravelerType;
(function (AddTravelerType) {
    AddTravelerType[AddTravelerType["IamTheTraveller"] = 0] = "IamTheTraveller";
    AddTravelerType[AddTravelerType["AutoComplete"] = 1] = "AutoComplete";
    AddTravelerType[AddTravelerType["Guest"] = 2] = "Guest";
})(AddTravelerType || (AddTravelerType = {}));
export var UnitsSystem;
(function (UnitsSystem) {
    UnitsSystem[UnitsSystem["Metric"] = 0] = "Metric";
    UnitsSystem[UnitsSystem["Imperial"] = 1] = "Imperial";
})(UnitsSystem || (UnitsSystem = {}));
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes[ActionTypes["Justification"] = 0] = "Justification";
    ActionTypes[ActionTypes["RestrictSelection"] = 1] = "RestrictSelection";
})(ActionTypes || (ActionTypes = {}));
export var Units;
(function (Units) {
    Units["KM"] = "KM";
    Units["MI"] = "MI";
})(Units || (Units = {}));
export var JustificationType;
(function (JustificationType) {
    JustificationType[JustificationType["Text"] = 0] = "Text";
    JustificationType[JustificationType["Select"] = 1] = "Select";
})(JustificationType || (JustificationType = {}));
export var TravelPolicyActions;
(function (TravelPolicyActions) {
    TravelPolicyActions[TravelPolicyActions["Justification"] = 0] = "Justification";
    TravelPolicyActions[TravelPolicyActions["Restrict"] = 1] = "Restrict";
})(TravelPolicyActions || (TravelPolicyActions = {}));
export var ReferanceFieldType;
(function (ReferanceFieldType) {
    ReferanceFieldType[ReferanceFieldType["ProductRelated"] = 0] = "ProductRelated";
    ReferanceFieldType[ReferanceFieldType["GeneralRemarks"] = 1] = "GeneralRemarks";
    ReferanceFieldType[ReferanceFieldType["FlightsLegacy"] = 2] = "FlightsLegacy";
    ReferanceFieldType[ReferanceFieldType["FlightsLCC"] = 3] = "FlightsLCC";
    ReferanceFieldType[ReferanceFieldType["MarketplaceFlights"] = 4] = "MarketplaceFlights";
    ReferanceFieldType[ReferanceFieldType["HotelsLegacy"] = 5] = "HotelsLegacy";
    ReferanceFieldType[ReferanceFieldType["HotelsNonGDS"] = 6] = "HotelsNonGDS";
    ReferanceFieldType[ReferanceFieldType["CarsLegacy"] = 7] = "CarsLegacy";
    ReferanceFieldType[ReferanceFieldType["CarsNonGDS"] = 8] = "CarsNonGDS";
    ReferanceFieldType[ReferanceFieldType["Ancillaries"] = 9] = "Ancillaries";
    ReferanceFieldType[ReferanceFieldType["HEX"] = 10] = "HEX";
    ReferanceFieldType[ReferanceFieldType["GroundScope"] = 11] = "GroundScope";
    ReferanceFieldType[ReferanceFieldType["FlightsLegacySplitTicket"] = 12] = "FlightsLegacySplitTicket";
    ReferanceFieldType[ReferanceFieldType["Evolvi"] = 13] = "Evolvi";
})(ReferanceFieldType || (ReferanceFieldType = {}));
export var ValidationOptionType;
(function (ValidationOptionType) {
    ValidationOptionType[ValidationOptionType["AtLeast"] = 0] = "AtLeast";
    ValidationOptionType[ValidationOptionType["Max"] = 1] = "Max";
    ValidationOptionType[ValidationOptionType["Equals"] = 2] = "Equals";
})(ValidationOptionType || (ValidationOptionType = {}));
export var QuoteCancellationType;
(function (QuoteCancellationType) {
    QuoteCancellationType[QuoteCancellationType["CreditCard"] = 1] = "CreditCard";
    QuoteCancellationType[QuoteCancellationType["E-Voucher"] = 2] = "E-Voucher";
    QuoteCancellationType[QuoteCancellationType["Forfeit"] = 3] = "Forfeit";
})(QuoteCancellationType || (QuoteCancellationType = {}));
export var QuoteStatus;
(function (QuoteStatus) {
    QuoteStatus[QuoteStatus["Selected"] = 0] = "Selected";
    QuoteStatus[QuoteStatus["Booked"] = 1] = "Booked";
    QuoteStatus[QuoteStatus["Ticketed"] = 2] = "Ticketed";
    QuoteStatus[QuoteStatus["Cancelled"] = 3] = "Cancelled";
    QuoteStatus[QuoteStatus["PendingTicketing"] = 4] = "PendingTicketing";
    QuoteStatus[QuoteStatus["Requested"] = 5] = "Requested";
    QuoteStatus[QuoteStatus["Debited"] = 6] = "Debited";
    QuoteStatus[QuoteStatus["Credited"] = 7] = "Credited";
    QuoteStatus[QuoteStatus["NonIssued"] = 8] = "NonIssued";
    QuoteStatus[QuoteStatus["Expired"] = 9] = "Expired";
    QuoteStatus[QuoteStatus["BookingError"] = 10] = "BookingError";
    QuoteStatus[QuoteStatus["PendingReissue"] = 11] = "PendingReissue";
    QuoteStatus[QuoteStatus["InProgress"] = 12] = "InProgress";
    QuoteStatus[QuoteStatus["InBasket"] = 13] = "InBasket";
    QuoteStatus[QuoteStatus["Deleted"] = 14] = "Deleted";
    QuoteStatus[QuoteStatus["TicketingError"] = 15] = "TicketingError";
    QuoteStatus[QuoteStatus["Disrupted"] = 16] = "Disrupted";
    QuoteStatus[QuoteStatus["PendingCancellation"] = 17] = "PendingCancellation";
    QuoteStatus[QuoteStatus["ReissueFailed"] = 18] = "ReissueFailed";
})(QuoteStatus || (QuoteStatus = {}));
export var QuoteStatusNames;
(function (QuoteStatusNames) {
    QuoteStatusNames["Selected"] = "selected";
    QuoteStatusNames["Booked"] = "booked";
    QuoteStatusNames["Ticketed"] = "ticketed";
    QuoteStatusNames["Cancelled"] = "cancelled";
    QuoteStatusNames["PendingTicketing"] = "pendingTicketing";
    QuoteStatusNames["Requested"] = "requested";
    QuoteStatusNames["Debited"] = "debited";
    QuoteStatusNames["Credited"] = "credited";
    QuoteStatusNames["NonIssued"] = "nonIssued";
    QuoteStatusNames["Expired"] = "expired";
    QuoteStatusNames["BookingError"] = "bookingError";
    QuoteStatusNames["PendingReissue"] = "pendingReissue";
    QuoteStatusNames["InProgress"] = "inProgress";
    QuoteStatusNames["InBasket"] = "inBasket";
    QuoteStatusNames["Deleted"] = "deleted";
    QuoteStatusNames["TicketingError"] = "ticketingError";
    QuoteStatusNames["Disrupted"] = "disrupted";
    QuoteStatusNames["PendingCancellation"] = "pendingCancellation";
    QuoteStatusNames["ReissueFailed"] = "reissueFailed";
})(QuoteStatusNames || (QuoteStatusNames = {}));
export var ProductStatusName;
(function (ProductStatusName) {
    ProductStatusName["PendingBooking"] = "Pending Booking";
    ProductStatusName["Confirmed"] = "Confirmed";
    ProductStatusName["Cancelled"] = "Cancelled";
    ProductStatusName["Finalizing"] = "Finalizing";
    ProductStatusName["OnHold"] = "On Hold";
    ProductStatusName["TicketingComplete"] = "Ticketing Complete";
    ProductStatusName["Expired"] = "Expired";
})(ProductStatusName || (ProductStatusName = {}));
export var TripValidationStatusType;
(function (TripValidationStatusType) {
    TripValidationStatusType[TripValidationStatusType["OK"] = 0] = "OK";
    TripValidationStatusType[TripValidationStatusType["Warning"] = 1] = "Warning";
    TripValidationStatusType[TripValidationStatusType["Error"] = 2] = "Error";
    TripValidationStatusType[TripValidationStatusType["Pending"] = 3] = "Pending";
    TripValidationStatusType[TripValidationStatusType["Partial"] = 4] = "Partial";
    TripValidationStatusType[TripValidationStatusType["ReserveOK"] = 5] = "ReserveOK";
    TripValidationStatusType[TripValidationStatusType["ReserveError"] = 6] = "ReserveError";
})(TripValidationStatusType || (TripValidationStatusType = {}));
export var TripValidationErrMsg;
(function (TripValidationErrMsg) {
    TripValidationErrMsg["VirtualCard"] = "virtualcard";
    TripValidationErrMsg["Invoice"] = "invoice";
    TripValidationErrMsg["CC"] = "cc";
})(TripValidationErrMsg || (TripValidationErrMsg = {}));
export var TripValidationOwnerType;
(function (TripValidationOwnerType) {
    TripValidationOwnerType[TripValidationOwnerType["personal"] = 0] = "personal";
    TripValidationOwnerType[TripValidationOwnerType["traveler"] = 1] = "traveler";
    TripValidationOwnerType[TripValidationOwnerType["lodge"] = 2] = "lodge";
    TripValidationOwnerType[TripValidationOwnerType["tmc"] = 3] = "tmc";
})(TripValidationOwnerType || (TripValidationOwnerType = {}));
export var TripValidationsNames;
(function (TripValidationsNames) {
    TripValidationsNames["SpecialRequest"] = "Special Request";
    TripValidationsNames["AdditionalPreferences"] = "Additional Preferences";
    TripValidationsNames["FrequentGuest"] = "Frequent Guest";
    TripValidationsNames["CancellationPolicy"] = "Cancellation Policy";
    TripValidationsNames["FormOfPayment"] = "Form of Payment";
    TripValidationsNames["Nationality"] = "Nationality";
    TripValidationsNames["ParkingContactInformation"] = "Details";
    TripValidationsNames["ParkingDeliveryPickup"] = "Drop-off Pick-up";
    TripValidationsNames["ContactInformation"] = "Contact Information";
    TripValidationsNames["PremierInnExtraServices"] = "Premier Inn Extra Services";
    TripValidationsNames["CarDriverLoyaltyID"] = "Car Driver / Loyality ID";
    TripValidationsNames["CarLoyaltyID"] = "Loyalty ID";
    TripValidationsNames["CarBillback"] = "Negotiated Rate & Billback";
    TripValidationsNames["CarAddCoupon"] = "Add Coupon";
    TripValidationsNames["CarSpecialEquipment"] = "Special Equipment";
    TripValidationsNames["CarSunnyFlightNumber"] = "Flight Number";
    TripValidationsNames["CarSunnyAdditionalOptions"] = "Additional Options";
    TripValidationsNames["CarSunnyAddLocation"] = "Add Location";
    TripValidationsNames["Seating"] = "Seating";
    TripValidationsNames["LoyaltyProgram"] = "Loyalty Program";
    TripValidationsNames["DeliveryMethod"] = "Delivery Method";
    TripValidationsNames["DateOfBirth"] = "Date Of Birth";
    TripValidationsNames["AgeAssociation"] = "Age Association";
    TripValidationsNames["RoomExtras"] = "Room Extras";
    TripValidationsNames["Baggage"] = "Baggage";
    TripValidationsNames["OptionalServices"] = "Optional Services";
    TripValidationsNames["CngCxlFees"] = "CNG & CXL fees";
    TripValidationsNames["FrequentFlyer"] = "Frequent Flyer";
    TripValidationsNames["Meals"] = "Meals";
    TripValidationsNames["PassengerInfo"] = "Passenger Info";
    TripValidationsNames["AncillaryOptions"] = "Ancillary Options";
    TripValidationsNames["ContactDetails"] = "Contact Details";
    TripValidationsNames["HandLuggage"] = "Hand Luggage";
    TripValidationsNames["AncillaryServices"] = "Ancillary Services";
    TripValidationsNames["speedyBoarding"] = "Speedy Boarding";
    TripValidationsNames["EditDetails"] = "Edit Details";
    TripValidationsNames["seats"] = "Seats";
    TripValidationsNames["NonLowestReason"] = "Reasons for non lowest";
    TripValidationsNames["Account"] = "Account / Child unit";
    TripValidationsNames["ClientReference"] = "Client Reference";
    TripValidationsNames["PaymentMethod"] = "Payment Method";
})(TripValidationsNames || (TripValidationsNames = {}));
export var FormOfPayment;
(function (FormOfPayment) {
    FormOfPayment[FormOfPayment["CC"] = 0] = "CC";
})(FormOfPayment || (FormOfPayment = {}));
export var AgreementType;
(function (AgreementType) {
    AgreementType[AgreementType["None"] = 0] = "None";
    AgreementType[AgreementType["Agency"] = 1] = "Agency";
    AgreementType[AgreementType["Corp"] = 2] = "Corp";
    AgreementType[AgreementType["Both"] = 3] = "Both";
    AgreementType[AgreementType["PackageRate"] = 4] = "PackageRate";
})(AgreementType || (AgreementType = {}));
export var BookingStage;
(function (BookingStage) {
    BookingStage[BookingStage["Checkout"] = 0] = "Checkout";
    BookingStage[BookingStage["PriorToBooking"] = 1] = "PriorToBooking";
    BookingStage[BookingStage["NewTrip"] = 2] = "NewTrip";
    BookingStage[BookingStage["ProfileSelect"] = 3] = "ProfileSelect";
    BookingStage[BookingStage["Search"] = 4] = "Search";
    BookingStage[BookingStage["Cart"] = 5] = "Cart";
    BookingStage[BookingStage["MyProfile"] = 999] = "MyProfile";
})(BookingStage || (BookingStage = {}));
// export enum TripStateName {
//   TripPlanning = 'Trip planning',
//   InApprovals = 'In Approvals',
//   PreparingItinerary = 'Preparing Itinerary',
//   Cancelled = 'Cancelled',
//   AssistanceRequested = 'Assistance Requested',
//   Declined = 'Declined',
//   Finalized = 'Finalized',
// }
export var filterOptions;
(function (filterOptions) {
    filterOptions["assistanceRequested"] = "Assistance Requested";
    filterOptions["tripPlanning"] = "Trip planning";
    filterOptions["inApprovals"] = "In Approvals";
    filterOptions["finalized"] = "Finalized";
    filterOptions["preparingItinerary"] = "Preparing Itinerary";
    filterOptions["declined"] = "Declined";
    filterOptions["preTripApproval"] = "Pre-Trip Approval";
    filterOptions["cancelled"] = "Cancelled";
})(filterOptions || (filterOptions = {}));
export var TripState;
(function (TripState) {
    TripState[TripState["SearchRequest"] = 0] = "SearchRequest";
    TripState[TripState["SelectRequest"] = 1] = "SelectRequest";
    TripState[TripState["Review"] = 2] = "Review";
    TripState[TripState["CompleteBooking"] = 3] = "CompleteBooking";
    TripState[TripState["AssistanceRequest"] = 4] = "AssistanceRequest";
    TripState[TripState["InApprovals"] = 5] = "InApprovals";
    TripState[TripState["Finalized"] = 6] = "Finalized";
    TripState[TripState["Declined"] = 7] = "Declined";
    TripState[TripState["Cancelled"] = 8] = "Cancelled";
    TripState[TripState["PreBooking"] = 9] = "PreBooking";
    TripState[TripState["PreTripRequest"] = 10] = "PreTripRequest";
    TripState[TripState["PreTripApproval"] = 11] = "PreTripApproval";
})(TripState || (TripState = {}));
export var ResponseEmailType;
(function (ResponseEmailType) {
    ResponseEmailType[ResponseEmailType["WithHeadersSummary"] = 0] = "WithHeadersSummary";
    ResponseEmailType[ResponseEmailType["WithHeadersDetails"] = 1] = "WithHeadersDetails";
    ResponseEmailType[ResponseEmailType["WithHeadersBoth"] = 2] = "WithHeadersBoth";
    ResponseEmailType[ResponseEmailType["NoHeadersSummary"] = 3] = "NoHeadersSummary";
    ResponseEmailType[ResponseEmailType["NoHeadersDetails"] = 4] = "NoHeadersDetails";
    ResponseEmailType[ResponseEmailType["NoHeadersBoth"] = 5] = "NoHeadersBoth";
})(ResponseEmailType || (ResponseEmailType = {}));
export var PriceOfferDetailsType;
(function (PriceOfferDetailsType) {
    PriceOfferDetailsType[PriceOfferDetailsType["Summary"] = 0] = "Summary";
    PriceOfferDetailsType[PriceOfferDetailsType["Details"] = 1] = "Details";
    PriceOfferDetailsType[PriceOfferDetailsType["Both"] = 2] = "Both";
})(PriceOfferDetailsType || (PriceOfferDetailsType = {}));
// Also in mixins.scss
export var BreakPoints;
(function (BreakPoints) {
    BreakPoints[BreakPoints["Mobile"] = 460] = "Mobile";
    BreakPoints[BreakPoints["Tablet"] = 760] = "Tablet";
    BreakPoints[BreakPoints["Desktop"] = 1280] = "Desktop";
    BreakPoints[BreakPoints["Widescreen"] = 1440] = "Widescreen";
    BreakPoints[BreakPoints["Fullhd"] = 1920] = "Fullhd";
})(BreakPoints || (BreakPoints = {}));
export var CreditCardsTypes;
(function (CreditCardsTypes) {
    CreditCardsTypes["AX"] = "American Express";
    CreditCardsTypes["CA"] = "MasterCard";
    CreditCardsTypes["DC"] = "DC";
    CreditCardsTypes["DS"] = "Discover";
    CreditCardsTypes["TP"] = "Air Plus";
    CreditCardsTypes["VI"] = "Visa";
    CreditCardsTypes["EH"] = "EHotel";
    CreditCardsTypes["virtualCard"] = "Virtual Card";
})(CreditCardsTypes || (CreditCardsTypes = {}));
export var CreditCardsCodes;
(function (CreditCardsCodes) {
    CreditCardsCodes[CreditCardsCodes["AX"] = 0] = "AX";
    CreditCardsCodes[CreditCardsCodes["CA"] = 1] = "CA";
    CreditCardsCodes[CreditCardsCodes["DC"] = 2] = "DC";
    CreditCardsCodes[CreditCardsCodes["DS"] = 3] = "DS";
    CreditCardsCodes[CreditCardsCodes["TP"] = 4] = "TP";
    CreditCardsCodes[CreditCardsCodes["EH"] = 5] = "EH";
    CreditCardsCodes[CreditCardsCodes["VI"] = 6] = "VI";
    CreditCardsCodes[CreditCardsCodes["virtualCard"] = 7] = "virtualCard";
})(CreditCardsCodes || (CreditCardsCodes = {}));
export const RailTypes = [
    Products.HeathrowExpress,
    Products.Evolvi,
    Products.Amtrak,
    Products.DBrail,
    Products.Rail,
    Products.EuRail,
];
export var ShareTypes;
(function (ShareTypes) {
    ShareTypes["Itinerary"] = "itinerary";
    ShareTypes["Quotes"] = "quotes";
    ShareTypes["Products"] = "products";
})(ShareTypes || (ShareTypes = {}));
export const AgentSuggestedProducts = [Products.Hotel, Products.Flight, Products.Rail, Products.Car, Products.Amtrak, Products.Evolvi];
export var WeekDayOptions;
(function (WeekDayOptions) {
    WeekDayOptions[WeekDayOptions["sunday"] = 0] = "sunday";
    WeekDayOptions[WeekDayOptions["monday"] = 1] = "monday";
})(WeekDayOptions || (WeekDayOptions = {}));
export var NotificationTypes;
(function (NotificationTypes) {
    NotificationTypes[NotificationTypes["Regular"] = 0] = "Regular";
    NotificationTypes[NotificationTypes["Mandatory"] = 10] = "Mandatory";
})(NotificationTypes || (NotificationTypes = {}));
export const Languages = [
    { code: 'en', name: 'English', icon: 'flags/en-flag.svg', isoCode: 'en-GB' },
    { code: 'de', name: 'German', icon: 'flags/de-flag.svg', isoCode: 'de-DE' },
    { code: 'nl', name: 'Dutch', icon: 'flags/nl-flag.svg', isoCode: 'nl-NL' },
    { code: 'fr', name: 'French', icon: 'flags/fr-flag.svg', isoCode: 'fr-FR' },
    { code: 'it', name: 'Italian', icon: 'flags/it-flag.svg', isoCode: 'it-IT' },
    { code: 'es', name: 'Spanish ', icon: 'flags/spain-flag.svg', isoCode: 'es' },
    { code: 'pt-PT', name: 'Portuguese', icon: 'flags/portuguese-flag.svg', isoCode: 'pt-PT' },
];
export var ManualQuoteType;
(function (ManualQuoteType) {
    ManualQuoteType[ManualQuoteType["HandlingFee"] = 0] = "HandlingFee";
    ManualQuoteType[ManualQuoteType["Insurance"] = 1] = "Insurance";
    ManualQuoteType[ManualQuoteType["Visa"] = 2] = "Visa";
    ManualQuoteType[ManualQuoteType["PerDiem"] = 3] = "PerDiem";
    ManualQuoteType[ManualQuoteType["Refund"] = 4] = "Refund";
    ManualQuoteType[ManualQuoteType["Other"] = 5] = "Other";
    ManualQuoteType[ManualQuoteType["Billback"] = 6] = "Billback";
    ManualQuoteType[ManualQuoteType["FlightChange"] = 7] = "FlightChange";
    ManualQuoteType[ManualQuoteType["HotelCancellation"] = 8] = "HotelCancellation";
    ManualQuoteType[ManualQuoteType["CarParking"] = 9] = "CarParking";
    ManualQuoteType[ManualQuoteType["FastTrack"] = 10] = "FastTrack";
    ManualQuoteType[ManualQuoteType["Transfers"] = 11] = "Transfers";
})(ManualQuoteType || (ManualQuoteType = {}));
export const ITEM_COUNT_SIZE = 10;
export const MY_TRIPS_COUNT_SIZE = 20;
export const CHECKBOX_DISPLAY_COUNT = 5;
export const RESULTS_LIMIT_DESKTOP = 7;
export const RESULTS_LIMIT_MOBILE = 5;
export const MAX_SHARE_COUNT = 4;
export const AMTRAK_MAX_SHARE_COUNT = 2;
export const MAX_YEARS_PASSPORT_EXPIRY = 15;
export const MAX_TRAVELERS_AMOUNT = 5;
export const MAX_REQUEST_ASSISTANCE_ITEMS = 10;
export const MAX_LENGTH_NOTIFICATION_MSG_MOBILE = 42;
export const MAX_LENGTH_NOTIFICATION_MSG_DESKTOP = 43;
