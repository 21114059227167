import * as flightConsts from '@/modules/products/flight/models/consts';
import * as _ from 'lodash';
import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
import { flightService } from '@/modules/products/flight/services/flight.service';
import { utilService } from '@/services/util.service';
import { loggerService } from '@/services/logger.service';
import cardImgSkeleton from '@/components/skeletons/card-img-skeleton.vue';
import appCarousel from '@/components/app-carousel.vue';
import flightPreview from '@/modules/products/flight/components/flight-preview/flight-preview.vue';
import flightDetails from '@/modules/products/flight/components/flight-details/flight-details.vue';
import legPricingOption from '@/modules/products/flight/components/pricing-options/leg-pricing-option/leg-pricing-option.vue';
import shareBtns from '@/components/app-share-quotes/app-share-products-btns.vue';
export default defineComponent({
    name: 'PricingOptions',
    components: { cardImgSkeleton, appCarousel, flightPreview, flightDetails, legPricingOption, shareBtns },
    props: {
        selectedFlightResult: {
            type: Object,
            required: true,
        },
        resultsIndex: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            pricingOptionMap: {},
            isCartBtnLoading: false,
            isShareBtnLoading: false,
            currentTabIndex: '0',
            pricingOptions: {},
            pricingOptionsToShow: [],
            isLoading: false,
            tabs: [],
            selectedCabinClass: '',
            selectedCabinClassIndex: '0',
            isDetailsShown: false,
            totalPrice: 0,
            totalPriceOrigCurrency: 0,
            previousOptionPrice: null,
            previousOptionOrigPrice: null,
            originalPricingOptions: {},
            carouselOptions: {
                bound: true,
                renderOnlyVisible: false,
                horizontal: true,
                align: 'prev',
                resizeOnContentsReady: true,
                noPanelStyleOverride: true,
            },
        };
    },
    async created() {
        eventBus.$on('cancelCartBtnLoading', this.cancelCartBtnLoading);
        eventBus.$on('resetPricingOptionsSelection', this.reset);
        eventBus.$on('handleSelectFlight', this.handleSelectFlight);
        await this.loadPricingOptions();
    },
    methods: {
        async handleSelectFlight() {
            let selectedPackageKey = '';
            const lastStepInfo = this.pricingOptionsToShow[this.pricingOptionsToShow?.length - 1].stepInfo;
            for (const key in lastStepInfo) {
                lastStepInfo[key].forEach((option) => {
                    if (option.isSelected) {
                        selectedPackageKey = option.packageKey;
                        return;
                    }
                });
            }
            this.isCartBtnLoading = true;
            const selectedPricingOption = this.originalPricingOptions.find((pricingOption) => pricingOption.packageKey === selectedPackageKey);
            if (this.searchOptions.searchFor === flightConsts.FlightSearchTypes.bySchedule) {
                eventBus.$emit('handleSelectingFlightBySchedule', selectedPricingOption);
                this.isCartBtnLoading = false;
            }
            else {
                try {
                    await this.$emit('addToCart', { selectedFlightResult: selectedPricingOption, isPricing: true });
                    if (selectedPricingOption.travelPolicyViolations) {
                        for (const key in lastStepInfo) {
                            lastStepInfo[key].forEach((option) => {
                                if (option.isSelected) {
                                    option.travelPolicyViolations = selectedPricingOption.travelPolicyViolations;
                                    return;
                                }
                            });
                        }
                    }
                }
                catch (err) {
                    loggerService.error(err);
                }
            }
        },
        cancelCartBtnLoading() {
            this.isCartBtnLoading = false;
        },
        getCabinClassTabMobileWidth(stepInfo) {
            switch (Object.keys(stepInfo)?.length) {
                case 1:
                    return '100%';
                case 2:
                    return '50%';
                case 3:
                    return '33.333333%';
                case 4:
                    return '25%';
                case 5:
                    return '20%';
            }
        },
        getCabinClassTabDesktopWidth(stepInfo) {
            let priceForTabsLength = 1;
            let shortestPriceStrByClassLength;
            for (const key in stepInfo) {
                shortestPriceStrByClassLength = parseInt(stepInfo[key][0].totalPriceDisplayCurrency, 10).toString()?.length;
                stepInfo[key].forEach((option) => {
                    if (parseInt(option.totalPriceDisplayCurrency, 10).toString()?.length <
                        parseInt(shortestPriceStrByClassLength, 10).toString()?.length) {
                        shortestPriceStrByClassLength = parseInt(option.totalPriceDisplayCurrency, 10).toString()?.length;
                    }
                });
                if (shortestPriceStrByClassLength > priceForTabsLength) {
                    priceForTabsLength = shortestPriceStrByClassLength;
                }
            }
            if (priceForTabsLength <= 3) {
                return '132px';
            }
            else if (priceForTabsLength === 4) {
                return '142px';
            }
            else if (priceForTabsLength === 5) {
                return '152px';
            }
        },
        reset() {
            if (!this.pricingOptionsToShow?.length) {
                return;
            }
            this.pricingOptionsToShow.forEach((stepOption) => {
                stepOption.isStepSelected = false;
                for (const key in stepOption.stepInfo) {
                    stepOption.stepInfo[key].forEach((option) => (option.isSelected = false));
                }
            });
            this.currentTabIndex = '0';
            this.pricingOptionsToShow = [this.pricingOptionsToShow[0]];
            this.pricingOptionsToShow = [...this.pricingOptionsToShow];
            this.selectedCabinClassIndex = '0';
            this.changeClass();
            this.getTotalPrice();
            this.getTotalPriceOrigCurrency();
        },
        changeClass(ev) {
            const index = ev?.index || 0;
            this.isDetailsShown = false;
            const currClass = Object.keys(this.pricingOptionsToShow[this.currentTabIndex].stepInfo)[index];
            this.selectedCabinClass = currClass;
        },
        changeToSelectedClass() {
            let updatedClass = '';
            for (const cabinClass in this.pricingOptionsToShow[this.currentTabIndex].stepInfo) {
                const isCurrCabinClassSelected = this.pricingOptionsToShow[this.currentTabIndex].stepInfo[cabinClass].some((option) => option.isSelected === true);
                if (isCurrCabinClassSelected) {
                    updatedClass = cabinClass;
                }
            }
            if (!updatedClass) {
                for (const cabinClass in this.pricingOptionsToShow[this.currentTabIndex].stepInfo) {
                    updatedClass = cabinClass;
                }
            }
            this.selectedCabinClass = updatedClass;
            this.changeClass();
        },
        async loadPricingOptions() {
            // if option is cached - use it
            if (this.pricingOptionMap[this.selectedFlightResult.packageKey]) {
                const pricingOptionsRes = this.pricingOptionMap[this.selectedFlightResult.packageKey];
                this.pricingOptions = pricingOptionsRes.pricingOptions;
                if (!this.pricingOptions?.length) {
                    this.pricingOptionsToShow = [];
                    return;
                }
                this.tabs = pricingOptionsRes.tabs;
                const wizard = {};
                this.pricingOptionsToShow = [this.getStep(this.pricingOptions, 0, wizard)];
                return;
            }
            this.isLoading = true;
            const searchData = {
                tripId: this.trip.id,
                guid: this.cacheKey,
                packageKey: this.selectedFlightResult.packageKey,
                isNearByAirports: this.searchOptions?.allowNearbyAirports,
                isSearchMyTmcOnly: false,
                isPTCBreakdown: false,
                isTwoOneWayEnabled: false,
                fullyRefundableFaresOnly: false,
                noOfStops: 3,
                totalPassengers: this.searchOptions?.numberOFTravelers,
                tripType: this.searchOptions?.tripType,
                travelerTypeInfo: this.searchOptions?.travelerTypeInfo,
            };
            try {
                const pricingOptionsRes = await flightService.searchPricingOptions(searchData);
                this.pricingOptionMap[searchData.packageKey] = pricingOptionsRes;
                this.pricingOptions = pricingOptionsRes.pricingOptions;
                if (!this.pricingOptions?.length) {
                    this.pricingOptionsToShow = [];
                    this.isLoading = false;
                    return;
                }
                this.tabs = pricingOptionsRes.tabs;
                this.originalPricingOptions = pricingOptionsRes.originalPricingOptions; // for by schedule use only
                // step 1
                const wizard = {};
                this.pricingOptionsToShow = [this.getStep(this.pricingOptions, 0, wizard)]; // will return only the relevant options in this step
            }
            catch (err) {
                loggerService.error(err);
                // if no res reset pricing options
                this.pricingOptionsToShow = [];
            }
            finally {
                this.isLoading = false;
            }
        },
        goToNextLeg(option) {
            if (option.isSelected) {
                for (let i = this.currentTabIndex; i < this.tabs?.length; i++) {
                    this.pricingOptionsToShow[i].isStepSelected = false;
                    for (const key in this.pricingOptionsToShow[i]?.stepInfo) {
                        this.pricingOptionsToShow[i]?.stepInfo[key].forEach((option) => (option.isSelected = false));
                    }
                }
                option.isSelected = false;
                this.pricingOptionsToShow = JSON.parse(JSON.stringify(this.pricingOptionsToShow));
                this.getTotalPrice();
                this.getTotalPriceOrigCurrency();
                return;
            }
            this.previousOptionPrice = option.avgPricePerPersonDisplayCurrency;
            this.previousOptionOrigPrice = option.avgPricePerPerson;
            for (const key in this.pricingOptionsToShow[this.currentTabIndex].stepInfo) {
                this.pricingOptionsToShow[this.currentTabIndex].stepInfo[key].forEach((option) => (option.isSelected = false));
            }
            option.isSelected = true;
            this.pricingOptionsToShow[this.currentTabIndex].isStepSelected = true;
            this.getTotalPrice();
            this.getTotalPriceOrigCurrency();
            if (+this.currentTabIndex === this.pricingOptions[0].segments?.length - 1) {
                // if it's the last segment, there is no next step so we stop here
                this.pricingOptionsToShow = JSON.parse(JSON.stringify(this.pricingOptionsToShow));
                return;
            }
            if (this.pricingOptionsToShow[+this.currentTabIndex + 1]) {
                this.pricingOptionsToShow.splice(+this.currentTabIndex + 1, this.pricingOptionsToShow?.length - (+this.currentTabIndex + 1));
            }
            const wizard = { brandName: option.brandName, brandDesc: option.brandDesc, agreementType: option.agreementType };
            const results = this.getStep(this.pricingOptionsToShow[this.currentTabIndex].filteredOptions, +this.currentTabIndex + 1, wizard);
            this.pricingOptionsToShow = [...this.pricingOptionsToShow, results];
            this.pricingOptionsToShow = JSON.parse(JSON.stringify(this.pricingOptionsToShow));
            this.currentTabIndex = (+this.currentTabIndex + 1).toString();
            this.selectedCabinClassIndex = '0';
            this.changeClass();
        },
        getStep(options, step, wizard) {
            // if wizard is set, filter options before working on them - this is basically saying what the user chose already and in which step
            const readyStep = {};
            readyStep.stepInfo = [];
            let workingStep = [];
            const filteredOptions = [];
            options.forEach((option) => {
                // get only the relevant segment of each option
                if (step === 0) {
                    workingStep.push(option.segments[step]);
                    filteredOptions.push(option);
                }
                else {
                    if ((!option.isAgreementBySegment &&
                        option.segments[step - 1].brandName === wizard.brandName &&
                        option.segments[step - 1].brandDesc === wizard.brandDesc &&
                        option.segments[step - 1].agreementType === wizard.agreementType) ||
                        (option.isAgreementBySegment &&
                            option.segments[step - 1].brandName === wizard.brandName &&
                            option.segments[step - 1].brandDesc === wizard.brandDesc)) {
                        workingStep.push(option.segments[step]);
                        filteredOptions.push(option);
                    }
                }
            });
            // group by brandName, brandDesc, agreementType, isOneWayPackage in order to dedup
            workingStep = _.groupBy(workingStep, (item) => {
                return [
                    item['brandName'],
                    item['brandDesc'],
                    item['agreementType'],
                    item['isOneWayPackage'],
                    item['baggage'],
                    item['agreementToolTip'],
                ];
            });
            for (const [key, value] of Object.entries(workingStep)) {
                // find the cheapest flight and return only it to the array
                const cheapest = value.reduce((previous, current) => {
                    return current.totalPriceDisplayCurrency < previous.totalPriceDisplayCurrency ? current : previous;
                });
                readyStep.stepInfo.push(cheapest);
            }
            readyStep.stepInfo = _.sortBy(readyStep.stepInfo, 'totalPriceDisplayCurrency');
            readyStep.stepInfo = _.groupBy(readyStep.stepInfo, 'cabinName');
            this.selectedCabinClass = Object.keys(readyStep.stepInfo)[0];
            readyStep.filteredOptions = filteredOptions;
            return readyStep;
        },
        getSelectedBrand(currentStepOption) {
            let currentBrand = '';
            for (const key in currentStepOption.stepInfo) {
                currentStepOption.stepInfo[key].forEach((step) => {
                    if (step.isSelected) {
                        // currentBrand = `${step.cabinName} ${step.brandName}`;
                        currentBrand = step.brandName;
                    }
                });
            }
            return currentBrand;
        },
        getTotalPrice() {
            let totalPrice = 0;
            this.pricingOptionsToShow.forEach((stepOption) => {
                if (stepOption?.isStepSelected) {
                    for (const key in stepOption.stepInfo) {
                        stepOption.stepInfo[key].forEach((option) => {
                            if (option.isSelected) {
                                totalPrice = option.avgPricePerPersonDisplayCurrency;
                            }
                        });
                    }
                }
            });
            this.totalPrice = totalPrice;
        },
        getTotalPriceOrigCurrency() {
            let totalPrice = 0;
            this.pricingOptionsToShow.forEach((stepOption) => {
                if (stepOption?.isStepSelected) {
                    for (const key in stepOption.stepInfo) {
                        stepOption.stepInfo[key].forEach((option) => {
                            if (option.isSelected) {
                                totalPrice = option.avgPricePerPerson;
                            }
                        });
                    }
                }
            });
            this.totalPriceOrigCurrency = totalPrice;
        },
        async handleAddToShare(skipCapacityViolation = false) {
            if (this.canShareQuoteCombinedWithAddToCart) {
                // Check if flight has capacity Restriction
                this.isShareBtnLoading = true;
                if (!skipCapacityViolation) {
                    const violationData = await this.$store.dispatch({
                        type: 'flightStore/handleFlightTravelPolicy',
                        flightResult: this.selectedFlightResult,
                        searchCacheKey: this.$store.getters['flightStore/cacheKey'](this.resultsIndex),
                        resultsIndex: this.resultsIndex,
                    });
                    this.isShareBtnLoading = false;
                    if (violationData) {
                        eventBus.$emit('openCapacityDialogForShare', this.selectedFlightResult, violationData.violations, this.addToShare);
                        return;
                    }
                    else {
                        // in case no violation
                        this.addToShare();
                    }
                }
                else {
                    // in case skipped violation
                    this.addToShare();
                    return;
                }
            }
            else {
                this.addToShare();
                return;
            }
        },
        addToShare() {
            const flight = utilService.deepClone(this.selectedFlightResult);
            const numOfPax = this.trip.numberOfPassengers;
            // editing the price according to selected option
            flight.displayCurrency = this.lastStepOption?.displayCurrency;
            flight.packageKey = this.lastStepOption?.packageKey;
            // total price is multiplied by pax on backend - need to divide
            flight.totalPriceDisplayCurrency = this.lastStepOption?.totalPriceDisplayCurrency / numOfPax;
            const product = {
                cacheKey: this.lastStepOption?.packageKey,
                flight,
                resultsIndex: this.resultsIndex,
            };
            this.$store.commit({ type: 'tripStore/addToShare', product });
        },
        removeFromShare() {
            this.$store.commit({ type: 'tripStore/removeFromShare', product: this.lastStepOption });
        },
        // This function can also be called from the flight details cmp - which means it might get full options already, without the need to build it here.
        // If we receive the full options - use as is
        openBrandedFares(option, useCurrentOptions = true) {
            const opts = useCurrentOptions
                ? {
                    packageKey: option.packageKey,
                    brandId: option.brandId || option.brandName,
                    brandName: option.brandName || option.brandId,
                    segmentIndex: this.selectedFlightResult.isOneWayPackage ? +this.currentTabIndex : null,
                }
                : option;
            this.$emit('openBrandedFares', opts);
        },
    },
    computed: {
        canShareQuoteCombinedWithAddToCart() {
            const user = this.$store.getters['authStore/loggedinUser'];
            return user.permissions.includes('ShareQuoteCombinedWithAddToCart');
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isAllStepsSelected() {
            return this.pricingOptionsToShow.every((stepOption) => stepOption.isStepSelected);
        },
        tripTypeName() {
            if (this.searchOptions.searchFor === flightConsts.FlightSearchTypes.bySchedule) {
                return this.$t('flight.thisRoute');
            }
            else {
                return this.$t(`flight.${flightConsts.TripType[this.searchOptions.tripType]}`);
            }
        },
        cacheKey() {
            return this.$store.getters['flightStore/cacheKey'](this.resultsIndex);
        },
        searchOptions() {
            return this.$store.getters['flightStore/searchOptions'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        lastStepOption() {
            const lastStepInfo = this.pricingOptionsToShow[this.pricingOptionsToShow?.length - 1].stepInfo;
            let selectedOption = null;
            for (const key in lastStepInfo) {
                lastStepInfo[key].forEach((option) => {
                    if (option.isSelected) {
                        selectedOption = option;
                        return;
                    }
                });
            }
            return selectedOption;
        },
        isAnyStepSelected() {
            return this.pricingOptionsToShow.some((stepOption) => stepOption?.isStepSelected);
        },
        isLastOptionSelected() {
            return this.pricingOptionsToShow[this.tabs?.length - 1]?.isStepSelected;
        },
        isLastTab() {
            return +this.currentTabIndex === this.tabs?.length - 1;
        },
        isAddBtnDisabled() {
            return (!this.isAllStepsSelected ||
                this.pricingOptionsToShow[this.currentTabIndex].stepInfo[this.selectedCabinClass][0]
                    ?.isRestricTravelPolicyViolation);
        },
        isShareMode() {
            return this.$store.getters['tripStore/isShareMode'];
        },
        isProductInShare() {
            const shareProducts = this.$store.getters['tripStore/productsToShare'];
            return shareProducts.products.some((product) => product.cacheKey === this.lastStepOption?.packageKey);
        },
        flightSearchTypes() {
            return flightConsts.FlightSearchTypes;
        },
    },
    watch: {
        // on switch flight - reset and reload
        async 'selectedFlightResult.packageKey'() {
            this.reset();
            await this.loadPricingOptions();
        },
        // on disable share mode - reset  (this cmp is living only on flight results page and will not trigger on other pages)
        isShareMode(isShareMode) {
            if (!isShareMode) {
                this.reset();
            }
        },
    },
    unmounted() {
        eventBus.$off('cancelCartBtnLoading', this.cancelCartBtnLoading);
        eventBus.$off('resetPricingOptionsSelection', this.reset);
        eventBus.$off('handleSelectFlight', this.handleSelectFlight);
    },
});
