export var CarTransmissionType;
(function (CarTransmissionType) {
    CarTransmissionType["Manual"] = "Manual";
    CarTransmissionType["Automatic"] = "Automatic";
})(CarTransmissionType || (CarTransmissionType = {}));
export var Decision;
(function (Decision) {
    Decision["Accept"] = "Accept";
    Decision["Decline"] = "Decline";
})(Decision || (Decision = {}));
export var HotelSpecialRates;
(function (HotelSpecialRates) {
    HotelSpecialRates["AAA"] = "AAA";
    HotelSpecialRates["SNR"] = "AARP";
    HotelSpecialRates["GVT"] = "Government";
    HotelSpecialRates["MIL"] = "Military";
})(HotelSpecialRates || (HotelSpecialRates = {}));
export var HotelRoomType;
(function (HotelRoomType) {
    HotelRoomType["Smoking"] = "Smoking";
    HotelRoomType["NonSmoking"] = "Non smoking";
})(HotelRoomType || (HotelRoomType = {}));
export var HotelBedType;
(function (HotelBedType) {
    HotelBedType["Single"] = "Single";
    HotelBedType["Double"] = "Double";
    HotelBedType["Triple"] = "Triple";
})(HotelBedType || (HotelBedType = {}));
export var FlightClass;
(function (FlightClass) {
    FlightClass["Y"] = "Economy";
    FlightClass["W"] = "Premium economy";
    FlightClass["C"] = "Business";
    FlightClass["F"] = "First";
})(FlightClass || (FlightClass = {}));
export var DbRailCardTypes;
(function (DbRailCardTypes) {
    DbRailCardTypes["BahnCardBusiness25"] = "BahnCard Business 25";
    DbRailCardTypes["BahnCardBusiness50"] = "BahnCard Business 50";
    DbRailCardTypes["DbBahnCard25"] = "DB BahnCard 25";
    DbRailCardTypes["DbBahnCard50"] = "DB BahnCard 50";
    DbRailCardTypes["BahnCardBusiness"] = "DB BahnCard 100";
})(DbRailCardTypes || (DbRailCardTypes = {}));
var LoginType;
(function (LoginType) {
    LoginType[LoginType["Agent"] = 0] = "Agent";
    LoginType[LoginType["Personal"] = 1] = "Personal";
})(LoginType || (LoginType = {}));
export var MealType;
(function (MealType) {
    MealType["regular"] = "Regular";
    MealType["IVML"] = "Indian Vegetarian Meal (IVML)";
    MealType["JPML"] = "Japanese Meal (JPML)";
    MealType["LSML"] = "Low Salt Meal (LSML)";
    MealType["RVML"] = "Vegetarian Raw Meal (RVML)";
    MealType["SFML"] = "Sea Food Meal (SFML)";
    MealType["VJML"] = "Vegetarian Jain Meal (VJML)";
    MealType["VOML"] = "Vegetarian Oriental Meal (VOML)";
    MealType["AVML"] = "Asian Vegetarian Meal (AVML)";
    MealType["BBML"] = "Baby Meal (BBML)";
    MealType["BLML"] = "Bland Meal (BLML)";
    MealType["CHML"] = "Children Meal (CHML)";
    MealType["DBML"] = "Diabetic Meal (DBML)";
    MealType["VGML"] = "Veg Vegan (VGML)";
    MealType["KSML"] = "Kosher Meal (KSML)";
    MealType["FPML"] = "FruitPlatter Meal (FPML)";
    MealType["GFML"] = "Gluten Intolerant Meal (GFML)";
    MealType["HNML"] = "Hindu Meal (HNML)";
    MealType["LCML"] = "Low Calorie Meal (LCML)";
    MealType["LFML"] = "Low Fat Meal (LFML)";
    MealType["VLML"] = "Vegetarian (Milk and Eggs) (VLML)";
    MealType["NLML"] = "Lactose Free (NLML)";
    MealType["MOML"] = "Muslim Meal (MOML)";
})(MealType || (MealType = {}));
export var ValidationType;
(function (ValidationType) {
    ValidationType[ValidationType["None"] = 0] = "None";
    ValidationType[ValidationType["Billback"] = 1] = "Billback";
})(ValidationType || (ValidationType = {}));
