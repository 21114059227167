import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Co2EmissionsSmallLabel',
    props: {},
    data() {
        return {};
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
