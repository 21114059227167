import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
import { Products, ProductStatusName, QuoteStatus } from '@/types/consts';
import { SuppliersId } from '@/modules/products/flight/models/consts';
import { eventBus } from '@/services/event-bus.service';
export default defineComponent({
    name: 'BaseQuoteHeader',
    components: {},
    props: {
        hideDate: {
            type: Boolean,
            default: false,
        },
        destination: {
            type: String,
            default: '',
        },
        quote: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showReissueTooltip: false,
            isLoadingCancelReissue: false,
        };
    },
    created() {
        this.showReissueTooltip = this.isReissue;
    },
    methods: {
        handleDropdownAction(action) {
            switch (action) {
                case 'cancelQuote':
                    this.$emit('cancelQuote', this.quote);
                    break;
                default:
                    return;
            }
        },
        cancelReissue() {
            this.$emit('cancelReissue', this.quote.quoteId);
            this.isLoadingCancelReissue = true;
            this.showReissueTooltip = false;
        },
        closeReissueTooltip() {
            this.showReissueTooltip = false;
        },
        proceedCheckout() {
            eventBus.$emit('highlightCheckoutButton');
        },
        getQuoteStatusText(quote) {
            const statusLabel = 'product.status.' + (quote.productStatusName.charAt(0) + quote.productStatusName.substring(1).toLowerCase());
            return this.$te(statusLabel) ? this.$t(statusLabel) : quote.productStatusName;
        },
        openQuoteErrorModal() {
            eventBus.$emit('openQuoteErrorModal', this.quote);
        },
    },
    computed: {
        quoteStartDate() {
            switch (this.quote.product) {
                case Products.Evolvi:
                case Products.Rail:
                    return utilService.isDateValid(this.quote.journeyStartDate) ? this.quote.journeyStartDate : '';
                default:
                    return utilService.isDateValid(this.quote.startDate) ? this.quote.startDate : '';
            }
        },
        isTicketVoided() {
            const tickets = this.quote.flightPricing?.flatMap((p) => p.PricingTickets);
            const isAnyVoid = tickets?.some((t) => t.TicketStatus === 'Voided');
            return isAnyVoid && this.isCancelled;
        },
        hasTitleSlot() {
            return !!this.$slots['quote-title'];
        },
        quoteTitle() {
            return utilService.getProductTitle(this.quote);
        },
        cancelDisabledTooltipContent() {
            let tooltipContent = this.$t('common.contactAgentForAssistance');
            if (this.isInProgress) {
                tooltipContent = this.$t('common.contactAgentForAssistance');
            }
            else if (!this.canBeCanceled) {
                tooltipContent = this.$t('common.CancelQuotesActionDisabledTicketedQuotes');
            }
            else if (this.isReissue) {
                tooltipContent = this.$t('common.cancelQuotesActionDisabledQuotes');
            }
            else if (this.isOldDBRailAPIQuote) {
                tooltipContent = this.$t('DBrail.oldDBRailQuoteMsgFull');
            }
            return tooltipContent;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isCancelled() {
            return this.quote.productStatusName === ProductStatusName.Cancelled;
        },
        canBeCanceled() {
            return !((this.quote.product === Products.Flight &&
                this.quote.productStatusName === ProductStatusName.TicketingComplete &&
                (this.quote.sourceSystem === SuppliersId[SuppliersId.YPSILON] ||
                    this.quote.sourceSystem === SuppliersId[SuppliersId.FUSION] ||
                    (this.quote.sourceSystem === SuppliersId[SuppliersId.UAPIGALILEO] && this.quote.isLowCost) ||
                    (this.quote.sourceSystem === SuppliersId[SuppliersId.AMADEUS] && this.quote.isLowCost) ||
                    (this.quote.sourceSystem === SuppliersId[SuppliersId.SABRE] && this.quote.isLowCost))) ||
                (this.quote.product === Products.Flight &&
                    (this.quote.productStatusName === ProductStatusName.Finalizing || this.quote.productStatusName === ProductStatusName.TicketingComplete) &&
                    !this.$store.getters['authStore/loggedinUser']?.permissions?.includes('CanCancelTicketedFlightQuotes')));
        },
        isOldDBRailAPIQuote() {
            return (this.quote?.sourceSystem === SuppliersId.DEUTSCHEBAHN && this.quote?.segments?.some((s) => !s.isNewDBRailAPI));
        },
        isInProgress() {
            return this.quote.status === QuoteStatus.InProgress;
        },
        isFinalizing() {
            return this.quote.productStatusName === ProductStatusName.Finalizing;
        },
        isReissue() {
            return this.quote.status === QuoteStatus.PendingReissue;
        },
        isReissueFailed() {
            return this.quote.status === QuoteStatus.ReissueFailed;
        },
        statusClass() {
            return utilService.getQuoteStatusClass(this.quote);
        },
        staySafeDetails() {
            return this.$store.getters['authStore/loggedinUser']?.staySafeDetails;
        },
        isCancelable() {
            //when stay safe product is in active mode (showAutomatedActiveStaySafe staySafeDetails is true) user cannot cancel the quote
            if (this.quote.product !== Products.StaySafe) {
                return true;
            }
            else if (this.quote.product === Products.StaySafe && !this.staySafeDetails?.showAutomatedActiveStaySafe) {
                return true;
            }
            return false;
        },
        isQuoteError() {
            return this.quote.errorMessage?.length && this.quote.status === QuoteStatus.Selected;
        },
    },
});
