import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6e1d8f05"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "co2-emissions-label is-flex is-align-items-center" };
const _hoisted_2 = { class: "mr-1" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { class: "tooltip-content" };
const _hoisted_6 = { class: "header is-flex is-justify-content-space-between" };
const _hoisted_7 = { class: "mb-4" };
const _hoisted_8 = { class: "is-lowercase ml-1" };
const _hoisted_9 = { class: "is-flex is-justify-content-space-between mb-3" };
const _hoisted_10 = { class: "muliSemiBold" };
const _hoisted_11 = { class: "is-flex is-justify-content-space-between mb-3" };
const _hoisted_12 = { class: "muliSemiBold" };
const _hoisted_13 = { class: "is-flex is-justify-content-space-between mb-4" };
const _hoisted_14 = { key: 0 };
const _hoisted_15 = { class: "is-lowercase" };
const _hoisted_16 = { key: 1 };
const _hoisted_17 = { class: "has-text-weight-bold" };
const _hoisted_18 = { key: 0 };
const _hoisted_19 = { class: "emission-description-bold" };
const _hoisted_20 = { class: "emission-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_tooltip = _resolveComponent("el-tooltip");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", {
                class: _normalizeClass(["app-label", _ctx.percentageDiff <= _ctx.lowEmissionCap ? 'success' : 'grayed'])
            }, [
                (_ctx.percentageDiff >= _ctx.highEmissionCap || _ctx.percentageDiff <= _ctx.lowEmissionCap)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_ctx.percentageDiff >= _ctx.highEmissionCap)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "+"))
                            : _createCommentVNode("v-if", true),
                        _createElementVNode("span", null, _toDisplayString(_ctx.percentageDiff) + "% " + _toDisplayString(_ctx.$t('common.emissions')), 1 /* TEXT */)
                    ], 64 /* STABLE_FRAGMENT */))
                    : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('common.average')), 1 /* TEXT */))
            ], 2 /* CLASS */)
        ]),
        (!_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_el_tooltip, {
                key: 0,
                placement: "top",
                effect: "light",
                trigger: "click",
                "popper-class": "co2-emissions-tooltip",
                visible: _ctx.isTooltipOpen
            }, {
                content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("h6", _hoisted_7, [
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.emissionsClass)
                                }, _toDisplayString(_ctx.emissionsText), 3 /* TEXT, CLASS */),
                                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('common.emissions')), 1 /* TEXT */)
                            ]),
                            _createElementVNode("span", {
                                class: "material-symbols-outlined is-size-5 is-clickable",
                                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.isTooltipOpen = false))
                            }, " close ")
                        ]),
                        _createElementVNode("p", _hoisted_9, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('flight.thisFlight')), 1 /* TEXT */),
                            _createElementVNode("span", _hoisted_10, _toDisplayString(Math.round(_ctx.carbonQuantity)) + "kg CO2 ", 1 /* TEXT */)
                        ]),
                        _createElementVNode("p", _hoisted_11, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.typicalForRoute')), 1 /* TEXT */),
                            _createElementVNode("span", _hoisted_12, _toDisplayString(Math.round(_ctx.emissionsAverage)) + "kg CO2 ", 1 /* TEXT */)
                        ]),
                        _createElementVNode("p", _hoisted_13, [
                            (_ctx.percentageDiff >= _ctx.highEmissionCap || _ctx.percentageDiff <= _ctx.lowEmissionCap)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('common.total')) + " " + _toDisplayString(Math.abs(_ctx.percentageDiff)) + "% ", 1 /* TEXT */),
                                    _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.percentageDiff >= _ctx.highEmissionCap ? _ctx.$t('common.higher') : _ctx.$t('common.lower')), 1 /* TEXT */)
                                ]))
                                : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t('common.totalChange')), 1 /* TEXT */)),
                            _createElementVNode("span", _hoisted_17, [
                                (_ctx.carbonQuantity - _ctx.emissionsAverage > 0)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_18, "+"))
                                    : _createCommentVNode("v-if", true),
                                _createTextVNode(_toDisplayString(Math.round(_ctx.carbonQuantity - _ctx.emissionsAverage)) + "kg CO2 ", 1 /* TEXT */)
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('flight.carbonEmissionsMessagePart1')), 1 /* TEXT */),
                        _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('flight.carbonEmissionsMessagePart2')), 1 /* TEXT */)
                    ])
                ]),
                default: _withCtx(() => [
                    _createElementVNode("span", {
                        class: "material-symbols-outlined is-size-5 is-clickable info-icon",
                        onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.isTooltipOpen = !_ctx.isTooltipOpen))
                    }, " info ")
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["visible"]))
            : _createCommentVNode("v-if", true)
    ]));
}
