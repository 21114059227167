import { defineComponent } from 'vue';
import { FLIGHT_FULL_NAME_DISPLAY_LENGTH, FLIGHT_MEAL_DISPLAY_LENGTH, postTicketingProperties, } from '@/modules/products/flight/models/consts';
import appCarousel from '@/components/app-carousel.vue';
export default defineComponent({
    name: 'FlightPostTicketingDetails',
    components: { appCarousel },
    props: {
        flight: {
            required: true,
            type: Object,
        },
        isCartDetails: {
            type: Boolean,
            default: false,
        },
        isTicketed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isShowMoreTravelerInfo: [],
        };
    },
    created() {
        if (this.isTicketed) {
            // populating isShowMoreTravelerInfo array with false as default - this array's length is the number of passengers
            this.isShowMoreTravelerInfo = this.flight.travelerFlightInfo.map((info) => false);
        }
    },
    methods: {
        travelerInfoForDisplay(travelerInfo) {
            return {
                fullName: `${travelerInfo.firstName} ${travelerInfo.lastName}`,
                ticketNumber: travelerInfo.ticketNumber,
                frequentFlyer: travelerInfo.fqtv,
                meal: travelerInfo.meal,
                seats: travelerInfo.seat,
            };
        },
        travelerMoreDetailsForDisplay(travelerInfo) {
            return [travelerInfo.passport, this.formattedDateStr(travelerInfo.birthDate), travelerInfo.gender];
        },
        formattedDateStr(dateStr) {
            return this.$filters.date(dateStr, 'dd MMM yy');
        },
        toggleMoreTravelerInfo(travelerIndex) {
            if (!this.isShowMoreTravelerInfo[travelerIndex]) {
                this.isShowMoreTravelerInfo[travelerIndex] = true;
            }
            else {
                this.isShowMoreTravelerInfo[travelerIndex] = false;
            }
        },
    },
    computed: {
        postTicketingTableHeaders() {
            return [
                `${this.$t('flight.traveler')}`,
                `${this.$t('flight.ticketNumber')}`,
                `${this.$t('flight.frequentFlyer')}`,
                `${this.$t('flight.extras.meal')}`,
                `${this.$t('common.seat')}`,
            ];
        },
        moreDetailsHeaders() {
            return [`${this.$t('flight.passport')}`, `${this.$t('flight.dateOfBirth')}`, `${this.$t('flight.gender')}`];
        },
        postTicketingProperties() {
            return postTicketingProperties;
        },
        fullNameMaxLength() {
            return FLIGHT_FULL_NAME_DISPLAY_LENGTH;
        },
        mealMaxLength() {
            return FLIGHT_MEAL_DISPLAY_LENGTH;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
