import { router } from '@/router';
import { $t } from '@/plugins/i18n';
import * as _ from 'lodash';
import { loggerService } from '@/services/logger.service';
import { Products, UnitsSystem, QuoteStatus } from '@/types/consts';
import { filterService } from '@/services/filter.service';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
import setYear from 'date-fns/setYear';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInHours from 'date-fns/differenceInHours';
import { differenceInCalendarDays, isWithinInterval } from 'date-fns';
import { amtrakService } from '@/modules/products/rails/amtrak/services/amtrak.service';
import store from '@/store/store';
import { helpers } from '@vuelidate/validators';
export const utilService = {
    capitalizeFirstLetter,
    lowerCaseFirstLetter,
    debounce,
    delay,
    getArrayFromEnum,
    getCurrencyCode,
    //getFormattedDate,
    getInitials,
    getRandomFloat,
    getRandomInt,
    getRandomItem,
    getRandomItems,
    makeId,
    makedigitsId,
    makeGUID,
    deepClone,
    getEnumToDictionary,
    minMax,
    convertMetersToMiles,
    convertMilesToMeters,
    detectBrowser,
    buildSearchQueryParams,
    parseUrlParams,
    routeHistoryPushState,
    getDayName,
    buildNestedObject,
    fixWrongLatinEncoding,
    curlyBracketsReplace,
    downloadFileFromStream,
    downloadFileFromURL,
    generateGuidId,
    setPageTitle,
    setProductPageTitle,
    normalizeDateToUTC,
    getDateOfDayBefore,
    formattedTime,
    getSelectOptionsFromEnum,
    getQuoteStatusClass,
    setProductList,
    isProductAccessible,
    isCanAddManualQuote,
    railTypesOptions,
    getProductTitle,
    getSearchOptionsFromArray,
    getProductDates,
    disableDatesByAge,
    capitalizeObjectKeys,
    splitPhoneToParts,
    downloadFile,
    openNewWindow,
    isDateValid,
    getUserTimeFormat,
    calculateTimeDifference,
    getCarbonQuantityInKG,
    formatDateTime,
    getObjectByPath,
    arrayValidator,
    convertUnformattedUTCToLocalDate,
    getEncryptedStr,
};
// function cleanQueryParams(filterBy) {
//     return Object.keys(filterBy).reduce((acc, key) => {
//         const currVal = filterBy[key];
//         const isArray = Array.isArray(currVal);
//         if ((!!currVal && !isArray) || (isArray && currVal.length > 0) || (currVal === false)) {
//             acc[key] = filterBy[key];
//         }
//         return acc;
//     }, {});
/// }
/**
 * This func handles a case where validation is required for an array
 * it gets the array and array of validation (Vuelidate validators) required to each item in the array
 *
 * @param {Array} rules - An array of Vuelidate validator rules to apply to each element in the array.
 * @param {number} length - The required length of the array (optional).
 * @returns {Function} - A Vuelidate validator function.
 */
function arrayValidator(rules, length = 0) {
    return helpers.withParams({ type: 'arrayValidator' }, (value) => {
        if (!Array.isArray(value) || (length !== 0 && value.length !== length)) {
            return false;
        }
        return value.every((item) => {
            return rules.every((rule) => rule.$validator(item));
        });
    });
}
/**
 * Retrieve the value at a given path from a nested object.
 *
 * This function traverses the object based on the dot-separated path and
 * returns the value found at that path. If the path does not exist, it returns undefined.
 *
 * @param {Object} obj - The object to traverse.
 * @param {string} path - The dot-separated path to the desired value.
 * @returns {*} - The value at the specified path or undefined if the path does not exist.
 *
 * @example
 * const obj = { a: { b: { c: 'This is the translation' } } };
 * const value = getObjectByPath(obj, 'a.b.c'); // 'This is the translation'
 */
function getObjectByPath(obj, path) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}
//add local pc location offset to date, i.e, if user is in australia, adds 10 hours to date, as per utc offset.
//input - date string indicating UTC date without UTC formatting
//returns - Date Object formatted for proper use.
function convertUnformattedUTCToLocalDate(dateUTC) {
    const timezoneOffset = new Date().getTimezoneOffset();
    const date = new Date(Date.parse(dateUTC));
    return date;
}
function getUserTimeFormat(dateString, expectedFormat = 'HH:mm') {
    if (!dateString || dateString === '') {
        return dateString;
    }
    // Handle "24:00" case
    let formattedDateString = dateString === '24:00' ? '00:00' : dateString;
    const userSystemUnits = store.getters['userStore/userUnitsSystem'];
    const isMetric = userSystemUnits === UnitsSystem.Metric;
    const orgDate = new Date();
    // Check for HH:mm format
    const hhmmRegex = /^([01]\d|2[0-3]):[0-5]\d$/;
    // Check for HH:mm:ss format
    const hhmmssRegex = /^([01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;
    if (dateString && dateString.includes('.')) {
        dateString = dateString.split('.')[0];
        formattedDateString = dateString === '24:00' ? '00:00' : dateString;
    }
    if (hhmmssRegex.test(dateString)) {
        expectedFormat = 'HH:mm:ss';
    }
    else if (hhmmRegex.test(dateString)) {
        expectedFormat = 'HH:mm';
    }
    const date = parse(formattedDateString, expectedFormat, orgDate);
    return format(date, isMetric ? 'HH:mm' : 'hh:mm a');
}
function isDateValid(formattedDateString) {
    const date = new Date(formattedDateString);
    return isValid(date) && date.getTime() >= 0;
}
/**
 * Formats a given ISO date string to a specified format pattern.
 * Prefer to use 'date' filter !
 *
 * @param {string} dateString - The ISO date string to be formatted.
 * @param {string} [formatPattern="yyyy-MM-dd'T'HH:mm:ss.SSSX"] - The pattern to format the date string. Defaults to "yyyy-MM-dd'T'HH:mm:ss.SSSX".
 * @returns {string} - The formatted date string according to the provided format pattern.
 *
 * @example
 * // Example usage:
 * const dateString = '2024-06-24T15:45:00.000Z';
 * console.log(formatDateTime(dateString)); // Output: 2024-06-24T15:45:00.000Z
 * console.log(formatDateTime(dateString, 'yyyy-MM-dd')); // Output: 2024-06-24
 *
 * @see {@link https://date-fns.org/v2.28.0/docs/format|date-fns format documentation}
 * @see {@link https://date-fns.org/v2.28.0/docs/parseISO|date-fns parseISO documentation}
 */
function formatDateTime(dateString, formatPattern = 'yyyy-MM-dd"T"HH:mm:ss.SSSX') {
    const formattedDate = format(parseISO(dateString), formatPattern);
    return formattedDate;
}
function getProductTitle(quote, isQuoteHeader = true) {
    // TODO: Replicate logic for the different products
    let str = '';
    if (quote.product === Products.Car) {
        if (isQuoteHeader) {
            str = `${$t('car.hireACarAt')} ${quote.carSegment.pickUpLocationName}`;
            return str;
        }
        else {
            const numOfDoors = quote.carSegment.numberOfDoors
                ? filterService.translationFallback(`car.doorsNum.${quote.carSegment.numberOfDoors}`, quote.carSegment.numberOfDoors)
                : '';
            const carType = quote.carSegment.carType || '';
            const carClass = quote.carSegment.carClass
                ? filterService.translationFallback(`car.carClasses.${quote.carSegment.carClass}`, quote.carSegment.carClass)
                : '';
            str = `${carClass} ${numOfDoors} ${carType ? `(${carType})` : ''}`;
            return str;
        }
    }
    else if (quote.product === Products.Flight) {
        str = `${$t('flight.flightTo')} ${quote.destinationCityName}`;
        return str;
    }
    else if (quote.product === Products.EuRail || quote.product === Products.Rail) {
        switch (quote.sourceSystem) {
            case 'DEUTSCHEBAHN':
                str = `${$t('products.dbRail')}`;
                break;
            default:
                str = `${$t('products.euRail')}`;
                break;
        }
        return str + ` ${$t('common.to')} ${quote.segments[0].destinationStationFullName}`;
    }
    else if (quote.product === Products.Evolvi) {
        str = `${$t('products.uk-rail')} ${$t('common.to')} ${quote.segments[0].destination}`;
        return str;
    }
    if (quote.product === Products.Amtrak) {
        const isMultiCity = amtrakService.isMultiCityQuote(quote);
        if (isMultiCity) {
            return `${$t('amtrak.amtrakRailsTo')} Multiple Destinations`;
        }
        return `${$t('amtrak.amtrakRailsTo')} ${quote.journeySegments[0].arrivalStationFullName}`;
    }
    if (quote.product === Products.GroundService) {
        str = `${$t('groundTransport.transportTo')} ${quote.dropoffAddress.street}`;
        return str;
    }
    if (quote.product === Products.StaySafe) {
        return `${$t('staySafe.atriisCare')}`;
    }
    if (quote.product === Products.Compensation) {
        return `${$t('compensation.climateContribution')}`;
    }
    if (quote.product === Products.Parking) {
        str = `${$t('parking.parkAt')}  ${quote.segment.airportCode}  ${$t('autocomplete.airport')} `;
        return str;
    }
    if (quote.product === Products.Hotel) {
        const daysDiff = differenceInCalendarDays(new Date(quote.checkoutDate), new Date(quote.checkinDate));
        str = `${daysDiff}  ${daysDiff === 1 ? $t('cart.nightAt') : $t('cart.nightsAt')} ${quote.cityName} `;
        return str;
    }
    // manual quotes types
    if (quote.product === Products.Manual) {
        return quote.title;
    }
    return '';
}
function getSelectOptionsFromEnum(obj, translateKey) {
    return Object.entries(obj).map(([key, value]) => ({
        label: translateKey ? $t(`${translateKey}.${value}`) : key,
        value: value,
    }));
}
function _formattedDateStr(dateStr) {
    const date = dateStr.split('T')[0];
    const dateFormat = 'EEE, dd MMM';
    const formattedDate = format(new Date(date), dateFormat);
    return formattedDate;
}
function getProductDates(quote) {
    let startDate;
    let endDate;
    switch (quote.product || quote.productName) {
        case Products.Hotel:
            startDate = quote.checkinDate || quote.checkInDate;
            endDate = quote.checkoutDate || quote.checkOutDate;
            break;
        case Products.HeathrowExpress:
            startDate = quote.startDate;
            break;
        case Products.Parking:
            startDate = quote.segment.departureDate;
            endDate = quote.segment.arrivalDate;
            break;
        case Products.GroundService:
            startDate = quote.pickupDateTime;
            break;
        case Products.Car:
            startDate = quote.carSegment.pickUpDate;
            endDate = quote.carSegment.dropOffDate;
            break;
        case Products.Flight:
            startDate = quote.segments[0].flights[0].depDate;
            endDate =
                quote.segments[quote.segments.length - 1].flights[quote.segments[quote.segments.length - 1].flights.length - 1]
                    .arrDate;
            break;
        case Products.Rail:
            startDate = quote.segments[0].arrivalDateTime;
            endDate = quote.segments[quote.segments.length - 1].departureDateTime;
            break;
        default:
            startDate = '';
            endDate = '';
            break;
    }
    return `${startDate ? _formattedDateStr(startDate) : ''} ${endDate ? '-' : ''} ${endDate ? _formattedDateStr(endDate) : ''}`;
}
function normalizeDateToUTC(date) {
    const newDate = new Date(date);
    const userTimezoneOffset = newDate.getTimezoneOffset() * 60000;
    const normalizedDate = new Date(newDate.getTime() - userTimezoneOffset);
    return normalizedDate.toISOString();
}
function getDateOfDayBefore(dateStr) {
    const d = new Date(dateStr || Date.now());
    d.setDate(d.getDate() - 1);
    return d.toISOString();
}
function getQuoteStatusClass(quote) {
    if (!quote.productStatusName) {
        return '';
    }
    if (quote.errorMessage?.length && quote.status === QuoteStatus.Selected) {
        return 'quote-error';
    }
    const name = quote.productStatusName;
    return name.toLowerCase().split(' ').join('-');
}
function isProductAccessible(product, loggedUser) {
    const isMobile = store.getters['isMobile'];
    if (isMobile) {
        if (![
            Products.Flight,
            Products.Hotel,
            Products.UkRail,
            Products.Evolvi,
            Products.Rail,
            Products.Car,
        ].includes(product)) {
            return false;
        }
    }
    return loggedUser.products.includes(product);
}
function isCanAddManualQuote(loggedinUser) {
    const isMobile = store.getters['isMobile'];
    if (isMobile) {
        return false;
    }
    return loggedinUser.permissions.includes('CanViewManualQuote');
}
function setProductList(products, loggedUser) {
    const productsArray = [
        products.Flight,
        products.Hotel,
        products.Car,
        products.Rail,
        products.GroundService,
        products.Parking,
        products.Manual,
    ];
    const relevantProducts = [];
    productsArray.forEach((product) => {
        if (product === products.Rail) {
            const rails = railTypesOptions(loggedUser);
            if (rails && rails.length > 0) {
                relevantProducts.push(product);
            }
        }
        else if (product === products.Manual) {
            if (isCanAddManualQuote(loggedUser)) {
                relevantProducts.push(product);
            }
        }
        else {
            if (isProductAccessible(product, loggedUser)) {
                relevantProducts.push(product);
            }
        }
    });
    const obj = {
        firstFive: [],
        other: [],
    };
    obj.firstFive = relevantProducts.slice(0, 5);
    obj.other = relevantProducts.slice(5);
    return obj;
}
function railTypesOptions(loggedUser) {
    const options = [];
    if (isProductAccessible(Products.Evolvi, loggedUser)) {
        options.push({
            value: 'UK Rail',
            label: Products.Evolvi.toString(),
        });
    }
    if (isProductAccessible(Products.Amtrak, loggedUser)) {
        options.push({
            value: 'Amtrak',
            label: Products.Amtrak.toString(),
        });
    }
    if (isProductAccessible(Products.HeathrowExpress, loggedUser)) {
        options.push({
            value: 'Heathrow Express',
            label: Products.HeathrowExpress.toString(),
        });
    }
    if (isProductAccessible(Products.Rail, loggedUser)) {
        options.push({
            value: 'EU Rail',
            label: Products.Rail,
        });
    }
    if (isProductAccessible(Products.DBrail, loggedUser)) {
        options.push({
            value: 'DB Rail',
            label: Products.DBrail.toString(),
        });
    }
    return options;
}
// extract product name from route object and rename page title for product result pages.
function setProductPageTitle(route) {
    const { params: { tripId }, } = route;
    const product = route.meta?.product;
    const pageTitle = `Trip ${tripId} > ${product}`;
    setPageTitle(pageTitle);
}
// rename a page's title, allows for an all new page title or expanding upon the existing title.
function setPageTitle(pageTitle, isNestedPage = false) {
    if (isNestedPage) {
        const parentName = document.title;
        return (document.title = parentName + ' > ' + pageTitle);
    }
    document.title = pageTitle;
}
function delay(ms = 1500) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}
function detectBrowser(userAgent) {
    let browserName = '';
    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'chrome';
    }
    else if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'firefox';
    }
    else if (userAgent.match(/safari/i)) {
        browserName = 'safari';
    }
    else if (userAgent.match(/opr\//i)) {
        browserName = 'opera';
    }
    else if (userAgent.match(/edg/i)) {
        browserName = 'edge';
    }
    else {
        browserName = 'No browser detection';
    }
    return browserName;
}
function debounce(func, delay = 700) {
    let timeout;
    return function executedFunction(args) {
        const later = () => {
            clearTimeout(timeout);
            func(args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, delay);
    };
}
function deepClone(element) {
    return JSON.parse(JSON.stringify(element));
}
function getRandomInt(min, max) {
    //exclusive min, inclusive max
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}
function getRandomFloat(min, max) {
    return Math.random() * (max - min) + min; //The maximum is exclusive and the minimum is inclusive
}
function getRandomItem(items) {
    return items[Math.floor(Math.random() * items.length)];
}
function getRandomItems(items, minReturn = 0) {
    const itemsCopy = [...items];
    const res = [];
    const resItemsNum = getRandomInt(minReturn, itemsCopy.length + 1); //generates random amount of items for res.length
    for (let index = 0; index < resItemsNum; index++) {
        const idx = Math.floor(Math.random() * itemsCopy.length); //generates a random idx (from itemsCopy)
        res.push(itemsCopy[idx]);
        itemsCopy.splice(idx, 1);
    }
    return res;
}
function makeId(length = 8) {
    let txt = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        txt += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return txt;
}
function makedigitsId(length = 8) {
    let txt = '';
    const possible = '0123456789';
    for (let i = 0; i < length; i++) {
        txt += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return parseInt(txt, 10);
}
function makeGUID() {
    return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
function getInitials(name) {
    const splitedName = name.split(' ');
    let initials = '';
    const firstName = splitedName.shift();
    if (firstName && firstName.trim.length > 0) {
        initials = firstName.charAt(0);
    }
    const lastName = splitedName.pop();
    if (lastName && lastName.trim.length > 0) {
        initials = initials + lastName.charAt(0);
    }
    return initials.toUpperCase();
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
function lowerCaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}
function getDayName(num) {
    const numDayMap = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    return numDayMap[num];
}
function getCurrencyCode(currency) {
    const currSymbolsMap = {
        USD: '$',
        GBP: '£',
        EUR: '€',
        AFN: '؋',
        THB: '฿',
        BRL: 'R$',
        TRY: '₺',
        ILS: '₪',
        VND: '₫',
        UAH: '₴',
        SEK: 'kr',
    };
    return currSymbolsMap[currency] || currency;
}
function getEnumToDictionary(enumObj) {
    const dict = {};
    const entries = Object.entries(enumObj).filter((item) => typeof item[1] === 'number');
    entries.forEach((el) => (dict[el[1]] = el[0]));
    return dict;
}
function minMax(items, getPropery) {
    let min = getPropery(items[0]);
    let max = getPropery(items[0]);
    items.forEach((itm) => {
        if (min > getPropery(itm)) {
            min = getPropery(itm);
        }
        if (max < getPropery(itm)) {
            max = getPropery(itm);
        }
    });
    return [min, max];
}
function getArrayFromEnum(enumObj) {
    return Object.values(enumObj).filter((value) => typeof value === 'string');
}
function getSearchOptionsFromArray(array, labelKey, valueKey, isFilterOutNoValue = false) {
    return array
        .filter((item) => !isFilterOutNoValue || item[valueKey]) //  if isFilterOutNoValue, dont return values that dont exist
        .map((item) => ({ label: item[labelKey] || item, value: item[valueKey] || item }));
}
const milesMeterRate = 1609.34;
function convertMetersToMiles(meters) {
    return meters / milesMeterRate;
}
function convertMilesToMeters(miles) {
    return miles * milesMeterRate;
}
// this function receives as input querystring and tries to parse
// query keys and its values which buold the object (model)
function parseUrlParams(querystring) {
    const params = new URLSearchParams(querystring);
    const obj = {};
    for (const key of params.keys()) {
        let value;
        if (params.getAll(key).length > 1) {
            value = params.getAll(key);
        }
        else {
            value =
                params.get(key)?.split('=').length > 1
                    ? parseUrlParams(params.get(key))
                    : params.get(key)?.replace(/_/g, ' ');
        }
        obj[key] = isNaN(value) ? value : +value;
    }
    return obj;
}
// this function receive any type of object and creates query string
// based on its properties, in case the ignoredFields provided - such
// fields will be not included into query string
function buildSearchQueryParams(params, pushHistoryState, ignoredFields) {
    const flatObjectProperties = getFlatObjectProperties(params, {}, ignoredFields);
    const query = Object.keys(flatObjectProperties)
        .map((key) => {
        if (!ignoredFields?.includes(key.toLocaleLowerCase())) {
            return `${key}=${encodeURIComponent(`${flatObjectProperties[key]}`.replace(/\s/g, '_'))}`;
        }
    })
        .join('&');
    if (pushHistoryState) {
        routeHistoryPushState(query);
    }
    return query;
}
//returns flat object (without hierachy levels) with properties and related values
function getFlatObjectProperties(params, newFlatObject = {}, ignoredFields) {
    Object.keys(params).map((key) => {
        if (params[key] !== null && typeof params[key] === 'object') {
            return getFlatObjectProperties(_.get(params, key), newFlatObject, ignoredFields);
        }
        if (!ignoredFields?.includes(key.toLocaleLowerCase())) {
            newFlatObject[_.camelCase(key)] = params[key];
        }
    });
    return newFlatObject;
}
//build nested object for the specific model based on provided properties
function buildNestedObject(parsedObj, nestedProperties, camelcase) {
    const obj = {};
    const properties = _.keys(parsedObj);
    const formatted = nestedProperties.map((element) => {
        return _.camelCase(element);
    });
    properties.forEach((prop) => {
        if (formatted.includes(_.camelCase(prop))) {
            const formattedProp = camelcase ? _.camelCase(prop) : _.upperFirst(prop);
            obj[formattedProp] = deepClone(parsedObj[prop]);
        }
    });
    return obj;
}
// function only replaces url without reloading a whole page
function routeHistoryPushState(params) {
    history.replaceState({}, '', router.currentRoute.value.path + '?' + params);
}
// fix wrongly encoded latin saved in our DB
function fixWrongLatinEncoding(str) {
    try {
        return decodeURIComponent(encodeURIComponent(str));
    }
    catch (err) {
        return str;
    }
}
function curlyBracketsReplace(msg, data) {
    // the function will replace each {CurlyBrackets} in the string with the data of the [Array].
    // data = ['one',two','three'];
    // msg = "counting {one}, {2} and {3}";
    // results: "counting one, two and three"
    data.forEach((d) => {
        msg = msg.substring(0, msg.indexOf('{')) + d + msg.substring(msg.indexOf('}') + 1, msg.length);
    });
    return msg;
}
async function downloadFileFromURL(url, fileName) {
    try {
        const response = await fetch(url);
        // Check if the response is successful
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        // Convert the response to a Blob
        const blob = await response.blob();
        // Create a temporary URL for the Blob
        const blobURL = window.URL.createObjectURL(blob);
        // Create a link element
        const a = document.createElement('a');
        a.href = blobURL;
        a.download = fileName;
        // Append the link to the body (required for some browsers)
        document.body.appendChild(a);
        // Programmatically click the link to start the download
        a.click();
        // Remove the link from the DOM
        document.body.removeChild(a);
        // Revoke the Blob URL to free up resources
        window.URL.revokeObjectURL(blobURL);
    }
    catch (error) {
        throw new Error('Error downloading the file:', error);
    }
}
async function downloadFileFromStream(data, fileName) {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the element again
}
function generateGuidId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
function formattedTime(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours && minutes ? `${hours}h ${minutes}m` : hours && !minutes ? `${hours}h` : `${minutes}m`;
}
function disableDatesByAge(dateToCheck, minAge, maxAge) {
    const today = new Date();
    const minDate = setYear(today, today.getFullYear() - maxAge);
    const maxDate = setYear(today, today.getFullYear() - minAge);
    return !isWithinInterval(dateToCheck, { start: minDate, end: maxDate });
}
function capitalizeObjectKeys(object) {
    const capitalizedObject = {};
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
            capitalizedObject[capitalizedKey] = object[key];
        }
    }
    return capitalizedObject;
}
function openNewWindow(isAlwaysNewTab, url, target) {
    const isMobile = store.getters['isMobile'];
    let res;
    if (isAlwaysNewTab) {
        res = window.open(url, target);
    }
    else if (isMobile) {
        router.push(url);
    }
    else {
        res = window.open(url, target);
    }
    return res;
}
function splitPhoneToParts(phone, separate = '-') {
    if (!phone) {
        return ['', '', ''];
    }
    //phoneParts[0] is the country code
    //phoneParts[1] is the area code
    //phoneParts[2] is the number
    let phoneParts = phone.split(separate);
    if (phoneParts.length !== 3) {
        phone = phone.replaceAll(separate, '');
        phoneParts = [];
        phoneParts[0] = phone.substring(0, 2) || '';
        phoneParts[1] = phone.substring(2, 5);
        phoneParts[2] = phone.substring(5, phone.length);
    }
    return phoneParts;
}
function downloadFile(file, name) {
    const src = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = src;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
function calculateTimeDifference(utcDateTimeString) {
    // Convert the UTC date-time string to a Date object
    const dateTime = new Date(utcDateTimeString);
    // Get the current UTC date and time
    const now = new Date();
    // Calculate the difference in hours and minutes
    const diffHours = differenceInHours(now, dateTime);
    const diffMinutes = differenceInMinutes(now, dateTime) % 60;
    // Format the result as HH:mm
    const formattedResult = format(new Date().setHours(diffHours, diffMinutes), 'HH:mm');
    return { hours: diffHours, minutes: diffMinutes };
}
function getCarbonQuantityInKG(carbonQuantity, carbonUnit) {
    let convertedQuantity = carbonQuantity;
    switch (carbonUnit) {
        case 'gram':
            convertedQuantity /= 1000; // 1 kg = 1000 grams
            break;
        case 'tonne':
            convertedQuantity *= 1000; // 1 tonne = 1000 kilograms
            break;
        case 'kg':
            // Quantity is already in kilograms, no conversion needed
            break;
        default:
            loggerService.error('Unknown carbonUnit specified', carbonUnit);
            return null;
    }
    return convertedQuantity;
}
function getEncryptedStr(str = '', lastDigitCount = 3) {
    str += '';
    if (!str) {
        return '';
    }
    return '*********' + str.substr(-lastDigitCount);
}
