import { eventBus } from '@/services/event-bus.service';
import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
import { SeatTypes } from '../../../modules/products/flight/models/consts';
export default defineComponent({
    name: 'SeatRichInformationPopup',
    data() {
        return {
            showModal: false,
            wasCheck: false,
            isProceedBtnLoading: false,
            seat: {},
            rowIndex: 0,
            rowNumber: 0,
        };
    },
    created() {
        eventBus.$on('showSeatRichInfoPopup', this.setPopup);
    },
    methods: {
        setPopup(seatObj) {
            this.seat = seatObj.seat;
            this.rowIndex = seatObj.index;
            this.rowNumber = seatObj.rowNumber;
            this.showModal = !this.showModal;
        },
        getCurrencyCode() {
            return utilService.getCurrencyCode(this.seat.displayCurrency);
        },
        getFixedPrice() {
            return Math.round(Number(this.seat.displayPrice)).toFixed(2);
        },
        getAvaiablityTest() {
            const seatStatus = !this.seat?.seatAvailability
                ? this.$t('flight.extras.seats.occupied')
                : this.seat.seatCharacteristics.includes(SeatTypes.PreferredSeat)
                    ? this.$t('flight.extras.seats.preferred')
                    : this.$t('flight.extras.seats.available');
            return seatStatus;
        },
        getSeatName() {
            let text = '';
            if (this.seat.seatDescription?.length > 0) {
                text = this.seat.seatDescription;
            }
            else if (this.rowNumber || this.seat?.seatNumber) {
                text = this.rowNumber + this.seat.seatNumber;
            }
            return text;
        },
        close() {
            this.showModal = false;
        },
        selectSeat() {
            eventBus.$emit('seatSelectedFromToolTip', { index: Number(this.rowIndex), seat: this.seat });
            this.showModal = false;
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
    unmounted() {
        eventBus.$off('showSeatRichInfoPopup', this.setPopup);
    },
});
