<template>
  <section class="app-no-results flex is-flex-direction-column align-center justify-center">
    <span class="material-symbols-outlined warning-icon">info</span>
    <slot name="no-result-title"
      ><h3>{{ $t('common.noResults') }}</h3></slot
    >
    <p>{{ description }}</p>
    <div class="is-flex">
      <app-btn
        v-if="isResetFilters && isDueToFilter"
        :size="isMobile ? 'small' : 'medium'"
        type="primary"
        @click="resetFilters">
        {{ $t('btn.resetFilters') }}
      </app-btn>
      <app-btn type="secondary" :size="isMobile ? 'small' : 'medium'" @click="openDrawer">
        {{ $t('trip.editSearch') }}
      </app-btn>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';

import { eventBus } from '@/services/event-bus.service';
import { $t } from '@/plugins/i18n';

export default defineComponent({
  name: 'AppNoResultsSearch',
  props: {
    product: {
      type: String,
      default: '',
    },
    description: {
      default: $t('common.noResultsFilter'),
      type: String,
    },
    isResetFilters: {
      type: Boolean,
      default: false,
    },
    isDueToFilter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openDrawer() {
      eventBus.$emit('openDrawer', this.product);
    },
    async resetFilters() {
      this.$emit('resetFilters', this.product);
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.app-no-results {
  text-align: center;
  padding: rem(36px);
  .material-symbols-outlined {
    font-size: rem(70px);
    color: $blue-info;
    margin-bottom: rem(30px);
  }

  .warning-icon {
    color: $orange-400;
  }

  p {
    margin-bottom: rem(24px);
  }
}
</style>
