import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-68c047e3"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "flight-list"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_flight_preview = _resolveComponent("flight-preview");
    const _component_app_no_results_search = _resolveComponent("app-no-results-search");
    return (_ctx.flightResults && _ctx.flightResults.length)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flightResults, (flightResult, index) => {
                return (_openBlock(), _createBlock(_component_flight_preview, {
                    key: `${flightResult.packageKey}-${index}`,
                    flightResult: flightResult,
                    id: index === _ctx.flightResults.length - 1 ? 'last-item' : '',
                    selectedSegments: _ctx.selectedSegments,
                    onFlightSelected: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('flightSelected', $event))),
                    onAddToCart: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('addToCart', $event))),
                    onSelectFlightBySchedule: ($event) => (_ctx.$emit('selectFlightBySchedule', flightResult)),
                    onOpenBrandedFares: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('openBrandedFares', $event)))
                }, null, 8 /* PROPS */, ["flightResult", "id", "selectedSegments", "onSelectFlightBySchedule"]));
            }), 128 /* KEYED_FRAGMENT */))
        ]))
        : (_openBlock(), _createBlock(_component_app_no_results_search, {
            key: 1,
            onResetFilters: _ctx.resetFilters,
            isDueToFilter: _ctx.totalResultsNumber > 0,
            product: _ctx.flightProduct,
            isResetFilters: !!(!_ctx.flightResults.length && (_ctx.flightsRes && _ctx.flightsRes.length && _ctx.flightsRes[0].results.length))
        }, null, 8 /* PROPS */, ["onResetFilters", "isDueToFilter", "product", "isResetFilters"]));
}
