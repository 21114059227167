import { ActionTypes } from '@/types/consts';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'FlightCapacityViolationWarning',
    props: {
        violationParameters: {
            required: true,
        },
    },
    methods: {
        async proceedAnyway() {
            if (this.violationParameters?.isFromBySchedule) {
                this.$emit('selectFlightBySchedule', this.violationParameters.flightPackage, true);
            }
            else if (this.violationParameters?.isShareQuote) {
                this.violationParameters.callBack();
                this.violationParameters.closeModal();
            }
            else {
                this.$emit('handleAddToCart', this.violationParameters.flightPackage, true);
            }
        },
    },
    computed: {
        isSingleFlight() {
            return this.violationParameters?.violations?.TravelPolicyViolationExplanation?.indexOf('<li>') === -1;
        },
        isRestrictViolation() {
            return this.violationParameters?.violations?.ActionType === ActionTypes.RestrictSelection;
        },
        flightList() {
            return this.isSingleFlight
                ? ''
                : this.violationParameters?.violations?.TravelPolicyViolationExplanation?.split(';;')[1];
        },
    },
});
