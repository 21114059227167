import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
import { loggerService } from '@/services/logger.service';
import { AddTravelerType } from '@/types/consts';
import userAutocomplete from '@/components/user-autocomplete/user-autocomplete.vue';
import addGuestForm from '@/components/trip/add-guest-form/add-guest-form.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import { eventBus } from '@/services/event-bus.service';
export default defineComponent({
    components: { userAutocomplete, addGuestForm, formValidationMsg },
    props: {
        traveler: {
            type: Object,
            required: true,
        },
        travelerIdx: {
            type: Number,
            required: true,
        },
        showImtBtn: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            travelerToEdit: null,
            isImtBtnLoading: false,
            isGuestBtnLoading: false,
            isGuestMode: false,
            isLoadingAutocompleteUser: false,
            showErrorMsg: false,
        };
    },
    methods: {
        async addUserAsTraveler() {
            try {
                const passengerInfo = {
                    passenger: {
                        TripId: this.trip.id || 0,
                        TravelerType: 0,
                        IsPrimary: this.travelerToEdit.isPrimary,
                        PaxId: 0,
                        CorporateUserId: this.loggedinUser.id,
                        CorporationId: this.loggedinUser.corporationId,
                    },
                    addTravellerType: AddTravelerType.IamTheTraveller,
                    updatePrimary: this.travelerToEdit.isPrimary,
                    idx: this.travelerIdx,
                };
                await this.$emit('selectUser', passengerInfo);
                this.onAddPassenger();
                this.close();
            }
            catch (err) {
                loggerService.error(err);
                throw err;
            }
        },
        async selectUser(user) {
            const existingUser = this.passengers.find((p) => {
                return p.corporateUserId === user.id;
            });
            if (existingUser) {
                this.showErrorMsg = true;
                return;
            }
            const passengerInfo = {
                passenger: {
                    TravelerType: AddTravelerType.AutoComplete,
                    IsPrimary: this.travelerToEdit.isPrimary,
                    PaxId: 0,
                    TripId: +this.trip.id,
                    CorporateUserId: user.id,
                    CorporationId: this.loggedinUser.corporationId,
                    Title: user.title,
                    Gender: user.gender
                },
                addTravellerType: AddTravelerType.AutoComplete,
                updatePrimary: this.travelerToEdit.isPrimary,
                idx: this.travelerIdx,
            };
            await this.$emit('selectUser', passengerInfo);
            this.onAddPassenger();
            this.close();
        },
        setGuestMode(isOpen) {
            this.isGuestMode = isOpen;
            this.$emit('setGuestMode', isOpen);
            this.onAddPassenger();
        },
        resetErrorMsg() {
            this.showErrorMsg = false;
        },
        close() {
            this.$emit('closeDialog');
        },
        onAddPassenger() {
            eventBus.$emit('validateRequiredInfo');
        },
        async onAddGuest(passengerInfo) {
            await this.$emit('selectUser', passengerInfo);
            this.onAddPassenger();
        },
    },
    computed: {
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        passengers() {
            return this.trip.passengers;
        },
        canBookForGuest() {
            return this.loggedinUser.permissions.includes('CanBookForGuest');
        },
        canBookForAll() {
            return this.loggedinUser.permissions.includes('IsBooker');
        },
        canBookForGroups() {
            return this.loggedinUser.permissions.includes('CanBookOnBehalfOfAssociatedUsersGroups') || this.loggedinUser.permissions.includes('CanBookForSelfAndForUserGroup');
        },
        canSearchTravelers() {
            return this.canBookForAll || this.canBookForGroups;
        },
        isAnyOtherPrimary() {
            return this.trip.passengers.some((p) => p.isPrimary);
        },
        canChangePrimaryPax() {
            return this.trip.canChangePrimaryPax;
        },
    },
    watch: {
        traveler: {
            handler() {
                this.travelerToEdit = utilService.deepClone(this.traveler);
                //if there is no any other primery mark it as primery
                this.travelerToEdit.isPrimary = this.isAnyOtherPrimary ? false : true;
            },
            immediate: true,
        },
    },
});
