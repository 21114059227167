import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
import { alertService } from '@/services/alert.service';
import { loggerService } from '@/services/logger.service';
import appAvatar from '@/components/app-avatar.vue';
import planeSeat from '../plane-seat/plane-seat.vue';
import seatsDictionary from '../seats-dictionary/seats-dictionary.vue';
import { SeatTypes, SuppliersId } from '../../../models/consts';
import { utilService } from '@/services/util.service';
import appOopBadge from '@/components/app-oop-badge.vue';
import seatRichInformationPopup from '@/components/trip/seat-rich-information-popup/seat-rich-information-popup.vue';
export default defineComponent({
    name: 'FlightDeck',
    components: { planeSeat, seatsDictionary, appAvatar, appOopBadge, seatRichInformationPopup },
    props: {
        selectedFlightResult: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
        selectedIndex: {
            type: Number,
            required: false,
            default: 0,
        },
        packageKey: {
            type: String,
            required: false,
            default: '',
        },
        isCart: {
            type: Boolean,
            required: false,
            default: false,
        },
        isFromResults: {
            type: Boolean,
            default: false,
        },
        resultsIndex: {
            type: Number,
            default: 0,
        },
        selectedPassengerIdx: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            seatsMap: [],
            row: null,
            exitRowIndex: [],
            selectedSeats: [],
            oopViolation: [],
            rowsNumbers: [],
            seatsNumber: [],
            isLoading: false,
            showOOP: false,
            oopRestricted: false,
            currentTabIndex: '0',
            selectedPax: null,
            selectedPaxIndex: null,
            selectedSeatsCount: {},
            totalSeatsCost: { displayPrice: 0, displayCurrency: '' },
            seatMapCacheKey: null,
            flightNumber: null,
            mergedFlights: [],
            isBusinessClass: false,
            flightKey: null,
            seatCurrency: null,
            isOOP: false,
            isOOPRestricted: false,
            oopReason: null,
            segmentNumberSeatMap: null,
        };
    },
    async created() {
        eventBus.$on('resetSeatsSelection', this.resetSeatSelection);
        eventBus.$on('seatSelectedFromToolTip', this.handleSeatSelectFromEmit);
        let mergedFlights = [];
        for (let i = 0; i < this.selectedFlightResult.segments.length; i++) {
            const item = this.selectedFlightResult.segments[i];
            mergedFlights = mergedFlights.concat(item.flights);
        }
        this.mergedFlights = mergedFlights;
        await this.loadData();
        if (this.selectedIndex !== null) {
            this.currentTabIndex = this.selectedIndex.toString();
        }
        if (this.selectedPax === null || this.selectedPassengerIdx) {
            this.selectedPax = this.passengers[this.selectedPassengerIdx || 0];
            this.selectedPaxIndex = this.selectedPassengerIdx || 0;
        }
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        flightSeatMap() {
            return this.$store.getters['flightStore/flightSeatMap'];
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        passengers() {
            return this.trip.passengers;
        },
        searchOptions() {
            return this.$store.getters['flightStore/searchOptions'];
        },
        cacheSelectedSeats() {
            return this.$store.getters['flightStore/cacheSelectedSeats'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isSaveAllowed() {
            let notAvailableBeforeBooking = false;
            if (this.selectedSeats) {
                this.selectedSeats.forEach((seats) => {
                    seats.forEach((seat) => {
                        if (seat.notAvailableBeforeBooking) {
                            notAvailableBeforeBooking = true;
                        }
                    });
                });
            }
            return this.oopRestricted || notAvailableBeforeBooking;
        },
    },
    methods: {
        async handleSeatSelectFromEmit(seatDetails) {
            if (seatDetails && seatDetails.seat && seatDetails.index >= 0) {
                await this.handleSeatSelect(seatDetails.index, seatDetails.seat);
            }
        },
        async loadData(index = null) {
            try {
                this.isLoading = true;
                //check if we have flight seatMap in store to avoid api call for existing ones
                const flight = this.mergedFlights[index ? index : this.selectedIndex];
                this.flight = flight;
                this.isBusinessClass = this.searchOptions
                    ? this.searchOptions.flightRows[0].class === 'C'
                    : flight.flightClass === 'C';
                const flightKey = `${flight.carrier}-${flight.flightNumber}-${flight.origin}-${flight.destCode}-${flight.flightClass}-${flight.depDate.split('T')[0]}`;
                this.flightKey = flightKey;
                if (this.selectedFlightResult.sourceSystem !== SuppliersId.SABRE &&
                    Object.keys(this.flightSeatMap).includes(flightKey) &&
                    this.flightSeatMap[flightKey]?.rows) {
                    this.seatsMap = this.flightSeatMap[flightKey].rows;
                    this.seatMapCacheKey = this.flightSeatMap[flightKey].cacheKey;
                    this.setExitRowIndex();
                }
                else {
                    const seatMapRes = await this.$store.dispatch({
                        type: 'flightStore/getFlightSeatMap',
                        leg: flight,
                        packageKey: this.selectedFlightResult.packageKey,
                        isCart: this.isCart,
                        quoteId: this.selectedFlightResult?.quoteId,
                        resultsIndex: this.resultsIndex,
                        pnr: this.selectedFlightResult?.pnr,
                    });
                    if (seatMapRes?.rows) {
                        this.seatsMap = seatMapRes.rows;
                        this.seatMapCacheKey = seatMapRes.cacheKey;
                        this.segmentNumberSeatMap = seatMapRes.segmentNumber;
                        this.setExitRowIndex();
                    }
                    else {
                        this.seatsMap = [];
                    }
                }
            }
            catch (error) {
                loggerService.error(error);
                this.isLoading = false;
            }
            finally {
                if (this.isCart && !this.cacheSelectedSeats[this.selectedFlightResult?.quoteId]?.length) {
                    try {
                        const selectedSeats = await this.$store.dispatch({
                            type: 'flightStore/getSelectedSeats',
                            quoteId: this.selectedFlightResult?.quoteId,
                        });
                        this.selectedSeats = await this.initSelectedSeats(selectedSeats);
                        this.isAnySeatOOP();
                        this.setTotalSeatsPrice();
                        await this.$store.dispatch({
                            type: 'flightStore/setSelectedSeats',
                            selectedSeats: this.selectedSeats,
                            deletedCached: false,
                            quoteId: this.selectedFlightResult?.quoteId,
                        });
                    }
                    catch (error) {
                        loggerService.error(error);
                        this.isLoading = false;
                    }
                }
                else if (this.isCart && this.selectedFlightResult.quoteId) {
                    //check cached seats
                    this.selectedSeats = utilService.deepClone(this.cacheSelectedSeats[this.selectedFlightResult.quoteId]);
                    if (this.seatsMap && this.selectedFlightResult.sourceSystem === SuppliersId.SABRE) {
                        //Sabre using multiple seat map, gds and ndc, need to initialize the selected seats based on current
                        //segment number and current seat map
                        const segmentNumber = Number(this.segmentNumberSeatMap) - 1;
                        if (this.selectedSeats.length > segmentNumber) {
                            this.selectedSeats[segmentNumber].forEach((seat) => {
                                if (seat?.seatNumber !== '') {
                                    seat.rowIndex = this.findIndexByRowNumber(this.seatsMap, seat.rowNumber);
                                    seat.seatMapCacheKey = this.seatMapCacheKey;
                                }
                            });
                        }
                    }
                    this.setTotalSeatsPrice();
                    this.isAnySeatOOP();
                }
                this.clearSeatToolTip();
                this.isLoading = false;
            }
        },
        async initSelectedSeats(selectedSeats) {
            const selectedSeatsBySegment = selectedSeats.length === 0
                ? new Array(this.mergedFlights.length).fill([])
                : selectedSeats
                    .reduce((result, seat) => {
                    const segmentNumber = seat.segmentNumber;
                    if (!result[segmentNumber]) {
                        result[segmentNumber] = [];
                    }
                    const rowNumber = seat.seat.slice(0, -1);
                    const seatNumber = seat.seat.slice(-1);
                    const rowIndex = this.findIndexByRowNumber(this.seatsMap, rowNumber);
                    const seatFromMap = this.getSeatFromMap(rowIndex, seatNumber);
                    const selectedSeatInfo = {
                        passengerId: seat.passengerId,
                        passengerIdx: seat.passengerIndex,
                        rowIndex: rowIndex,
                        seatNumber: seatNumber,
                        rowNumber: rowNumber,
                        seatInfo: `${rowNumber}${seatNumber} • ${this.getCurrencySymbol(seat.displayCurrency)}${seat.displayPrice.toFixed(2)}`,
                        currentTabIndex: seat.segmentNumber - 1,
                        displayPrice: seat.displayPrice,
                        flight: {
                            flightNumber: seat.flightNumber,
                            carrier: seat.carrier,
                        },
                        seatMapCacheKey: this.seatMapCacheKey,
                        currency: this.getCurrencySymbol(seat?.displayCurrency),
                        isOOP: seatFromMap?.isOOP,
                        isOOPRestricted: seatFromMap?.isOOPRestricted,
                        oopReason: seatFromMap?.oopReason,
                        notAvailableBeforeBooking: seatFromMap?.notAvailableBeforeBooking,
                    };
                    result[segmentNumber].push(selectedSeatInfo);
                    return result;
                }, [])
                    .filter(Boolean);
            return selectedSeatsBySegment;
        },
        getSeatClass(row, rowIndex, seatIndex, seat) {
            if (!this.seatCurrency) {
                this.seatCurrency = utilService.getCurrencyCode(seat.displayCurrency);
            }
            const characteristics = seat && seat?.seatCharacteristics;
            let className = '';
            if (characteristics) {
                if (characteristics.includes(SeatTypes.AisleSeat) &&
                    !this.isBusinessClass &&
                    !this.seatsMap[rowIndex]?.seats[seatIndex - 1]?.seatCharacteristics.includes(SeatTypes.AisleSeat) &&
                    !this.seatsMap[rowIndex]?.seats[seatIndex + 2]?.seatCharacteristics.includes(SeatTypes.AisleSeat)) {
                    className += 'aisle ';
                }
                if ((characteristics.includes(SeatTypes.ExitRow) &&
                    this.seatsMap[rowIndex - 1]?.seats[seatIndex] &&
                    !this.seatsMap[rowIndex - 1].seats[seatIndex].seatCharacteristics.includes(SeatTypes.ExitRow)) ||
                    this.exitRowIndex.includes(rowIndex)) {
                    className += 'exit-row-seat ';
                }
                if (characteristics.includes(SeatTypes.NoSeatInLocation)) {
                    className += 'no-seat ';
                }
                if (characteristics.includes(SeatTypes.LimitedComfortSeat)) {
                    className += 'limited ';
                }
                if (characteristics.includes(SeatTypes.Lavatory)) {
                    className += 'lavatory ';
                }
                if (characteristics.includes(SeatTypes.PreferredSeat)) {
                    className += 'preferred ';
                }
                if (characteristics.includes(SeatTypes.ExtraLegRoom)) {
                    className += 'extra-leg-room ';
                }
                if (characteristics.includes(SeatTypes.Priority)) {
                    className += 'priority-seat ';
                }
                if (rowIndex === 0) {
                    className += 'first-row-seat ';
                }
                if (this.isBusinessClass) {
                    className += 'business-cabin ';
                }
                this.lastSeatIndex = seatIndex;
            }
            return className;
        },
        getRowClass(row, rowIndex) {
            let className = '';
            if (this.exitRowIndex.includes(rowIndex) && rowIndex !== 0) {
                className = 'exit-row ';
            }
            else if (row.rowNumber.length === 1) {
                className = 'extend-aisle-width ';
            }
            if (this.isBusinessClass) {
                className = 'business-cabin ';
            }
            return className;
        },
        async handleSeatSelect(rowIndex, seat) {
            eventBus.$emit('openSeatToolTip', { rowIndex: rowIndex, seat: seat });
            if (!seat?.seatAvailability || seat?.seatCharacteristics.includes(SeatTypes.NoSeatInLocation)) {
                return;
            }
            if (seat.displayPrice > 0 && !this.loggedinUser.permissions.includes('CanBuyPaidSeats')) {
                return;
            }
            let selectedSeatsForTab = utilService.deepClone(this.selectedSeats)[this.currentTabIndex] || [];
            // Check if the clicked seat is already selected for any passenger
            const existingSeatIndex = selectedSeatsForTab.findIndex((s) => s?.rowIndex === rowIndex && s?.seatNumber === seat.seatNumber);
            // If the clicked seat is already selected, clear the selection
            if (existingSeatIndex !== -1) {
                this.selectedPaxIndex = selectedSeatsForTab[existingSeatIndex].passengerIdx;
                this.selectedPax = this.passengers[selectedSeatsForTab[existingSeatIndex].passengerIdx];
                selectedSeatsForTab[existingSeatIndex] = {
                    ...selectedSeatsForTab[existingSeatIndex],
                    rowNumber: '',
                    seatNumber: '',
                    displayPrice: 0,
                    seatInfo: null,
                };
            }
            else {
                const selectedSeat = {
                    passengerId: this.selectedPax.id,
                    passengerIdx: this.selectedPaxIndex,
                    rowIndex: rowIndex,
                    seatNumber: seat.seatNumber,
                    rowNumber: this.seatsMap[rowIndex].rowNumber,
                    seatInfo: `${this.seatsMap[rowIndex].rowNumber}${seat.seatNumber} • ${this.getCurrencySymbol(seat.displayCurrency)}${seat.displayPrice.toFixed(2)}`,
                    currentTabIndex: this.currentTabIndex,
                    displayPrice: seat.displayPrice,
                    seatMapCacheKey: this.seatMapCacheKey,
                    flight: this.flight,
                    flightKey: this.flightKey,
                    currency: this.getCurrencySymbol(seat?.displayCurrency),
                    isOOP: seat.isOOP,
                    isOOPRestricted: seat.isOOPRestricted,
                    oopReason: seat.oopReason,
                    notAvailableBeforeBooking: seat.notAvailableBeforeBooking,
                };
                selectedSeatsForTab.push(selectedSeat);
            }
            // remove any seats that the user might have already added, keep only latest modification
            selectedSeatsForTab = Object.values(selectedSeatsForTab.reduce((acc, seat) => {
                const { passengerIdx } = seat;
                acc[passengerIdx] = seat;
                return acc;
            }, {}));
            this.selectedSeats[this.currentTabIndex] = selectedSeatsForTab;
            if (existingSeatIndex === -1) {
                this.handleNextPax();
            }
            // Recalculate total seat cost
            this.totalSeatsCost.displayPrice = this.selectedSeats.reduce((acc, curr) => {
                return acc + (curr[0]?.displayPrice || 0);
            }, 0);
            if (seat.currency) {
                this.totalSeatsCost.displayCurrency = this.getCurrencySymbol(seat.displayCurrency);
            }
            // Save selected seats for the current tab index to store
            this.updateSeatsSelection(this.selectedSeats, false);
            this.isAnySeatOOP();
        },
        getSeatInfoByPaxId(id) {
            return this.selectedSeats[this.currentTabIndex].find((seat) => seat.passengerId === id);
        },
        handleNextPax() {
            if (this.selectedPaxIndex < this.passengers.length - 1) {
                // Advance selectedPax to the next one.
                this.selectedPaxIndex = this.selectedPaxIndex + 1;
                this.selectedPax = this.passengers[this.selectedPaxIndex];
            }
        },
        setExitRowIndex() {
            this.seatsMap.forEach((row, rowIdx) => {
                row.seats?.forEach((seat, seatIdx) => {
                    if (seat.seatCharacteristics.includes(SeatTypes.AisleSeat) &&
                        (!this.seatsMap[rowIdx].seats[seatIdx - 1]?.seatCharacteristics.includes(SeatTypes.AisleSeat) ||
                            this.isBusinessClass)) {
                        this.seatsNumber.push('aisle');
                    }
                    if (seat.seatCharacteristics.includes(SeatTypes.ExitRow) &&
                        !this.exitRowIndex.includes(rowIdx) &&
                        rowIdx !== 0) {
                        //collect all exit row indexes
                        this.exitRowIndex.push(rowIdx);
                    }
                });
            });
        },
        handlePaxSelect(idx) {
            this.selectedPax = this.passengers[idx];
            this.selectedPaxIndex = idx;
            this.clearSeatToolTip();
        },
        isAnySeatOOP() {
            this.oopViolation = [];
            if (this.selectedSeats.length) {
                for (let i = 0; i < this.selectedSeats.length; i++) {
                    let jndex = 0;
                    const selectedSeatsSet = this.selectedSeats[i];
                    let oopText = '';
                    const oops = [];
                    this.passengers.forEach((a) => {
                        if (selectedSeatsSet && selectedSeatsSet[jndex]) {
                            const rowIndex = this.findIndexByRowNumber(this.seatsMap, selectedSeatsSet[jndex].rowNumber);
                            const seatFromMap = this.getSeatFromMap(rowIndex, selectedSeatsSet[jndex].seatNumber);
                            const ret = seatFromMap?.isOOP;
                            oops.push(ret);
                            this.oopRestricted = seatFromMap?.isOOPRestricted;
                            if (ret) {
                                oopText = seatFromMap?.oopReason;
                                if (this.oopRestricted) {
                                    oopText += ' (' + this.$t('validation.destinationAutocompleteHelp') + ')';
                                }
                            }
                        }
                        jndex++;
                    });
                    const anyTrue = oops.find((a) => {
                        return a;
                    });
                    this.showOOP = anyTrue;
                    if (this.oopViolation.length === 0 && anyTrue) {
                        this.oopViolation.push(oopText);
                    }
                }
            }
            else {
                this.oopViolation = [];
                this.isOOPRestricted = false;
                this.showOOP = false;
            }
            eventBus.$emit('setOopViolationsSeat', this.oopViolation);
            return false;
        },
        setTotalSeatsPrice() {
            this.totalSeatsCost.displayPrice = this.selectedSeats?.reduce((acc, curr) => {
                return acc + curr?.reduce((acc, curr) => acc + curr?.displayPrice, 0);
            }, 0);
        },
        async resetSeatSelection() {
            if (this.selectedSeats && this.selectedSeats.length > 0) {
                this.selectedSeats[this.currentTabIndex].isOOP = false;
                this.selectedSeats[this.currentTabIndex].isOOPRestricted = false;
                this.selectedSeats[this.currentTabIndex].oopReason = null;
            }
            const newSum = this.selectedSeats[this.currentTabIndex]?.reduce((acc, curr) => {
                return acc + curr?.displayPrice;
            }, 0);
            this.totalSeatsCost.displayPrice -= newSum;
            const updatedSeats = [...this.selectedSeats];
            updatedSeats[this.currentTabIndex] = updatedSeats[this.currentTabIndex].map((item) => {
                item.rowNumber = '';
                item.seatNumber = '';
                item.displayPrice = 0;
                item.seatInfo = null;
                return item;
            });
            this.selectedSeats[this.currentTabIndex] = updatedSeats[this.currentTabIndex];
            this.isAnySeatOOP();
            this.showOOP = false;
            this.oopViolation = [];
            this.oopRestricted = false;
            await this.updateSeatsSelection(updatedSeats, false);
        },
        async updateSeatsSelection(updatedSeats, deletedCached) {
            return this.$store.dispatch({
                type: 'flightStore/setSelectedSeats',
                selectedSeats: updatedSeats,
                deletedCached: deletedCached,
                packageKey: this.selectedFlightResult?.packageKey,
                quoteId: this.selectedFlightResult?.quoteId,
            });
        },
        async handleSeatsSelection() {
            if (this.selectedSeats.length > 0) {
                this.isLoading = true;
                await this.updateSeatsSelection(this.selectedSeats, false);
                if (this.isCart) {
                    const res = await this.$store.dispatch({
                        type: 'flightStore/saveSeatsFromCart',
                        selectedSeats: this.selectedSeats,
                        flight: this.flight,
                        quoteId: this.selectedFlightResult.quoteId,
                    });
                    if (!res) {
                        alertService.error('' + this.$t('alert.general.error'));
                        return;
                    }
                    if (this.selectedFlightResult.segments) {
                        for (let i = 0; i < this.selectedFlightResult.segments.length; i++) {
                            const leg = this.selectedFlightResult.segments[i].flights[0];
                            const key = `${leg.carrier}-${leg.flightNumber}-${leg.origin}-${leg.destCode}-${leg.flightClass}-${leg.depDate.split('T')[0]}`;
                            this.$store.commit({ type: 'flightStore/setFlightSeatMap', flightSeatMapRes: null, key: key });
                        }
                    }
                    //this.$emit('validationChanged');
                    this.$emit('reloadQuotes');
                    alertService.success('' + this.$t('alert.general.saved'));
                    this.$emit('closeDrawer');
                    //await this.loadQuotes(); // get updated prices
                }
            }
        },
        async handleCancel() {
            await this.$store.dispatch({
                type: 'flightStore/setSelectedSeats',
                selectedSeats: [],
                deletedCached: true,
                packageKey: this.selectedFlightResult?.packageKey,
                quoteId: this.selectedFlightResult?.quoteId,
                isClosing: true,
            });
            this.$emit('closeDrawer');
        },
        async loadQuotes() {
            await this.$store.dispatch({ type: 'tripStore/loadQuotes', resetJustifications: false });
        },
        findIndexByRowNumber(seatMap, rowNumber) {
            for (let i = 0; i < seatMap.length; i++) {
                if (seatMap[i].rowNumber === rowNumber || seatMap[i].rowNumber === rowNumber.toString()) {
                    return i;
                }
            }
            return null;
        },
        getSeatFromMap(index, seatNumber) {
            let ret;
            if (this.seatsMap && index !== undefined && index !== null) {
                const seatsRows = this.seatsMap[index];
                ret = seatsRows.seats.find((a) => {
                    if (a.seatNumber === seatNumber) {
                        return true;
                    }
                });
            }
            return ret;
        },
        getCurrencySymbol(currencyCode) {
            if (this.seatCurrency) {
                return this.seatCurrency;
            }
            this.seatCurrency = utilService.getCurrencyCode(currencyCode);
            return this.seatCurrency;
        },
        clearSeatToolTip() {
            this.seatsMap.forEach((row, rowIdx) => {
                row.seats.forEach((seat, _) => {
                    eventBus.$emit('cloeAllSeatToolTip', { rowNumber: row, seat: seat, index: rowIdx });
                });
            });
        },
        advanceFlight() {
            this.currentTabIndex =
                +this.currentTabIndex < this.mergedFlights.length
                    ? (+this.currentTabIndex + 1).toString()
                    : +this.mergedFlights.length.toString();
        },
    },
    watch: {
        currentTabIndex: {
            immediate: false,
            handler() {
                this.seatsNumber = [];
                this.exitRowIndex = [];
                this.selectedPax = this.passengers[this.selectedPassengerIdx || 0];
                this.selectedPaxIndex = this.selectedPassengerIdx || 0;
                this.loadData(this.currentTabIndex);
                this.$nextTick(() => {
                    if (this.selectedPassengerIdx) {
                        this.$emit('clearSelectedPaxIdx');
                    }
                });
            },
        },
    },
    unmounted() {
        eventBus.$off('resetSeatsSelection', this.resetSeatSelection);
        eventBus.$off('seatSelectedFromToolTip', this.handleSeatSelectFromEmit);
    },
});
