export const inputNoSpaces = {
    // when the element is inserted in the DOM
    mounted: function (el) {
        let elInput = el;
        // check if the element is an input - if not - catch the input thats inside of it
        if (el.nodeName !== 'INPUT') {
            elInput = el.querySelector('input');
        }
        elInput.addEventListener('input', (ev) => {
            // if the input has a space in it - remove the space and emit a new input event
            // its will prevent all spaces in the value - even if its pasted into the input
            if (elInput.value.includes(' ')) {
                elInput.value = elInput.value.replaceAll(' ', '');
                elInput.dispatchEvent(new Event('input', { bubbles: true }));
            }
        });
    },
};
