import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-599aa06f"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "other-products-edit-details" };
const _hoisted_2 = { class: "card-title" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = {
    key: 1,
    class: "field column mb-0"
};
const _hoisted_5 = { class: "card-title" };
const _hoisted_6 = { class: "app-column" };
const _hoisted_7 = { class: "label" };
const _hoisted_8 = { class: "help" };
const _hoisted_9 = { class: "app-columns is-flex is-align-items-flex-end" };
const _hoisted_10 = { class: "app-column is-3" };
const _hoisted_11 = { class: "label" };
const _hoisted_12 = { class: "control" };
const _hoisted_13 = { class: "app-column is-3" };
const _hoisted_14 = { class: "label" };
const _hoisted_15 = { class: "control" };
const _hoisted_16 = { class: "app-column is-3" };
const _hoisted_17 = { class: "label" };
const _hoisted_18 = { class: "control" };
const _hoisted_19 = { class: "help" };
const _hoisted_20 = { class: "app-column is-3" };
const _hoisted_21 = { class: "label" };
const _hoisted_22 = { class: "help" };
const _hoisted_23 = { class: "app-column" };
const _hoisted_24 = { class: "label" };
const _hoisted_25 = { class: "control" };
const _hoisted_26 = { class: "btns flex" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_skeleton = _resolveComponent("el-skeleton");
    const _component_el_input = _resolveComponent("el-input");
    const _component_form_validation_msg = _resolveComponent("form-validation-msg");
    const _component_app_datepicker = _resolveComponent("app-datepicker");
    const _component_app_select = _resolveComponent("app-select");
    const _component_app_btn = _resolveComponent("app-btn");
    const _component_el_card = _resolveComponent("el-card");
    const _component_extra_layout = _resolveComponent("extra-layout");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(_component_extra_layout, {
            quote: _ctx.quote,
            hasValue: true,
            isValid: _ctx.isValid,
            ref: "extraLayout",
            onIsOpenChanged: _ctx.isOpenChanged
        }, {
            "extra-title": _withCtx(() => [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('btn.edit')) + " " + _toDisplayString(_ctx.$t('chat.details')), 1 /* TEXT */)
            ]),
            "extra-content": _withCtx(() => [
                _createVNode(_component_el_card, { class: "extras-card mb-2" }, {
                    default: _withCtx(() => [
                        _createCommentVNode(" ***************[Layout ]**************** "),
                        _createElementVNode("div", null, [
                            (_ctx.isLoading)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                    _createVNode(_component_el_skeleton, {
                                        rows: 3,
                                        animated: ""
                                    })
                                ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('btn.edit')) + " " + _toDisplayString(_ctx.$t('chat.details')), 1 /* TEXT */),
                                    _createElementVNode("section", null, [
                                        _createElementVNode("div", _hoisted_6, [
                                            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('common.title')), 1 /* TEXT */),
                                            _createVNode(_component_el_input, {
                                                class: _normalizeClass({ 'is-danger': _ctx.v$.formData.title.$invalid }),
                                                modelValue: _ctx.formData.title,
                                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.formData.title) = $event)),
                                                disabled: _ctx.isDisabled
                                            }, null, 8 /* PROPS */, ["class", "modelValue", "disabled"]),
                                            (_ctx.isFieldMarked('title'))
                                                ? (_openBlock(), _createBlock(_component_form_validation_msg, {
                                                    key: 0,
                                                    type: "error"
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('validation.required')), 1 /* TEXT */)
                                                    ]),
                                                    _: 1 /* STABLE */
                                                }))
                                                : _createCommentVNode("v-if", true)
                                        ]),
                                        _createElementVNode("div", _hoisted_9, [
                                            _createElementVNode("div", _hoisted_10, [
                                                _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('manualQuote.startingFrom')), 1 /* TEXT */),
                                                _createElementVNode("div", _hoisted_12, [
                                                    _createVNode(_component_app_datepicker, {
                                                        modelValue: _ctx.formData.startDate,
                                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.formData.startDate) = $event)),
                                                        disabled: _ctx.isDisabled,
                                                        disablePastDates: false,
                                                        showYearArrows: "",
                                                        defaultStyle: "",
                                                        placeholder: _ctx.$t('common.startDate'),
                                                        onInput: _ctx.startDateChanged
                                                    }, null, 8 /* PROPS */, ["modelValue", "disabled", "placeholder", "onInput"])
                                                ])
                                            ]),
                                            _createElementVNode("div", _hoisted_13, [
                                                _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('manualQuote.endsBy')), 1 /* TEXT */),
                                                _createElementVNode("div", _hoisted_15, [
                                                    _createVNode(_component_app_datepicker, {
                                                        modelValue: _ctx.formData.endDate,
                                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.formData.endDate) = $event)),
                                                        defaultStyle: "",
                                                        disabled: _ctx.isDisabled,
                                                        disablePastDates: false,
                                                        placeholder: _ctx.$t('common.endDate')
                                                    }, null, 8 /* PROPS */, ["modelValue", "disabled", "placeholder"])
                                                ])
                                            ]),
                                            _createElementVNode("div", _hoisted_16, [
                                                _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('common.totalPrice')), 1 /* TEXT */),
                                                _createElementVNode("div", _hoisted_18, [
                                                    _createVNode(_component_el_input, {
                                                        class: _normalizeClass({ 'is-danger': _ctx.v$.formData.price.$invalid }),
                                                        modelValue: _ctx.formData.price,
                                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.formData.price) = $event)),
                                                        disabled: _ctx.isDisabled
                                                    }, null, 8 /* PROPS */, ["class", "modelValue", "disabled"]),
                                                    (_ctx.isFieldMarked('price'))
                                                        ? (_openBlock(), _createBlock(_component_form_validation_msg, {
                                                            key: 0,
                                                            type: "error"
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t('validation.required')), 1 /* TEXT */)
                                                            ]),
                                                            _: 1 /* STABLE */
                                                        }))
                                                        : _createCommentVNode("v-if", true)
                                                ])
                                            ]),
                                            _createElementVNode("div", _hoisted_20, [
                                                _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('common.currency')), 1 /* TEXT */),
                                                _createVNode(_component_app_select, {
                                                    class: _normalizeClass({ 'is-danger': _ctx.v$.formData.currency.$invalid }),
                                                    defaultStyle: "",
                                                    options: _ctx.currencyOpts,
                                                    modelValue: _ctx.formData.currency,
                                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.formData.currency) = $event)),
                                                    disabled: _ctx.isDisabled
                                                }, null, 8 /* PROPS */, ["class", "options", "modelValue", "disabled"]),
                                                (_ctx.isFieldMarked('currency'))
                                                    ? (_openBlock(), _createBlock(_component_form_validation_msg, {
                                                        key: 0,
                                                        type: "error"
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t('validation.required')), 1 /* TEXT */)
                                                        ]),
                                                        _: 1 /* STABLE */
                                                    }))
                                                    : _createCommentVNode("v-if", true)
                                            ])
                                        ]),
                                        _createElementVNode("div", _hoisted_23, [
                                            _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t('common.comment')), 1 /* TEXT */),
                                            _createElementVNode("div", _hoisted_25, [
                                                _createVNode(_component_el_input, {
                                                    type: "textarea",
                                                    modelValue: _ctx.formData.comments,
                                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.formData.comments) = $event)),
                                                    disabled: _ctx.isDisabled
                                                }, null, 8 /* PROPS */, ["modelValue", "disabled"])
                                            ])
                                        ])
                                    ])
                                ]))
                        ]),
                        _createElementVNode("div", _hoisted_26, [
                            _createVNode(_component_app_btn, { onClick: _ctx.close }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('btn.cancel')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["onClick"]),
                            _createVNode(_component_app_btn, {
                                onClick: _ctx.saveManualQuote,
                                type: "primary",
                                class: "no-border",
                                isLoading: _ctx.isSaving,
                                isDisabled: _ctx.isDisabled
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('btn.save')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["onClick", "isLoading", "isDisabled"])
                        ])
                    ]),
                    _: 1 /* STABLE */
                })
            ]),
            _: 1 /* STABLE */
        }, 8 /* PROPS */, ["quote", "isValid", "onIsOpenChanged"])
    ]));
}
