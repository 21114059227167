import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
import { SeatTypes } from '../../../models/consts';
import { utilService } from '@/services/util.service';
import seatRichInformationPopup from '@/components/trip/seat-rich-information-popup/seat-rich-information-popup.vue';
export default defineComponent({
    name: 'PlaneSeat',
    components: { seatRichInformationPopup },
    props: {
        loggedinUser: {
            type: Object,
            required: true,
            default: () => {
                return {};
            },
        },
        seat: {
            type: Object,
            required: false,
        },
        selectedPax: {
            type: Object,
            required: false,
        },
        currentTabIndex: {
            type: String,
            required: false,
            default: '0',
        },
        selectedSeats: {
            type: Array,
            required: false,
        },
        rowIdx: {
            type: Number,
            required: false,
            default: 0,
        },
        rowNumber: {
            type: String,
            required: false,
            default: '',
        },
        seatCurrency: {
            type: String,
            required: false,
            default: '',
        },
        isFromResults: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        eventBus.$on('cloeAllSeatToolTip', this.closeAllToolTip);
    },
    data() {
        return {
            isTooltipOpen: false,
        };
    },
    computed: {
        seatImageClass() {
            let classes = '';
            if (this.selectedSeats && this.isSelectedSeat) {
                classes += 'selected ';
            }
            if (!this.seat?.seatAvailability) {
                classes += 'occupied ';
            }
            if (this.seat?.seatCharacteristics.includes(SeatTypes.Priority)) {
                classes += 'priority-seat ';
            }
            if (this.seat?.seatCharacteristics.includes(SeatTypes.PreferredSeat)) {
                classes += 'preferred  ';
            }
            if (this.seat?.seatCharacteristics.includes(SeatTypes.ExtraLegRoom)) {
                classes += 'extra-leg-room ';
            }
            if (this.isFromResults) {
                classes += 'default-cursor ';
            }
            return classes;
        },
        seatImageName() {
            if (this.selectedSeats && this.isSelectedSeat) {
                return 'man';
            }
            if (!this.seat?.seatAvailability) {
                return 'close';
            }
            return '';
        },
        tooltipContent() {
            if (this.isEmptySeat) {
                return '';
            }
            const toolTipContent = {};
            toolTipContent.seatInfo = `${this.rowNumber}${this.seat?.seatNumber}`;
            const currency = this.seat?.displayCurrency === 0 ? '' : this.getCurrencySymbol(this.seat?.displayCurrency);
            toolTipContent.seatStatus = !this.seat?.seatAvailability
                ? this.$t('flight.extras.seats.occupied')
                : this.seat.seatCharacteristics.includes(SeatTypes.PreferredSeat)
                    ? this.$t('flight.extras.seats.preferred')
                    : this.$t('flight.extras.seats.available');
            toolTipContent.seatStatus += ` ${this.$t('common.seat')}`;
            toolTipContent.seatPrice = this.seat?.displayPrice ? ' · ' + currency + Math.round(this.seat?.displayPrice) : '';
            if (this.cantBuyPaidSeats) {
                toolTipContent.cantBuyPaidSeats = this.$t('flight.extras.seats.cantBuySeat');
            }
            if (this.notAvailableBeforeBooking) {
                toolTipContent.notAvailableBeforeBooking = this.$t('flight.extras.seats.seatNotAvailableBeforeBooking');
            }
            if (this.seat.seatCharacteristics.includes(SeatTypes.SpeedyBoardingAndExtraLegRoom)) {
                toolTipContent.extraLeg = this.$t('flight.extras.seats.extraLegRoom') + ` ${this.$t('common.seat')}`;
                toolTipContent.speedyBoarding = this.$t('flight.extras.seats.speedyBoarding');
            }
            if (this.seat.seatCharacteristics.includes(SeatTypes.Priority)) {
                toolTipContent.priority = this.$t('flight.extras.seats.priority');
            }
            if (this.seat.seatCharacteristics.includes(SeatTypes.EconomyComfort)) {
                toolTipContent.economyComfort = this.$t('flight.extras.seats.economyComfort');
            }
            if (this.seat.seatCharacteristics.includes(SeatTypes.ExtraLegRoom)) {
                toolTipContent.extraLeg = this.$t('flight.extras.seats.extraLegRoom') + ` ${this.$t('common.seat')}`;
            }
            if (this.seat.seatCharacteristics.includes(SeatTypes.LimitedComfortSeat)) {
                toolTipContent.restrictedRecline = this.$t('flight.extras.seats.restrictedRecline') + ` ${this.$t('common.seat')}`;
            }
            return toolTipContent;
        },
        cantBuyPaidSeats() {
            return this.seat.displayPrice > 0 && !this.loggedinUser.permissions.includes('CanBuyPaidSeats');
        },
        notAvailableBeforeBooking() {
            return this.seat.notAvailableBeforeBooking;
        },
        isEmptySeat() {
            return this.seat?.seatCharacteristics.includes(SeatTypes.NoSeatInLocation);
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isArrow() {
            return this.isMobile ? 'none' : 'always';
        },
        passengers() {
            return this.trip.passengers;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        selectedSeatIdx() {
            if (this.selectedSeats.length > 0 && this.selectedSeats[+this.currentTabIndex]?.length) {
                return this.selectedSeats[+this.currentTabIndex]?.findIndex((s) => s.rowIndex === this.rowIdx && s.seatNumber === this.seat.seatNumber);
            }
            return -1;
        },
        isSelectedSeat() {
            return this.selectedSeatIdx !== -1;
        },
        paxInitials() {
            if (this.isSelectedSeat) {
                const paxIdx = this.selectedSeats[this.currentTabIndex][this.selectedSeatIdx]?.passengerIdx;
                return `${this.passengers[paxIdx]?.firstName.charAt(0)} ${this.passengers[paxIdx]?.lastName.charAt(0)}`;
            }
            return '';
        },
        paxFullName() {
            if (this.isSelectedSeat) {
                const paxIdx = this.selectedSeats[this.currentTabIndex][this.selectedSeatIdx]?.passengerIdx;
                return `${this.passengers[paxIdx]?.firstName} ${this.passengers[paxIdx]?.lastName}`;
            }
            return '';
        },
    },
    methods: {
        selectSeat() {
            eventBus.$emit('seatSelectedFromToolTip', { index: this.rowIdx, seat: this.seat });
        },
        getTooltipEvents() {
            const tooltipEvents = {
                click: () => this.openToolTip(),
            };
            if (this.isMobile) {
                return tooltipEvents;
            }
            if (!this.seat.photos || !this.seat.photos.length > 0) {
                tooltipEvents.mouseenter = () => this.toggleTooltipStatus();
                tooltipEvents.mouseleave = () => this.toggleTooltipStatus(false);
            }
            return tooltipEvents;
        },
        toggleTooltipStatus(bool = true) {
            if (!this.isSelectedSeat) {
                this.isTooltipOpen = bool;
            }
            else {
                this.isTooltipOpen = true;
            }
        },
        moreDetails() {
            eventBus.$emit('showSeatRichInfoPopup', { index: this.rowIdx, seat: this.seat, rowNumber: this.rowNumber });
            this.isTooltipOpen = false;
        },
        openToolTip() {
            eventBus.$emit('cloeAllSeatToolTip', { rowNumber: this.rowNumber, seat: this.seat, index: this.rowIdx });
            if (!this.isSelectedSeat) {
                this.isTooltipOpen = !this.isTooltipOpen;
            }
        },
        closeAllToolTip(obj) {
            const objSeat = Number(obj.rowNumber) + '' + obj.seat.seatNumber;
            const currentSeat = Number(this.rowNumber) + '' + this.seat.seatNumber;
            if (objSeat !== currentSeat) {
                this.isTooltipOpen = false;
            }
        },
        getCurrencySymbol(currencyCode) {
            if (this.seatCurrency) {
                return this.seatCurrency;
            }
            return utilService.getCurrencyCode(currencyCode);
        },
    },
    unmounted() {
        eventBus.$off('cloeAllSeatToolTip', this.closeAllToolTip);
    },
});
