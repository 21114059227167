import { utilService } from '@/services/util.service';
import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
import { FlightStopsTypes, SortByTypes } from '../../models/consts';
export default defineComponent({
    name: 'FlightComparison',
    props: {
        collapseVisible: {
            type: Array,
        },
        resultsIndex: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            carriers: [],
            tableData: [],
            showScrollLeft: false,
            showScrollRight: true,
            selectedCell: null,
            filterBy: {},
            tableClassName: '',
        };
    },
    created() {
        eventBus.$on('clearAllFilter', this.clearSelection);
        this.filterBy = utilService.deepClone(this.flightFilter);
    },
    mounted() {
        this.prepareTableData();
        this.checkScrollButtons();
    },
    methods: {
        prepareTableData() {
            // Get unique carrier names
            // If Carrier name is "Mul" this means it's a multi carrier
            this.carriers = Array.from(new Set(this.comparisonTable?.map((fare) => ({
                name: fare.carrier !== 'Mul' ? fare.carrierName || fare.carrier : this.$t('flight.multiCarrier'),
                code: fare.carrier,
            }))));
            //calc the table width according to number of carriers
            this.getTableWidthClass();
            // Prepare table data
            this.tableData = [
                { stopType: FlightStopsTypes.NonStop, carrierData: {} },
                { stopType: FlightStopsTypes.OneStop, carrierData: {} },
                { stopType: FlightStopsTypes.TwoMoreStops, carrierData: {} },
            ];
            // Populate carrier data for each stop type
            let lowestFare = Infinity; // Initialize with a large value
            this.tableData.forEach((row) => {
                this.carriers.forEach((carrier) => {
                    const fare = this.comparisonTable?.find((fare) => fare.carrier === carrier.code);
                    const priceProp = row.stopType;
                    const price = fare && fare[`${priceProp}Found`] && fare[`${priceProp}Total`] !== 0
                        ? Math.round(fare[`${priceProp}Total`])
                        : Infinity;
                    lowestFare = Math.min(lowestFare, price);
                    const cellData = {
                        isFound: fare && fare[`${priceProp}Found`] && fare[`${priceProp}Total`] !== 0,
                        price,
                        currency: fare && this.getCurrencyCode(fare[`${priceProp}Currency`]),
                        selected: false,
                    };
                    row.carrierData[carrier.code] = cellData;
                });
            });
            this.lowestFare = lowestFare;
        },
        getTableWidthClass() {
            if (this.carriers.length < 10) {
                this.tableClassName = `width-${this.carriers.length}`;
                return;
            }
            this.tableClassName = 'large-table';
        },
        isLowestFare(row, carrier) {
            return row.carrierData[carrier.code].price === this.lowestFare;
        },
        getCarrierLogo(carrier) {
            let res = '';
            try {
                res = `flights/airlinesIcons/${carrier}.gif`;
            }
            catch (e) {
                res = 'flights/airlinesIcons/flight.svg';
            }
            return res;
        },
        handleCellClick(stopType, carrierCode = null, carrierName = null) {
            // we want to set the selected cell and adjust the flight filters accordingly
            if (carrierCode) {
                //case user selected a cell that includes carrier and not just stopType
                this.tableData.forEach((row) => {
                    //we will iterate through all cells and find the one that matches stop type and carrier
                    if (row.stopType === stopType) {
                        Object.keys(row.carrierData).forEach((carrier) => {
                            const cellData = row.carrierData[carrier];
                            cellData.selected = carrier === carrierCode;
                        });
                    }
                    else {
                        //clear selected cell when selecting new cell
                        Object.keys(row.carrierData).forEach((carrier) => {
                            const cellData = row.carrierData[carrier];
                            cellData.selected = false;
                        });
                    }
                });
            }
            this.filter(carrierName, stopType);
        },
        clearSelection(clearFilters = false) {
            this.tableData.forEach((row) => {
                Object.keys(row.carrierData).forEach((carrier) => {
                    const cellData = row.carrierData[carrier];
                    cellData.selected = false;
                });
            });
            if (clearFilters) {
                this.clearFiltersSelections();
            }
        },
        filter(carrier, stopType) {
            //get current filterBy from store:
            const updatedFilterBy = utilService.deepClone(this.flightFilter);
            //update cmp-specific fields:
            updatedFilterBy.inPolicy = this.filterBy.inPolicy;
            updatedFilterBy.sortBy = SortByTypes.Cheapest;
            updatedFilterBy.carriers = [];
            if (carrier === 'Multi Airlines') {
                carrier = 'Multi carrier';
            }
            if (carrier) {
                updatedFilterBy.carriers.push(carrier);
            }
            updatedFilterBy.stops = [];
            //match to pre-built filter naming
            if (stopType === FlightStopsTypes.NonStop) {
                stopType = 'No stops';
            }
            else if (stopType === FlightStopsTypes.OneStop) {
                stopType = '1 stop';
            }
            else if (stopType === FlightStopsTypes.TwoMoreStops) {
                stopType = '2 stops';
            }
            if (stopType) {
                updatedFilterBy.stops.push(stopType);
            }
            this.filterBy = updatedFilterBy;
            this.filterFlights(updatedFilterBy);
        },
        async clearFiltersSelections() {
            if (!this.isMobile) {
                await this.$store.dispatch({ type: 'flightStore/resetFilter' });
            }
            else {
                this.filterBy = await this.$store.dispatch({ type: 'flightStore/recalculateFilters', applyFilter: false });
            }
        },
        async filterFlights(filterBy) {
            await this.$store.dispatch({ type: 'flightStore/filterFlights', filterBy });
            this.$store.dispatch({
                type: 'flightStore/recalculateFilters',
                isRecalculateSortByOnFilteredFlights: true,
                modifiedFilterBy: filterBy,
            });
        },
        handleScrollTable(direction) {
            // scroll table header
            let elToScroll = this.$refs.flightTable.$el.querySelector('.el-table__header-wrapper');
            this.scrollTable(direction, elToScroll);
            // scroll table body
            elToScroll = this.$refs.flightTable.$el.querySelector('.el-scrollbar__wrap');
            this.scrollTable(direction, elToScroll);
        },
        scrollTable(direction, el) {
            // const tableRef = this.$refs.flightTable.$el.querySelector('.el-table__header-wrapper');
            // const tableRef = this.$refs.flightTable.$el.querySelector('.el-scrollbar__wrap');
            const scrollAmount = direction === 'left' ? -483 : 483;
            const scrollDuration = 1000;
            const startTime = performance.now();
            const startScrollLeft = el.scrollLeft;
            const animateScroll = (currentTime) => {
                const elapsed = currentTime - startTime;
                const easing = this.easeInOutQuad(elapsed, startScrollLeft, scrollAmount, scrollDuration);
                el.scrollLeft = easing;
                if (elapsed < scrollDuration) {
                    requestAnimationFrame(animateScroll);
                }
                this.checkScrollButtons();
            };
            requestAnimationFrame(animateScroll);
        },
        easeInOutQuad(t, b, c, d) {
            t /= d / 2;
            if (t < 1) {
                return (c / 2) * t * t + b;
            }
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        },
        checkScrollButtons() {
            const tableRef = this.$refs.flightTable.$el.querySelector('.el-scrollbar__wrap');
            const maxScrollLeft = tableRef.scrollWidth - tableRef.clientWidth - 1;
            this.showScrollLeft = tableRef.scrollLeft > 0;
            this.showScrollRight = tableRef.scrollLeft < maxScrollLeft;
        },
        getCurrencyCode(code) {
            return utilService.getCurrencyCode(code);
        },
    },
    computed: {
        comparisonTable() {
            return this.$store.getters['flightStore/flightsRes']?.[this.resultsIndex]?.comparisonTable;
        },
        flightFilter() {
            return this.$store.getters['flightStore/flightFilter'];
        },
    },
    watch: {
        resultsIndex() {
            this.prepareTableData();
        },
    },
    unmounted() {
        eventBus.$off('clearAllFilter', this.clearSelection);
    },
});
