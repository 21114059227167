import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5f19da69"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "stay-safe-banner is-flex" };
const _hoisted_2 = { class: "stay-safe-banner-wrapper is-flex" };
const _hoisted_3 = { class: "available-msg" };
const _hoisted_4 = { class: "stay-safe-info is-flex" };
const _hoisted_5 = { class: "is-flex is-align-items-center" };
const _hoisted_6 = { class: "carrier-img-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_unused_tickets = _resolveComponent("app-unused-tickets");
    const _component_el_dialog = _resolveComponent("el-dialog");
    const _component_app_img = _resolveComponent("app-img");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('common.unusedTickets')), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("p", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t('common.unusedTicketsBanner')) + " ", 1 /* TEXT */),
                            _createElementVNode("span", {
                                class: "view-details-btn",
                                onClick: _cache[0] || (_cache[0] =
                                    //@ts-ignore
                                    (...args) => (_ctx.toggleUnusedTickets && _ctx.toggleUnusedTickets(...args)))
                            }, _toDisplayString(_ctx.$t('btn.viewDetails')), 1 /* TEXT */),
                            _createVNode(_component_el_dialog, {
                                class: "app-dialog",
                                "append-to-body": "",
                                title: `${_ctx.$t('common.unusedTickets')}`,
                                modelValue: _ctx.unusedTicketsVisible,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.unusedTicketsVisible) = $event)),
                                width: '40%',
                                center: ""
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_app_unused_tickets, {
                                        unusedTickets: _ctx.passengers[0].unusedTickets
                                    }, null, 8 /* PROPS */, ["unusedTickets"])
                                ]),
                                _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["title", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uniqueCarriers, (carrier, idx) => {
                                return (_openBlock(), _createBlock(_component_app_img, {
                                    class: "carrier-img",
                                    key: idx,
                                    externalUrl: _ctx.carrierIcon(carrier),
                                    isIcon: "false"
                                }, null, 8 /* PROPS */, ["externalUrl"]));
                            }), 128 /* KEYED_FRAGMENT */))
                        ])
                    ])
                ])
            ])
        ])
    ]));
}
