import { defineComponent } from 'vue';
import flightPreview from '@/modules/products/flight/components/flight-preview/flight-preview.vue';
import appNoResultsSearch from '@/components/app-no-results-search.vue';
export default defineComponent({
    name: 'FlightList',
    props: {
        flightResults: {
            type: Array,
            default: () => [],
        },
        selectedSegments: {
            type: Array,
            default: () => [],
        },
        flightProduct: {
            type: String,
            default: 'flight',
        },
        resultsIndex: {
            type: Number,
            default: 0,
        },
        totalResultsNumber: {
            type: Number,
            default: 0,
        },
    },
    components: { flightPreview, appNoResultsSearch },
    data() {
        return {
            observer: null,
        };
    },
    mounted() {
        // set loading observer for lazy loading on mobile
        if (this.isMobile) {
            this.setLazyLoadListener();
        }
    },
    methods: {
        resetFilters() {
            this.$emit('resetFilters');
        },
        setLazyLoadListener() {
            this.observer = new window.IntersectionObserver(this.onIntersection);
            if (!this.flightResults.length) {
                return;
            }
            this.observer.observe(document.getElementById('last-item'));
        },
        onIntersection(entries) {
            if (!entries[0].isIntersecting) {
                return;
            }
            const flightsLength = this.flightResults.length;
            this.$emit('load-more');
            this.$nextTick(() => {
                // no new flights - reached the end - disconnect observer
                if (flightsLength === this.flightResults.length || !this.flightResults.length) {
                    this.observer?.disconnect();
                    return;
                }
            });
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        flightsRes() {
            // TODO: add new type
            return this.$store.getters['flightStore/flightsRes'];
        },
    },
    watch: {
        flightResults: {
            // on mobile flights change - set a new observer on the last item for lazy loading
            deep: true,
            handler(newFlights, prevFlights) {
                if (this.isMobile) {
                    if (!newFlights.length) {
                        return;
                    }
                    this.$nextTick(() => {
                        this.observer?.disconnect();
                        this.observer?.observe(document.getElementById('last-item'));
                    });
                }
                else {
                    const isSame = newFlights.every((flight) => {
                        return prevFlights.includes(flight);
                    });
                    if (!isSame) {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }
                }
            },
        },
    },
});
