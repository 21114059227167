import { defineComponent } from 'vue';
import { flightService } from '@/modules/products/flight/services/flight.service';
import { eventBus } from '@/services/event-bus.service';
import { TravelerType, QuoteStatus } from '@/types/consts';
import appTooltipOfficeHoursRestricted from '@/components/app-tooltip-office-hours-restricted/app-tooltip-office-hours-restricted.vue';
import { postTicketingProperties, FlightSearchTypes, FlightAdvisories, } from '@/modules/products/flight/models/consts';
import { utilService } from '@/services/util.service';
import { loggerService } from '@/services/logger.service';
import flightPreview from '@/modules/products/flight/components/flight-preview/flight-preview.vue';
import ticketLayout from '@/components/ticket-layout.vue';
import flightDeck from '@/modules/products/flight/components/flight-seats/flight-deck/flight-deck.vue';
import flightDetailsTooltip from '@/modules/products/flight/components/flight-details/flight-details-tooltip/flight-details-tooltip.vue';
import cardImgSkeleton from '@/components/skeletons/card-img-skeleton.vue';
import flightPostTicketingDetails from '../flight-post-ticketing-details/flight-post-ticketing-details.vue';
import shareBtns from '@/components/app-share-quotes/app-share-products-btns.vue';
import ecoLabel from '@/modules/utils/eco-label/eco-label.vue';
import flightTimedQuote from '@/modules/products/flight/components/flight-timed-quote/flight-timed-quote.vue';
import isPast from 'date-fns/isPast';
import isBefore from 'date-fns/isBefore';
export default defineComponent({
    name: 'FlightDetails',
    components: {
        flightPreview,
        ticketLayout,
        cardImgSkeleton,
        flightDeck,
        flightDetailsTooltip,
        shareBtns,
        ecoLabel,
        flightTimedQuote,
        appTooltipOfficeHoursRestricted,
        flightPostTicketingDetails,
    },
    props: {
        selectedFlightResult: {
            type: Object,
            required: true,
        },
        segmentToShowIndex: {
            type: Number,
            default: null,
        },
        isPricingOptions: {
            type: Boolean,
            default: false,
        },
        isFareRulesShown: {
            type: Boolean,
            default: true,
        },
        isCartDetails: {
            type: Boolean,
            default: false,
        },
        searchCacheKey: {
            type: String,
            default: null,
        },
        isTicketed: {
            type: Boolean,
            default: false,
        },
        resultsIndex: {
            type: Number,
            default: 0,
        },
        isOnBySchedulePricingPage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            isCartBtnLoading: false,
            isShareBtnLoading: false,
            isSeatsBtnLoading: false,
            flightDetails: {},
            isDrawerOpen: false,
            flightSelectedSeatsCached: false,
        };
    },
    async created() {
        eventBus.$on('cancelCartBtnLoading', this.cancelCartBtnLoading);
        await this.loadFlightDetails();
    },
    methods: {
        async loadFlightDetails() {
            this.isLoading = true;
            try {
                if (!this.selectedFlightResult.quoteId) {
                    // details load from flight-results
                    const searchData = {
                        guid: this.cacheKey,
                        packageKey: this.selectedFlightResult.packageKey,
                        tripId: this.trip.id,
                    };
                    if (this.searchCacheKey) {
                        searchData.guid = this.searchCacheKey;
                    }
                    const flightDetailsRes = await flightService.searchFlightDetails(searchData);
                    this.flightDetails = flightDetailsRes;
                    this.isLoading = false;
                }
                else {
                    // details load from Cart
                    const flightDetailsRes = await flightService.searchFlightDetailsFromCart(this.selectedFlightResult.quoteId);
                    this.flightDetails = flightDetailsRes;
                    this.isLoading = false;
                }
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        handleSelectFlight() {
            //additional protection, in case user load the page before the expiration date but
            //pressed the button after the expiration date
            if (this.isAddToCardDisabled) {
                return;
            }
            // If we have searchCacheKey, its the quote change page
            this.isCartBtnLoading = true;
            if (this.searchCacheKey ||
                (this.searchOptions.searchFor === FlightSearchTypes.bySchedule && !this.isOnBySchedulePricingPage)) {
                this.$emit('selectFlightBySchedule', this.selectedFlightResult);
                if (this.searchCacheKey) {
                    this.$emit('closeDrawer');
                }
            }
            else {
                this.$emit('addToCart');
            }
        },
        cancelCartBtnLoading() {
            this.isCartBtnLoading = false;
        },
        formattedDateStr(dateStr) {
            return this.$filters.date(dateStr, 'dd MMM yy');
        },
        formattedDateTimeStr(dateStr) {
            let time = dateStr.split('T')[1];
            time = utilService.getUserTimeFormat(time.substring(0, time.length - 3));
            const formattedDate = this.$filters.date(dateStr, 'eee, dd MMM yy');
            return `${time} · ${formattedDate}`;
        },
        getLegTitle(flightSegment) {
            let legTitle = '';
            flightSegment.flights.forEach((flight, index) => {
                if (index < flightSegment.flights.length) {
                    legTitle += `${flight.origin} - `;
                }
            });
            legTitle += flightSegment.flights[flightSegment.flights.length - 1].destCode;
            return legTitle;
        },
        async handleAddToShare(skipCapacityViolation = false) {
            if (this.canShareQuoteCombinedWithAddToCart) {
                // Check if flight has capacity Restriction
                this.isShareBtnLoading = true;
                if (!skipCapacityViolation) {
                    const violationData = await this.$store.dispatch({
                        type: 'flightStore/handleFlightTravelPolicy',
                        flightResult: this.selectedFlightResult,
                        searchCacheKey: this.cacheKey,
                        resultsIndex: this.resultsIndex,
                    });
                    this.isShareBtnLoading = false;
                    if (violationData) {
                        eventBus.$emit('openCapacityDialogForShare', this.selectedFlightResult, violationData.violations, this.addToShare);
                        return;
                    }
                    else {
                        this.addToShare();
                    }
                    this.addToShare();
                }
                else {
                    this.addToShare();
                }
            }
            else {
                this.addToShare();
            }
        },
        addToShare() {
            const product = {
                cacheKey: this.selectedFlightResult.packageKey,
                flight: this.selectedFlightResult,
                resultsIndex: this.resultsIndex,
            };
            this.$store.commit({ type: 'tripStore/addToShare', product });
        },
        removeFromShare() {
            this.$store.commit({ type: 'tripStore/removeFromShare', product: this.selectedFlightResult });
        },
        travelerInfoForDisplay(travelerInfo) {
            return {
                fullName: `${travelerInfo.firstName} ${travelerInfo.lastName}`,
                ticketNumber: travelerInfo.ticketNumber,
                passport: travelerInfo.passport,
                birthDate: this.formattedDateStr(travelerInfo.birthDate),
                gender: travelerInfo.gender,
                frequentFlyer: travelerInfo.frequentFlyer,
                meal: travelerInfo.meal,
                seats: travelerInfo.seats,
            };
        },
        carrierIcon(carrierSymbol) {
            let res = '';
            try {
                res = require(`@/assets/img/flights/airlinesIcons/${carrierSymbol}.gif`);
            }
            catch (e) {
                res = require('@/assets/icon/flight.svg');
            }
            return res;
        },
        hasStopsOrStopOver(flightSegment, flight, index) {
            return (this.isContainStopOverAdvisory(flightSegment, flight) ||
                (flightSegment.stops.length && index < flightSegment.flights.length - 1));
        },
        isContainStopOverAdvisory(flightSegment, flight) {
            return (flightSegment.advisories?.some((advisory) => advisory.imageClass === FlightAdvisories.stopAdvisory) &&
                flight.stopOver);
        },
        openBrandedFares(flight, segmentIndex) {
            const opts = {
                brandId: flight.brandId,
                brandName: flight.brandName,
                carrier: flight.carrier,
                packageKey: this.selectedFlightResult?.packageKey,
                segmentIndex: this.selectedFlightResult.isOneWayPackage ? segmentIndex : null,
            };
            this.$emit('openBrandedFares', opts);
        },
        getDateObject(dateUTC) {
            return utilService.convertUnformattedUTCToLocalDate(dateUTC);
        },
    },
    computed: {
        canShareQuoteCombinedWithAddToCart() {
            const user = this.$store.getters['authStore/loggedinUser'];
            return user.permissions.includes('ShareQuoteCombinedWithAddToCart');
        },
        postTicketingProperties() {
            return postTicketingProperties;
        },
        isExpired() {
            if (!this.selectedFlightResult || !this.selectedFlightResult.lastTktDate) {
                return false;
            }
            // Parse the UTS date and time string to a Date object in UTC timezone
            const dateTime = this.getDateObject(this.selectedFlightResult.lastTktDate);
            // Check if the parsed date and time is in the past
            return isPast(dateTime); // Returns true if the date and time is in the past, false otherwise
        },
        isExpiredOfficeHours() {
            if (!this.selectedFlightResult || !this.selectedFlightResult.nextOfficeHoursExpiration) {
                return false;
            }
            // Parse the UTS date and time string to a Date object in UTC timezone
            const dateTime = this.getDateObject(this.selectedFlightResult.nextOfficeHoursExpiration);
            // Check if the parsed date and time is in the past
            return isPast(dateTime); // Returns true if the date and time is in the past, false otherwise
        },
        isOfficeHoursExpiration() {
            return !isBefore(new Date(this.selectedFlightResult.lastTktDate), new Date(this.selectedFlightResult.nextOfficeHoursExpiration));
        },
        isAddToCardDisabled() {
            return (this.selectedFlightResult?.isRestricTravelPolicyViolation ||
                this.selectedFlightResult?.isHideBrandRule ||
                this.isExpired ||
                this.isExpiredOfficeHours);
        },
        allFlights() {
            return this.flightDetails.segments.reduce((acc, flightSegment) => acc.concat(flightSegment.flights), []);
        },
        getTravelerTypeName() {
            const passengerType = this.flightDetails.changeAndCancellationDetails?.[0].passengerType !== undefined
                ? this.flightDetails.changeAndCancellationDetails?.[0].passengerType
                : this.flightDetails.priceBreakDownByPtc?.[0].pricedPtc;
            return TravelerType[passengerType] === 'Child'
                ? `${TravelerType[passengerType]}ren`
                : `${TravelerType[passengerType]}s`;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        quotes() {
            return this.$store.getters['tripStore/quotes'];
        },
        cacheKey() {
            return this.$store.getters['flightStore/cacheKey'](this.resultsIndex);
        },
        cacheSelectedSeats() {
            return this.$store.getters['flightStore/cacheSelectedSeats'];
        },
        searchOptions() {
            return this.$store.getters['flightStore/searchOptions'];
        },
        isShareMode() {
            return this.$store.getters['tripStore/isShareMode'];
        },
        isQuoteChange() {
            return !!this.searchCacheKey;
        },
        isProductInShare() {
            const shareProducts = this.$store.getters['tripStore/productsToShare'];
            return shareProducts.products.some((product) => product.cacheKey === this.selectedFlightResult.packageKey);
        },
        currQuote() {
            return this.quotes.find((quote) => quote.quoteId.toString() === this.flightDetails.hashCode);
        },
        flightSearchTypes() {
            return FlightSearchTypes;
        },
        isReissue() {
            return this.currQuote?.status === QuoteStatus.PendingReissue;
        },
        exchangePricing() {
            const exchangeFlightPricing = this.currQuote?.flightPricing.filter((flightPricing) => flightPricing?.IsExchangePricing);
            return exchangeFlightPricing?.[0];
        },
        exchangeTotalPrices() {
            const totals = {
                DisplayExchangeDiffInFare: 0,
                DisplayExchangeChangeFee: 0,
                DisplayTotalPrice: 0,
            };
            if (this.flightDetails?.isNDC) {
                this.currQuote?.flightPricing.forEach((flightPricing) => {
                    if (flightPricing?.IsExchangePricing) {
                        totals.DisplayExchangeDiffInFare += flightPricing.DisplayExchangeDiffInFare;
                        totals.DisplayExchangeChangeFee += flightPricing.DisplayExchangeChangeFee;
                        totals.DisplayTotalPrice += flightPricing.DisplayTotalPrice;
                    }
                });
            }
            else {
                totals.DisplayExchangeDiffInFare += this.exchangePricing.DisplayExchangeDiffInFare;
                totals.DisplayExchangeChangeFee += this.exchangePricing.DisplayExchangeChangeFee;
                totals.DisplayTotalPrice += this.exchangePricing.DisplayTotalPrice;
            }
            return totals;
        },
    },
    unmounted() {
        eventBus.$off('cancelCartBtnLoading', this.cancelCartBtnLoading);
    },
});
