import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1c7af6a9"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "branded-fares" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { class: "skeletons padding" };
const _hoisted_4 = { class: "skel-row1 flex" };
const _hoisted_5 = { class: "skel-image" };
const _hoisted_6 = { class: "skel-text" };
const _hoisted_7 = { class: "skel-row2" };
const _hoisted_8 = { key: 1 };
const _hoisted_9 = { class: "mb-4" };
const _hoisted_10 = { key: 0 };
const _hoisted_11 = { class: "is-flex is-align-items-center p-4" };
const _hoisted_12 = { class: "origin-dest mb-4 has-text-black" };
const _hoisted_13 = { class: "carrier-name has-text-black is-size-7" };
const _hoisted_14 = { class: "brand-services pt-2" };
const _hoisted_15 = {
    key: 0,
    class: "included my-4"
};
const _hoisted_16 = { class: "service-title" };
const _hoisted_17 = { class: "service-title" };
const _hoisted_18 = {
    key: 0,
    class: "service-item muliSemiBold"
};
const _hoisted_19 = ["innerHTML"];
const _hoisted_20 = {
    key: 1,
    class: "included my-4"
};
const _hoisted_21 = { class: "service-title" };
const _hoisted_22 = { class: "service-title" };
const _hoisted_23 = ["innerHTML"];
const _hoisted_24 = {
    key: 2,
    class: "additional-charge my-4"
};
const _hoisted_25 = { class: "service-title" };
const _hoisted_26 = ["innerHTML"];
const _hoisted_27 = {
    key: 3,
    class: "not-included"
};
const _hoisted_28 = { class: "service-title" };
const _hoisted_29 = ["innerHTML"];
const _hoisted_30 = { class: "brand-name" };
const _hoisted_31 = {
    key: 0,
    class: "service-item-title has-text-weight-bold"
};
const _hoisted_32 = {
    key: 1,
    class: "service-item-title has-text-weight-bold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_skeleton_item = _resolveComponent("el-skeleton-item");
    const _component_el_skeleton = _resolveComponent("el-skeleton");
    const _component_app_img = _resolveComponent("app-img");
    const _component_el_tab_pane = _resolveComponent("el-tab-pane");
    const _component_el_tabs = _resolveComponent("el-tabs");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_el_skeleton_item, { variant: "image" })
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", null, [
                                _createVNode(_component_el_skeleton, {
                                    rows: 4,
                                    animated: ""
                                })
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_el_skeleton, {
                            rows: 10,
                            animated: ""
                        })
                    ])
                ])
            ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                    (_ctx.noBrandResults && _ctx.noBrandFeatures)
                        ? (_openBlock(), _createElementBlock("section", _hoisted_10, [
                            _createElementVNode("h6", null, _toDisplayString(_ctx.$t('flight.brandedFaresUnavailable')), 1 /* TEXT */)
                        ]))
                        : (_ctx.brandedFares)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createCommentVNode(" using @update:modelValue event to eliminate el-tabs bug - scrolling to start "),
                                _createVNode(_component_el_tabs, {
                                    modelValue: _ctx.activeBrandId,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_ctx.activeTabId = $event))
                                }, {
                                    default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.brandedFares, (brand, idx) => {
                                            return (_openBlock(), _createBlock(_component_el_tab_pane, {
                                                key: `tab-${idx}`,
                                                name: brand.brandId || brand.brandName
                                            }, {
                                                label: _withCtx(() => [
                                                    _createElementVNode("div", _hoisted_11, [
                                                        _createVNode(_component_app_img, {
                                                            class: "carrier-img mr-1",
                                                            iconName: `${brand.carrier}.gif`,
                                                            iconPath: "flights/airlinesIcons/"
                                                        }, null, 8 /* PROPS */, ["iconName"]),
                                                        _createElementVNode("div", null, [
                                                            _createElementVNode("p", _hoisted_12, _toDisplayString(brand.carrierName), 1 /* TEXT */),
                                                            _createElementVNode("p", _hoisted_13, _toDisplayString(brand.brandName), 1 /* TEXT */)
                                                        ])
                                                    ])
                                                ]),
                                                default: _withCtx(() => [
                                                    _createElementVNode("section", _hoisted_14, [
                                                        _createElementVNode("h4", null, _toDisplayString(brand.brandName), 1 /* TEXT */),
                                                        (brand.ancillary?.length)
                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                                                _createElementVNode("h6", _hoisted_16, _toDisplayString(_ctx.$t('flight.brandIncludedAncillaries')), 1 /* TEXT */),
                                                                _createElementVNode("h6", _hoisted_17, _toDisplayString(_ctx.$t('flight.otherAncillariesExtraCost')) + ":", 1 /* TEXT */),
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(brand.ancillary, (ancillary, idx) => {
                                                                    return (_openBlock(), _createElementBlock("div", { key: idx }, [
                                                                        (ancillary.ancillaryName)
                                                                            ? (_openBlock(), _createElementBlock("h6", _hoisted_18, _toDisplayString(ancillary.ancillaryName), 1 /* TEXT */))
                                                                            : _createCommentVNode("v-if", true),
                                                                        _createCommentVNode(" sometimes will receive html from suppliers, need to support - v-html "),
                                                                        (ancillary.ancillaryDescription)
                                                                            ? (_openBlock(), _createElementBlock("p", {
                                                                                key: 1,
                                                                                class: "service-item",
                                                                                innerHTML: ancillary.ancillaryDescription
                                                                            }, null, 8 /* PROPS */, _hoisted_19))
                                                                            : _createCommentVNode("v-if", true)
                                                                    ]));
                                                                }), 128 /* KEYED_FRAGMENT */))
                                                            ]))
                                                            : _createCommentVNode("v-if", true),
                                                        (_ctx.hasIncludedItems)
                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                                                _createCommentVNode(" If we have ancillaries info - no need to put the titles again "),
                                                                (!brand.ancillary?.length)
                                                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                                        _createElementVNode("h6", _hoisted_21, _toDisplayString(_ctx.$t('flight.brandIncludedAncillaries')), 1 /* TEXT */),
                                                                        _createElementVNode("h6", _hoisted_22, _toDisplayString(_ctx.$t('flight.otherAncillariesExtraCost')) + ":", 1 /* TEXT */)
                                                                    ], 64 /* STABLE_FRAGMENT */))
                                                                    : _createCommentVNode("v-if", true),
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(brand.included, (service, idx) => {
                                                                    return (_openBlock(), _createElementBlock("p", {
                                                                        class: "service-item",
                                                                        key: idx,
                                                                        innerHTML: service
                                                                    }, null, 8 /* PROPS */, _hoisted_23));
                                                                }), 128 /* KEYED_FRAGMENT */))
                                                            ]))
                                                            : _createCommentVNode("v-if", true),
                                                        (brand.additionalCharge?.length)
                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                                                _createElementVNode("h6", _hoisted_25, _toDisplayString(_ctx.$t('flight.additionalChargeAncillaries')) + ":", 1 /* TEXT */),
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(brand.additionalCharge, (service, idx) => {
                                                                    return (_openBlock(), _createElementBlock("p", {
                                                                        class: "service-item",
                                                                        key: idx,
                                                                        innerHTML: service
                                                                    }, null, 8 /* PROPS */, _hoisted_26));
                                                                }), 128 /* KEYED_FRAGMENT */))
                                                            ]))
                                                            : _createCommentVNode("v-if", true),
                                                        (brand.notIncluded?.length)
                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                                                _createElementVNode("h6", _hoisted_28, _toDisplayString(_ctx.$t('flight.notIncludedAncillaries')) + ":", 1 /* TEXT */),
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(brand.notIncluded, (service, idx) => {
                                                                    return (_openBlock(), _createElementBlock("p", {
                                                                        class: "service-item",
                                                                        key: idx,
                                                                        innerHTML: service
                                                                    }, null, 8 /* PROPS */, _hoisted_29));
                                                                }), 128 /* KEYED_FRAGMENT */))
                                                            ]))
                                                            : _createCommentVNode("v-if", true)
                                                    ])
                                                ]),
                                                _: 2 /* DYNAMIC */
                                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"]));
                                        }), 128 /* KEYED_FRAGMENT */))
                                    ]),
                                    _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["modelValue"])
                            ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                            : (_ctx.brandFeatures)
                                ? (_openBlock(), _createBlock(_component_el_tabs, {
                                    key: 2,
                                    modelValue: _ctx.activeBrandId,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => (_ctx.activeTabId = $event))
                                }, {
                                    default: _withCtx(() => [
                                        _createElementVNode("h4", _hoisted_30, _toDisplayString(_ctx.brandNameLowerCase), 1 /* TEXT */),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.brandFeatures, (bf, idx) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                                key: idx,
                                                class: "service-item-title"
                                            }, [
                                                _createElementVNode("h6", null, _toDisplayString(bf.serviceGroup), 1 /* TEXT */),
                                                (bf.frees?.length)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_ctx.$t('common.free')), 1 /* TEXT */))
                                                    : _createCommentVNode("v-if", true),
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bf.frees, (f, idx) => {
                                                    return (_openBlock(), _createElementBlock("li", {
                                                        class: "service-item",
                                                        key: idx
                                                    }, _toDisplayString(f.commericalName), 1 /* TEXT */));
                                                }), 128 /* KEYED_FRAGMENT */)),
                                                (bf.chargeables?.length)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.$t('common.chargeable')), 1 /* TEXT */))
                                                    : _createCommentVNode("v-if", true),
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bf.chargeables, (c, idx) => {
                                                    return (_openBlock(), _createElementBlock("li", {
                                                        class: "service-item",
                                                        key: idx
                                                    }, _toDisplayString(c.commericalName), 1 /* TEXT */));
                                                }), 128 /* KEYED_FRAGMENT */))
                                            ]));
                                        }), 128 /* KEYED_FRAGMENT */))
                                    ]),
                                    _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["modelValue"]))
                                : _createCommentVNode("v-if", true)
                ])
            ]))
    ]));
}
