import { defineComponent } from 'vue';
import { Products } from '@/types/consts';
import { eventBus } from '@/services/event-bus.service';
import { tripService } from '@/services/trip.service';
import { RoutesNames } from '@/router';
import { ITEM_COUNT_SIZE, TripType } from '@/modules/products/flight/models/consts';
import { utilService } from '@/services/util.service';
import { flightService } from '@/modules/products/flight/services/flight.service';
import appPagination from '@/components/app-pagination.vue';
import appBreadcrumb from '@/components/app-breadcrumb.vue';
import flightListSort from '@/modules/products/flight/components/flight-list-sort/flight-list-sort.vue';
import flightList from '@/modules/products/flight/components/flight-list/flight-list.vue';
import flightListFilter from '@/modules/products/flight/components/flight-filter/flight-filter.vue';
import flightDetails from '@/modules/products/flight/components/flight-details/flight-details.vue';
import fareRules from '@/modules/products/flight/components/fare-rules/fare-rules.vue';
import brandedFares from '@/modules/products/flight/components/branded-fares/branded-fares.vue';
import pricingOptions from '@/modules/products/flight/components/pricing-options/pricing-options.vue';
import flightDeck from '@/modules/products/flight/components/flight-seats/flight-deck/flight-deck.vue';
import euRailOptions from '@/modules/products/flight/components/eu-rail-options/eu-rail-options.vue';
import flightStepper from '@/modules/products/flight/components/flight-stepper/flight-stepper.vue';
import flightSelectedSegments from '@/modules/products/flight/components/flight-selected-segments/flight-selected-segments.vue';
import flightBySchedulePricing from '@/modules/products/flight/components/flight-by-schedule-pricing/flight-by-schedule-pricing.vue';
import cardImgSkeleton from '@/components/skeletons/card-img-skeleton.vue';
import filterSkeleton from '@/components/skeletons/filter-skeleton.vue';
import staySafeBanner from '@/components/trip/stay-safe-banner/stay-safe-banner.vue';
import appProductLoader from '@/components/app-product-loader/app-product-loader.vue';
import { loggerService } from '@/services/logger.service';
import unusedTicketsBanner from '@/components/trip/unused-tickets-banner/unused-tickets-banner.vue';
import flightCapacityViolationWarning from '@/components/trip/flight-capacity-violation-warning/flight-capacity-violation-warning.vue';
export default defineComponent({
    name: 'FlightResults',
    components: {
        cardImgSkeleton,
        flightList,
        flightListFilter,
        filterSkeleton,
        flightDetails,
        fareRules,
        pricingOptions,
        flightListSort,
        appBreadcrumb,
        appPagination,
        euRailOptions,
        flightDeck,
        brandedFares,
        flightStepper,
        flightSelectedSegments,
        flightBySchedulePricing,
        staySafeBanner,
        unusedTicketsBanner,
        appProductLoader,
        flightCapacityViolationWarning,
    },
    props: {
        isForPriceCheck: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            isShowSkeleton: false,
            filterBy: null,
            showFilter: false,
            selectedFlightResult: null,
            selectedPricingResult: null,
            isFilterSelected: false,
            isDetailsDrawerOpen: false,
            isBrandedFaresDrawerOpen: false,
            isFareRulesDrawerOpen: false,
            isPricingOptionsDrawerOpen: false,
            isSeatsDrawerOpen: false,
            segmentToShowIndex: null,
            selectedLeg: null,
            selectedIndex: null,
            resultsIndex: 0,
            selectedSegments: [],
            brandOptions: null,
            packageKeyForPricingOptions: '',
            isOnBySchedulePricingPage: false,
            bySchedulePricing: null,
            collapseVisible: ['selected-segments-collapse'],
            collapseVisibleStatus: true,
            selectedFlightResultPackageKey: '',
            Products: Products,
            capacityViolationParameters: null,
            isPricing: false,
        };
    },
    async created() {
        eventBus.$on('handleAddToCart', this.handleAddToCart);
        if (this.isForPriceCheck) {
            // means that we created this component in the cart in check-price cmp and we only need the add to cart flow
            return;
        }
        utilService.setProductPageTitle(this.$route);
        eventBus.$on('reloadFlightResults', this.loadData);
        eventBus.$on('cancelBtnsLoading', this.cancelBtnsLoading);
        eventBus.$on('proceedWithRecentQuote', this.proceedWithRecentQuote);
        eventBus.$on('continueSelectingFlight', this.continueSelectingFlight);
        eventBus.$on('handleSelectingFlightBySchedule', this.handleSelectingFlightBySchedule);
        eventBus.$on('openCapacityDialogForShare', this.openCapacityDialogForShare);
        if (this.isMobile) {
            this.showFilter = false;
        }
        if (!this.isMobile) {
            this.getCollapseVisibleStatus();
        }
        this.loadData();
    },
    methods: {
        async loadData() {
            this.resetByScheduleSelections();
            this.isLoading = true;
            this.isShowSkeleton = false;
            try {
                if (!this.searchOptions) {
                    this.$router.push({
                        name: RoutesNames.tripIndex,
                        params: { tripId: this.trip?.id, product: this.flightProduct },
                    });
                }
                const isSearchEuRailOptions = this.searchOptions.tripType !== TripType.multiCity &&
                    this.loggedinUser.permissions.includes('AirRailMixedDisplay');
                let cacheKey = '';
                if (document.location.href?.indexOf('query=') > -1) {
                    cacheKey = document.location.href.split('query=')[1];
                }
                if (this.$route.query.searchCacheKey) {
                    // in case we come here from price check(search alternatives)
                    cacheKey = this.$route.query.searchCacheKey;
                    await this.$store.dispatch({
                        type: 'flightStore/loadFlights',
                        isSearchEuRailOptions,
                        resultsIndex: this.resultsIndex,
                        cacheKey: cacheKey,
                        isGoStraightToSearch: true,
                    });
                }
                else {
                    await this.$store.dispatch({
                        type: 'flightStore/loadFlights',
                        isSearchEuRailOptions,
                        resultsIndex: this.resultsIndex,
                        cacheKey: cacheKey,
                    });
                }
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                // here only stopping the loading animation in case of no results.
                // in case results return, cancelling the animation happens through an emit in app-product-loader.
                if (!this.flightsResNumber) {
                    this.stopLoading();
                }
            }
        },
        stopLoading() {
            this.isLoading = false;
            this.isShowSkeleton = false;
        },
        async searchSegmentForBySchedule(segmentToModifyIndex, editedSegment) {
            this.isLoading = true;
            try {
                await this.$store.dispatch({
                    type: 'flightStore/searchSegmentForBySchedule',
                    segmentToModifyIndex,
                    editedSegment,
                });
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                // here only stopping the loading animation in case of no results.
                // in case results return, cancelling the animation happens through an emit in app-product-loader.
                if (!this.flightsResNumber) {
                    this.stopLoading();
                }
            }
        },
        openCapacityDialogForShare(flightResult, violation, callBack) {
            this.capacityViolationParameters = {
                violations: violation,
                flightPackage: flightResult,
                isFromBySchedule: false,
                isShareQuote: true,
                callBack: callBack,
                closeModal: this.closeFlightCapacityViolationWarning,
            };
        },
        openConflictingQuotesModal(quoteConflicts, product, productIdentifier) {
            this.$emit('openConflictingQuotesModal', {
                quoteConflicts,
                product,
                productIdentifier,
            });
        },
        closeFlightCapacityViolationWarning() {
            if (!this.capacityViolationParameters.isShareQuote) {
                this.cancelBtnsLoading();
            }
            this.capacityViolationParameters = null;
        },
        async handleAddToCart(selectedFlightResultRes, skipCapacityViolation = false, searchCacheKeyFromCheckPrice = '') {
            let selectedFlightResult;
            // determine the actual flight result.
            if (selectedFlightResultRes) {
                selectedFlightResult = selectedFlightResultRes.packageKey
                    ? selectedFlightResultRes
                    : selectedFlightResultRes.selectedFlightResult;
            }
            else {
                selectedFlightResult = this.selectedFlightResult;
            }
            this.selectedFlightResultPackageKey = selectedFlightResult.packageKey;
            if (!skipCapacityViolation) {
                try {
                    const violationData = await this.$store.dispatch({
                        type: 'flightStore/handleFlightTravelPolicy',
                        flightResult: selectedFlightResult,
                        searchCacheKey: searchCacheKeyFromCheckPrice || this.cacheKey,
                        resultsIndex: this.resultsIndex,
                    });
                    if (violationData) {
                        this.capacityViolationParameters = {
                            violations: violationData.violations,
                            flightPackage: violationData.flightPackage,
                            isFromBySchedule: false,
                        };
                        return;
                    }
                }
                catch (err) {
                    this.cancelBtnsLoading();
                    loggerService.error(err);
                }
            }
            else {
                this.capacityViolationParameters = null;
            }
            const primaryPassenger = this.trip.passengers.find((passenger) => passenger.isPrimary);
            const optionsForCheckingConflicts = {
                product: Products.Flight,
                origin: this.searchOptions.flightRows[0].origin.Code,
                destCode: this.searchOptions.flightRows[this.searchOptions.flightRows.length - 1].destination.Code,
                depDate: selectedFlightResult.segments[0].flights[0].depDate,
                arrDate: selectedFlightResult.segments[selectedFlightResult.segments.length - 1].flights[selectedFlightResult.segments[selectedFlightResult.segments.length - 1].flights.length - 1].arrDate,
                tripId: this.trip.id,
                corporateUserId: primaryPassenger.corporateUserId,
            };
            try {
                if (searchCacheKeyFromCheckPrice) {
                    // means that we're here from check-price
                    this.addToCart(selectedFlightResultRes, searchCacheKeyFromCheckPrice);
                }
                else {
                    const quoteConflicts = await tripService.checkConflictTrip(optionsForCheckingConflicts);
                    if (!quoteConflicts?.length) {
                        this.addToCart();
                    }
                    else {
                        // should be invoked on pricing options and on by schedule
                        if (selectedFlightResultRes?.isPricing) {
                            this.selectedPricingResult = selectedFlightResult;
                            this.isPricing = true;
                        }
                        eventBus.$emit('openConflictingQuotesModal', {
                            quoteConflicts,
                            product: Products.Flight,
                            productIdentifier: selectedFlightResult.packageKey,
                        });
                    }
                }
            }
            catch (err) {
                this.cancelBtnsLoading();
                loggerService.error(err);
            }
        },
        async addToCart(selectedFlightResultRes, searchCacheKeyFromCheckPrice = '') {
            let selectedFlightResult;
            if (selectedFlightResultRes) {
                selectedFlightResult = selectedFlightResultRes?.packageKey
                    ? selectedFlightResultRes
                    : selectedFlightResultRes?.selectedFlightResult || this.selectedFlightResult;
            }
            try {
                await this.$store.dispatch({
                    type: 'flightStore/selectFlight',
                    packageCacheKey: selectedFlightResult?.packageKey || this.selectedFlightResultPackageKey,
                    resultsIndex: this.resultsIndex,
                    searchCacheKeyFromCheckPrice,
                });
                if (searchCacheKeyFromCheckPrice) {
                    eventBus.$emit('loadQuotesAndValidations');
                }
            }
            catch (err) {
                this.cancelBtnsLoading();
                this.$alert.error('alert.general.error', err);
            }
        },
        cancelBtnsLoading() {
            eventBus.$emit('cancelCartBtnLoadingBySchedule');
            eventBus.$emit('cancelCartBtnLoading');
        },
        async proceedWithRecentQuote(conflictingProductType, productIdentifier) {
            if (conflictingProductType !== Products.Flight || productIdentifier !== this.selectedFlightResultPackageKey) {
                return;
            }
            if (this.isPricing) {
                this.addToCart(this.selectedPricingResult);
            }
            else {
                this.addToCart(this.filteredFlights.find((f) => f.packageKey === productIdentifier));
            }
        },
        async handleSelectingFlightBySchedule(selectedFlightResult, skipCapacityViolation = false) {
            if (!skipCapacityViolation) {
                const violationData = await this.$store.dispatch({
                    type: 'flightStore/handleFlightTravelPolicy',
                    flightResult: selectedFlightResult,
                    searchCacheKey: this.cacheKey,
                    resultsIndex: this.resultsIndex,
                });
                if (violationData) {
                    this.capacityViolationParameters = {
                        violations: violationData.violations,
                        flightPackage: violationData.flightPackage,
                        isFromBySchedule: true,
                    };
                    return;
                }
            }
            else {
                this.capacityViolationParameters = null;
            }
            this.cancelBtnsLoading();
            this.selectFlightBySchedule(selectedFlightResult);
        },
        async selectFlightBySchedule(flightResult) {
            this.closeDrawer('details');
            this.closeDrawer('pricing');
            eventBus.$emit('closeModify');
            // cancel btns loading
            this.cancelBtnsLoading();
            this.selectedSegments[this.resultsIndex] = flightResult;
            const nextUnselectedSegmentIndex = this.flightsRes.reduce((acc, _, index) => {
                if (acc === null && !this.selectedSegments[index]?.segments) {
                    acc = index;
                }
                return acc;
            }, null);
            if (nextUnselectedSegmentIndex === null) {
                this.isOnBySchedulePricingPage = true;
                await this.getBySchedulePricing();
                return;
            }
            await this.$store.dispatch({
                type: 'flightStore/recalculateFilters',
                resultsIndex: nextUnselectedSegmentIndex,
                isRecalculateCountFunction: true,
            });
            this.resultsIndex = nextUnselectedSegmentIndex;
        },
        async goToPreviousSegment(resultsSegmentIndex) {
            this.selectedSegments[resultsSegmentIndex] = {};
            this.goToSegment(resultsSegmentIndex);
        },
        async goToSegment(resultsSegmentIndex) {
            await this.$store.dispatch({
                type: 'flightStore/recalculateFilters',
                resultsIndex: resultsSegmentIndex,
                isRecalculateCountFunction: true,
            });
            this.resultsIndex = resultsSegmentIndex;
        },
        async getBySchedulePricing() {
            this.selectedSegments;
            const searchData = {
                tripId: this.trip.id,
                guid: this.cacheKey,
                multiplePackageKeys: this.selectedSegments.map((selectedSegment) => selectedSegment.packageKey),
                isNearByAirports: this.searchOptions?.allowNearbyAirports,
                isSearchMyTmcOnly: false,
                isPTCBreakdown: false,
                isTwoOneWayEnabled: false,
                fullyRefundableFaresOnly: false,
                noOfStops: 3,
                totalPassengers: this.searchOptions?.numberOFTravelers,
                tripType: this.searchOptions?.tripType,
                travelerTypeInfo: this.searchOptions?.travelerTypeInfo,
                isFromSchedule: true,
            };
            try {
                const res = await flightService.getBySchedulePricing(searchData);
                this.bySchedulePricing = res;
                if (res && res.results) {
                    const filtered = utilService.deepClone(this.filteredFlights);
                    res.results.forEach((p) => {
                        if (!filtered.find((f) => f.packageKey === p.packageKey)) {
                            filtered.push(p);
                            this.$store.commit({ type: 'flightStore/setFilteredFlights', filteredFlights: filtered });
                        }
                    });
                }
                this.collapseVisible.pop();
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        goToEuRailOptions() {
            const railSearchOptions = {
                options: {
                    NumberOfPassengers: this.searchOptions.numberOFTravelers,
                    TripType: this.searchOptions.tripType,
                    Origin: this.euRailOptionsRes.railRequest.Origin,
                    Destination: this.euRailOptionsRes.railRequest.Destination,
                    DepartDate: this.searchOptions.flightRows[0].departureDate,
                    DepartTime: '07:00',
                    ReturnDate: this.searchOptions.flightRows[this.searchOptions.flightRows.length - 1].arrivalDate,
                    ReturnTime: '16:00',
                    Via: false,
                    ViaStation: '',
                    NumberOfStops: '3',
                    OriginType: 'Departure',
                    DestinationType: 'Departure',
                    PassengerRailInfos: this.trip.passengers.map(() => ({ Age: 'A' })),
                    TripId: this.trip.id,
                },
            };
            this.$store.commit({ type: 'euRailStore/setEuRailSearchOptions', searchOptions: railSearchOptions });
            const routeData = this.$router.resolve({ name: RoutesNames.euRailResults });
            utilService.openNewWindow(false, routeData.href, '_blank');
        },
        loadMoreFlights() {
            // check flight page limit
            let pageCount = this.$store.getters['flightStore/filteredFlights'].length / ITEM_COUNT_SIZE;
            if (pageCount % 1 !== 0) {
                pageCount = Math.floor(pageCount) + 1;
            }
            if (this.page + 1 > pageCount) {
                return;
            }
            this.setPage(this.page + 1);
        },
        setPage(page) {
            this.$store.commit({ type: 'flightStore/setPage', page });
        },
        setShowFilter(isOpen) {
            this.showFilter = isOpen;
        },
        openBrandedFaresDrawer(flightOptions) {
            // saving also the name and the id - the brands API is not very consistent - so sometimes name will work and sometime id will...
            const brandOptions = {
                packageKey: flightOptions.packageKey,
                brandId: flightOptions.brandId || flightOptions.brandName,
                brandName: flightOptions.brandName || flightOptions.brandId,
                segmentIndex: flightOptions.segmentIndex,
            };
            this.brandOptions = brandOptions;
            this.isBrandedFaresDrawerOpen = true;
        },
        openFareRulesDrawer(event) {
            this.packageKeyForPricingOptions = event.packageKey;
            this.segmentToShowIndex = event.segmentToShowIndex;
            this.isFareRulesDrawerOpen = true;
        },
        setSelectFlightAndDrawer(event) {
            const drawerType = event.drawerType;
            this.selectedFlightResult = event.flightResult;
            if (typeof event.segmentToShowIndex === 'number') {
                this.segmentToShowIndex = event.segmentToShowIndex;
            }
            else {
                this.segmentToShowIndex = null;
            }
            if (drawerType === 'details') {
                this.isDetailsDrawerOpen = true;
            }
            else if (drawerType === 'rules') {
                this.packageKeyForPricingOptions = '';
                this.isFareRulesDrawerOpen = true;
            }
            else if (drawerType === 'pricing') {
                this.isPricingOptionsDrawerOpen = true;
            }
            else if (drawerType === 'seats') {
                this.isSeatsDrawerOpen = true;
            }
        },
        closeDrawer(drawerType) {
            if (drawerType === 'details') {
                this.isDetailsDrawerOpen = false;
            }
            else if (drawerType === 'rules') {
                this.isFareRulesDrawerOpen = false;
            }
            else if (drawerType === 'brandedFares') {
                this.brandOptions = null;
                this.isBrandedFaresDrawerOpen = false;
            }
            else if (drawerType === 'pricing') {
                this.isPricingOptionsDrawerOpen = false;
                this.isPricing = false;
                eventBus.$emit('resetPricingOptionsSelection');
                eventBus.$emit('resetMarkSelectedFlight');
            }
            else if (drawerType === 'seats') {
                this.isSeatsDrawerOpen = false;
            }
        },
        filterFlights(filterBy) {
            this.$store.dispatch({ type: 'flightStore/filterFlights', filterBy, resultsIndex: this.resultsIndex });
            this.isFilterSelected = true;
            this.$store.dispatch({
                type: 'flightStore/recalculateFilters',
                isRecalculateSortByOnFilteredFlights: true,
                modifiedFilterBy: filterBy,
                resultsIndex: this.resultsIndex,
            });
        },
        async clearFiltersSelections() {
            this.isFilterSelected = false;
            await this.$store.dispatch({ type: 'flightStore/resetFilter' });
        },
        resetSeatSelection() {
            eventBus.$emit('resetSeatSelection');
        },
        setShareMode() {
            this.$store.commit({
                type: 'tripStore/setShareMode',
                isShareMode: !this.isShareMode,
                productType: Products.Flight,
            });
        },
        toggleSelectedSegmentsCollapse() {
            if (this.collapseVisible.includes('selected-segments-collapse')) {
                this.collapseVisible.pop();
            }
            else {
                this.collapseVisible.push('selected-segments-collapse');
            }
        },
        async getCollapseVisibleStatus() {
            const status = await flightService.getHideComparisonTableStatus(this.trip.id);
            this.collapseVisibleStatus = !status;
        },
        async setCollapseVisibleStatus() {
            this.collapseVisibleStatus = !this.collapseVisibleStatus;
            await flightService.setHideComparisonTableStatus(this.trip.id, !this.collapseVisibleStatus);
        },
        resetByScheduleSelections() {
            this.selectedSegments = [];
            this.resultsIndex = 0;
            this.isOnBySchedulePricingPage = false;
            this.bySchedulePricing = null;
        },
    },
    computed: {
        showUnusedTicketsBanner() {
            return this.trip.passengers.some((p) => p.unusedTickets?.length > 0);
        },
        isUnusedTicketsBannerDisabled() {
            return this.trip.passengers.length > 1;
        },
        isMultiCarrier() {
            if (this.flightsRes[this.resultsIndex].results[0].segments.length === 1) {
                // if we only have one segment such as in "one way" trips or "bySchedule", there's only one carrier so we'll return false
                return false;
            }
            const isMultiCarrier = this.flightsRes[this.resultsIndex].results.some((flightResult) => {
                const firstSegmentCarrier = flightResult.segments[0].flights[0].carrierName; // getting the first segment carrier for comparison
                return !flightResult.segments.every((segment) => segment.flights[0].carrierName === firstSegmentCarrier);
            });
            return isMultiCarrier;
        },
        cacheKey() {
            return this.$store.getters['flightStore/cacheKey'](this.resultsIndex);
        },
        flightProduct() {
            return Products.Flight;
        },
        routesNames() {
            return RoutesNames;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        products() {
            return Products;
        },
        filteredFlights() {
            return this.$store.getters['flightStore/filteredFlights'];
        },
        euRailOptionsRes() {
            return this.$store.getters['euRailStore/railsAnswer'];
        },
        fareRules() {
            return this.$store.getters['flightStore/fareRules'];
        },
        pricingOptions() {
            return this.$store.getters['flightStore/pricingOptions'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        flightsResNumber() {
            if (!this.flightsRes || this.flightsRes.length < 1) {
                return 0;
            }
            return this.flightsRes[0].results.length;
        },
        flightsRes() {
            // TODO: add new type
            return this.$store.getters['flightStore/flightsRes'];
        },
        destCountryCode() {
            return this.flightsRes?.[this.resultsIndex]?.destCountryCode;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        searchOptions() {
            return this.$store.getters['flightStore/searchOptions'];
        },
        flightsToShow() {
            // get the relevant flights
            const count = ITEM_COUNT_SIZE;
            const { page } = this.flightFilter;
            let startIdx = (page - 1) * count;
            if (!page) {
                startIdx = 0;
            }
            if (this.isMobile) {
                return this.filteredFlights.slice(0, startIdx + count);
            }
            return this.filteredFlights.slice(startIdx, startIdx + count);
        },
        drawerTitle() {
            if (this.selectedFlightResult) {
                return 'More details';
            }
            return '';
        },
        page() {
            return this.$store.getters['flightStore/page'];
        },
        flightFilter() {
            return this.$store.getters['flightStore/flightFilter'];
        },
        activeFiltersAmount() {
            return this.$store.getters['flightStore/activeFiltersAmount'];
        },
        isShareMode() {
            return this.$store.getters['tripStore/isShareMode'];
        },
        canSendPriceOffer() {
            return this.$store.getters['tripStore/canSendPriceOffer'];
        },
        showStaySafeBanner() {
            return this.loggedinUser?.dutyOfCareToken;
        },
    },
    unmounted() {
        eventBus.$off('reloadFlightResults', this.loadData);
        eventBus.$off('cancelBtnsLoading', this.cancelBtnsLoading);
        eventBus.$off('proceedWithRecentQuote', this.proceedWithRecentQuote);
        eventBus.$off('continueSelectingFlight', this.continueSelectingFlight);
        eventBus.$off('handleAddToCart', this.handleAddToCart);
        eventBus.$off('handleSelectingFlightBySchedule', this.handleSelectingFlightBySchedule);
        eventBus.$off('openCapacityDialogForShare', this.openCapacityDialogForShare);
    },
});
