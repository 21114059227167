<template>
  <section class="ticket-agreement is-flex">
    <template v-if="hasAgreementsToShow">
      <!-- AgencyRate -->
      <el-tooltip v-if="showAgencyRate" placement="top" effect="light" :open-delay="200">
        <template #content>
          <ul class="agreement-tooltip-list">
            <li v-for="(agreement, index) in agencyRateTooltipContant" :key="index">
              <div class="is-flex agreement-tooltip__item">
                <div class="agreement-tooltip__icon" v-if="agreement.icon != null && agreement.icon.length > 0">
                  <app-img :externalUrl="agreement.icon" />
                </div>
                <div class="agreement-tooltip__icon agreement-tooltip__icon--palceholder" v-else>
                  <span class="material-symbols-outlined agreement-img">handshake</span>
                </div>
                <div class="agreement-tooltip__description">{{ displayAgreement(agreement) }}</div>
              </div>
            </li>
          </ul>
        </template>
        <div>
          <span class="material-symbols-outlined agreement-img">handshake</span>
          {{ $t('common.agencyRate') }}
        </div>
      </el-tooltip>

      <!-- CorporateRate -->
      <el-tooltip v-if="showCorporateRate" placement="top" effect="light" :open-delay="200">
        <template #content>
          <ul class="agreement-tooltip-list">
            <li v-for="(agreement, index) in corporateRateTooltipContant" :key="index">
              <div class="is-flex agreement-tooltip__item">
                <div class="agreement-tooltip__icon" v-if="agreement.icon != null && agreement.icon.length > 0">
                  <app-img :externalUrl="agreement.icon" />
                </div>
                <div class="agreement-tooltip__icon agreement-tooltip__icon--palceholder" v-else>
                  <span class="material-symbols-outlined agreement-img">handshake</span>
                </div>
                <div class="agreement-tooltip__description">{{ displayAgreement(agreement) }}</div>
              </div>
            </li>
          </ul>
        </template>
        <div>
          <span class="material-symbols-outlined agreement-img">handshake</span>
          {{ $t('common.corporateRate') }}
        </div>
      </el-tooltip>

      <!-- Both -->
      <el-tooltip v-if="showBoth" placement="top" effect="light" :open-delay="200">
        <template #content>
          <ul class="agreement-tooltip-list">
            <li v-for="(agreement, index) in bothRateTooltipContant" :key="index">
              <div class="is-flex agreement-tooltip__item">
                <div class="agreement-tooltip__icon" v-if="agreement.icon != null && agreement.icon.length > 0">
                  <app-img :externalUrl="agreement.icon" />
                </div>
                <div class="agreement-tooltip__icon agreement-tooltip__icon--palceholder" v-else>
                  <span class="material-symbols-outlined agreement-img">handshake</span>
                </div>
                <div class="agreement-tooltip__description">{{ displayAgreement(agreement) }}</div>
              </div>
            </li>
          </ul>
        </template>
        <div>
          <span class="material-symbols-outlined agreement-img">handshake</span>
          {{ $t('common.bothRate') }}
        </div>
      </el-tooltip>
    </template>

    <!-- PackageRate -->
    <el-tooltip
      v-if="showPackageRate"
      :content="packageRateTooltipContant"
      placement="top"
      effect="light"
      :open-delay="200">
      <div>
        <app-img class="agreement-img" :isIcon="true" iconName="package-rate.svg" />
        {{ $t('common.packageRate') }}
      </div>
    </el-tooltip>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { AgreementType } from '@/types/consts';
import appImg from './app-img.vue';
const PACKAGE_STRING = 'MerchantPackage';


export default defineComponent({
  name: 'TicketAgreements',
  components: { appImg },
  props: {
    agreements: { type: Array },
  },
  methods: {
    displayAgreement(agreement) {
      return agreement.toolTip || agreement.contractName;
    },
  },
  computed: {
    agreementsType() {
      return this.agreements.map((agr) => {
        return agr.type;
      });
    },
    showAgencyRate() {
      return this.agreementsType.includes(AgreementType.Agency);
    },
    showCorporateRate() {
      return this.agreementsType.includes(AgreementType.Corp);
    },
    showBoth() {
      return this.agreementsType.includes(AgreementType.Both);
    },
    showPackageRate() {
      return this.agreements.find((agr) => agr.description === PACKAGE_STRING);
    },
    agencyRateTooltipContant() {
      if (!this.showAgencyRate) {
        return '';
      }
      const agreement = this.agreements.find((agr) => agr.type === AgreementType.Agency);
      if (agreement && !agreement.agreements?.length) {
        return [agreement];
      }
      return agreement.agreements;
    },

    corporateRateTooltipContant() {
      if (!this.showCorporateRate) {
        return '';
      }
      const agreement = this.agreements.find((agr) => agr.type === AgreementType.Corp);
      if (agreement && !agreement.agreements) {
        return [agreement];
      }
      return agreement.agreements;
    },

    bothRateTooltipContant() {
      if (!this.showBoth) {
        return '';
      }
      const agreement = this.agreements.find((agr) => agr.type === AgreementType.Both);
      if (agreement && !agreement.agreements) {
        return [agreement];
      }
      return agreement.agreements;
    },

    packageRateTooltipContant() {
      if (!this.showPackageRate) {
        return '';
      }
      return this.$t('hotel.noPackageRateMsg');
    },
    hasAgreementsToShow() {
      return this.agreementsType.some((type) => type !== AgreementType.None);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts/functions';
.ticket-agreement {
  font-size: rem(12px);
  gap: 1rem;

  & > div {
    cursor: default;
    display: flex;
    align-items: center;
    gap: rem(4px);
  }
  .agreement-img {
    width: rem(20.9px);
    font-size: rem(20.9px);
  }
}

.agreement-tooltip-list {
  .agreement-tooltip__item {
    margin-bottom: rem(4px);
    .agreement-tooltip__icon {
      height: rem(24px);
      width: rem(24px);
      margin-right: rem(8px);

      img {
        height: rem(24px);
      }
    }
  }
}
</style>
