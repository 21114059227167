import { tripService } from '../services/trip.service';
import { loggerService } from '@/services/logger.service';
import { reminderService } from '@/services/reminder.service';
import { alertService } from '@/services/alert.service';
import store from '@/store/store';
import { BookingStage, MAX_SHARE_COUNT, Products, TripValidationsNames } from '@/types/consts';
import { referenceFieldService } from '@/services/reference-fields.service';
import { utilService } from '@/services/util.service';
import { DisplaySettingsForBooker } from '@/types/ReferenceField/consts';
import { eventBus } from '@/services/event-bus.service';
export const initialState = () => ({
    trips: [],
    pastTrips: [],
    inTravelTrips: [],
    trip: null,
    quotes: [],
    preTripQuotes: [],
    cancelledQuotes: [],
    quotesMap: null,
    productsToShare: { type: '', products: [] },
    isShareMode: false,
    tripValidations: [],
    refFieldsMap: {},
    refFieldsMapOrg: {},
    travelersInProcess: [],
    tripParticipantsRes: {},
    preTripItems: [],
    preTripApproverName: '',
    requestAssistanceItems: [],
    approvalFlowSteps: null,
    approvalModifyUsers: [],
    requestAssistanceQuotes: [],
    tripOOPJustifications: [],
    savedTripOOPJustifications: [],
    tripsHomePage: null,
    showStaySafe: false,
    compensationPricing: {},
    isCarParallelSearchShown: false,
    isHotelParallelSearchShown: false,
    isRecalculateApprovalFlowOnChange: false,
});
export const tripStore = {
    namespaced: true,
    state: initialState(),
    getters: {
        trips(state) {
            return state.trips;
        },
        tripsHomePage(state) {
            return state.tripsHomePage;
        },
        pastTrips(state) {
            return state.pastTrips;
        },
        inTravelTrips(state) {
            return state.inTravelTrips;
        },
        trip(state) {
            return state.trip;
        },
        quotes(state) {
            return state.quotes;
        },
        cancelledQuotes(state) {
            return state.cancelledQuotes;
        },
        isRecalculateApprovalFlowOnChange(state) {
            return state.isRecalculateApprovalFlowOnChange;
        },
        quotesMap(state) {
            return state.quotesMap;
        },
        isShareMode(state) {
            return state.isShareMode;
        },
        productsToShare(state) {
            return state.productsToShare;
        },
        tripValidations(state) {
            return state.tripValidations;
        },
        refFieldsMap(state) {
            return state.refFieldsMap;
        },
        refFieldsMapOrg(state) {
            return state.refFieldsMapOrg;
        },
        travelersInProcess(state) {
            return state.travelersInProcess;
        },
        tripParticipantsRes(state) {
            return state.tripParticipantsRes;
        },
        preTripItems(state) {
            return state.preTripItems;
        },
        preTripApproverName(state) {
            return state.preTripApproverName;
        },
        requestAssistanceItems(state) {
            return state.requestAssistanceItems;
        },
        approvalFlowSteps(state) {
            return state.approvalFlowSteps;
        },
        approvalModifyUsers(state) {
            return state.approvalModifyUsers;
        },
        requestAssistanceQuotes(state) {
            return state.requestAssistanceQuotes;
        },
        tripOOPJustifications(state) {
            return state.tripOOPJustifications;
        },
        savedTripOOPJustifications(state) {
            return state.savedTripOOPJustifications;
        },
        canSendPriceOffer(state, getters, globalStore) {
            const user = globalStore.authStore.user;
            return user.permissions.includes('CanViewPriceOffer');
        },
        showStaySafe(state) {
            return state.showStaySafe;
        },
        compensationPricing(state) {
            return state.compensationPricing;
        },
        isCarParallelSearchShown(state) {
            return state.isCarParallelSearchShown;
        },
        isHotelParallelSearchShown(state) {
            return state.isHotelParallelSearchShown;
        },
    },
    mutations: {
        setTravelersInProcess(state, { travelersInProcess }) {
            state.travelersInProcess = travelersInProcess;
        },
        setTrips(state, { trips }) {
            state.trips = trips;
        },
        setTripsHomePage(state, { trips }) {
            state.tripsHomePage = trips;
        },
        setPastTrips(state, { trips }) {
            state.pastTrips = trips;
        },
        setIsRecalculateApprovalFlowOnChange(state, { isRecalculateApprovalFlowOnChange }) {
            state.isRecalculateApprovalFlowOnChange = isRecalculateApprovalFlowOnChange;
        },
        setInTravelTrips(state, { trips }) {
            state.inTravelTrips = trips;
        },
        setTrip(state, { trip }) {
            state.trip = trip;
            tripService.setTripOptions();
            //Init any relevant old data
            state.travelersInProcess = [];
        },
        addTripTraveller(state, { traveller }) {
            state.trip.passengers.push(traveller);
        },
        updateTripTravellerProfileInfo(state, { passenger }) {
            const idx = state.trip.passengers.findIndex((q) => q.id === passenger.id);
            state.trip.passengers.splice(idx, 1, passenger);
        },
        clearTrip(state) {
            state.trip = null;
            state.travelersInProcess = [];
        },
        setNumberOfPassengers(state, { numberOfPassengers }) {
            if (state.trip) {
                state.trip.numberOfPassengers = numberOfPassengers;
            }
        },
        removeQuote(state, { quoteId }) {
            // remove from standalone quotes array
            let idx = state.quotes.findIndex((q) => q.quoteId === quoteId);
            state.quotes.splice(idx, 1);
            // remove from trip quotes array
            idx = state.trip?.quotes.findIndex((q) => q.quoteId === quoteId);
            state.trip?.quotes.splice(idx, 1);
        },
        setQuotes(state, { quotesResponse }) {
            //get quotesResponse and flatten all quotes to one array
            const quotes = [];
            for (const quotesType in quotesResponse) {
                const currQuotes = quotesResponse[quotesType];
                quotes.push(...currQuotes);
            }
            state.quotesMap = quotesResponse;
            state.quotes = quotes || [];
        },
        updateQuotes(state, { quotes }) {
            if (quotes) {
                const clonedQuotes = utilService.deepClone(quotes);
                state.quotes = clonedQuotes;
            }
        },
        updateQuote(state, { quote }) {
            // Updating Quote[] only and NOT quotesmap
            if (quote) {
                const clonedQuotes = utilService.deepClone(state.quotes);
                const index = clonedQuotes.findIndex((q) => q.quoteId === quote.quoteId);
                clonedQuotes.splice(index, 1, quote);
                state.quotes = clonedQuotes;
            }
        },
        setPreTripQuotes(state, { preTripQuotesResponse }) {
            const quotes = [];
            for (const quotesType in preTripQuotesResponse) {
                if (quotesType === 'TripId' || quotesType === '$type') {
                    //TODO: $type ???
                    continue;
                }
                const currQuotes = preTripQuotesResponse[quotesType] || [];
                // Set the productName on each quote
                currQuotes.forEach((quote) => {
                    quote.productName = quotesType.toLowerCase().slice(0, -1);
                });
                quotes.push(...currQuotes);
            }
            state.preTripItems = quotes || [];
        },
        setShareMode(state, { isShareMode, productType = '' }) {
            state.productsToShare.type = productType;
            // reset items in share when exiting share mode
            if (!isShareMode) {
                state.productsToShare = {
                    type: '',
                    products: [],
                };
            }
            state.isShareMode = isShareMode;
        },
        addToShare(state, { product }) {
            if (state.productsToShare.products.length < MAX_SHARE_COUNT) {
                const productsToShare = utilService.deepClone(state.productsToShare);
                productsToShare.products.push(product);
                state.productsToShare = productsToShare;
            }
        },
        removeFromShare(state, { product, cacheKeyToRemove }) {
            const idx = state.productsToShare.products.findIndex((p) => {
                // if we put cacheKeyToRemove to remove it, or try to find cacheKey in the product
                return p.cacheKey === cacheKeyToRemove || p.cacheKey === product.cacheKey || p.cacheKey === product.packageKey;
            });
            if (idx !== -1) {
                state.productsToShare.products.splice(idx, 1);
            }
        },
        setTripValidations(state, { tripValidationsResponse }) {
            state.tripValidations = tripValidationsResponse;
        },
        setCancelledQuotes(state, { cancelledQuotesResponse }) {
            const quotes = [];
            for (const quotesType in cancelledQuotesResponse) {
                const currQuotes = cancelledQuotesResponse[quotesType];
                quotes.push(...currQuotes);
            }
            state.cancelledQuotes = quotes || [];
        },
        setRefFields(state, { stage, refFields }) {
            state.refFieldsMap[stage] = refFields;
        },
        setRefFieldsOrg(state, { stage, refFields }) {
            state.refFieldsMapOrg[stage] = refFields;
        },
        setTripParticipants(state, { participantsRes }) {
            state.tripParticipantsRes = participantsRes;
        },
        addPreTripItem(state, { preTripItem }) {
            const preTripItemCopy = utilService.deepClone(preTripItem);
            state.preTripItems.push(preTripItemCopy);
        },
        removePreTripItem(state, { preTripItemId }) {
            const idx = state.preTripItems.findIndex((item) => item.id === preTripItemId);
            if (idx !== -1) {
                state.preTripItems.splice(idx, 1);
            }
        },
        updatePreTripItem(state, { preTripItemId, comment }) {
            const idx = state.preTripItems.findIndex((item) => item.id === preTripItemId);
            state.preTripItems[idx].searchOptions.comment = comment;
        },
        setPreTripItems(state, { preTripResponse }) {
            state.preTripItems = utilService.deepClone(preTripResponse);
        },
        addPreTripApproverName(state, { approverName }) {
            state.preTripApproverName = approverName;
        },
        // Request Assistance
        setAssistanceQuotes(state, { assistanceQuotesResponse }) {
            const quotes = [];
            for (const quotesType in assistanceQuotesResponse) {
                if (quotesType === 'tripId' || quotesType === '$type' || quotesType === 'relatedAccount') {
                    //TODO: $type ???
                    continue;
                }
                const currQuotes = assistanceQuotesResponse[quotesType] || [];
                // Set the productName on each quote
                currQuotes.forEach((quote) => {
                    quote.productName = quotesType.toLowerCase().slice(0, -1);
                });
                quotes.push(...currQuotes);
            }
            state.requestAssistanceItems = utilService.deepClone(quotes);
            state.requestAssistanceQuotes = quotes || [];
        },
        addRequestAssistanceItem(state, { requestAssistanceItem }) {
            const requestAssistanceItemCopy = utilService.deepClone(requestAssistanceItem);
            state.requestAssistanceItems.push(requestAssistanceItemCopy);
        },
        removeAssistanceItem(state, { requestAssistantItemId }) {
            const idx = state.requestAssistanceItems.findIndex((item) => item.id === requestAssistantItemId);
            if (idx !== -1) {
                state.requestAssistanceItems.splice(idx, 1);
            }
        },
        updateRequestAssistantItem(state, { updatedAssist }) {
            const idx = state.requestAssistanceItems.findIndex((item) => item.id === updatedAssist.id);
            state.requestAssistanceItems[idx] = utilService.deepClone(updatedAssist);
        },
        // Request Assistance -END
        setApprovalFlowSteps(state, { approvalFlowStepsResponse }) {
            state.approvalFlowSteps = utilService.deepClone(approvalFlowStepsResponse);
        },
        setApprovalModifyUsers(state, { approvalModifyUsers }) {
            state.approvalModifyUsers = utilService.deepClone(approvalModifyUsers);
        },
        setTripOOPJustifications(state, { justifications }) {
            const clonedJustifications = utilService.deepClone(justifications);
            const clonedTripOOPJustifications = utilService.deepClone(state.tripOOPJustifications);
            // if all justifications are saved together:
            if (clonedJustifications.length === clonedTripOOPJustifications.length || !clonedTripOOPJustifications.length) {
                state.tripOOPJustifications = clonedJustifications;
                return;
            }
            //  find the relevant justification
            clonedJustifications.forEach((justification) => {
                if (justification.Text || justification.Code) {
                    const relevantQuoteIdx = clonedTripOOPJustifications.findIndex((item) => item.QuoteId === justification.QuoteId && justification.JustificationType === item.JustificationType);
                    // replace the relevant justification with the new one
                    if (relevantQuoteIdx !== -1) {
                        clonedTripOOPJustifications[relevantQuoteIdx] = justification;
                    }
                    else {
                        clonedTripOOPJustifications.push(justification);
                    }
                    state.tripOOPJustifications = clonedTripOOPJustifications;
                }
            });
        },
        setSavedTripOOPJustifications(state, { savedTripOOPJustifications }) {
            state.savedTripOOPJustifications = utilService.deepClone(savedTripOOPJustifications);
        },
        resetTripOOPJustifications(state) {
            state.tripOOPJustifications = [];
        },
        setInitialState(state) {
            Object.assign(state, initialState());
        },
        setShowStaySafe(state, { showStaySafeResponse }) {
            state.showStaySafe = showStaySafeResponse;
        },
        setCompensationPricing(state, { compensationPricingResponse, compensationQuoteId }) {
            state.compensationPricing[compensationQuoteId] = compensationPricingResponse;
        },
        setIsCarParallelSearchShown(state, { isShown }) {
            state.isCarParallelSearchShown = isShown;
        },
        setIsHotelParallelSearchShown(state, { isShown }) {
            state.isHotelParallelSearchShown = isShown;
        },
    },
    actions: {
        // reset trip and user's products state
        async initState({ commit, dispatch }) {
            commit({ type: 'setInitialState' });
            await dispatch({ type: 'resetProductState' });
        },
        async resetProductState({ commit }) {
            const loggedinUser = store.getters['authStore/loggedinUser'];
            for (const key in Products) {
                if (utilService.isProductAccessible(Products[key], loggedinUser)) {
                    commit(`${utilService.lowerCaseFirstLetter(key)}Store/setInitialState`, null, { root: true });
                }
            }
        },
        async cancelQuote({ commit }, { quoteId, selectedRefund, product = '' }) {
            try {
                const res = await tripService.cancelQuote(quoteId, selectedRefund);
                if (res && res.isSuccess === true) {
                    commit({ type: 'removeQuote', quoteId });
                    if (product && product === Products.Compensation) {
                        commit({ type: 'setCompensationPricing', compensationPricingResponse: 0, compensationQuoteId: quoteId });
                    }
                }
                return res;
            }
            catch (err) {
                loggerService.error(err);
                throw err;
            }
        },
        async createTrip({ commit }) {
            try {
                const trip = tripService.createTrip();
                commit({ type: 'setTrip', trip });
            }
            catch (err) {
                loggerService.error(err);
                throw err;
            }
        },
        async reloadDataOnTravelerChanged({ commit, dispatch, getters }) {
            const trip = getters.trip;
            await dispatch({ type: 'loadTripValidations', tripId: +trip.id });
            //await dispatch({ type: 'loadApprovalFlowSteps'});
        },
        async loadTrip({ commit, state, dispatch, getters }, { tripId, passengerId = 0, accountName = '', sourceSystem = '' }) {
            try {
                if (tripId === undefined) {
                    tripId = getters.trip?.id;
                }
                const trip = await tripService.getById(tripId, true);
                // if we are loading a new trip while having a current trip
                if (state.trip && state.trip.id !== trip.id) {
                    await dispatch({ type: 'resetProductState' });
                }
                commit({ type: 'setTrip', trip });
                if (passengerId) {
                    const currUser = store.getters['authStore/loggedinUser'];
                    eventBus.$emit('checkForProfileInfoSabre', {
                        PassengerId: passengerId,
                        AccountName: accountName,
                        UserName: currUser.userName,
                        SourceSystem: sourceSystem,
                    });
                }
            }
            catch (err) {
                loggerService.error(err);
                throw err;
            }
        },
        async removeTraveler({ commit, getters, dispatch, state, rootGetters }, { travelerId }) {
            const trip = getters.trip;
            // When removing travel add him to the in process travelers so the loader of the tsg will be on.
            const travelerToRemoveIdx = trip.passengers.findIndex((passenger) => passenger.id === travelerId);
            if (travelerToRemoveIdx !== -1) {
                const travelersInProcess = [...getters.travelersInProcess, travelerToRemoveIdx];
                commit({ type: 'setTravelersInProcess', travelersInProcess });
            }
            try {
                await tripService.removePassenger(trip.id, travelerId);
                const userProfile = rootGetters['userStore/userProfile'];
                if (userProfile) {
                    commit({ type: 'userStore/setUserProfile', userProfileData: null }, { root: true });
                }
                alertService.success('alert.traveler.removedSuccessfully');
                //reload data
                await dispatch({ type: 'reloadDataOnTravelerChanged' });
            }
            catch (error) {
                alertService.error('alert.general.error', error);
            }
            finally {
                if (travelerToRemoveIdx !== -1) {
                    const travelersInProcess = getters.travelersInProcess.filter((idx) => idx !== travelerToRemoveIdx);
                    commit({ type: 'setTravelersInProcess', travelersInProcess });
                }
            }
        },
        async addTraveler({ commit, getters, dispatch, state, rootGetters }, { passengerInfo }) {
            const trip = getters.trip;
            const loggedinUser = rootGetters['authStore/loggedinUser'];
            try {
                // User profile Mandate Fields
                if (passengerInfo.passenger.CorporateUserId === loggedinUser.id ||
                    (passengerInfo.passenger.CorporateUserId &&
                        ((!loggedinUser.permissions.includes('PreventUpdatingOthersAndOwnProfile') &&
                            loggedinUser.permissions.includes('CanEditAndViewProfile')) ||
                            loggedinUser.permissions.includes('CanViewProfile') ||
                            loggedinUser.permissions.includes('PreventUpdatingOthersAndOwnProfile') ||
                            (!loggedinUser.permissions.includes('CanEditAndViewProfile') &&
                                !loggedinUser.permissions.includes('CanViewProfile') &&
                                !loggedinUser.permissions.includes('PreventUpdatingOthersAndOwnProfile'))))) {
                    //Can view profiles
                    // If user added new guest no need to load user profile (no id property on guest user)
                    await dispatch({
                        type: 'userStore/loadUserProfile',
                        userId: passengerInfo.passenger.CorporateUserId,
                        tripId: trip.id,
                        passengerInfo,
                    }, { root: true });
                    const userProfileMandateFields = rootGetters['userStore/userProfile'].mandateFields;
                    if (userProfileMandateFields.length) {
                        return;
                    }
                }
                passengerInfo.passenger.TripId = +trip.id;
                if (!trip.passengers.length) {
                    passengerInfo.passenger.IsPrimary = true;
                    passengerInfo.updatePrimary = true;
                }
                //only if we have the passenger index
                if (passengerInfo.idx !== null && passengerInfo.idx !== undefined) {
                    const travelersInProcess = [...getters.travelersInProcess, passengerInfo.idx];
                    commit({ type: 'setTravelersInProcess', travelersInProcess });
                }
                const traveler = await tripService.addTraveler(passengerInfo.passenger, passengerInfo.addTravellerType);
                // If both first & last name are coming as null from BE -
                // the case is that first & last name are already exist in the system and it is not supported in the app now.
                // so in this case we return error.
                if (traveler.FirstName === null && traveler.LastName === null) {
                    alertService.error('notifications.thisUserIsAlreadySelected', null);
                    return;
                }
                alertService.success('alert.traveler.savedSuccessfully');
                if (passengerInfo.updatePrimary) {
                    tripService.updatePrimaryUser(+trip.id, traveler.Id);
                }
                const currentuser = store.getters['authStore/loggedinUser'];
                //do async
                (async () => {
                    try {
                        const reminders = await reminderService.getNotificationsAddUser(traveler.CorpLocator, traveler.GdsProfileLocator, currentuser.corporationId, trip.id);
                        if (reminders && reminders.length > 0) {
                            await reminderService.showOptionalNotifications(reminders);
                        }
                    }
                    catch (error) {
                        // Handle any errors
                        alertService.error('An error occurred while processing reminders:', error);
                    }
                })();
                //reload data
                dispatch({ type: 'reloadDataOnTravelerChanged' });
                //Add temporary traveller
                const newTraveler = {
                    id: traveler.Id,
                    firstName: traveler.FirstName,
                    lastName: traveler.LastName,
                    middleName: traveler.MiddleName,
                    email: traveler.Email,
                    gender: traveler.Gender,
                    hasFlightCapacityPolicy: traveler.HasFlightCapacityPolicy,
                };
                await commit({ type: 'addTripTraveller', traveller: newTraveler });
                dispatch({
                    type: 'loadTrip',
                    tripId: +trip.id,
                    passengerId: traveler.Id,
                    accountName: traveler.CorpLocator,
                    sourceSystem: traveler.LocatorSourceSystem,
                }); //execute async without to await
            }
            catch (err) {
                alertService.error('alert.general.error', err);
                throw err;
            }
            finally {
                if (passengerInfo.idx !== null && passengerInfo.idx !== undefined) {
                    const travelersInProcess = state.travelersInProcess.filter((x) => x !== passengerInfo.idx);
                    commit({ type: 'setTravelersInProcess', travelersInProcess });
                }
            }
        },
        async loadPastTrips({ commit }, { fromDate, toDate }) {
            // TODO : for MVP - requesting only specific type atm
            const types = {
                MonitorType: 0,
                FilterType: 4,
                fromDate: fromDate,
                toDate: toDate,
                notCache: true,
            };
            try {
                const trips = (await tripService.query(types)) || [];
                commit({ type: 'setPastTrips', trips });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadInTravelTrips({ commit }) {
            // TODO : for MVP - requesting only specific type atm
            const types = {
                MonitorType: 0,
                FilterType: 2, //InTravelTrips
            };
            try {
                const trips = (await tripService.query(types)) || [];
                commit({ type: 'setInTravelTrips', trips });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadTrips({ commit }) {
            // TODO : for MVP - requesting only specific type atm
            const types = {
                MonitorType: 0,
            };
            try {
                const trips = (await tripService.query(types)) || [];
                commit({ type: 'setTrips', trips });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadTripsHomePage({ commit }) {
            // TODO : for MVP - requesting only specific type atm
            const types = {
                MonitorType: 0,
            };
            try {
                const trips = (await tripService.queryHomePage(types)) || [];
                commit({ type: 'setTripsHomePage', trips });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadCompensationPricing({ commit }, { tripId, quotes, currency, sourceSystem, compensationQuote }) {
            try {
                const compensationPricingResponse = await tripService.getCompensationPricing(tripId, quotes, currency, sourceSystem, compensationQuote);
                commit({
                    type: 'setCompensationPricing',
                    compensationPricingResponse,
                    compensationQuoteId: compensationQuote.quoteId,
                });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadQuotes({ commit, getters, rootState }, { resetJustifications = true }) {
            //reset oop justifications before loading quotes- every quote will update his justifications if existing
            if (resetJustifications) {
                commit({ type: 'resetTripOOPJustifications' });
            }
            try {
                const data = await Promise.all([
                    tripService.getQuotes(getters.trip.id),
                    tripService.getCacelledQuotes(getters.trip.id),
                ]);
                // TODO: Revert this change when done working with local queries
                const quotesResponse = data[0];
                // const quotesResponse: QuotesResponse | null = require('./amtrak-quote.json');
                const currentuser = store.getters['authStore/loggedinUser'];
                reminderService.getNotificationsCart(quotesResponse, getters.trip.id, currentuser.corporationId);
                commit({ type: 'setQuotes', quotesResponse });
                const cancelledQuotesResponse = data[1];
                commit({ type: 'setCancelledQuotes', cancelledQuotesResponse });
                const initJustifications = utilService
                    .deepClone(getters.quotes)
                    .flatMap((quote) => quote.quoteTravelPolicyJustifications || []);
                commit({
                    type: 'setSavedTripOOPJustifications',
                    savedTripOOPJustifications: utilService.deepClone(initJustifications),
                });
                commit({ type: 'setTripOOPJustifications', justifications: utilService.deepClone(initJustifications) });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadPreTripQuotes({ commit, getters }) {
            try {
                const preTripQuotesResponse = await tripService.getPreTripQuotes(getters.trip.id);
                commit({ type: 'setPreTripQuotes', preTripQuotesResponse });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadApprovalFlowSteps({ commit, getters }) {
            const approvalFlowStepsResponse = await tripService.getApprovalFlowSteps(getters.trip.id);
            commit({ type: 'setApprovalFlowSteps', approvalFlowStepsResponse });
        },
        async checkIfRecalculateApprovalFlowOnChange({ commit, getters }, { remarksToSend }) {
            const quoteIds = getters.quotes.map((quote) => quote.id);
            const req = {
                TripId: getters.trip.id,
                CorporateUserGroupId: getters.trip.passengers[0].corporateUserGroupId,
                TripApprovalCheckOutRemarks: remarksToSend,
                QuoteIds: [...quoteIds],
            };
            const res = await tripService.checkIfCalculateApprovalFlow(req);
            commit({ type: 'setIsRecalculateApprovalFlowOnChange', isRecalculateApprovalFlowOnChange: res });
        },
        async updateTripNumberOfPassengers({ commit, getters }, { numberOfPassengers }) {
            const lastNumberOfPassengers = getters.trip.numberOfPassengers;
            const req = {
                TripId: getters.trip.id,
                NumberOfPassengers: numberOfPassengers,
            };
            try {
                commit({ type: 'setNumberOfPassengers', numberOfPassengers });
                await tripService.updateNumberOfPassengers(req);
            }
            catch (err) {
                loggerService.error(err);
                commit({ type: 'setNumberOfPassengers', numberOfPassengers: lastNumberOfPassengers });
            }
        },
        async loadAssistanceQuotes({ commit, getters }) {
            try {
                const assistanceQuotesResponse = await tripService.loadAssistanceQuotes(getters.trip.id);
                commit({ type: 'setAssistanceQuotes', assistanceQuotesResponse });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadTripValidations({ commit, getters }, { tripId }) {
            try {
                const tripValidations = await tripService.getTripValidations(tripId);
                if (!tripValidations?.length) {
                    commit({ type: 'setTripValidations', tripValidationsResponse: tripValidations });
                    return;
                }
                // bug 52124 - because tripValidations is fetched additional times after we already load in the quotes, we have to update the ccCharge into the quote's display price from the new tripValidations
                const updatedQuotes = new Map(); // Track quotes that need updates
                // Check each validation to find quotes needing updates
                for (const validation of tripValidations) {
                    const quote = getters.quotes.find((q) => q.quoteId === validation.QuoteId);
                    if (!quote) {
                        continue;
                    }
                    const fopValidation = validation.Validations.find((v) => v.name?.includes(TripValidationsNames.FormOfPayment));
                    if (fopValidation?.creditCardCharge > 0 && quote.creditCardAmount === 0) {
                        // Clone the quote if we haven't already
                        const quoteToUpdate = updatedQuotes.get(quote.quoteId) || utilService.deepClone(quote);
                        quoteToUpdate.totalPrice += fopValidation.creditCardCharge;
                        quoteToUpdate.displayTotalPrice += fopValidation.creditCardCharge;
                        updatedQuotes.set(quote.quoteId, quoteToUpdate);
                    }
                }
                // If we have any updates, create new quotes array and commit once
                if (updatedQuotes.size > 0) {
                    const finalQuotes = getters.quotes.map((quote) => updatedQuotes.has(quote.quoteId) ? updatedQuotes.get(quote.quoteId) : quote);
                    commit({ type: 'updateQuotes', quotes: finalQuotes });
                }
                commit({ type: 'setTripValidations', tripValidationsResponse: tripValidations });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadRefFields({ commit }, { tripId, stage }) {
            let refFieldsClone = null;
            try {
                // clear prev ref fields
                commit({ type: 'setRefFields', stage, refFields: null });
                let refFields = await referenceFieldService.getReferenceField(stage, tripId, 0);
                refFieldsClone = utilService.deepClone(refFields);
                refFields = refFields?.filter((q) => q.displaySettingsForBooker !== DisplaySettingsForBooker.DontDisplay) || [];
                if (stage === BookingStage.Checkout) {
                    refFields =
                        refFields?.filter((q) => q.displaySettingsForBooker === DisplaySettingsForBooker.AtCheckout) || [];
                }
                else if (stage === BookingStage.ProfileSelect) {
                    refFields =
                        refFields?.filter((q) => q.displaySettingsForBooker === DisplaySettingsForBooker.ProfileSelection) || [];
                }
                if (refFields?.length) {
                    commit({ type: 'setRefFields', stage, refFields });
                }
                if (refFieldsClone?.length) {
                    commit({ type: 'setRefFieldsOrg', stage, refFields: refFieldsClone });
                }
            }
            catch (err) {
                loggerService.error(err);
                throw err;
            }
            return refFieldsClone;
        },
        async saveManualQuote({ commit }, { req }) {
            await tripService.saveManualQuote(req);
        },
        addPreTripItem({ commit }, { preTripItem }) {
            preTripItem.id = utilService.makeId();
            commit({ type: 'addPreTripItem', preTripItem });
        },
        addRequestAssistanceItem({ commit }, { requestAssistanceItem }) {
            requestAssistanceItem.id = utilService.makeId();
            commit({ type: 'addRequestAssistanceItem', requestAssistanceItem });
        },
        addRequestAssistanceParallelItems({ commit }, { parallelProductName, searchOptions }) {
            const parallelItem = {
                id: utilService.makeId(),
                productName: parallelProductName,
                searchOptions: JSON.parse(JSON.stringify(searchOptions)),
                isParallelSearchItem: true,
            };
            commit({ type: 'addRequestAssistanceItem', requestAssistanceItem: parallelItem });
        },
        toggleIsParallelSearchItemShown({ commit, state }, { parallelSearchProductType, isShown }) {
            if (Object.values(Products).includes(parallelSearchProductType)) {
                const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
                const mutationName = `setIs${capitalizeFirstLetter(parallelSearchProductType)}ParallelSearchShown`;
                commit(mutationName, { isShown });
            }
            else {
                loggerService.error('Invalid parallelSearchProductType');
            }
        },
        async removePreTripItem({ commit }, { tripId, preTripItemId }) {
            try {
                await tripService.removePreTripItem(tripId, preTripItemId);
                commit({ type: 'removePreTripItem', preTripItemId });
            }
            catch (err) {
                loggerService.error(err);
                throw err;
            }
        },
        async removeAssistanceItem({ commit }, { tripId, productType, requestAssistanceGuid }) {
            try {
                await tripService.removeAssistanceItem(tripId, productType, requestAssistanceGuid);
                commit({ type: 'removeAssistanceItem', requestAssistanceGuid });
            }
            catch (err) {
                loggerService.error(err);
                throw err;
            }
        },
        updatePreTripItem({ commit }, { preTripItemId, comment }) {
            commit({ type: 'updatePreTripItem', preTripItemId, comment });
        },
        async loadProfileRefFields({ commit }, { tripId, stage, passengerId }) {
            try {
                // clear prev ref fields
                commit({ type: 'setRefFields', stage, refFields: null });
                const refFields = await referenceFieldService.getReferenceField(stage, tripId, passengerId);
                if (refFields?.length) {
                    commit({ type: 'setRefFields', stage, refFields });
                }
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async clearProfileRefFields({ commit }, { tripId, stage, passengerId }) {
            try {
                // clear prev ref fields
                commit({ type: 'setRefFields', stage, refFields: null });
                commit({ type: 'setRefFieldsOrg', stage, refFields: null });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadTripParticipants({ commit, rootState }) {
            try {
                const participantsResponse = await tripService.loadTripParticipants(rootState.tripStore.trip.id);
                commit({ type: 'setTripParticipants', participantsRes: participantsResponse });
            }
            catch (err) {
                loggerService.error(err);
                commit({ type: 'setTripParticipants', participantsResponse: null });
            }
        },
        // APPROVALS FLOW
        async getApprovalModifyUsers({ commit }, { tripId }) {
            try {
                const approvalModifyUsers = await tripService.getApprovalModifyUsers(tripId);
                commit({ type: 'setApprovalModifyUsers', approvalModifyUsers });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async updateTripApprovalModify({ commit }, { updatedApprovalFlowSteps }) {
            try {
                const approvalFlowStepsResponse = await tripService.updateTripApprovalModify(updatedApprovalFlowSteps);
                commit({ type: 'setApprovalFlowSteps', approvalFlowStepsResponse });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        storeQuoteJustifications({ commit }, { justifications }) {
            commit({ type: 'setTripOOPJustifications', justifications: utilService.deepClone(justifications) });
        },
        saveTripJustifications({ commit, getters }) {
            if (getters.tripOOPJustifications) {
                const tripJustifications = utilService.deepClone(getters.tripOOPJustifications);
                tripService.saveQuotesJustifications(getters.trip.id, tripJustifications);
                commit({ type: 'setSavedTripOOPJustifications', savedTripOOPJustifications: tripJustifications });
            }
        },
        async saveStaySafeStatus({ commit }, { tripId }) {
            try {
                const showStaySafeResponse = await tripService.getStaySafeStatus(tripId);
                commit({ type: 'setShowStaySafe', showStaySafeResponse });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
    },
};
