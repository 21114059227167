import { defineComponent } from 'vue';
import { Products } from '@/types/consts';
import { DbRailCardTypes, HotelSpecialRates, } from '@/types/types';
import { alphaNum, requiredIf } from '@vuelidate/validators';
import { utilService } from '@/services/util.service';
import { euRailService } from '@/modules/products/rails/eu-rail/services/eu-rail.service';
import { alertService } from '@/services/alert.service';
import { loggerService } from '@/services/logger.service';
import { useVuelidate } from '@vuelidate/core';
import appSelect from '@/components/app-select.vue';
import appSelectV2 from '@/components/app-select-v2.vue';
import appDatepicker from '@/components/form/app-datepicker.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import { ValidateEach } from '@vuelidate/components';
export default defineComponent({
    name: 'FrequentProgramsForm',
    components: {
        appSelect,
        appDatepicker,
        formValidationMsg,
        ValidateEach,
        appSelectV2,
    },
    props: {
        currData: {
            type: Object,
        },
        airplaneCarriers: {
            type: Array,
        },
        hotelLoyaltyCodes: {
            type: Array,
        },
        hotelChains: {
            type: Array,
        },
        corporateUserId: {
            type: Number,
        },
        tripId: {
            type: Number,
            default: 0,
        },
        disableEdit: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            formData: {
                carPrograms: [],
                railPrograms: [],
                hotelPrograms: [],
                flightPrograms: [],
                dbRailPrograms: [],
                externalLogins: [],
                hotelSpecialRate: {},
                tPC: '',
            },
            markFields: false,
            isLoadingBtn: false,
            isRemoveDialogOpen: false,
            itemToRemove: {},
            isSpecialHotelRate: false,
            euRailVendors: [],
        };
    },
    async created() {
        this.setFormData();
        await this.loadData();
    },
    methods: {
        async loadData() {
            this.euRailVendors = await euRailService.getLoyaltyVendors();
            if (!this.carAgencyVendors) {
                await this.$store.dispatch({ type: 'carStore/loadAgencies' });
            }
        },
        async handleSubmit() {
            this.markFields = true;
            if (this.v$.$invalid) {
                return;
            }
            this.isLoadingBtn = true;
            const { carPrograms, railPrograms, hotelPrograms, flightPrograms, externalLogins, hotelSpecialRate, dbRailPrograms, tPC, } = this.formData;
            const frequentProgramsInfo = {
                frequentPrograms: [...carPrograms, ...railPrograms, ...hotelPrograms, ...flightPrograms],
                externalSupplierLogins: externalLogins,
                hotelSpecialRates: hotelSpecialRate,
                dbRailPrograms: dbRailPrograms.map((program) => ({
                    ...program,
                    year: new Date(program.validUntil).getFullYear(),
                    month: new Date(program.validUntil).getMonth() + 1,
                    day: new Date(program.validUntil).getDate(),
                })),
                tPC,
            };
            try {
                await this.$store.dispatch({
                    type: 'userStore/saveFrequentProgramsInfo',
                    frequentProgramsInfo,
                    tripId: this.tripId,
                });
                this.$emit('checkMandateFieldsStatus');
                alertService.success('alert.general.saved');
            }
            catch (err) {
                alertService.error('alert.general.error', err);
            }
            finally {
                this.isLoadingBtn = false;
            }
        },
        addLoyaltyProgram(product) {
            const key = `${product}Programs`;
            this.formData[key].push({
                programVendor: '',
                programNumber: '',
                product,
                corporateUserId: this.corporateUserId,
                tempId: utilService.makeId(),
            });
        },
        addEmptyExternalLoginForm() {
            const externalLogin = {
                carrier: '',
                login: '',
                password: '',
                userId: this.corporateUserId,
                companyId: this.userCompanyId,
                tempId: utilService.makeId(),
            };
            this.formData.externalLogins.push(externalLogin);
        },
        addDbRailProgram() {
            const dbRailProgram = {
                programNumber: '',
                product: 'rail',
                type: '',
                validUntil: null,
                corporateUserId: this.corporateUserId,
                tempId: utilService.makeId(),
            };
            this.formData.dbRailPrograms.push(dbRailProgram);
        },
        openRemoveDialog(itemType, idx, itemId) {
            this.itemToRemove = { itemType, idx, itemId };
            this.isRemoveDialogOpen = true;
        },
        async onRemoveItemConfirm() {
            const { itemType, idx, itemId } = this.itemToRemove;
            // If no id - its an item that didn't saved yet (or external login) - no need for API call
            if (!itemId) {
                if (itemType === 'externalLogin') {
                    this.formData.externalLogins.splice(idx, 1);
                }
                else {
                    this.formData[`${itemType}Programs`].splice(idx, 1);
                }
            }
            else {
                try {
                    if (itemType === 'dbRail') {
                        await this.$store.dispatch({ type: 'userStore/removeProfileDBRailProgram', itemId, tripId: this.tripId });
                        this.formData[`${itemType}Programs`].splice(idx, 1);
                    }
                    else {
                        await this.$store.dispatch({ type: 'userStore/removeProfileFrequentProgram', itemId, tripId: this.tripId });
                    }
                }
                catch (err) {
                    loggerService.error(err);
                }
            }
            this.itemToRemove = null;
            this.isRemoveDialogOpen = false;
        },
        setFormData() {
            const programTypes = [Products.Hotel, Products.Flight, Products.Car, Products.Rail];
            programTypes.forEach((product) => {
                const programs = this.currData.frequentPrograms.filter((program) => program.product === product);
                this.formData[`${product}Programs`] = utilService.deepClone(programs);
            });
            const newDbRailPrograms = this.currData.dBRailPrograms.map((program) => ({
                ...utilService.deepClone(program),
                validUntil: `${program.year}-${program.month}-${program.day}`,
                tempId: utilService.makeId(),
            }));
            this.formData.dbRailPrograms = [...newDbRailPrograms];
            this.formData.externalLogins = [...this.currData.externalSupplierLogins];
            this.formData.hotelSpecialRate = {
                ...this.currData.hotelSpecialRates,
            };
            this.formData.tPC = this.currData.tPC;
            this.isSpecialHotelRate = this.currData.hotelSpecialRates?.specialRates?.length > 0;
        },
    },
    computed: {
        carrierOpts() {
            if (!this.airplaneCarriers) {
                return [];
            }
            return this.airplaneCarriers.map((carrier) => {
                return {
                    value: carrier.code,
                    label: carrier.name,
                };
            });
        },
        euRailVendorOpts() {
            if (!this.euRailVendors) {
                return [];
            }
            return this.euRailVendors.map((vendor) => {
                return {
                    value: vendor.code,
                    label: vendor.name,
                };
            });
        },
        dbRailCardOpts() {
            const bahnCardTypes = Object.values(DbRailCardTypes);
            return bahnCardTypes.map((type) => {
                return {
                    value: type,
                    label: type,
                };
            });
        },
        hotelSpecialRateOpts() {
            const hotelSpecialRatesValues = Object.values(HotelSpecialRates);
            const hotelSpecialRatesKeys = Object.keys(HotelSpecialRates);
            return hotelSpecialRatesValues.map((type, idx) => {
                return {
                    value: hotelSpecialRatesKeys[idx],
                    label: type,
                };
            });
        },
        hotelLoyaltyCodesOpts() {
            return this.hotelLoyaltyCodes?.map((code) => {
                return {
                    value: code.ChainCode,
                    label: code.Name ? `${code.Name} (${code.ChainCode})` : code.ChainCode,
                };
            });
        },
        hotelChainsOpts() {
            return this.hotelChains?.map((code) => {
                return {
                    value: code.code,
                    label: `${code.name} (${code.code})`,
                };
            });
        },
        carAgencyVendorsOpts() {
            return this.$store.getters['carStore/rentalAgencyOptions'];
        },
        userCompanyId() {
            return this.$store.getters['authStore/loggedinUser'].corporationId;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        validationRulesEach() {
            return {
                flightPrograms: {
                    programNumber: {
                        alphaNum,
                    },
                },
                externalLogins: {
                    login: {
                        required: requiredIf(() => {
                            return this.formData.externalLogins.length > 0;
                        }),
                    },
                    password: {
                        required: requiredIf(() => {
                            return this.formData.externalLogins.length > 0;
                        }),
                    },
                },
                hotelPrograms: {
                    programNumber: {
                        alphaNum,
                    },
                },
                carPrograms: {
                    programNumber: {
                        alphaNum,
                    },
                },
                railPrograms: {
                    programNumber: {
                        alphaNum,
                    },
                },
                dbRailPrograms: {
                    programNumber: {
                        alphaNum,
                    },
                },
                formData: {
                    tPC: {
                        alphaNum,
                    },
                },
            };
        },
    },
    validations() {
        return this.validationRulesEach;
    },
    watch: {
        currData() {
            this.setFormData();
        },
    },
});
