import { defineComponent } from 'vue';
import { CreditCardsCodes, CreditCardsTypes } from '@/types/consts';
import { required, alphaNum, requiredIf, numeric } from '@vuelidate/validators';
import { utilService } from '@/services/util.service';
import { paymentService } from '@/services/payment.service';
import { alertService } from '@/services/alert.service';
import { useVuelidate } from '@vuelidate/core';
import appSelect from '@/components/app-select.vue';
import appDatepicker from '@/components/form/app-datepicker.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
export default defineComponent({
    name: 'ProfileFormOfPayment',
    components: {
        appSelect,
        appDatepicker,
        formValidationMsg,
    },
    props: {
        currData: {
            type: Array,
        },
        nationalities: {
            type: Array,
        },
        corporateUserId: {
            type: Number,
        },
        tripId: {
            type: Number,
            default: 0,
        },
        mandateFieldOptions: {
            type: Array,
            default: () => [],
        },
        mandateFields: {
            type: Array,
            default: () => [],
        },
        disableEdit: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            formData: {
                creditCard: {
                    name: '',
                    type: {
                        code: '',
                        name: '',
                    },
                    displayNumber: '',
                    expirationMonth: '',
                    expirationYear: '',
                    cardHolderName: '',
                    cardOwner: '',
                    isDefaultFOP: false,
                    isDefault: false,
                    billingAddress: {
                        address: '',
                        city: '',
                        country: '',
                        state: '',
                        zipCode: '',
                    },
                },
                airPlus: {
                    airPlusNumber: '',
                    airPlusNumberEncrypted: '',
                },
                azureToken: {
                    creditCardNumber: '',
                },
            },
            isCardModalOpen: false,
            formsOfPayment: [],
            markFields: false,
            isLoadingBtn: false,
            isRemoveDialogOpen: false,
            itemIdToRemove: null,
            isAddCardFormVisible: false,
            creditCardTypes: [],
        };
    },
    async created() {
        this.formsOfPayment = [...utilService.deepClone(this.currData)];
        // If form is mandatory (by administration) open the form in the creation
        if (!this.formsOfPayment.length && this.isMandateForm) {
            this.isAddCardFormVisible = true;
            this.markFields = true;
        }
        this.creditCardTypes = await paymentService.getAllCreditCardTypes();
    },
    methods: {
        async handleSubmit() {
            this.markFields = true;
            if (this.v$.$invalid) {
                return;
            }
            this.isLoadingBtn = true;
            const profileFormOfPayment = {
                ...this.formData,
                creditCard: {
                    ...this.formData.creditCard,
                    expirationDate: new Date(this.formData.creditCard.expirationYear, +this.formData.creditCard.expirationMonth, 1).toISOString(), // create BE format required
                },
                corporateUserId: this.corporateUserId,
                corporationId: this.userCompanyId,
            };
            try {
                await this.$store.dispatch({
                    type: 'userStore/addProfileFormOfPayment',
                    profileFormOfPayment,
                    tripId: this.tripId,
                });
                this.$emit('checkMandateFieldsStatus');
                alertService.success('alert.general.saved');
                this.isAddCardFormVisible = false;
            }
            catch (err) {
                alertService.error('alert.general.error', err);
            }
            finally {
                await this.$store.dispatch('userStore/loadUserProfile', { userId: this.corporateUserId, tripId: this.tripId });
                this.isLoadingBtn = false;
            }
        },
        async setAsDefaultCard(cardId) {
            const defaultCardFop = utilService.deepClone(this.formsOfPayment.find((fop) => fop.creditCard.id === cardId));
            defaultCardFop.creditCard.isDefault = true;
            try {
                await this.$store.dispatch({
                    type: 'userStore/updateProfileFormOfPayment',
                    defaultCardFop,
                    tripId: this.tripId,
                });
                alertService.success('alert.general.saved');
                await this.$store.dispatch('userStore/loadUserProfile', { userId: this.corporateUserId, tripId: this.tripId });
                //reload data  to prevent concurrency
                this.formsOfPayment = [...utilService.deepClone(this.currData)];
            }
            catch (err) {
                alertService.error('alert.general.error', err);
            }
        },
        getLast4CardDigits(cardNum) {
            return cardNum.slice(-4);
        },
        removePassportForm(idx) {
            this.formData.passports.splice(idx, 1);
        },
        openRemoveDialog(id) {
            this.itemIdToRemove = id;
            this.isRemoveDialogOpen = true;
        },
        async onRemoveItemConfirm() {
            try {
                await this.$store.dispatch({
                    type: 'userStore/removeProfileFormOfPayment',
                    itemId: this.itemIdToRemove,
                    tripId: this.tripId,
                });
                this.formsOfPayment = this.formsOfPayment.filter((form) => form.creditCard.id !== this.itemIdToRemove);
            }
            catch (err) {
                alertService.error('alert.general.error', err);
            }
            this.isRemoveDialogOpen = false;
        },
        handleAzureTokenizeResponse(azureTokenRes) {
            this.isCardModalOpen = false;
            this.formData.creditCard.displayNumber = azureTokenRes.token;
            this.extractCreditCardCompany();
        },
        openCardModal() {
            if (!this.formData.creditCard.type.name) {
                return;
            }
            this.isCardModalOpen = true;
        },
        closeCardModal() {
            this.isCardModalOpen = false;
            this.formData.airPlus.airPlusNumber = '';
            this.formData.azureToken.creditCardNumber = '';
        },
        async tokenizeWithAzure() {
            const res = await paymentService.tokenizeWithAzure(this.formData.azureToken.creditCardNumber);
            this.handleAzureTokenizeResponse(res);
        },
        async insertAirPlusNumber() {
            if (this.v$.formData.airPlus.$invalid) {
                return;
            }
            const guidId = utilService.generateGuidId();
            const encNumber = (await paymentService.encryptAirPlusNumber(guidId, this.formData.airPlus.airPlusNumber)) || null;
            if (encNumber) {
                this.formData.airPlus.airPlusNumberEncrypted = encNumber;
                this.formData.creditCard.displayNumber = encNumber;
                this.isCardModalOpen = false;
            }
        },
        extractCreditCardCompany() {
            this.formData.creditCard.type.code = this.formData.creditCard.type.name;
            this.formData.creditCard.type.name = CreditCardsTypes[this.formData.creditCard.type.name];
        },
    },
    computed: {
        isTokenizeWithAzure() {
            return (this.formData.creditCard.type.name !== CreditCardsCodes[CreditCardsCodes.TP] &&
                this.formData.creditCard.type.name !== CreditCardsCodes[CreditCardsCodes.EH]);
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        expirationMonthsOpt() {
            const monthNums = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
            return monthNums.map((month) => ({ label: month, value: month }));
        },
        expirationYearsOpts() {
            // generates the expiration years: from current year to +6 years (max years)
            const relevantExpYears = [];
            const currentYear = new Date().getFullYear();
            const numYears = 10;
            for (let i = 0; i < numYears; i++) {
                relevantExpYears.push((currentYear + i).toString());
            }
            return relevantExpYears.map((year) => ({ label: year, value: year }));
        },
        nationalityTypeOpts() {
            if (!this.nationalities) {
                return [];
            }
            return this.nationalities.map((n) => {
                return {
                    value: n.code,
                    label: `${n.name} (${n.code})`,
                };
            });
        },
        creditCardTypesOpts() {
            return this.creditCardTypes.map((type) => {
                return {
                    value: type.code,
                    label: type.name,
                };
            });
        },
        userCompanyId() {
            return this.$store.getters['authStore/loggedinUser'].corporationId;
        },
        isMandateForm() {
            return this.mandateFields.includes('Form Of Payment Details');
        },
    },
    validations() {
        return {
            formData: {
                creditCard: {
                    name: {
                        required,
                        isAlphaAndSpace: (value) => /^[a-zA-Z\s]*$/.test(value),
                    },
                    type: {
                        name: {
                            required,
                        },
                    },
                    displayNumber: {
                        required,
                    },
                    cardHolderName: {
                        isAlphaAndSpace: (value) => /^[a-zA-Z\s]*$/.test(value),
                    },
                    expirationMonth: {
                        required,
                    },
                    expirationYear: {
                        required,
                    },
                    billingAddress: {
                        address: {
                            required,
                        },
                        city: {
                            required,
                            isAlphaAndSpace: (value) => /^[a-zA-Z\s]*$/.test(value),
                        },
                        country: {
                            required,
                        },
                        zipCode: {
                            required,
                            alphaNum,
                        },
                    },
                },
                airPlus: {
                    airPlusNumber: {
                        numeric,
                        required: requiredIf(() => {
                            return !this.isTokenizeWithAzure && this.isCardModalOpen;
                        }),
                    },
                },
                azureToken: {
                    creditCardNumber: {
                        numeric,
                        required: requiredIf(() => {
                            return this.isTokenizeWithAzure && this.isCardModalOpen;
                        }),
                    },
                },
            },
        };
    },
    watch: {
        currData: {
            deep: true,
            handler(newData) {
                this.formsOfPayment = [...utilService.deepClone(newData)];
            },
        },
    },
});
