import { eventBus } from '@/services/event-bus.service';
import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
import { ProductStatusName, Products, QuoteStatus } from '@/types/consts';
import tripEditTraveler from '@/components/trip/trip-edit-traveler/trip-edit-traveler.vue';
import { SuppliersId } from '@/modules/products/flight/models/consts';
export default defineComponent({
    components: { tripEditTraveler },
    name: 'extrasLayout',
    data() {
        return {
            isOpen: false,
            isCheckoutActivated: false,
            isDialogOpen: false,
            isDialogGuest: false,
            traveler: null,
            travelerIdx: 0,
        };
    },
    props: {
        quote: {
            type: Object,
            required: true,
        },
        isValid: {
            type: Boolean,
            required: true,
        },
        isShowSelectedValue: {
            type: Boolean,
            default: false,
        },
        hasValue: {
            type: Boolean,
            required: true,
        },
        mustHavePassengers: {
            type: Boolean,
            default: false,
        },
        showAccordion: {
            type: Boolean,
            default: true,
        },
        isWaitingApproval: {
            type: Boolean,
            default: false,
        },
        allowEdit: {
            type: Boolean,
            default: false,
        },
        isWarning: {
            type: Boolean,
            default: false,
        },
        isViewMode: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        eventBus.$on('checkoutActivated', this.checkoutActivated);
    },
    methods: {
        close() {
            this.isOpen = false;
            this.$emit('isOpenChanged', false);
        },
        open() {
            if (this.showAccordion) {
                this.isOpen = true;
            }
            this.$emit('isOpenChanged', true);
        },
        openEditTraveler() {
            this.traveler = this.getEmptyTraveler();
            this.isDialogOpen = true;
        },
        closeEditTraveler() {
            this.isDialogOpen = false;
        },
        switchDialogToGuestMode(isOpen) {
            this.isDialogGuest = isOpen;
        },
        getEmptyTraveler() {
            return {
                isEmpty: true,
                isPrimary: false,
                tempId: utilService.makeId(),
            };
        },
        checkoutActivated() {
            this.isCheckoutActivated = true;
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        passengers() {
            return this.trip.passengers;
        },
        isReadOnlyState() {
            return (!this.allowEdit && //No ReadOnly if allowEdit is true
                this.quote.product !== Products.Manual && //No ReadOnly if manual
                !(this.quote.sourceSystem === SuppliersId.LUFTHANSA) && //No ReadOnly for some source-systems
                !(
                //No ReadOnly in some product Status
                (this.quote.productStatusName === ProductStatusName.PendingBooking ||
                    this.quote.productStatusName === ProductStatusName.OnHold ||
                    this.quote.productStatusName === ProductStatusName.Finalizing ||
                    this.quote.status === QuoteStatus.PendingReissue))
                &&
                    !((((this.quote.product === Products.Car || this.quote.product === Products.Hotel) && (this.quote.sourceSystem === SuppliersId[SuppliersId.SABRE] || this.quote.sourceSystem === SuppliersId[SuppliersId.AMADEUS])))));
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        hasTravelers() {
            return this.passengers.length;
        },
        isShowInvalid() {
            return !this.isValid;
        },
        isShowRedBorder() {
            return this.isShowInvalid && this.isCheckoutActivated;
        },
        isShowAddTraveler() {
            return this.mustHavePassengers && !this.hasTravelers;
        },
        dialogTitle() {
            if (this.isDialogGuest) {
                return `${this.$t('trip.addGuestForm.addGuestToTrip')}`;
            }
            return `${this.$t('trip.editTraveller')}`;
        },
        isPending() {
            return this.quote?.productStatusName === ProductStatusName.PendingBooking;
        },
    },
    unmounted() {
        eventBus.$off('checkoutActivated', this.checkoutActivated);
    },
    watch: {
        passengers(newVal, oldVal) {
            //in any change of passengers we should to close the extra (if it's open) to 
            //reload the extras data again (can be depend on passengers)
            this.isOpen = false;
            this.$emit('reloadData');
        },
    },
});
