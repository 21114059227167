import { defineComponent } from 'vue';
import { flightService } from '@/modules/products/flight/services/flight.service';
import productDetailsSkeleton from '@/components/skeletons/product-details-skeleton.vue';
import { loggerService } from '@/services/logger.service';
export default defineComponent({
    name: 'FareRules',
    components: { productDetailsSkeleton },
    props: {
        packageKey: {
            type: String,
            default: '',
        },
        segmentToShowIndex: {
            type: Number,
            default: null,
        },
        quoteId: {
            type: Number,
            default: null,
        },
        resultsIndex: {
            type: Number,
            default: 0,
        },
        searchCacheKey: {
            type: String,
            default: null,
        },
        isUnusedTicket: {
            type: Boolean,
            default: false,
        },
        unusedTicket: {
            default: null,
        },
    },
    data() {
        return {
            currentTabIndex: '0',
            isLoading: false,
            fareRules: {},
            selectedFareRulesSection: '',
        };
    },
    async created() {
        await this.loadFareRules();
        if (this.segmentToShowIndex !== null) {
            this.currentTabIndex = this.segmentToShowIndex.toString();
        }
    },
    methods: {
        async loadFareRules() {
            this.isLoading = true;
            try {
                if (this.isUnusedTicket && this.unusedTicket !== null) {
                    // Fare Rules load from unused ticket
                    const fareRulesRes = await flightService.searchFareRulesFromUnusedTicket(this.unusedTicket);
                    this.fareRules = flightService.formatFlightFareRules(fareRulesRes);
                    this.isLoading = false;
                }
                else if (!this.quoteId) {
                    // Fare Rules load from flight-results, of from check price result from cart
                    const searchData = {
                        guid: this.searchCacheKey ? this.searchCacheKey : this.cacheKey,
                        packageKey: this.packageKey,
                        tripId: this.trip.id,
                    };
                    const fareRulesRes = await flightService.searchFareRules(searchData);
                    this.fareRules = flightService.formatFlightFareRules(fareRulesRes);
                    this.isLoading = false;
                }
                else {
                    // Fare Rules load from Cart
                    const fareRulesRes = await flightService.searchFareRulesFromCart(this.quoteId);
                    this.fareRules = flightService.formatFlightFareRules(fareRulesRes);
                    this.isLoading = false;
                }
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        hideiOSKeyboard() {
            //this is a patch to remove the keyboard on mobile devices
            const element = this.$refs.fareRulesSelect.$el.getElementsByTagName('input');
            if (element) {
                element[0].blur(); //blur the first one
            }
        },
        jumpToSection() {
            const ref = this.$refs[this.selectedFareRulesSection][0];
            if (ref) {
                this.$nextTick(() => {
                    ref.scrollIntoView({ behavior: 'smooth' });
                });
            }
        },
        removeHtmlTags(str) {
            return str.replace(/<[^>]*>/g, '');
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        cacheKey() {
            return this.$store.getters['flightStore/cacheKey'](this.resultsIndex);
        },
        fareRulesSectionOptions() {
            const options = this.fareRules.fareBasisRuleLines[this.currentTabIndex]?.ruleLines?.reduce((acc, ruleLine) => {
                if (ruleLine.title) {
                    acc.push(ruleLine.title);
                }
                return acc;
            }, []);
            return options;
        },
    },
});
