import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
import flightmixins from '@/modules/products/flight/mixins/flight.mixins';
import appTooltipOfficeHoursRestricted from '@/components/app-tooltip-office-hours-restricted/app-tooltip-office-hours-restricted.vue';
import isPast from 'date-fns/isPast';
import isBefore from 'date-fns/isBefore';
import appOopBadge from '@/components/app-oop-badge.vue';
export default defineComponent({
    name: 'legPricingOption',
    mixins: [flightmixins],
    components: { appTooltipOfficeHoursRestricted, appOopBadge },
    props: {
        pricingOptionsToShow: {
            type: Array,
            default: () => []
        },
        option: {
            type: Object,
            default: null,
        },
        selectedFlightResult: {
            type: Object,
            default: null,
        },
        currentTabIndex: {
            type: String,
            default: '0',
        },
        selectedCabinClass: {
            type: String,
            default: '',
        },
        tripTypeName: {
            type: String,
            default: '',
        },
        tabsLength: {
            type: Number,
            default: 1,
        },
        optionIndex: {
            type: Number,
            default: 0,
        },
        previousOptionPrice: {
            type: Number,
            default: 0,
        },
        previousOptionOrigPrice: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        getFilteredOptionsByPackageKey(option) {
            return this.filteredOptions.filter((op) => op.packageKey === option.packageKey);
        },
        getDateObject(dateUTC) {
            return utilService.convertUnformattedUTCToLocalDate(dateUTC);
        },
    },
    computed: {
        canShareQuoteCombinedWithAddToCart() {
            const user = this.$store.getters['authStore/loggedinUser'];
            return user.permissions.includes('ShareQuoteCombinedWithAddToCart');
        },
        isAddToCardDisabled() {
            return ((this.getFilteredOptionsByPackageKey(this.option)[0]?.isRestricTravelPolicyViolation ||
                this.isExpired ||
                this.option.isHideBrandRule ||
                this.isExpiredOfficeHours) &&
                (!this.isShareMode || (this.isShareMode && this.canShareQuoteCombinedWithAddToCart)));
        },
        isExpired() {
            if (!this.option) {
                return false;
            }
            // Parse the UTS date and time string to a Date object in UTC timezone
            const dateTime = this.getDateObject(this.option.lastTktDate);
            // Check if the parsed date and time is in the past
            return isPast(dateTime); // Returns true if the date and time is in the past, false otherwise
        },
        isExpiredOfficeHours() {
            if (!this.option) {
                return false;
            }
            // Parse the UTS date and time string to a Date object in UTC timezone
            const dateTime = this.getDateObject(this.option.nextOfficeHoursExpiration);
            // Check if the parsed date and time is in the past
            return isPast(dateTime); // Returns true if the date and time is in the past, false otherwise
        },
        isOfficeHoursExpiration() {
            return !isBefore(new Date(this.option.lastTktDate), new Date(this.option.nextOfficeHoursExpiration));
        },
        currentPricingOptions() {
            return this.pricingOptionsToShow[this.currentTabIndex];
        },
        filteredOptions() {
            return this.pricingOptionsToShow[this.currentTabIndex].filteredOptions;
        },
        agreementName() {
            return this.getAgreementName(this.option.agreementType);
        },
        agreementIcon() {
            return this.option.agreementIcon;
        },
        isShareMode() {
            return this.$store.getters['tripStore/isShareMode'];
        },
        btnTxt() {
            if (!this.option.isSelected) {
                return this.isShareMode ? this.$t('common.selectToShare') : this.$t('common.select');
            }
            else {
                return this.$t('common.unselect');
            }
        },
        hasGreenBrand() {
            return this.option.isGreenBrand;
        },
        searchOptions() {
            return this.$store.getters['flightStore/searchOptions'];
        },
        numberOFTravelers() {
            return this.searchOptions.numberOFTravelers;
        },
        oopViolations() {
            return this.option?.travelPolicyViolations?.map((violation) => {
                return violation.travelPolicyViolationExplanation ? violation.travelPolicyViolationExplanation : violation;
            });
        },
    },
});
