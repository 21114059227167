import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
import { SortByTypes } from '@/modules/products/flight/models/consts';
import { flightService } from '@/modules/products/flight/services/flight.service';
import flightComparison from '@/modules/products/flight/components/flight-comparison/flight-comparison.vue';
import * as flightConsts from '@/modules/products/flight/models/consts';
export default defineComponent({
    name: 'FlightListSort',
    components: {
        flightComparison,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        flightResults: {
            type: Array,
            default() {
                return [];
            },
        },
        isForMobileFilter: {
            type: Boolean,
            default: false,
        },
        resultsIndex: {
            type: Number,
            default: 0,
        },
        collapseVisibleStatus: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            extendedSortByOptions: [],
            filterBy: {},
            collapseVisibleIds: [], //array that holds the ids of the collapsed elements
        };
    },
    created() {
        this.extendedSortByOptions = flightService.getSortOptions(this.isMobile);
        this.filterBy = utilService.deepClone(this.flightFilter);
        this.collapseVisibleIds = this.collapseVisibleStatus ? ['ticket-collapse'] : [];
    },
    methods: {
        filter(val) {
            // To prevent filter when user press the sort dropdown without choosing value. comes as '' from computed "otherSortLabel" if no value.
            if (val === '') {
                return;
            }
            //get current filterBy from store:
            const updatedFilterBy = utilService.deepClone(this.flightFilter);
            //update cmp-specific fields:
            updatedFilterBy.inPolicy = this.filterBy.inPolicy;
            updatedFilterBy.sortBy = this.filterBy.sortBy;
            updatedFilterBy.hasUnusedTickets = this.filterBy.hasUnusedTickets;
            this.filterBy = updatedFilterBy;
            this.setFilterFlights(updatedFilterBy);
        },
        async setFilterFlights(filterBy) {
            await this.$store.dispatch({ type: 'flightStore/filterFlights', filterBy, resultsIndex: this.resultsIndex });
        },
        sortBy(type) {
            this.filterBy.sortBy = type;
            this.filter(type);
        },
        getFormattedTime(totalMinutes) {
            return utilService.formattedTime(totalMinutes);
        },
        getCurrencySymbol(currencyCode) {
            return utilService.getCurrencyCode(currencyCode);
        },
        isBestSort(text) {
            return text && text.toLowerCase() === SortByTypes.Best.toLowerCase();
        },
        setCollapse() {
            this.$emit('setCollapseVisibleStatus');
            if (!this.collapseVisibleStatus) {
                this.collapseVisibleIds = ['ticket-collapse'];
                return;
            }
        },
    },
    computed: {
        showComparisonTable() {
            // dont show comparison table if by schedule
            if (this.searchOptions?.searchFor === flightConsts.FlightSearchTypes.bySchedule) {
                return false;
            }
            return !!this.flightsRes?.[this.resultsIndex]?.comparisonTable;
        },
        searchOptions() {
            return this.$store.getters['flightStore/searchOptions'];
        },
        hasOutOfPolicy() {
            const flightResults = this.flightsRes[this.resultsIndex]?.results;
            return flightResults?.some((h) => h.travelPolicyViolations.length);
        },
        flightFilter() {
            return this.$store.getters['flightStore/flightFilter'];
        },
        flightsRes() {
            return this.$store.getters['flightStore/flightsRes'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        canSendPriceOffer() {
            return this.$store.getters['tripStore/canSendPriceOffer'];
        },
        otherSortLabel() {
            return this.filterBy.sortBy === SortByTypes.Best ||
                this.filterBy.sortBy === SortByTypes.Cheapest ||
                this.filterBy.sortBy === SortByTypes.Quickest
                ? ''
                : this.filterBy.sortBy;
        },
        currentSortType() {
            const sortByTypesMap = {
                [SortByTypes.PriceDescending]: this.$t('filter.priceHighToLow'),
                [SortByTypes.Stops]: this.$t('filter.stops'),
                [SortByTypes.LowestCarbon]: this.$t('filter.lowestCarbon'),
                [SortByTypes.DurationDescending]: this.$t('filter.durationDescending'),
                [SortByTypes.DepartureAscending]: this.$t('filter.departureAscending'),
                [SortByTypes.DepartureDescending]: this.$t('filter.departureDescending'),
                [SortByTypes.ArrivalAscending]: this.$t('filter.arrivalAscending'),
                [SortByTypes.ArrivalDescending]: this.$t('filter.arrivalDescending'),
            };
            return sortByTypesMap[this.filterBy.sortBy] ? sortByTypesMap[this.filterBy.sortBy] : '';
        },
    },
    watch: {
        flightFilter: {
            deep: true,
            handler(newVal) {
                this.filterBy = utilService.deepClone(newVal);
            },
        },
    },
});
