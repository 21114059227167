import { defineComponent } from 'vue';
export default defineComponent({
    name: 'EuRailOptions',
    props: {
        euRailResults: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            lowestPrice: 0,
            lowestPriceCurrency: ''
        };
    },
    created() {
        this.setLowestPriceAndCurrency();
    },
    methods: {
        setLowestPriceAndCurrency() {
            this.lowestPrice = this.euRailResults[0].railSegments[0].displayTotalPrice;
            this.lowestPriceCurrency = this.euRailResults[0].railSegments[0].displayCurrency;
            this.euRailResults.forEach(euRailResult => {
                euRailResult.railSegments.forEach(railSegment => {
                    if (railSegment.displayTotalPrice < this.lowestPrice) {
                        this.lowestPrice = railSegment.displayTotalPrice;
                        this.lowestPriceCurrency = railSegment.displayCurrency;
                    }
                });
            });
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        }
    }
});
