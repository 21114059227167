export const inputDefaultValue = {
    // when the element is inserted in the DOM
    mounted: function (el, binding) {
        let elInput = el;
        // check if the element is an input - if not - catch the input thats inside of it
        if (el.nodeName !== 'INPUT') {
            elInput = el.querySelector('input');
        }
        elInput.addEventListener('input', (ev) => {
            // if the input is empty - change its value to required value and emit an input event to update
            if (!elInput.value) {
                elInput.value = binding.value;
                elInput.dispatchEvent(new Event('input', { bubbles: true }));
            }
        });
    },
};
