<template>
  <el-skeleton class="filters-skeleton" animated>
    <template #template>
      <el-skeleton-item v-if="!hideImg" variant="image" class="filter-skeleton-map-img" />
      <el-skeleton-item variant="h1" class="img-skeleton-h1 w-100" />
      <div v-for="(numOfFilter, idx) in numOfFilters" :key="idx">
        <div class="filter-skeleton-filters">
          <hr />
          <el-skeleton-item variant="h1" class="skeleton-h1" />
          <div class="flex space-between">
            <el-skeleton-item variant="h1" style="width: 46%" />
            <el-skeleton-item variant="h1" style="width: 14%" />
          </div>
          <div class="flex space-between">
            <el-skeleton-item variant="h1" style="width: 64%" />
            <el-skeleton-item variant="h1" style="width: 14%" />
          </div>
          <div class="flex space-between">
            <el-skeleton-item variant="h1" style="width: 21%" />
            <el-skeleton-item variant="h1" style="width: 14%" />
          </div>
        </div>
      </div>
    </template>
  </el-skeleton>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FilterSkeleton',
  props: {
    numOfFilters: {
      type: Number,
      default: 3,
    },
    hideImg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
});
</script>
<style lang="scss" scoped>
@import '@/assets/style/abstracts';
.filters-skeleton {
  height: 75vh;
  margin-bottom: rem(100px);
  .filter-skeleton-map-img {
    height: rem(112px);
    margin-bottom: 2rem;
    &.el-skeleton__item.el-skeleton__image {
      border-radius: rem(4px);
    }
  }
  .img-skeleton-h1,
  .skeleton-h1 {
    height: rem(40px);
  }

  .skeleton-h1 {
    width: 67%;
  }

  .filter-skeleton-filters {
    & > * {
      margin-bottom: 1.5rem;
    }
    hr {
      margin: 1.5rem 0;
    }
  }
}
</style>
