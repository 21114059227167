import { defineComponent } from 'vue';
import { loggerService } from '@/services/logger.service';
import { utilService } from '@/services/util.service';
import * as hotelsConsts from '@/modules/products/hotels/models/consts';
import { paymentService } from '@/services/payment.service';
export default defineComponent({
    name: 'Fusion3DS',
    props: {
        quotes: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            cQuotes: [],
            cardAuthentication: {},
        };
    },
    async created() {
        loggerService.debug('Fusion 3DS created');
        this.cQuotes = utilService.deepClone(this.quotes);
        window.addEventListener('message', this.getTokenResponse);
    },
    methods: {
        async getTokenResponse(ev) {
            // got a response from fusion...
            loggerService.debug('Fusion 3DS token, resp setting auth for');
            loggerService.debug(ev);
            if (ev && ev.data && !ev.data.event) {
                loggerService.debug('Fusion 3DS token, setting auth for :', this.cQuotes[0].quoteId, 'iFrame URL is:', this.iframeUrl);
                if (this.setAuthorization(this.cQuotes[0].quoteId)) {
                    this.cQuotes.shift();
                    if (!this.cQuotes || !this.cQuotes.length) {
                        loggerService.debug('Fusion 3DS completed');
                        this.$emit('fusion3dsCompleted');
                    }
                }
            }
        },
        setAuthorization(quoteId) {
            const cardAuth = {};
            cardAuth.QuoteId = quoteId;
            cardAuth.ResetIsCardAuthentication = false;
            cardAuth.ResetErrorMessage = true;
            cardAuth.SourceSystem = hotelsConsts.SuppliersId.FUSION;
            cardAuth.AuthorizationParameters = null;
            loggerService.debug('Fusion 3DS cardAuthentication for :', quoteId);
            loggerService.debug('no await here, could be? :');
            paymentService.cardAuthentication(cardAuth);
            return true;
        },
        openNewTab() {
            this.cQuotes.shift();
            window.open(this.iframeUrl, '_blank');
        },
    },
    computed: {
        nextQuote() {
            const nextQuote = [];
            nextQuote.push(this.cQuotes[0]);
            return nextQuote;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        iframeUrl() {
            const url = this.cQuotes[0]?.authenticationLink;
            if (!url) {
                return '#';
            }
            return url;
        },
        currentStep() {
            return this.quotes.length - this.cQuotes.length;
        },
    },
    unmounted() {
        window.removeEventListener('message', this.getTokenResponse);
    },
});
