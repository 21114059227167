import { defineComponent } from 'vue';
import { format } from 'date-fns';
import { eventBus } from '@/services/event-bus.service';
import { airportMarkingsService } from '@/modules/products/flight/services/airport-marking.service';
import { TripType } from '@/modules/products/flight/models/consts';
import flightmixins from '@/modules/products/flight/mixins/flight.mixins';
import ticketLayout from '@/components/ticket-layout.vue';
import flightPreviewSegment from '@/modules/products/flight/components/flight-preview/flight-preview-segment/flight-preview-segment.vue';
import flightExtraInfo from '@/modules/products/flight/components/flight-preview/flight-extra-info/flight-extra-info.vue';
import greenFare from '@/modules/products/flight/components/green-fare/green-fare.vue';
import co2EmissionsLabel from '@/modules/products/flight/components/co2-emissions-label/co2-emissions-label.vue';
import co2EmissionsSmallLabel from '@/modules/products/flight/components/co2-emissions-small-label/co2-emissions-small-label.vue';
import ecoLabel from '@/modules/utils/eco-label/eco-label.vue';
import NewSeatLabel from '@/modules/products/flight/components/new-seat-label/new-seat-label.vue';
export default defineComponent({
    name: 'FlightPreview',
    components: {
        ticketLayout,
        flightPreviewSegment,
        flightExtraInfo,
        ecoLabel,
        greenFare,
        co2EmissionsLabel,
        co2EmissionsSmallLabel,
        NewSeatLabel
    },
    mixins: [flightmixins],
    props: {
        selectedSegments: {
            type: Array,
            default: () => [],
        },
        flightResult: {
            type: Object,
            default: null,
        },
        isDetails: {
            type: Boolean,
            default: false,
        },
        isPricingOptions: {
            type: Boolean,
            default: false,
        },
        isQuoteChange: {
            type: Boolean,
            default: false,
        },
        isCart: {
            type: Boolean,
            default: false,
        },
        segmentToShowIndex: {
            type: Number,
            default: null,
        },
        isHoldBtnLoading: {
            type: Boolean,
            default: false,
        },
        isQuoteValid: {
            type: Boolean,
            default: false,
        },
        isDetailsShown: {
            type: Boolean,
            default: false,
        },
        isAgentQuote: {
            type: Boolean,
            default: false,
        },
        resultsIndex: {
            type: Number,
            default: 0,
        },
        isFareRulesShown: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isCartBtnLoading: false,
            isShareBtnLoading: false,
            isMarkSelectedFlight: false,
        };
    },
    created() {
        eventBus.$on('resetMarkSelectedFlight', this.resetMarkSelectedFlight);
        eventBus.$on('cancelCartBtnLoading', this.cancelCartBtnLoading);
        eventBus.$on('handleAddToShare', this.handleAddToShare);
    },
    methods: {
        addToCart() {
            this.isCartBtnLoading = true;
            this.$emit('addToCart', { selectedFlightResult: this.flightResult });
        },
        cancelCartBtnLoading() {
            this.isCartBtnLoading = false;
        },
        selectFlightBySchedule() {
            this.isCartBtnLoading = true;
            // this event will take care of disabling the loading
            this.$emit('selectFlightBySchedule');
        },
        formattedDateStr(dateStr) {
            const date = dateStr.split('T')[0];
            const dateReformat = date.replaceAll('-', '/');
            const dateFormat = 'EEE, dd MMM';
            const formattedDate = format(new Date(dateReformat), dateFormat);
            return formattedDate;
        },
        handleFlightSelect(ev) {
            if (ev.drawerType === 'pricing') {
                this.isMarkSelectedFlight = true;
            }
            this.$emit('flightSelected', ev);
        },
        checkIfIsAllFlightContainsNewSeat() {
            let ret = false;
            if (this.flightResult.segments) {
                if (this.flightResult.segments[0].isAllSegmentsContainsNewSeatFare) {
                    ret = true;
                }
            }
            return ret;
        },
        checkIfIsAnyFlightContainsNewSeat() {
            let isAllFlightContainsNewSeat = this.checkIfIsAllFlightContainsNewSeat();
            if (!isAllFlightContainsNewSeat && this.flightResult.segments) {
                if (!this.flightResult.segments[0].IsAllSegmentsContainsNewSeatFare) {
                    this.flightResult.segments.forEach((segment) => {
                        segment.flights.forEach((flt) => {
                            if (!isAllFlightContainsNewSeat) {
                                isAllFlightContainsNewSeat = flt.isNewSeatFare;
                            }
                        });
                    });
                }
            }
            return isAllFlightContainsNewSeat;
        },
        async handleAddToShare(skipCapacityViolation = false) {
            if (this.canShareQuoteCombinedWithAddToCart) {
                // Check if flight has capacity Restriction
                if (!skipCapacityViolation) {
                    this.isShareBtnLoading = true;
                    const violationData = await this.$store.dispatch({
                        type: 'flightStore/handleFlightTravelPolicy',
                        flightResult: this.flightResult,
                        searchCacheKey: this.$store.getters['flightStore/cacheKey'](this.resultsIndex),
                        resultsIndex: this.resultsIndex,
                    });
                    this.isShareBtnLoading = false;
                    if (violationData) {
                        eventBus.$emit('openCapacityDialogForShare', this.flightResult, violationData.violations, this.addToShare);
                        return;
                    }
                    else {
                        this.addToShare();
                        return;
                    }
                }
                else {
                    this.addToShare();
                    return;
                }
            }
            else {
                this.addToShare();
                return;
            }
        },
        addToShare(flightResult) {
            if (flightResult) {
                const product = {
                    cacheKey: flightResult.packageKey,
                    flight: flightResult,
                    resultsIndex: this.resultsIndex,
                };
                this.$store.commit({ type: 'tripStore/addToShare', product });
            }
            else {
                const product = {
                    cacheKey: this.flightResult.packageKey,
                    flight: this.flightResult,
                    resultsIndex: this.resultsIndex,
                };
                this.$store.commit({ type: 'tripStore/addToShare', product });
            }
        },
        removeFromShare() {
            this.$store.commit({ type: 'tripStore/removeFromShare', product: this.flightResult });
        },
        handleClick() {
            if (this.isMobile && !this.isDetails && !this.isPricingOptions) {
                this.$emit('flightSelected', { flightResult: this.flightResult, drawerType: 'details' });
            }
        },
        resetMarkSelectedFlight() {
            this.isMarkSelectedFlight = false;
        },
        openBrandedFares(brandName = '', segmentIndex) {
            const opts = {
                brandName,
                brandId: brandName,
                segmentIndex: this.flightResult.isOneWayPackage ? segmentIndex : null,
                packageKey: this.flightResult.packageKey,
            };
            this.$emit('openBrandedFares', opts);
        },
    },
    computed: {
        canShareQuoteCombinedWithAddToCart() {
            const user = this.$store.getters['authStore/loggedinUser'];
            return user.permissions.includes('ShareQuoteCombinedWithAddToCart');
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isLowCost() {
            return this.flightResult.isLowCost;
        },
        showSeperateTickets() {
            return this.flightResult.isOneWayPackage && !this.isPricingOptions;
        },
        agreementName() {
            return this.getAgreementName(this.flightResult.agreementType);
        },
        agreementIcon() {
            return this.flightResult.agreementIcon;
        },
        agreementContent() {
            let content = '';
            if (this.flightResult.isMarine) {
                content = this.$t('flight.marineFare');
            }
            else if (this.flightResult?.agreementToolTip && this.flightResult?.agreementToolTip !== '') {
                content = this.flightResult?.agreementToolTip;
            }
            else if (this.flightResult?.agreementCode && this.flightResult?.agreementCode !== '') {
                content = this.flightResult?.agreementCode;
            }
            return content;
        },
        hasOopSlot() {
            return !!this.$slots['oop-justification'];
        },
        hasExtraInfoSlot() {
            return !!this.$slots['ticket-extra-info'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isShareMode() {
            return this.$store.getters['tripStore/isShareMode'];
        },
        isProductInShare() {
            const shareProducts = this.$store.getters['tripStore/productsToShare'];
            return shareProducts.products.some((product) => product.cacheKey === this.flightResult.packageKey);
        },
        hasGreenBrand() {
            return this.flightResult?.segments?.some((segment) => segment?.isGreenBrand);
        },
        co2EmissionsAverage() {
            return this.$store.getters['flightStore/flightFilter']?.emissionsAverage;
        },
        searchOptions() {
            return this.$store.getters['flightStore/searchOptions'];
        },
        flightMarkings() {
            if (this.isPricingOptions) {
                return [];
            }
            const strategyOffset = {
                detailed: 0,
                simplified: -1,
            };
            const isValid = (seg) => {
                return JSON.stringify(seg) !== '{}';
            };
            const flightRes = this.flightResult;
            const selected = this.selectedSegments;
            const lastSelectedIdx = selected.filter((s) => isValid(s)).length - 1;
            const offset = this.searchOptions.tripType === TripType.multiCity ? strategyOffset.detailed : strategyOffset.simplified;
            const expectedSelections = this.searchOptions.flightRows.length - offset;
            const selectingLast = lastSelectedIdx === expectedSelections - 2;
            return flightRes.segments.map((cur, idx) => {
                const marking = {
                    arrivalMarked: false,
                    departureMarked: false,
                    originMarked: false,
                    destinationMarked: false,
                };
                const isBySchedule = this.searchOptions.searchFor === 'bySchedule';
                if (isBySchedule) {
                    const lstSelected = selected[lastSelectedIdx];
                    if (lstSelected && isValid(lstSelected)) {
                        marking.originMarked = airportMarkingsService.markIfConnectingToSameCityDiffAirport(lstSelected, cur);
                    }
                    const fstSelected = selected[0];
                    if (selectingLast && fstSelected && isValid(fstSelected)) {
                        marking.arrivalMarked = airportMarkingsService.markIfConnectingToSameCityDiffAirport(cur, fstSelected);
                    }
                }
                else {
                    const isFirst = idx === 0;
                    const lastSegIdx = this.flightResult.segments.length - 1;
                    const isLast = idx === lastSegIdx;
                    if (isFirst || isLast) {
                        const marked = airportMarkingsService.markIfConnectingToSameCityDiffAirport(flightRes.segments[lastSegIdx], flightRes.segments[0]);
                        marking.arrivalMarked = isLast && marked;
                        marking.departureMarked = isFirst && marked;
                    }
                    const prv = flightRes.segments[idx - 1];
                    if (prv) {
                        marking.originMarked = airportMarkingsService.markIfConnectingToSameCityDiffAirport(prv, cur);
                    }
                    const nxt = flightRes.segments[idx + 1];
                    if (nxt) {
                        marking.destinationMarked = airportMarkingsService.markIfConnectingToSameCityDiffAirport(cur, nxt);
                    }
                }
                return marking;
            });
        },
    },
    unmounted() {
        eventBus.$off('resetMarkSelectedFlight', this.resetMarkSelectedFlight);
        eventBus.$off('cancelCartBtnLoading', this.cancelCartBtnLoading);
        eventBus.$off('handleAddToShare', this.handleAddToShare);
    },
});
