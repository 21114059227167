import { defineComponent } from 'vue';
import { loggerService } from '@/services/logger.service';
export default defineComponent({
    name: 'AppGenesys',
    props: {},
    data() {
        return {
            isShowModal: false,
            dialogVisible: false
        };
    },
    async created() {
        await this.injectExternalScript();
    },
    methods: {
        chatToggle() {
            if (!this.isShowModal) {
                let tripId = null;
                if (this.trip) {
                    tripId = this.trip.id;
                }
                let travellerName = '';
                let corporateUserGroupName = this.loggedinUser?.corporateUserGroupName;
                if (this.trip && this.trip.passengers && this.trip.passengers.length > 0) {
                    travellerName = this.trip.passengers[0].firstName + ' ' + this.trip.passengers[0].lastName;
                    if (this.trip.passengers[0].corporateUserGroupName && this.trip.passengers[0].corporateUserGroupName !== '') {
                        corporateUserGroupName = this.trip.passengers[0].corporateUserGroupName;
                    }
                }
                window.PURECLOUD_WEBCHAT_FRAME_CONFIG = {
                    containerEl: 'purecloud-chat-container'
                };
                let webchat;
                window.ININ.webchat.create({
                    webchatAppUrl: 'https://apps.mypurecloud.com/webchat',
                    webchatServiceUrl: 'https://realtime.mypurecloud.com:443',
                    orgGuid: '36fc48f9-1117-4a46-b8c1-40626af42852',
                    orgId: '61156',
                    orgName: 'internovatravelgroup',
                    queueName: 'ALT_Atriis_VIP_Chat',
                    logLevel: 'ERROR',
                    reconnectEnabled: false,
                    data: {
                        firstName: this.loggedinUser?.personalDetails.firstName,
                        lastName: this.loggedinUser?.personalDetails.lastName,
                        email: this.loggedinUser?.email,
                        tripId: tripId,
                        travellerName: travellerName,
                        corporateName: this.loggedinUser?.corporationName,
                        userGroupName: corporateUserGroupName
                    }
                }).then(function (result) {
                    webchat = result;
                    return webchat.renderFrame({
                        containerEl: document.getElementById('purecloud-chat-container')
                    });
                }).catch(function (error) {
                    loggerService.error(error);
                });
                this.isShowModal = true;
            }
            else {
                this.dialogVisible = true;
            }
        },
        async injectExternalScript() {
            const tag = document.createElement('script');
            tag.setAttribute('src', 'https://apps.mypurecloud.com/webchat/jsapi-v1.js');
            tag.setAttribute('id', 'purecloud-webchat-js');
            tag.setAttribute('region', 'us-east-1');
            tag.setAttribute('type', 'text/javascript');
            tag.setAttribute('org-guid', '36fc48f9-1117-4a46-b8c1-40626af42852');
            tag.setAttribute('deployment-key', 'dc651e31-133f-4888-942a-dbe2ec58ff9f');
            document.body.appendChild(tag);
        },
        confirmDialog() {
            this.isShowModal = !this.isShowModal;
            this.dialogVisible = false;
        },
        cancelDialog() {
            this.dialogVisible = false;
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        }
    }
});
