import { defineComponent } from 'vue';
export default defineComponent({
    name: 'NewSeatLabel',
    props: {
        isAllContainsNewSeat: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
