import { createRouter, createWebHistory } from 'vue-router';
import { authenticationGuard, referenceFieldGuard, removeProduction } from '@/guards';
import tripApp from '@/views/trip/trip-app.vue';
import tripIndex from '@/views/trip/trip-index.vue';
import tripCart from '@/views/trip/trip-cart/trip-cart.vue';
import tripReferenceForm from '@/views/trip/trip-reference-form.vue';
import emptyRouter from '@/views/empty-router.vue';
import signinCallBack from '@/views/signin-callback.vue';
import signoutCallBack from '@/views/signout-callback.vue';
import pageNotFound from '@/views/page-not-found.vue';
import signinPage from '@/views/signin-page.vue';
import signoutPage from '@/views/signout-page.vue';
import loggedInPage from '@/views/logged-in-page.vue';
import bookingSuccessPage from '@/views/booking-success.vue';
import preTripBookingSuccessPage from '@/views/pre-trip-booking-success.vue';
import myTrips from '@/views/trip/my-trips.vue';
import home from '@/views/home/home-page.vue';
import status from '@/views/status-page.vue';
import paymentToken from '@/views/trip/payment/payment-token.vue';
import tripCancellationConfirmed from '@/views/trip-cancellation-confirmed.vue';
import userApp from '@/views/user/user-app.vue'; // the inner component 'ser-profile.vue' is lazy-loading so no need to add it here
// ---------[Temp]---------------
import inputsPage from '@/temp/design-system/inputs-page.vue';
import formsPage from '@/temp/design-system/forms-page.vue';
import dialogsDrawers from '@/temp/design-system/dialogs-drawers.vue';
import validations from '@/temp/design-system/validations/validations.vue';
import whiteboardPage from '@/temp/whiteboard-page.vue';
import whiteboardReferenceField from '@/temp/whiteboard-ReferenceField.vue';
const selfRegistration = () => import(/* webpackChunkName: 'self-registration' */ '@/views/self-registration/self-registration.vue');
const agentSelfRegistration = () => import(/* webpackChunkName: 'self-registration' */ '@/views/agent-self-registration/agent-self-registration.vue');
const groundTransportResults = () => import(/* webpackChunkName: 'ground-transport' */ '@/views/ground-transport/ground-transport-results.vue');
const heathrowExpressResults = () => import(
/* webpackChunkName: 'heathrow-express' */ '@/views/heathrow-express/heathrow-express-results/heathrow-express-results.vue');
const parkingResults = () => import(/* webpackChunkName: 'parking' */ '@/views/parking/parking-results/parking-results.vue');
const euRailResults = () => import(
/* webpackChunkName: 'eu-rail' */ '@/modules/products/rails/eu-rail/views/eu-rail-results/eu-rail-results.vue');
const ukRailResults = () => import(
/* webpackChunkName: 'uk-rail' */ './modules/products/rails/uk-rail/views/uk-rail-results/uk-rail-results.vue');
const carResults = () => import(/* webpackChunkName: 'car' */ '@/views/car/car-results/car-results.vue');
const carCitySearch = () => import(/* webpackChunkName: 'car' */ '@/views/car/car-city-search/car-city-search.vue');
const flightResults = () => import(/* webpackChunkName: 'flight' */ '@/modules/products/flight/views/flight-results/flight-results.vue');
const flightChange = () => import(/* webpackChunkName: 'flight' */ '@/modules/products/flight/views/flight-change/flight-change.vue');
const hotelDetails = () => import(/* webpackChunkName: 'hotel' */ '@/views/hotel/hotel-details.vue');
const hotelResults = () => import(/* webpackChunkName: 'hotel' */ '@/views/hotel/hotel-results.vue');
const manualQuotePage = () => import(/* webpackChunkName: 'manual-quote' */ '@/views/trip/manual-quote/manual-quote-page.vue');
const amtrakResults = () => import(/* webpackChunkName: 'amtrak' */ './modules/products/rails/amtrak/views/amtrak-results/amtrak-results.vue');
export var RoutesNames;
(function (RoutesNames) {
    RoutesNames["home"] = "home";
    RoutesNames["powerbiReport"] = "Report";
    RoutesNames["signin"] = "signin";
    RoutesNames["signout"] = "signout";
    RoutesNames["selfRegistration"] = "selfRegistration";
    RoutesNames["agentSelfRegistration"] = "agentSelfRegistration";
    RoutesNames["tripApp"] = "tripApp";
    RoutesNames["tripIndex"] = "tripIndex";
    RoutesNames["hotelResults"] = "hotelResults";
    RoutesNames["hotelDetails"] = "hotelDetails";
    RoutesNames["groundTransportResults"] = "groundTransportResults";
    RoutesNames["parkingSearch"] = "parkingSearch";
    RoutesNames["parkingResults"] = "parkingResults";
    RoutesNames["carResults"] = "carResults";
    RoutesNames["carCitySearch"] = "carCitySearch";
    RoutesNames["flightSearch"] = "flightSearch";
    RoutesNames["flightResults"] = "flightResults";
    RoutesNames["amtrakSearch"] = "amtrakSearch";
    RoutesNames["amtrakResults"] = "amtrakResults";
    RoutesNames["ukRailResults"] = "ukRailResults";
    RoutesNames["euRailResults"] = "euRailResults";
    RoutesNames["pageNotFound"] = "pageNotFound";
    RoutesNames["signoutCallBack"] = "signoutCallBack";
    RoutesNames["signinCallBack"] = "signinCallBack";
    RoutesNames["bookingSuccess"] = "bookingSuccess";
    RoutesNames["preTripBookingSuccess"] = "preTripBookingSuccess";
    RoutesNames["myTrips"] = "myTrips";
    RoutesNames["tripCart"] = "tripCart";
    RoutesNames["tripDetails"] = "tripDetails";
    RoutesNames["tripReferenceForm"] = "tripReferenceForm";
    RoutesNames["paymentToken"] = "paymentToken";
    RoutesNames["tripCancellationConfirmed"] = "tripCancellationConfirmed";
    RoutesNames["heathrowExpress"] = "heathrowExpress";
    RoutesNames["heathrowExpressResults"] = "heathrowExpressResults";
    RoutesNames["requestAssistance"] = "requestAssistance";
    RoutesNames["manualQuote"] = "manualQuote";
    RoutesNames["flightChange"] = "flightChange";
    RoutesNames["userApp"] = "userApp";
})(RoutesNames || (RoutesNames = {}));
export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '',
            component: loggedInPage,
            children: [
                {
                    path: '',
                    redirect: () => {
                        return { name: RoutesNames.home };
                    },
                },
                {
                    path: 'home',
                    name: RoutesNames.home,
                    component: home,
                },
                {
                    path: 'trips',
                    component: myTrips,
                    name: RoutesNames.myTrips,
                },
                {
                    path: 'payment-token',
                    component: paymentToken,
                    name: RoutesNames.paymentToken,
                },
                {
                    path: 'cancellation-confirmed',
                    name: RoutesNames.tripCancellationConfirmed,
                    component: tripCancellationConfirmed,
                },
                {
                    path: 'report',
                    name: RoutesNames.powerbiReport,
                    component: () => import(/* webpackChunkName: 'powerbiReport' */ '@/views/powerBi/powerbi-report.vue'),
                },
                {
                    path: 'user/:userId?',
                    name: RoutesNames.userApp,
                    component: userApp,
                },
                {
                    path: '/trips/:tripId',
                    component: tripApp,
                    name: RoutesNames.tripApp,
                    children: [
                        {
                            path: 'reference-form',
                            component: tripReferenceForm,
                            name: RoutesNames.tripReferenceForm,
                            beforeEnter: referenceFieldGuard,
                        },
                        {
                            path: 'cart',
                            component: tripCart,
                            name: RoutesNames.tripCart,
                        },
                        {
                            path: 'booking-success/:successType',
                            name: RoutesNames.bookingSuccess,
                            component: bookingSuccessPage,
                            props: true,
                        },
                        {
                            path: 'quote-change/flight/:quoteId',
                            name: RoutesNames.flightChange,
                            meta: { product: 'Flight Change' },
                            component: flightChange,
                        },
                        {
                            path: 'pre-trip-booking-success',
                            name: RoutesNames.preTripBookingSuccess,
                            component: preTripBookingSuccessPage,
                        },
                        {
                            path: 'products',
                            component: emptyRouter,
                            children: [
                                {
                                    path: 'hotels',
                                    component: () => import(/* webpackChunkName: 'hotel' */ '@/views/hotel/hotel-app.vue'),
                                    children: [
                                        {
                                            path: 'results/:searchCacheKey?',
                                            component: emptyRouter,
                                            children: [
                                                {
                                                    path: '',
                                                    component: hotelResults,
                                                    name: RoutesNames.hotelResults,
                                                    props: true,
                                                    meta: { product: 'Hotels' },
                                                },
                                                {
                                                    path: ':hotelCacheKey/rooms',
                                                    component: hotelDetails,
                                                    name: RoutesNames.hotelDetails,
                                                    meta: { product: 'Hotels' },
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    path: 'ground-transport',
                                    component: () => import(
                                    /* webpackChunkName: "ground-transport" */ '@/views/ground-transport/ground-transport-app.vue'),
                                    children: [
                                        {
                                            path: '',
                                            component: groundTransportResults,
                                            name: RoutesNames.groundTransportResults,
                                            meta: { product: 'Transport' },
                                        },
                                    ],
                                },
                                {
                                    path: 'parking',
                                    component: () => import(/* webpackChunkName: "parking" */ '@/views/parking/parking-app.vue'),
                                    children: [
                                        {
                                            path: 'results',
                                            component: parkingResults,
                                            name: RoutesNames.parkingResults,
                                            meta: { product: 'Parking' },
                                        },
                                    ],
                                },
                                {
                                    path: 'flight',
                                    component: () => import(/* webpackChunkName: "flight" */ '@/modules/products/flight/views/flight-app.vue'),
                                    children: [
                                        {
                                            path: 'results',
                                            component: flightResults,
                                            name: RoutesNames.flightResults,
                                            meta: { product: 'Flights' }
                                        },
                                    ],
                                },
                                {
                                    path: 'heathrow-express',
                                    component: () => import(
                                    /* webpackChunkName: "heathrow-express" */ '@/views/heathrow-express/heathrow-express-app.vue'),
                                    children: [
                                        {
                                            path: 'results',
                                            component: heathrowExpressResults,
                                            name: RoutesNames.heathrowExpressResults,
                                            meta: { product: 'HeathrowExpress' },
                                        },
                                    ],
                                },
                                {
                                    path: 'uk-rail',
                                    component: () => import(/* webpackChunkName: "uk-rail" */ '@/views/uk-rail/uk-rail-app.vue'),
                                    children: [
                                        {
                                            path: 'results',
                                            component: ukRailResults,
                                            name: RoutesNames.ukRailResults,
                                            meta: { product: 'UK Rail' },
                                        },
                                    ],
                                },
                                {
                                    path: 'eu-rail',
                                    component: () => import(/* webpackChunkName: "eu-rail" */ '@/views/rails/eu-rail/eu-rail-app.vue'),
                                    children: [
                                        {
                                            path: 'results',
                                            component: euRailResults,
                                            name: RoutesNames.euRailResults,
                                            meta: { product: 'EU Rail' },
                                        },
                                    ],
                                },
                                {
                                    path: 'db-rail',
                                    component: () => import(/* webpackChunkName: "db-rail" */ '@/views/rails/db-rail/db-rail-app.vue'),
                                },
                                {
                                    path: 'amtrak',
                                    component: () => import(
                                    /* webpackChunkName: "amtrak" */ '@/modules/products/rails/amtrak/views/amtrak-app/amtrak-app.vue'),
                                    children: [
                                        {
                                            path: 'results',
                                            component: amtrakResults,
                                            name: RoutesNames.amtrakResults,
                                            meta: { product: 'Amtrak' },
                                        },
                                    ],
                                },
                                {
                                    path: 'car',
                                    component: () => import(/* webpackChunkName: "car" */ '@/views/car/car-app.vue'),
                                    children: [
                                        {
                                            path: 'results',
                                            component: carResults,
                                            name: RoutesNames.carResults,
                                            meta: { product: 'Cars' },
                                        },
                                        {
                                            path: 'city-search',
                                            component: carCitySearch,
                                            name: RoutesNames.carCitySearch,
                                            meta: { product: 'Cars' },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: 'travel-requests',
                            component: () => import(
                            /* webpackChunkName: "request-assistance" */ '@/views/trip/request-assistance/request-assistance.vue'),
                            name: RoutesNames.requestAssistance,
                            props: true,
                        },
                        {
                            path: 'manual-quote',
                            name: RoutesNames.manualQuote,
                            component: manualQuotePage,
                            meta: { product: 'Manual-quote' },
                        },
                        {
                            path: ':product?',
                            component: tripIndex,
                            name: RoutesNames.tripIndex,
                        },
                    ],
                },
            ],
        },
        {
            path: '/status',
            component: status,
            meta: { isAnonymous: true },
            name: 'statusPage',
        },
        {
            path: '/whiteboard',
            component: emptyRouter,
            meta: { isAnonymous: true },
            beforeEnter: removeProduction,
            children: [
                {
                    path: '',
                    component: whiteboardPage,
                    name: 'whiteboardPage',
                    meta: { isAnonymous: true },
                },
                {
                    path: 'reference',
                    component: whiteboardReferenceField,
                    name: 'whiteboardPageReference',
                    meta: { isAnonymous: true },
                },
            ],
        },
        {
            path: '/ds',
            component: emptyRouter,
            meta: { isAnonymous: true },
            beforeEnter: removeProduction,
            children: [
                {
                    path: 'forms',
                    component: formsPage,
                    name: 'formsPage',
                    meta: { isAnonymous: true },
                    beforeEnter: removeProduction,
                },
                {
                    path: 'inputs',
                    component: inputsPage,
                    name: 'inputsPage',
                    meta: { isAnonymous: true },
                    beforeEnter: removeProduction,
                },
                {
                    path: 'dialogs-drawers',
                    component: dialogsDrawers,
                    name: 'dialogsDrawers',
                    meta: { isAnonymous: true },
                    beforeEnter: removeProduction,
                },
                {
                    path: 'validation',
                    component: validations,
                    name: 'validations',
                    meta: { isAnonymous: true },
                    beforeEnter: removeProduction,
                },
            ],
        },
        {
            path: '/signin-callback',
            name: RoutesNames.signinCallBack,
            component: signinCallBack,
            meta: { isAnonymous: true },
        },
        {
            path: '/signout-callback',
            name: RoutesNames.signoutCallBack,
            component: signoutCallBack,
            meta: { isAnonymous: true },
        },
        {
            path: '/signin',
            name: RoutesNames.signin,
            component: signinPage,
            meta: { isAnonymous: true },
        },
        {
            path: '/signout',
            name: RoutesNames.signout,
            component: signoutPage,
            meta: { isAnonymous: true },
        },
        {
            path: '/selfRegistration/:pathMatch(.*)*',
            name: RoutesNames.selfRegistration,
            component: selfRegistration,
            meta: { isAnonymous: true },
        },
        {
            path: '/agentSelfRegistration/:pathMatch(.*)*',
            name: RoutesNames.agentSelfRegistration,
            component: agentSelfRegistration,
            meta: { isAllowAgent: true },
        },
        {
            path: '/:pathMatch(.*)*',
            name: RoutesNames.pageNotFound,
            meta: { isAnonymous: true },
            component: pageNotFound,
        },
    ],
    scrollBehavior: scrollBehavior,
});
function scrollBehavior(to, from, savedPosition) {
    if (to.hash && !to.hash.includes('id_token')) {
        //prevent confusion with auth callback
        return {
            selector: to.hash,
        };
    }
    else if (savedPosition) {
        return savedPosition;
    }
    else {
        return { left: 0, top: 0 };
    }
}
router.beforeEach(authenticationGuard);
