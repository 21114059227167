import { RoomAmenityType, RoomRefundableStatus } from './../models/consts';
import { utilService } from '@/services/util.service';
import { orderBy } from 'lodash';
//TODO: create hotel const and adjust getRoomFilterData to receive info from there.
import * as hotelsConsts from '@/modules/products/hotels/models/consts';
import { TermsType } from '@/modules/products/hotels/models/consts';
import { httpService } from '@/services/http.service';
import { AgreementType } from '@/types/consts';
import { tripService } from '@/services/trip.service';
import store from '@/store/store';
export const roomService = {
    getRoomFilterData,
    getRoomsByHotelCacheKey,
    getFilterRooms,
    getSortedRooms,
    selectRoom,
    getRoomRules,
    getSabreFullDetails,
    getHotelExtras,
    saveHotelExtras,
    getPremierInnExtraServices,
    savePremierInnExtraServices,
    filterByAgreements,
    // getPriceRange
};
function getPremierInnExtraServices(quoteId, tripId) {
    return httpService.get(`/premierInnExtraServices?quoteId=${quoteId}&tripId=${tripId}`, null);
}
async function savePremierInnExtraServices(premierInnExtraServices) {
    return httpService.post('/premierInnExtraServices', premierInnExtraServices);
}
function getRoomRules(payload) {
    return httpService.post('/hotelRoomRules', payload);
}
function selectRoom(tripId, roomCacheKey, hotelCacheKey) {
    const affId = tripService.loadAffiliateId();
    let endpoint = `/selectroom?cacheKey=${roomCacheKey}&tripId=${tripId}&hotelCacheKey=${hotelCacheKey}&relatedAccount=0`;
    endpoint += affId ? `&affiliateId=${affId}` : '';
    return httpService.get(endpoint, null);
}
async function getSabreFullDetails(tripId, roomCacheKey) {
    const data = { TripId: tripId, CacheKey: roomCacheKey };
    return await httpService.post('/roomDescriptionPriceCheck', data);
}
async function getHotelExtras(quoteId) {
    //const val : IHotelExtras[] = require('@/temp/JSONs/hotelExtras.json');
    //return val;
    return await httpService.get(`/hotelExtras?quoteid=${quoteId}`, null);
}
async function saveHotelExtras(data) {
    return await httpService.post('/hotelExtras', data);
}
async function getRoomsByHotelCacheKey(tripId, searchCacheKey, hotelCacheKey) {
    const hotelSearchResponse = await httpService.get(`/roomsearch?tripId=${tripId}&searchCacheKey=${searchCacheKey}&hotelCacheKey=${hotelCacheKey}`, null, {
        caching: {
            cachingMsTime: 1000 * 60 * 15,
            hardCached: false,
            cachingCategory: 'hotelsearch',
        },
    });
    //const hotelSearchResponse : IRoomSearchResponse = require('@/temp/JSONs/roomSearchResponse.json');
    if (!hotelSearchResponse) {
        return { rooms: [], page: 1, resultsCountMap: {} };
    }
    const rooms = hotelSearchResponse.rooms;
    const joyDefaultSort = hotelSearchResponse.joyDefaultSort;
    const filterData = _getRoomFilterData();
    const resultsCountMap = filterData.reduce((acc, data) => {
        if (data.countFunction) {
            acc[data.key] = data.countFunction(rooms);
        }
        else {
            acc[data.key] = _countRoomResults(rooms, data.key);
        }
        return acc;
    }, {});
    return { rooms, page: 1, resultsCountMap, joyDefaultSort };
}
function getFilterRooms(rooms, filterBy) {
    //Filter : non Refundable
    if (filterBy?.terms?.length) {
        rooms = rooms.filter((room) => {
            return filterBy.terms.every((filterTerm) => {
                if (filterTerm === TermsType.nonRefundable) {
                    return room.refundableStatus === RoomRefundableStatus.Refundable;
                }
                else {
                    return true; //ignore any ather option
                }
            });
        });
    }
    //Filter : paymentMethods & Refundable 
    if (filterBy?.paymentMethods?.some((p) => p === hotelsConsts.PaymentMethods.refundable.toString())) {
        //2 = Refundable
        rooms = rooms.filter((room) => {
            return room.refundableStatus === RoomRefundableStatus.Refundable;
        });
    }
    if (filterBy?.paymentMethods?.filter((p) => p !== hotelsConsts.PaymentMethods.refundable.toString() && p !== hotelsConsts.PaymentMethods.depositNotRequired.toString()).length) {
        rooms = rooms.filter((room) => {
            return filterBy?.paymentMethods
                ?.filter((p) => p !== hotelsConsts.PaymentMethods.refundable.toString())
                .some((paymentMethod) => {
                return room.paymentMethods.some(p => p + '' === paymentMethod);
            });
        });
    }
    //Fliter: No Deposit
    if (filterBy?.paymentMethods?.some((p) => p === hotelsConsts.PaymentMethods.depositNotRequired.toString())) {
        rooms = rooms.filter((room) => {
            return !room.isDeposit;
        });
    }
    //Filter : Amenities
    if (filterBy?.roomAmenities?.length) {
        rooms = rooms.filter((room) => {
            const enumAmenities = utilService.getArrayFromEnum(hotelsConsts.RoomAmenityType);
            const filteredBy = filterBy.roomAmenities.filter((ra) => enumAmenities.includes(RoomAmenityType[+ra]));
            const generalAmenities = filteredBy.every((filterAmenity) => room.amenities.some((roomAmenity) => roomAmenity.type + '' === filterAmenity));
            const specificRoomTypesAmenities = filterBy.roomAmenities.filter((ra) => !enumAmenities[+ra]).length
                ? filterBy.roomAmenities
                    .filter((ra) => !enumAmenities[+ra])
                    .some((filterAmenity) => room.amenities.some((roomAmenity) => {
                    if (roomAmenity.type === RoomAmenityType.BedType) {
                        return roomAmenity.description === filterAmenity;
                    }
                }))
                : true;
            return generalAmenities && specificRoomTypesAmenities;
        });
    }
    //Filter : suppliers
    //For supplierIds and agreement, notice the different term in filterBy vs room object.
    if (filterBy?.supplierIds?.length) {
        rooms = rooms.filter((room) => {
            return filterBy.supplierIds.some((supplierId) => room.supplierId === supplierId);
        });
    }
    //Filter : Agreement
    if (filterBy?.agreement?.length) {
        rooms = this.filterByAgreements(rooms, filterBy?.agreement);
    }
    //Filter : in Policy
    if (filterBy.inPolicy) {
        rooms = rooms.filter((room) => !room.travelPolicyViolations.length);
    }
    //Filter :price
    if (filterBy.priceRange.length) {
        rooms = rooms.filter((room) => room.displayTotalPrice >= filterBy.priceRange[0] && room.displayTotalPrice <= filterBy.priceRange[1]);
    }
    return rooms;
}
function filterByAgreements(rooms, agreements) {
    return rooms.filter((room) => {
        return agreements.some((filterAgreement) => {
            if (store.getters['authStore/loggedinUser'].permissions.includes('DefaultNonAgreementGDSHotelsActive') &&
                room.nonGDS) {
                return true;
            }
            let roomAgreementType = _getRoomAgreementType(room);
            if (!roomAgreementType) {
                roomAgreementType = room.agreementType;
            }
            if (roomAgreementType === AgreementType.Both) {
                return Number(filterAgreement) === AgreementType.Corp || Number(filterAgreement) === AgreementType.Agency;
            }
            return roomAgreementType === Number(filterAgreement);
        });
    });
}
function _getRoomAgreementType(room) {
    const count = [];
    room.agreements.forEach((agreement) => {
        count.push(agreement.type);
    });
    if (!count.length) {
        return AgreementType.None;
    }
    else if (count.includes(AgreementType.Both) ||
        (count.includes(AgreementType.Corp) && count.includes(AgreementType.Agency))) {
        return AgreementType.Both;
    }
    else if (count.includes(AgreementType.Corp)) {
        return AgreementType.Corp;
    }
    else if (count.includes(AgreementType.Agency)) {
        return AgreementType.Agency;
    }
    return count[0];
}
function getSortedRooms(rooms, filterBy) {
    let sortedRooms = [];
    switch (filterBy.sortBy) {
        case 'price': {
            sortedRooms = orderBy(rooms, ['displayTotalPrice', 'name'], ['asc', 'asc']);
            break;
        }
        case 'supplier': {
            sortedRooms = orderBy(rooms, ['supplierId', 'displayTotalPrice'], ['asc', 'asc']);
            break;
        }
        case 'roomName': {
            sortedRooms = orderBy(rooms, ['name', 'displayTotalPrice'], ['asc', 'asc']);
            break;
        }
        case 'default': {
            sortedRooms = orderBy(rooms, [
                (room) => room.agreements.some((agreement) => agreement.type === AgreementType.Corp || agreement.type === AgreementType.Both),
                'displayTotalPrice',
                'name',
            ], ['desc', 'asc', 'asc']);
            break;
        }
    }
    return sortedRooms;
}
function _countRoomResults(rooms, filterKeys) {
    return rooms.reduce((acc, room) => {
        const roomFilterKey = Reflect.get(room, filterKeys);
        if (Array.isArray(roomFilterKey)) {
            roomFilterKey?.forEach((filterKey) => {
                if (!acc[filterKey]) {
                    acc[filterKey] = 0;
                }
                acc[filterKey]++;
            });
            return acc;
        }
        else {
            if (filterKeys === 'supplierIds') {
                filterKeys = 'supplierId';
            }
            if (filterKeys === 'agreement') {
                filterKeys = 'agreementIds';
            }
            if (!acc[roomFilterKey]) {
                acc[roomFilterKey] = 0;
            }
            acc[roomFilterKey]++;
            return acc;
        }
    }, {});
}
function _getRoomFilterData() {
    return [
        {
            key: 'terms',
            countFunction: (rooms) => {
                const dictionary = {};
                dictionary[TermsType.nonRefundable] = 0;
                rooms.forEach((room) => {
                    if (room.refundableStatus !== RoomRefundableStatus.Refundable) {
                        dictionary[TermsType.nonRefundable]++;
                    }
                });
                return dictionary;
            },
        },
        {
            key: 'paymentMethods',
            countFunction: (rooms) => {
                const dictionary = {};
                rooms.forEach((room) => {
                    if (room.refundableStatus === RoomRefundableStatus.Refundable) {
                        if (!dictionary[hotelsConsts.PaymentMethods.refundable.toString()]) {
                            dictionary[hotelsConsts.PaymentMethods.refundable.toString()] = 0;
                        }
                        dictionary[hotelsConsts.PaymentMethods.refundable.toString()]++;
                    }
                    if (!room.isDeposit) {
                        if (!dictionary[hotelsConsts.PaymentMethods.depositNotRequired.toString()]) {
                            dictionary[hotelsConsts.PaymentMethods.depositNotRequired.toString()] = 0;
                        }
                        dictionary[hotelsConsts.PaymentMethods.depositNotRequired.toString()]++;
                    }
                    room.paymentMethods.forEach(paymentMethod => {
                        const key = paymentMethod;
                        if (!dictionary[key]) {
                            dictionary[key] = 0;
                        }
                        dictionary[key]++;
                    });
                });
                return dictionary;
            },
        },
        {
            key: 'roomAmenities',
            countFunction: (rooms) => {
                const dictionary = {};
                rooms.forEach((room) => {
                    const keys = room.amenities;
                    keys.forEach((k) => {
                        const amenityType = k.type === RoomAmenityType.BedType ? k.description : k.type;
                        if (!dictionary[amenityType]) {
                            dictionary[amenityType] = 0;
                        }
                        dictionary[amenityType]++;
                    });
                });
                return dictionary;
            },
        },
        {
            key: 'agreement',
            countFunction: (rooms) => {
                const dictionary = {};
                rooms.forEach((room) => {
                    const key = room.agreementType;
                    if (key === AgreementType.Corp || key === AgreementType.Agency || key === AgreementType.None) {
                        if (!dictionary[key]) {
                            dictionary[key] = 0;
                        }
                        dictionary[key]++;
                    }
                    else if (key === AgreementType.Both) {
                        dictionary[AgreementType.Corp]++;
                        dictionary[AgreementType.Agency]++;
                    }
                });
                return dictionary;
            },
        },
        {
            key: 'supplierIds',
            countFunction: (rooms) => {
                const dictionary = {};
                rooms.forEach((room) => {
                    const key = room.supplierId;
                    if (!dictionary[key]) {
                        dictionary[key] = 0;
                    }
                    dictionary[key]++;
                });
                return dictionary;
            },
        },
    ];
}
function getRoomFilterData() {
    return [
        // {
        //     key: "terms",
        //     txt: "reservationPolicy",
        //     items: utilService.getArrayFromEnum(hotelsConsts.TermsType),
        // },
        {
            key: 'paymentMethods',
            txt: 'payment',
            items: utilService.getArrayFromEnum(hotelsConsts.PaymentMethods),
        },
        {
            key: 'roomAmenities',
            txt: 'amenities',
            items: utilService.getArrayFromEnum(hotelsConsts.RoomAmenityType),
        },
        {
            key: 'agreement',
            txt: 'agreement',
            items: [AgreementType.None, AgreementType.Agency, AgreementType.Corp].reduce((acc, dataKey) => {
                acc[dataKey] = AgreementType[dataKey];
                return acc;
            }, {}),
        },
    ];
}
// function getPriceRange(obj: IRoom[]) {
//     return [obj.sort(function (a: any, b: any) { return a.price - b.price; })[0].price, obj.sort(function (a: any, b: any) { return b.price - a.price; })[0].price];
//   }
