import { utilService } from '@/services/util.service';
import { roomService } from '@/modules/products/hotels/services/room.service';
import { userService } from '@/services/user.service';
import store from '@/store/store';
import * as hotelsConsts from '@/modules/products/hotels/models/consts';
import { httpService } from '@/services/http.service';
import { AgreementType } from '@/types/consts';
import { $t } from '@/plugins/i18n';
import { SuppliersId } from '../models/consts';
export const hotelService = {
    hotelQuoteRules,
    addSpecialRequest,
    saveAdditionalPreferences,
    getAdditionalPreferences,
    filterHotels,
    //getById,
    getHotelFilterCheckboxes,
    //getHotelFilterFromStorage,
    getHotelFilterPaymentMethods,
    getHotelFilterRadio,
    getHotelLoyaltyCodes,
    getHotelChains,
    getPointOfInterest,
    // saveHotelFilter,
    autocompleteSearch,
    searchHotels,
    searchHotelsByGeocode,
    searchHotelsByGeocodeById,
    getHoteldetails,
    getHotelQuoteDetails,
    addFrequentGuest,
    getFrequentPrograms,
    getVoucher,
    hotelRoomTravelPolicy,
    checkIfRoomRestrictedRefundRule,
    getHotelSuppliers,
    getCancellationPolicies,
    getPaymentMethodData,
    savePaymentMethod,
    getHotelFilterEcoLabels,
    getNumberOfRoomOptions,
    getHotelCrossSellSuggestions,
    // getPriceRange
};
// GETS:
function createRoomCountOption(num) {
    return {
        value: num,
        label: `${num} ${num === 1 ? $t('hotel.room') : $t('hotel.rooms')}`,
    };
}
function getNumberOfRoomOptions(numberOFTravelers, canSearchFiveTravelersOneRoom) {
    const options = [];
    if (canSearchFiveTravelersOneRoom) {
        if (numberOFTravelers !== 1 && numberOFTravelers !== 2) {
            //If the user has the relevant permission, add 1 room option(in case one is not already there)
            options.push(createRoomCountOption(1));
        }
    }
    options.push(createRoomCountOption(numberOFTravelers));
    if (numberOFTravelers % 2 === 0) {
        options.unshift(createRoomCountOption(numberOFTravelers / 2));
    }
    return options.sort((a, b) => {
        return a.value - b.value;
    });
}
async function autocompleteSearch(queryString) {
    const autocompleteRow = await httpService.get(`/hotelsearchautocomplete?str=${queryString}`, null);
    return autocompleteRow;
}
async function getPointOfInterest(corpId, tripId) {
    const autocompleteRow = await httpService.get(`/pointofinterest?corpId=${corpId}&tripId=${tripId}`, null, {
        caching: {
            cachingMsTime: 1000 * 60 * 60 * 24,
            hardCached: true,
            cachingCategory: 'pointofinterest',
        },
    });
    return autocompleteRow;
}
function getHotelLoyaltyCodes() {
    return httpService.get('/HotelLoyalityCodes', null);
}
function getHotelChains() {
    return httpService.get('/hotelchains', null);
}
async function checkIfRoomRestrictedRefundRule(room) {
    const userIp = await userService.getUserIp();
    const userBrowser = await utilService.detectBrowser(navigator.userAgent);
    const payload = {
        cacheKey: room.cacheKey,
        tripId: store.getters['tripStore/trip'].id,
        userDetails: {
            userIp,
            userBrowser,
        },
    };
    const res = await roomService.getRoomRules(payload);
    if (res.hotelRuleLines) {
        room.refundableStatus = res.refundableStatus;
        room.termsChecked = true;
        const travelPolicyAfterTerms = await hotelRoomTravelPolicy(store.getters['tripStore/trip'].id, room.cacheKey);
        if (travelPolicyAfterTerms) {
            room.isRestricTravelPolicyViolation = travelPolicyAfterTerms.isRestricted;
            room.travelPolicyViolations = travelPolicyAfterTerms.travelPolicyViolation;
        }
    }
    return room;
}
async function getFrequentPrograms(quoteId) {
    return await httpService.get(`/FrequentPrograms?quoteId=${quoteId}`, null);
}
async function getPaymentMethodData(quoteId) {
    return await httpService.get(`/PaymentMethod?quoteId=${quoteId}`, null);
}
async function getHotelSuppliers() {
    return httpService.get('/hotelsuppliers', null);
}
function getHotelCrossSellSuggestions(tripId) {
    return httpService.get(`/HotelSuggestion?tripId=${tripId}`, null);
}
async function getCancellationPolicies() {
    return httpService.get('/cancellationpolicy', null);
}
async function getHotelQuoteDetails(quoteId, tripId, hasBillBack = false) {
    const hotelQuoteDetails = await httpService.get(`/hotelquotedetails?quoteId=${quoteId}&tripId=${tripId}&hasBillBack=${hasBillBack}`, null);
    return hotelQuoteDetails;
}
async function getHoteldetails(tripId, searchCacheKey, hotelCacheKey) {
    //let hotel: any  = require('@/temp/JSONs/hoteldetailsResponse.json');
    const hotel = await httpService.get(`/hoteldetails?tripId=${tripId}&searchCacheKey=${searchCacheKey}&hotelCacheKey=${hotelCacheKey}`, null, {
        caching: {
            cachingMsTime: 1000 * 60 * 60 * 24,
            hardCached: false,
            cachingCategory: 'hoteldetails',
        },
    });
    return { hotel };
}
async function getVoucher(tripId, quoteId, lang) {
    return await httpService.get(`/hotelVoucher?tripId=${tripId}&quoteId=${quoteId}&lang=${lang}`, null, {
        returnType: 'blob',
    });
}
// POSTs:
async function searchHotels(searchRequest, searchCacheKey) {
    //TODO: MOCK
    // const hotelSearchResponse : IHotelSearchResponse = require('@/temp/JSONs/hotelSearchResponse.json');
    let hotels;
    let hotelSearchRequest;
    let cacheKey;
    if (searchCacheKey) {
        const res = await httpService.get(`/hotelsearch?cacheKey=${searchCacheKey}`, null, {
            caching: {
                cachingMsTime: 1000 * 60 * 60 * 24,
                hardCached: false,
                cachingCategory: 'hotelsearch',
            },
        });
        // invalid or expired CK : return empty result -> will show the edit search box
        if (!res || !res.hotelSearchResponse) {
            return { hotels: [], page: 1, resultsCountMap: {}, searchCacheKey: '', hotelSearchRequest: null };
        }
        hotels = res.hotelSearchResponse.hotels;
        cacheKey = res.hotelSearchResponse.cacheKey;
        hotelSearchRequest = res.hotelSearchRequest;
    }
    else {
        const res = await httpService.post('/hotelsearch', searchRequest, {
            caching: {
                cachingMsTime: 1000 * 60 * 15,
                hardCached: false,
                cachingCategory: 'hotelsearch',
            },
        });
        //res.hotels =  res.hotels;
        cacheKey = res.cacheKey;
        hotels = res.hotels;
    }
    hotels = hotels.filter((h) => {
        return h.suppliers.length > 0;
    });
    for (const i in hotels) {
        hotels[i].name = utilService.fixWrongLatinEncoding(hotels[i].name);
        hotels[i].loc.address = utilService.fixWrongLatinEncoding(hotels[i].loc.address || '');
    }
    const filterData = _getHotelFiltersForResultsCount();
    const resultsCountMap = filterData.reduce((acc, data) => {
        if (data.countFunction) {
            acc[data.key] = data.countFunction(hotels);
        }
        else {
            acc[data.key] = countHotelResults(hotels, data.key);
        }
        return acc;
    }, {});
    return { hotels, page: 1, resultsCountMap, searchCacheKey: cacheKey, hotelSearchRequest };
}
async function searchHotelsByGeocode(data) {
    return await httpService.get(`/hotelSearchGeoLocation?tripId=${data.tripId}&lat=${data.lat}&lng=${data.lng}&distance=${data.distance}&distanceType=${data.unitsSystem}`, null);
}
async function searchHotelsByGeocodeById(tripId, hotelId) {
    return await httpService.get(`/hotelSearchGeoLocation?tripId=${tripId}&hotelId=${hotelId}`, null);
}
async function savePaymentMethod(options) {
    return await httpService.post('/PaymentMethod', options);
}
async function hotelQuoteRules(hotelQuoteRules) {
    return await httpService.post('/hotelQuoteRules', hotelQuoteRules);
}
async function hotelRoomTravelPolicy(tripId, cacheKey) {
    return await httpService.get(`/calculateTravelPolicy?tripId=${tripId}&cacheKey=${cacheKey}`, null);
}
async function addSpecialRequest(specialRequest) {
    return await httpService.post('/specialrequest', specialRequest);
}
async function saveAdditionalPreferences(preferences) {
    return await httpService.post('/preferences', preferences);
}
async function getAdditionalPreferences(quoteId) {
    return await httpService.get(`/preferences?quoteid=${quoteId}`, null);
}
async function addFrequentGuest(request) {
    const res = await httpService.post('/frequentprograms', request);
    return res;
}
function filterHotels(hotels, filterBy) {
    //Frontend filters
    //TODO: is ther any better why ? should we deep copy it ?
    let filteredHotels = utilService.deepClone(hotels);
    if (filterBy.q) {
        const searchRex = new RegExp(filterBy.q, 'i');
        filteredHotels = filteredHotels.filter((hotel) => searchRex.test(hotel.name));
    }
    if (filterBy.ecoLabels?.length) {
        filteredHotels = filteredHotels.filter((hotel) => {
            return hotel.ecoLabels && hotel.ecoLabels.length > 0;
        });
    }
    if (filterBy.paymentMethods?.length) {
        filteredHotels = filteredHotels.filter((hotel) => {
            return filterBy.paymentMethods.some((paymentMethod) => hotel.paymentMethods.some((pm) => pm + '' === paymentMethod));
        });
    }
    if (filterBy?.agreement?.length) {
        filteredHotels = filteredHotels.filter((hotel) => {
            if (hotel.suppliers.some(s => s.supplierId === SuppliersId['AMADEUS-AD'])) {
                return true;
            }
            return filterBy.agreement.some((filterAgreement) => {
                if (store.getters['authStore/loggedinUser'].permissions.includes('DefaultNonAgreementGDSHotelsActive') &&
                    hotel.nonGDS) {
                    return true;
                }
                const hotelAgreementType = _getHotelAgreementType(hotel.suppliers);
                if (hotelAgreementType === AgreementType.Both) {
                    return Number(filterAgreement) === AgreementType.Corp || Number(filterAgreement) === AgreementType.Agency;
                }
                return hotelAgreementType === Number(filterAgreement);
            });
        });
    }
    if (filterBy.amenities?.length) {
        filteredHotels = filteredHotels.filter((hotel) => {
            return filterBy.amenities.every((filterAmenity) => hotel.amenities.some((am) => am + '' === filterAmenity));
        });
    }
    if (filterBy.supplierIds?.length) {
        filteredHotels = filteredHotels.filter((hotel) => {
            return filterBy.supplierIds.some((supplierId) => hotel.suppliers.some((supplier) => supplier.supplierId === supplierId));
        });
    }
    if (filterBy.minStars?.length) {
        filteredHotels = filteredHotels.filter((hotel) => hotel.stars && filterBy.minStars.includes(hotel.stars));
    }
    if (filterBy.inPolicy) {
        filteredHotels = filteredHotels.filter((hotel) => !hotel.travelPolicyViolations.length);
    }
    if (filterBy.sortBy) {
        if (filterBy.sortBy === 'mostRecommended') {
            filteredHotels = _categorizeAndSortHotels(filteredHotels);
        }
        if (filterBy.sortBy === 'price') {
            filteredHotels = filteredHotels.sort((a, b) => a.displayPrice - b.displayPrice);
        }
        if (filterBy.sortBy === 'distance') {
            filteredHotels = filteredHotels.sort((a, b) => a.distanceFromLoc.value - b.distanceFromLoc.value);
        }
        if (filterBy.sortBy === 'lowestCarbon') {
            filteredHotels = filteredHotels.sort((a, b) => a.carbonQuantity - b.carbonQuantity);
        }
    }
    if (filterBy.priceRange.length) {
        filteredHotels = filteredHotels.filter((hotel) => hotel.displayPrice >= filterBy.priceRange[0] && hotel.displayPrice < filterBy.priceRange[1] + 1);
    }
    if (filterBy.searchRadius) {
        // searchRadius is in meters while hotel distanceFromLoc in KM
        // distance filter return in meters (also for miles)
        const km = filterBy.searchRadius / 1000;
        filteredHotels = filteredHotels.filter((hotel) => hotel.distanceFromLoc.value <= km);
    }
    return filteredHotels;
    // return hotels;
}
function _categorizeAndSortHotels(hotels) {
    // Helper function to determine agreement type
    const getAgreement = (hotel) => _getHotelAgreementType(hotel.suppliers);
    // Helper function to sort by distance
    const sortByDistance = (a, b) => {
        const distanceA = a.distanceFromLoc.value === 0 ? -Infinity : a.distanceFromLoc.value;
        const distanceB = b.distanceFromLoc.value === 0 ? -Infinity : b.distanceFromLoc.value;
        return distanceA - distanceB;
    };
    // Categorize hotels
    const categories = {
        preferred: [],
        corpHotels: [],
        recommended: [],
        agencyHotels: [],
        others: []
    };
    for (const hotel of hotels) {
        const agreement = getAgreement(hotel);
        if (hotel.tags?.includes('preferred')) {
            categories.preferred.push(hotel);
        }
        else if (agreement === AgreementType.Both || agreement === AgreementType.Corp) {
            categories.corpHotels.push(hotel);
        }
        else if (hotel.tags?.includes('recommended')) {
            categories.recommended.push(hotel);
        }
        else if (agreement === AgreementType.Agency) {
            categories.agencyHotels.push(hotel);
        }
        else {
            categories.others.push(hotel);
        }
    }
    // Sort each category by distance
    Object.values(categories).forEach(category => category.sort(sortByDistance));
    // Combine results in the desired order
    return [
        ...categories.preferred,
        ...categories.corpHotels,
        ...categories.recommended,
        ...categories.agencyHotels,
        ...categories.others
    ];
}
function countHotelResults(hotels, filterKeys) {
    //for each hotel
    return hotels.reduce((acc, hotel) => {
        //take the value of the key , example : "amenities => [0: "Concierge",1: "Gym", 2: "Internet"]"
        const hotelFilterKey = Reflect.get(hotel, filterKeys);
        if (Array.isArray(hotelFilterKey)) {
            //if the value is  array, for each item add to the acc++
            hotelFilterKey?.forEach((filterKey) => {
                if (!acc[filterKey]) {
                    acc[filterKey] = 0;
                }
                acc[filterKey]++;
            });
            return acc;
        }
        else {
            if (!acc[hotelFilterKey]) {
                acc[hotelFilterKey] = 0;
            }
            acc[hotelFilterKey]++;
            return acc;
        }
    }, {});
}
// function getPriceRange(hotels: IHotel[]) {
//   if (!hotels.length) {
//     return;
//   }
//   return [hotels.sort(function (a: any, b: any) { return a.price - b.price; })[0].price, hotels.sort(function (a: any, b: any) { return b.price - a.price; })[0].price];
// }
// initialize items
//STATIC DEMO DATA:
// import { hotelListResponse } from '../../JSONs/hotels.js';
// function createHotels() {
//   localStorage.setItem('hotel', JSON.stringify(hotelListResponse.hotels));
// }
//ACTIVE DEMO DATA:
// function _createHotels(): IHotelsCityMapping {
//   let cityHotelsMap = ['london', 'paris', 'amsterdam'].reduce(
//     (map, city) => ({
//       ...map,
//       [city]: _makeHotelsForCity(city),
//     }),
//     {}
//   );
//   localStorageService.save('cityHotelsMap', cityHotelsMap);
//   return cityHotelsMap;
// }
function _getHotelFiltersForResultsCount() {
    return [
        {
            key: 'paymentMethods',
        },
        {
            key: 'rate',
            countFunction: (hotels) => {
                const rateCountMap = hotels.reduce((acc, hotel) => {
                    if (!acc[hotel.stars || 'null']) {
                        acc[hotel.stars || 'null'] = 0;
                    }
                    acc[hotel.stars || 'null']++;
                    return acc;
                }, {});
                return rateCountMap;
            },
        },
        {
            key: 'amenities',
        },
        {
            key: 'supplierIds',
            countFunction: (hotels) => {
                const dictionary = {};
                hotels.forEach((hotel) => {
                    hotel.suppliers.forEach((supplier) => {
                        if (!dictionary[supplier.supplierId]) {
                            dictionary[supplier.supplierId] = 0;
                        }
                        dictionary[supplier.supplierId]++;
                    });
                });
                return dictionary;
            },
        },
        {
            key: 'agreement',
            countFunction: (hotels) => {
                const dictionary = {};
                //forEach hotel
                hotels.forEach((hotel) => {
                    //forEach Supliers
                    const hotelAgreementType = _getHotelAgreementType(hotel.suppliers);
                    if (hotelAgreementType === AgreementType.Both) {
                        dictionary[AgreementType.Corp] ? dictionary[AgreementType.Corp]++ : (dictionary[AgreementType.Corp] = 1);
                        dictionary[AgreementType.Agency]
                            ? dictionary[AgreementType.Agency]++
                            : (dictionary[AgreementType.Agency] = 1);
                        return;
                    }
                    else if (!dictionary[hotelAgreementType]) {
                        dictionary[hotelAgreementType] = 0;
                    }
                    dictionary[hotelAgreementType]++;
                });
                return dictionary;
            },
        },
        {
            key: 'ecoLabels',
            countFunction: (hotels) => {
                const dictionary = {};
                dictionary['ecoCertificated'] = 0;
                hotels.forEach((hotel) => {
                    if (hotel.ecoLabels) {
                        dictionary['ecoCertificated']++;
                    }
                });
                return dictionary;
            },
        },
    ];
}
function _getHotelAgreementType(suppliers) {
    const count = [];
    suppliers.forEach((supplier) => {
        supplier.agreement.every((agreement) => {
            const key = agreement.type;
            count.push(key);
        });
    });
    if (!count.length) {
        return AgreementType.None;
    }
    else if (count.includes(AgreementType.Both) ||
        (count.includes(AgreementType.Corp) && count.includes(AgreementType.Agency))) {
        return AgreementType.Both;
    }
    else if (count.includes(AgreementType.Corp)) {
        return AgreementType.Corp;
    }
    else if (count.includes(AgreementType.Agency)) {
        return AgreementType.Agency;
    }
    return count[0];
}
function getHotelFilterRadio() {
    // return [9, 8, 7, null]
    return {
        key: 'rate',
        txt: 'review',
        items: { '9': 9, '8': 8, '7': 7, null: null },
    };
    //
}
function getHotelFilterEcoLabels() {
    return {
        key: 'ecoLabels',
        txt: 'eco',
        transKey: 'filter',
        items: { ecoCertificated: 'ecoCertificated' },
    };
}
function getHotelFilterPaymentMethods() {
    return {
        key: 'paymentMethods',
        txt: 'payment',
        items: utilService.getEnumToDictionary(hotelsConsts.PaymentMethods),
    };
}
function getHotelFilterCheckboxes() {
    return [
        {
            key: 'amenities',
            txt: 'amenities',
            items: utilService.getEnumToDictionary(hotelsConsts.AmenitiesHotel),
        },
        {
            key: 'agreement',
            txt: 'agreement',
            items: [AgreementType.None, AgreementType.Agency, AgreementType.Corp].reduce((acc, dataKey) => {
                acc[dataKey] = AgreementType[dataKey];
                return acc;
            }, {}),
        },
    ];
}
