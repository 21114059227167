import { amtrakService } from '../services/amtrak.service';
import { tripService } from '@/services/trip.service';
import { TripType } from '../models/consts';
import { loggerService } from '@/services/logger.service';
import { reminderService } from '@/services/reminder.service';
import { utilService } from '@/services/util.service';
//localStorage keys
const localStoragePrefix = 'amtrak.store';
const localStorageSearchOptions = `${localStoragePrefix}.searchOptions`;
//Private functions
function saveIAmtrakSearchOptions(options) {
    tripService.saveTripOptions(localStorageSearchOptions, options);
}
export const initialSearchOptions = () => ({
    type: TripType.RoundTrip.toString(),
    tripId: 0,
    numberOfPassengers: 1,
    tripType: TripType.RoundTrip,
    selectedCorporation: {},
    isReserch: false,
    segments: [
        { time: '', to: '', from: '', date: '' },
        { time: '', to: '', from: '', date: '' },
    ],
    promotionCode: '',
    passangerDiscountTypeTypes: [],
});
const initialState = () => ({
    searchOptions: initialSearchOptions(),
    amtrakAnswer: {},
    discountedPrice: {},
});
export const amtrakStore = {
    namespaced: true,
    state: initialState(),
    getters: {
        amtrakRails(state) {
            return state.amtrakAnswer?.responses || [];
        },
        amtrakAnswer(state) {
            return state.amtrakAnswer || {};
        },
        searchOptions(state) {
            return state.searchOptions;
        },
        discountedPrice(state) {
            return state.discountedPrice;
        },
    },
    mutations: {
        setAmtrakAnswer(state, { amtrakAnswer, isReturn = false }) {
            if (isReturn && !!amtrakAnswer && !!amtrakAnswer.responses) {
                const stateAnswer = utilService.deepClone(state.amtrakAnswer);
                stateAnswer.responses.push(amtrakAnswer.responses[0]);
                stateAnswer.returnCacheKey = amtrakAnswer.cacheKey;
                state.amtrakAnswer = stateAnswer;
            }
            else {
                state.amtrakAnswer = amtrakAnswer;
            }
        },
        setIAmtrakSearchOptions(state, { searchOptions }) {
            state.searchOptions = searchOptions;
            saveIAmtrakSearchOptions(searchOptions);
        },
        applyDiscounts(state, { discountedPrice }) {
            state.discountedPrice = discountedPrice;
        },
        clearDiscount(state) {
            state.discountedPrice = null;
        },
        setInitialState(state) {
            Object.assign(state, initialState());
        },
        clearReturnRailResults(state) {
            const stateAnswer = utilService.deepClone(state.amtrakAnswer);
            const firstResult = stateAnswer.responses[0];
            stateAnswer.responses = [];
            stateAnswer.responses.push(firstResult);
            stateAnswer.returnCacheKey = null;
            state.amtrakAnswer = stateAnswer;
        }
    },
    actions: {
        async loadAmtrak({ commit, state, rootState }) {
            const options = state.searchOptions;
            try {
                const amtrakAnswer = await amtrakService.searchAmtrak(options);
                // const amtrakAnswer: IAmtrakResult = require('./amtrakResults.json');
                commit({ type: 'setAmtrakAnswer', amtrakAnswer });
                const notifications = {
                    tripId: rootState.tripStore.trip.id,
                    user: { corporateId: rootState.authStore.user.corporationId },
                    searchData: options,
                };
                reminderService.getNotificationsAmtrakSearch(notifications);
            }
            catch (err) {
                loggerService.error(err);
                commit({ type: 'setAmtrakAnswer', amtrakAnswer: null });
            }
        },
        clearSearchData({ commit }) {
            commit({ type: 'setAmtrakAnswer', amtrakAnswer: null });
        },
        async calculateDiscount({ commit }, { discountRequest }) {
            try {
                const res = await amtrakService.amtrakPricing(discountRequest);
                commit({ type: 'applyDiscounts', discountedPrice: res });
                return res;
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async amtrakSelect({ dispatch }, { amtrakSelectOptions }) {
            try {
                const { tripId } = amtrakSelectOptions;
                const res = await amtrakService.amtrakSelect(amtrakSelectOptions);
                if (res.Success) {
                    await dispatch({ type: 'tripStore/loadTrip', tripId }, { root: true });
                }
                return res;
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadReturnRails({ dispatch, commit, state, rootState }, { selectedRailOptions }) {
            try {
                const amtrakAnswer = await amtrakService.searchAmtrak(selectedRailOptions);
                // const amtrakAnswer: IAmtrakResult = require('./amtrakResults.json');
                commit({ type: 'setAmtrakAnswer', amtrakAnswer, isReturn: true });
                const notifications = {
                    tripId: rootState.tripStore.trip.id,
                    user: { corporateId: rootState.authStore.user.corporationId },
                    searchData: selectedRailOptions,
                };
                reminderService.getNotificationsAmtrakSearch(notifications);
            }
            catch (err) {
                loggerService.error(err);
                commit({ type: 'setAmtrakAnswer', amtrakAnswer: null, isReturn: true });
            }
        },
        async clearReturnRails({ dispatch, commit, state, rootState }, { selectedRailOptions }) {
            commit({ type: 'clearReturnRailResults' });
        },
    },
};
