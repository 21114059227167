import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
import { format } from 'date-fns';
import { required } from '@vuelidate/validators';
import { utilService } from '@/services/util.service';
import { TripType } from '@/modules/products/flight/models/consts';
import { useVuelidate } from '@vuelidate/core';
import appDatepicker from '@/components/form/app-datepicker.vue';
import airportAutocomplete from '@/components/airport-autocomplete.vue';
import appDaterange from '@/components/form/app-daterange.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import appCarousel from '@/components/app-carousel.vue';
export default defineComponent({
    name: 'FlightStepper',
    components: {
        appDatepicker,
        airportAutocomplete,
        appDaterange,
        formValidationMsg,
        appCarousel,
    },
    props: {
        resultsIndex: {
            type: Number,
            default: 0,
        },
        selectedSegments: {
            type: Array,
            default: () => [],
        },
        isOnBySchedulePricingPage: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            isShowModify: false,
            markFields: false,
            isModifyingSegment: false,
            segmentToModifyIndex: 0,
            stepperOptions: {
                align: 'prev',
            },
            segmentToEdit: {
                options: {
                    origin: {},
                    destination: {},
                    departureDate: null,
                },
            },
        };
    },
    created() {
        eventBus.$on('closeModify', this.closeModify);
    },
    methods: {
        modifySegment(segmentIdx) {
            if (this.isModifyingSegment) {
                this.isModifyingSegment = false;
                return;
            }
            const opts = utilService.deepClone(this.searchOptions);
            const { origin, destination, arrivalDate, departureDate } = opts.flightRows[0];
            if (opts.tripType === TripType.multiCity) {
                this.segmentToEdit.options.origin = opts.flightRows[segmentIdx].origin;
                this.segmentToEdit.options.destination = opts.flightRows[segmentIdx].destination;
                this.segmentToEdit.options.departureDate = opts.flightRows[segmentIdx].departureDate;
            }
            else if (segmentIdx === 0) {
                // first round trip segment
                this.segmentToEdit.options.origin = origin;
                this.segmentToEdit.options.destination = destination;
                this.segmentToEdit.options.departureDate = departureDate;
            }
            else {
                // second round trip segment
                this.segmentToEdit.options.origin = destination;
                this.segmentToEdit.options.destination = origin;
                this.segmentToEdit.options.departureDate = arrivalDate;
            }
            this.isModifyingSegment = true;
            this.segmentToModifyIndex = segmentIdx;
        },
        getFormattedTime(dateTimeStr) {
            const dateFormat = 'HH:mm';
            return format(new Date(dateTimeStr), dateFormat);
        },
        handleSearchSegment() {
            this.isModifyingSegment = false;
            if (this.isValid()) {
                this.$emit('handleSearchSegment', this.segmentToModifyIndex, this.segmentToEdit);
            }
        },
        selectedOrigin(origin) {
            this.segmentToEdit.options.origin = origin;
        },
        handleChangeOrigin(val) {
            if (val !== this.segmentToEdit.options.origin.Name) {
                this.segmentToEdit.options.origin = {};
            }
        },
        selectedDestination(destination) {
            this.segmentToEdit.options.destination = destination;
        },
        handleChangeDestination(val) {
            if (val !== this.segmentToEdit.options.destination.Name) {
                this.segmentToEdit.options.destination = {};
            }
        },
        closeModify() {
            this.isModifyingSegment = false;
        },
        isValid() {
            this.markFields = true;
            return !this.v$.segmentToEdit.$invalid;
        },
    },
    computed: {
        tripType() {
            return TripType;
        },
        searchOptions() {
            return this.$store.getters['flightStore/searchOptions'];
        },
        flightsRes() {
            return this.$store.getters['flightStore/flightsRes'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
    validations() {
        return {
            segmentToEdit: {
                options: {
                    departureDate: { required },
                    origin: { required },
                    destination: { required },
                },
            },
        };
    },
});
