<template>
  <form @submit.prevent="emitVal">
    <div class="app-text-search flex">
      <el-input v-model="txt" @input="emitVal" :placeholder="placeholder">
        <template v-if="iconPath" #prefix>
          <span class="material-symbols-outlined">search</span>
        </template>
        <template v-if="hasRemoveBtn" #suffix>
          <span class="material-symbols-outlined is-clickable" @click="clearInput">close</span>
        </template>
      </el-input>
    </div>
  </form>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppTextSearch',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    iconPath: { type: String, default: '' },
    placeholder: { type: String },
    hasRemoveBtn: { type: Boolean, default: false },
  },
  data() {
    return {
      txt: this.modelValue,
    };
  },
  watch: {
    modelValue(newTxt) {
      this.txt = newTxt;
    },
  },
  methods: {
    emitVal() {
      this.$emit('update:modelValue', this.txt);
    },
    clearInput() {
      this.txt = '';
      this.emitVal();
    },
  },
});
</script>

<style lang="scss">
@import '@/assets/style/abstracts';

.app-text-search {
  .material-symbols-outlined {
    font-size: rem(20px);
    color: $gray-2300;
  }
}
</style>
