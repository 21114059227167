import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        justifications: { type: Array, default: () => [] },
        policyViolations: { type: Array, default: () => [] },
        showTooltip: { type: Boolean, default: true },
    },
    methods: {
        getJustificationTxt(justification) {
            // justification can have either a 'Text' field if its free text, or a 'Code' field if its a selected option.
            if (justification.Text) {
                return justification.Text;
            }
            const selectedOption = justification?.OutOfPolicyJustifications.find((options) => options.Code === justification.Code);
            return selectedOption.Text;
        },
    },
    computed: {
        hasMultipleJustifications() {
            return this.justificationsToShow.length > 1;
        },
        hasMultipleViolations() {
            return this.policyViolations.length > 1;
        },
        justificationsToShow() {
            return this.justifications.filter((justification) => justification.Code || justification.Text);
        },
    },
});
