import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
import { RoutesNames } from '@/router';
import { Products, QuoteStatus } from '@/types/consts';
import { utilService } from '@/services/util.service';
import flightResults from '@/modules/products/flight/views/flight-results/flight-results.vue';
export default defineComponent({
    name: 'CheckPrice',
    components: {
        flightResults,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        originalQuote: {
            type: Object,
            default: () => {
                return {};
            },
            required: true,
        },
    },
    data() {
        return {
            isProceedBtnLoading: false,
        };
    },
    methods: {
        searchAlternatives() {
            this.$router.push({
                name: RoutesNames.flightResults,
                query: { searchCacheKey: this.priceCheckResult.searchCacheKey },
            });
        },
        proceedWithSuggestedResult() {
            this.isProceedBtnLoading = true;
            eventBus.$emit('handleAddToCart', this.priceCheckResult.result, false, this.priceCheckResult.searchCacheKey);
            if (this.originalQuote.status !== QuoteStatus.Cancelled) {
                eventBus.$emit('cancelExpiredQuote', this.originalQuote);
            }
        },
        handleOpenFareRules() {
            if (this.priceCheckResult?.result) {
                eventBus.$emit('openFareRulesForFlightResult', this.priceCheckResult.result?.packageKey, this.priceCheckResult.searchCacheKey, this.originalQuote?.quoteId);
            }
            else {
                eventBus.$emit('openFareRulesForQuote', this.originalQuote?.quoteId);
            }
        },
        getCurrencyCode(currency) {
            return utilService.getCurrencyCode(currency);
        },
    },
    computed: {
        statusClass() {
            return utilService.getQuoteStatusClass(this.originalQuote);
        },
        getQuoteStatusText() {
            const statusLabel = 'product.status.' +
                (this.originalQuote.productStatusName.charAt(0) +
                    this.originalQuote.productStatusName.substring(1).toLowerCase());
            return this.$te(statusLabel) ? this.$t(statusLabel) : this.originalQuote.productStatusName;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        products() {
            return Products;
        },
        priceCheckResult() {
            return this.$store.getters['flightStore/checkPriceResult'];
        },
        getRouteTitle() {
            return `${this.originalQuote.segments[0].flights[0].origin} - ${this.originalQuote.segments[0].flights[this.originalQuote.segments[0].flights.length - 1].destCode}`;
        },
        getRouteDates() {
            return `${this.$filters.date(this.originalQuote.segments[0].flights[0].depDate, 'eee, dd MMM')} - ${this.$filters.date(this.originalQuote.segments[this.originalQuote.segments.length - 1].flights[this.originalQuote.segments[this.originalQuote.segments.length - 1].flights.length - 1].depDate, 'eee, dd MMM')}`;
        },
        getBrandNames() {
            const quoteToDisplay = this.priceCheckResult?.result ? this.priceCheckResult.result : this.originalQuote;
            const uniqueBrandNames = Array.from(new Set(quoteToDisplay.segments.map((s) => s.flights[0].brandName)));
            return uniqueBrandNames?.join(', ') || '';
        },
        getTitle() {
            return !this.priceCheckResult?.success
                ? this.$t('common.noAvailability')
                : this.isPriceChanged
                    ? this.$t('common.priceChange')
                    : this.$t('common.priceAvailable');
        },
        getSubTitle() {
            return this.isLoading
                ? this.$t('cart.priceCheck.checkingIfPriceAvailable')
                : !this.priceCheckResult?.success
                    ? this.$t('cart.priceCheck.priceNotAvailableDesc')
                    : this.isPriceChanged
                        ? this.$t('cart.priceCheck.priceAvailableAndChangedDesc')
                        : this.$t('cart.priceCheck.priceAvailableDesc');
        },
        isPriceChanged() {
            return (this.originalQuote.displayTotalPrice.toFixed(2) !==
                this.priceCheckResult?.result?.totalPriceDisplayCurrency.toFixed(2));
        },
    },
});
