// --------------[Search]-----------------
export var TripType;
(function (TripType) {
    TripType[TripType["OneWay"] = 0] = "OneWay";
    TripType[TripType["RoundTrip"] = 1] = "RoundTrip";
    TripType[TripType["MultiCity"] = 2] = "MultiCity";
})(TripType || (TripType = {}));
export const baseSegment = {
    time: '',
    from: '',
    to: '',
    date: '',
};
export var AmtrakDirections;
(function (AmtrakDirections) {
    AmtrakDirections["From"] = "from";
    AmtrakDirections["To"] = "to";
})(AmtrakDirections || (AmtrakDirections = {}));
// --------------[Results]-----------------
export var AmtrakAmenities;
(function (AmtrakAmenities) {
    AmtrakAmenities["Snack"] = "Snack";
    AmtrakAmenities["QuietCar"] = "QuietCar";
    AmtrakAmenities["Network"] = "Network";
    AmtrakAmenities["SeatAsgn"] = "SeatAsgn";
    AmtrakAmenities["Baggage"] = "Baggage";
    AmtrakAmenities["Lounge"] = "Lounge";
    AmtrakAmenities["Dining"] = "Dining";
    AmtrakAmenities["TrayMeal"] = "TrayMeal";
    AmtrakAmenities["WCRamp"] = "WCRamp";
})(AmtrakAmenities || (AmtrakAmenities = {}));
export var AmtrakAmenitiesLabels;
(function (AmtrakAmenitiesLabels) {
    AmtrakAmenitiesLabels["Snack"] = "Cafe";
    AmtrakAmenitiesLabels["Dining"] = "Traditional Dining";
    AmtrakAmenitiesLabels["QuietCar"] = "Quiet car";
    AmtrakAmenitiesLabels["Network"] = "Free Wi-Fi";
    AmtrakAmenitiesLabels["SeatAsgn"] = "Reserved Seats";
    AmtrakAmenitiesLabels["Baggage"] = "Checked Baggage";
    AmtrakAmenitiesLabels["Lounge"] = "Cafe";
    AmtrakAmenitiesLabels["TrayMeal"] = "Flexible Dining";
    AmtrakAmenitiesLabels["WCRamp"] = "Wheelchair Ramp";
})(AmtrakAmenitiesLabels || (AmtrakAmenitiesLabels = {}));
export var AmtrakSegmentInfoTypes;
(function (AmtrakSegmentInfoTypes) {
    AmtrakSegmentInfoTypes["Departure"] = "departure";
    AmtrakSegmentInfoTypes["Arrival"] = "arrival";
})(AmtrakSegmentInfoTypes || (AmtrakSegmentInfoTypes = {}));
export var AmtrakFilterTypes;
(function (AmtrakFilterTypes) {
    AmtrakFilterTypes["Price (Lowest to Highest)"] = "Price (Lowest to Highest)";
    AmtrakFilterTypes["Price (Highest to Lowest)"] = "Price (Highest to Lowest)";
    AmtrakFilterTypes["Depart (Ascending)"] = "Depart (Ascending)";
    AmtrakFilterTypes["Depart (Descending)"] = "Depart (Descending)";
    AmtrakFilterTypes["Arrive (Descending)"] = "Arrive (Descending)";
    AmtrakFilterTypes["Arrive (Ascending)"] = "Arrive (Ascending)";
    AmtrakFilterTypes["Duration (Descending)"] = "Duration (Descending)";
    AmtrakFilterTypes["Duration (Ascending)"] = "Duration (Ascending)";
    AmtrakFilterTypes["Number of Stops (Ascending)"] = "Number of Stops (Ascending)";
    AmtrakFilterTypes["Number of Stops (Descending)"] = "Number of Stops (Descending)";
})(AmtrakFilterTypes || (AmtrakFilterTypes = {}));
// --------------[Discounts]-----------------
export var AmtrakDiscountTypes;
(function (AmtrakDiscountTypes) {
    AmtrakDiscountTypes["Senior"] = "E";
    AmtrakDiscountTypes["Military-Adult"] = "M";
    AmtrakDiscountTypes["NARP"] = "AR";
    AmtrakDiscountTypes["Veterans Advantage"] = "VA";
})(AmtrakDiscountTypes || (AmtrakDiscountTypes = {}));
export var AmtrakLoyaltyCards;
(function (AmtrakLoyaltyCards) {
    AmtrakLoyaltyCards["NARP"] = "NARP";
    AmtrakLoyaltyCards["AGR"] = "AGR";
})(AmtrakLoyaltyCards || (AmtrakLoyaltyCards = {}));
// --------------[Contact Information]-----------------
export var ExtraTabs;
(function (ExtraTabs) {
    ExtraTabs["New"] = "0";
    ExtraTabs["Existing"] = "1";
})(ExtraTabs || (ExtraTabs = {}));
export var PhoneTypes;
(function (PhoneTypes) {
    PhoneTypes["BusinessPhone"] = "B";
    PhoneTypes["HomePhone"] = "H";
    PhoneTypes["CellularPhone"] = "C";
})(PhoneTypes || (PhoneTypes = {}));
export var AmtrakFrequentFlyerCarrier;
(function (AmtrakFrequentFlyerCarrier) {
    AmtrakFrequentFlyerCarrier["2V"] = "2V";
})(AmtrakFrequentFlyerCarrier || (AmtrakFrequentFlyerCarrier = {}));
// --------------[Cart]-----------------
export var AmtrakCancellationTypes;
(function (AmtrakCancellationTypes) {
    AmtrakCancellationTypes[AmtrakCancellationTypes["CreditCard"] = 1] = "CreditCard";
    AmtrakCancellationTypes[AmtrakCancellationTypes["E-Voucher"] = 2] = "E-Voucher";
    AmtrakCancellationTypes[AmtrakCancellationTypes["Forfeit"] = 3] = "Forfeit";
})(AmtrakCancellationTypes || (AmtrakCancellationTypes = {}));
export const BUS = 'BUS';
export const ACELA = 'acela';
export const TermsAndConditionsURL = 'https://www.amtrak.com/services/contentService.ibcontentpopup.terms-and-conditions.html';
