import { utilService } from '@/services/util.service';
import { defineComponent } from 'vue';
import format from 'date-fns/format';
export default defineComponent({
    name: 'AppToolTipHoursRestricted',
    props: {
        customClass: {
            // add a class to the tooltip
            type: String,
            default: 'hours-limitation-description',
        },
        expiredDate: {
            type: String,
        },
        isOfficeHours: {
            type: Boolean,
        },
    },
    computed: {
        expiredDateDisplayed() {
            if (!this.expiredDate) {
                return '';
            }
            const UtcDate = this.expiredDate;
            const dateFormat = 'dd MMM yy';
            const formattedDate = `${format(new Date(UtcDate), dateFormat)} ${utilService.getUserTimeFormat(format(new Date(UtcDate), 'HH:mm'))}`;
            return formattedDate;
        },
    },
});
