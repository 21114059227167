import { defineComponent } from 'vue';
import { PhoneFormat } from '@/types/consts';
import { email, requiredIf, numeric, alphaNum } from '@vuelidate/validators';
import { utilService } from '@/services/util.service';
import { useVuelidate } from '@vuelidate/core';
import appSelect from '@/components/app-select.vue';
import appAvatar from '@/components/app-avatar.vue';
import appDatepicker from '@/components/form/app-datepicker.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
export default defineComponent({
    name: 'ContactInfoForm',
    components: {
        appAvatar,
        appSelect,
        appDatepicker,
        formValidationMsg,
    },
    props: {
        currData: {
            type: Object,
        },
        nationalities: {
            type: Array,
        },
        USStates: {
            type: Array,
        },
        tripId: {
            type: Number,
            default: 0,
        },
        mandateFieldOptions: {
            type: Array,
            default: () => [],
        },
        mandateFields: {
            type: Array,
            default: () => [],
        },
        disableEdit: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            formData: {
                phone: '',
                mobileParams: ['', '', ''],
                faxParams: [],
                homePhoneParams: [],
                businessContactDetails: {
                    address: '',
                    city: '',
                    zipCode: '',
                    country: '',
                    state: '',
                    phoneParams: [],
                    fax: '',
                },
                personalContactDetails: {
                    address: '',
                    city: '',
                    zipCode: '',
                    country: '',
                    state: '',
                    phoneParams: [],
                    fax: '',
                },
                emergencyContact: {
                    name: '',
                    email: '',
                    mobileParams: ['', '', ''],
                },
            },
            markFields: false,
            isLoadingBtn: false,
        };
    },
    mounted() {
        this.setFormData();
        this.checkIsMandateField();
    },
    methods: {
        async handleSubmit() {
            this.markFields = true;
            if (this.v$.$invalid) {
                return;
            }
            this.isLoadingBtn = true;
            // Building object to match the phones format of '000-00-000000'
            const userContactInfo = {
                ...this.formData,
                mobile: this.formData.mobileParams?.join('-') || '',
                fax: this.formData.faxParams?.join('-') || '',
                homePhone: this.formData.homePhoneParams?.join('-') || '',
                phone: this.formData.businessContactDetails.phoneParams?.join('-') || '',
                businessContactDetails: {
                    ...this.formData.businessContactDetails,
                    phone: this.formData.businessContactDetails.phoneParams?.join('-') || '',
                },
                personalContactDetails: {
                    ...this.formData.personalContactDetails,
                    phone: this.formData.personalContactDetails?.phoneParams?.join('-') || '',
                },
                emergencyContact: {
                    ...this.formData.emergencyContact,
                    mobile: this.formData.emergencyContact?.mobileParams?.join('-') || '',
                },
            };
            try {
                await this.$store.dispatch({ type: 'userStore/saveContactInfo', userContactInfo, tripId: this.tripId });
                this.$emit('checkMandateFieldsStatus');
                this.$alert.success('alert.general.saved');
            }
            catch (err) {
                this.$alert.error('alert.general.error', err);
            }
            finally {
                this.isLoadingBtn = false;
            }
        },
        setFormData() {
            const copyData = utilService.deepClone(this.formData);
            const modifiedFormData = {
                ...copyData,
                mobileParams: utilService.splitPhoneToParts(this.currData.mobile),
                faxParams: utilService.splitPhoneToParts(this.currData.fax),
                homePhoneParams: utilService.splitPhoneToParts(this.currData.homePhone),
                businessContactDetails: {
                    ...copyData.businessContactDetails,
                    ...this.currData.businessContactDetails,
                    phoneParams: utilService.splitPhoneToParts(this.currData.phone),
                },
                personalContactDetails: {
                    ...copyData.personalContactDetails,
                    ...this.currData.personalContactDetails,
                    phoneParams: utilService.splitPhoneToParts(this.currData.personalContactDetails.phone),
                },
                emergencyContact: {
                    ...copyData.emergencyContact,
                    ...this.currData.emergencyContact,
                    mobileParams: utilService.splitPhoneToParts(this.currData.emergencyContact.mobile),
                },
            };
            this.formData = { ...copyData, ...modifiedFormData };
        },
        checkIsMandateField(fieldName) {
            const isMandateField = this.mandateFields.includes(fieldName);
            this.markFields = isMandateField;
            return isMandateField;
        },
    },
    computed: {
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isUSPhoneFormat() {
            return this.loggedinUser.preferences.phoneFormat === PhoneFormat.USFormat;
        },
        nationalityTypeOpts() {
            if (!this.nationalities) {
                return [];
            }
            return this.nationalities.map((n) => {
                return {
                    value: n.code,
                    label: `${n.name} (${n.code})`,
                };
            });
        },
        USStatesTypeOpts() {
            if (!this.USStates) {
                return [];
            }
            return this.USStates.map((s) => {
                return {
                    value: s.code,
                    label: `${s.name} (${s.code})`,
                };
            });
        },
        isMustFillPassengerPersonalDetails() {
            return this.loggedinUser.permissions.includes('MustFillPassengerPersonalDetails');
        },
    },
    validations() {
        return {
            formData: {
                mobileParams: {
                    validPhoneArray: utilService.arrayValidator([
                        requiredIf(() => {
                            return this.checkIsMandateField('Mobile');
                        }),
                        numeric,
                    ]),
                },
                faxParams: {
                    validPhoneArray: utilService.arrayValidator([
                        requiredIf(() => {
                            return this.checkIsMandateField('Fax');
                        }),
                        numeric,
                    ]),
                },
                homePhoneParams: {
                    validPhoneArray: utilService.arrayValidator([
                        requiredIf(() => {
                            return this.checkIsMandateField('Home Phone');
                        }),
                        numeric,
                    ]),
                },
                businessContactDetails: {
                    phoneParams: {
                        validPhoneArray: utilService.arrayValidator([
                            requiredIf(() => {
                                return this.checkIsMandateField('Business Phone');
                            }),
                            numeric,
                        ]),
                    },
                    city: {
                        isAlphaAndSpace: (value) => /^[a-zA-Z\s]*$/.test(value),
                    },
                },
                personalContactDetails: {
                    city: {
                        isAlphaAndSpace: (value) => /^[a-zA-Z\s]*$/.test(value),
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Personal Address City');
                        }),
                    },
                    address: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Personal Address Address');
                        }),
                    },
                    zipCode: {
                        alphaNum,
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Personal Address Zip code');
                        }),
                    },
                    country: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Personal Address Country');
                        }),
                    },
                },
                emergencyContact: {
                    mobileParams: {
                        validPhoneArray: utilService.arrayValidator([
                            requiredIf(() => {
                                return this.checkIsMandateField('Emergency Contact Mobile');
                            }),
                            numeric,
                        ]),
                    },
                    name: {
                        isAlphaAndSpace: (value) => /^[a-zA-Z\s]*$/.test(value),
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Emergency Contact Name');
                        }),
                    },
                    email: {
                        email,
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Emergency Contact E-mail');
                        }),
                    },
                },
                phoneType: {
                    required: requiredIf(() => {
                        return this.isMustFillPassengerPersonalDetails;
                    }),
                },
                birthDate: {
                    required: requiredIf(() => {
                        return this.isMustFillPassengerPersonalDetails;
                    }),
                },
            },
        };
    },
});
