import { defineComponent } from 'vue';
import { format } from 'date-fns';
import { utilService } from '@/services/util.service';
// this component is using timestamp or number of minutes as values and emits the values as recieved
export default defineComponent({
    props: {
        modelValue: { type: Array },
        min: { type: Number },
        max: { type: Number },
        title: { type: String },
        isShowMarks: { type: Boolean, default: false },
        isTimeStamp: { type: Boolean, default: true }, // false for number of minutes
    },
    data() {
        return {
            range: [],
            marks: {},
            needsTransformTranslate: false,
            format: format,
        };
    },
    created() {
        this.range = [...this.modelValue];
        this.setMarks(this.min, this.max);
    },
    methods: {
        setMarks(a, b) {
            if (this.isShowMarks) {
                this.marks[a] = this.isTimeStamp ? format(a, 'HH:mm') : utilService.formattedTime(a);
                this.marks[b] = this.isTimeStamp ? format(b, 'HH:mm') : utilService.formattedTime(b);
            }
        },
        emitVal() {
            this.marks = {};
            this.setMarks(this.range[0], this.range[1]);
            this.$emit('update:modelValue', this.range);
        },
    },
    computed: {
        startVal() {
            return this.isTimeStamp
                ? utilService.getUserTimeFormat(format(this.range[0], 'HH:mm'))
                : utilService.formattedTime(this.range[0]);
        },
        endVal() {
            return this.isTimeStamp
                ? utilService.getUserTimeFormat(format(this.range[1], 'HH:mm'))
                : utilService.formattedTime(this.range[1]);
        },
    },
    watch: {
        modelValue: {
            deep: true,
            handler(newVal) {
                this.range = [...newVal];
            },
        },
    },
});
