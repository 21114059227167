import { defineComponent } from 'vue';
import { config } from '@/config/config';
import { utilService } from '@/services/util.service';
export default defineComponent({
    name: 'StaySafeBanner',
    props: {
        destCountryCode: {
            type: [String, Number],
            required: true,
        },
    },
    methods: {
        redirectStaySafe() {
            const countryCode = this.destCountryCode;
            let staySafeUrl = '';
            if (!this.isMobile) {
                const baseUrl = config.staySafeUrl;
                const dutyOfCareToken = this.loggedinUser.dutyOfCareToken;
                staySafeUrl = `${baseUrl}/${countryCode}/${dutyOfCareToken}`;
                utilService.openNewWindow(true, staySafeUrl, '_blank');
            }
            else {
                const baseUrl = config.staySafeUrlMobileApp;
                staySafeUrl = `${baseUrl}/${countryCode}?customerToken=4RtVwX7Y2aPq9ZsL0KpJ`;
                window.location.href = staySafeUrl;
            }
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
    },
});
