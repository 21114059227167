import { defineComponent } from 'vue';
import { flightService } from '@/modules/products/flight/services/flight.service';
import { loggerService } from '@/services/logger.service';
export default defineComponent({
    name: 'BrandedFares',
    props: {
        brandOptions: {
            type: Object,
            required: true,
        },
        quoteId: {
            type: Number,
            default: null,
        },
        resultsIndex: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            activeBrandId: this.brandOptions.brandId || this.brandOptions.brandName,
            isLoading: false,
            isUnavailable: false,
            brandedFares: null,
            brandFeatures: null,
        };
    },
    async created() {
        await this.loadFares();
    },
    methods: {
        async loadFares() {
            this.isLoading = true;
            try {
                let res;
                if (!this.quoteId) {
                    // Branded fares load from flight-results
                    const searchData = {
                        guid: this.brandOptions.searchCacheKey ? this.brandOptions.searchCacheKey : this.cacheKey,
                        packageKey: this.brandOptions.packageKey,
                        brandId: this.brandOptions.brandId,
                        oneWayIndex: this.brandOptions.segmentIndex,
                        tripId: this.trip.id,
                    };
                    res = await flightService.getBrandedFares(searchData);
                    this.brandedFares = res.brandsDetails;
                    this.brandFeatures = res.brandFeatures;
                }
                else {
                    // Branded fares load from Cart
                    const brandId = this.brandOptions.brandId;
                    const carrier = this.brandOptions.carrier;
                    const brandName = this.brandOptions.brandName;
                    res = await flightService.getQuoteBrandedFares(this.trip.id, this.quoteId, brandId, carrier, brandName);
                    this.brandedFares = res.brandsDetails;
                    this.brandFeatures = res.brandFeatures;
                }
                if (!res.brandsDetails) {
                    this.isUnavailable = true;
                    return;
                }
                const brandName = this.brandOptions.brandName?.toLowerCase();
                const brandId = this.brandOptions?.brandId?.toLowerCase();
                // check if and name or id matches the options
                const selectedTab = this.brandedFares?.find((brand) => (brandId && brand.brandId?.toLowerCase() === brandId) ||
                    (brandName && brand.brandName?.toLowerCase() === brandName));
                // detect whether its the id or the name that matches, and move accordingly
                if (selectedTab) {
                    if (selectedTab.brandId === this.brandOptions?.brandId) {
                        this.activeBrandId = selectedTab.brandId;
                    }
                    else {
                        this.activeBrandId = selectedTab.brandName;
                    }
                }
                else {
                    // If the current tab does not exist in the response - move to first tab
                    this.activeBrandId = this.brandedFares[0].brandId || this.brandedFares[0].brandName;
                }
            }
            catch (err) {
                loggerService.error(err);
                this.isUnavailable = true;
            }
            finally {
                this.isLoading = false;
            }
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        cacheKey() {
            return this.$store.getters['flightStore/cacheKey'](this.resultsIndex);
        },
        hasIncludedItems() {
            // sometimes we will receive array -> [null] -> in this case we dont want to show the title
            if (this.brandedFares) {
                return this.brandedFares.some((brand) => {
                    return brand.included?.some((item) => item);
                });
            }
            return false;
        },
        noBrandResults() {
            // if unavailable or that the brand info is empty
            if (this.isUnavailable) {
                return true;
            }
            else if (this.brandedFares) {
                // check if every brand is empty - CHECK
                return this.brandedFares.every((brand) => !brand.included?.length && !brand.notIncluded?.length && !brand.additionalCharge?.length);
            }
            return false;
        },
        noBrandFeatures() {
            if (this.brandFeatures === null) {
                return true;
            }
            return false;
        },
        brandNameLowerCase() {
            return this.brandFeatures[0].brandName.toLowerCase();
        },
    },
});
