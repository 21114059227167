import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2a8587b5"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "booking-response-errors" };
const _hoisted_2 = {
    key: 0,
    class: "my-3"
};
const _hoisted_3 = {
    key: 1,
    class: "my-3"
};
const _hoisted_4 = { class: "muliBold" };
const _hoisted_5 = { class: "muliRegular" };
const _hoisted_6 = {
    key: 2,
    class: "my-3"
};
const _hoisted_7 = { class: "muliBold" };
const _hoisted_8 = {
    key: 0,
    class: "muliRegular"
};
const _hoisted_9 = {
    key: 1,
    class: "muliRegular"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_booking_response_preview = _resolveComponent("booking-response-preview");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.quotesMap.successItems?.length)
            ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quotesMap.successItems, (item) => {
                    return (_openBlock(), _createElementBlock("section", {
                        key: item.res.quoteId,
                        class: "my-2 preview-container"
                    }, [
                        _createVNode(_component_booking_response_preview, {
                            response: item.res,
                            quote: item.quote
                        }, null, 8 /* PROPS */, ["response", "quote"])
                    ]));
                }), 128 /* KEYED_FRAGMENT */))
            ]))
            : _createCommentVNode("v-if", true),
        (_ctx.quotesMap.changedItems?.length)
            ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
                _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.$t('common.priceChange')), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('flight.changedPrice')), 1 /* TEXT */),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quotesMap.changedItems, (item) => {
                    return (_openBlock(), _createElementBlock("section", {
                        key: item.res.quoteId,
                        class: "my-3 preview-container"
                    }, [
                        _createVNode(_component_booking_response_preview, {
                            response: item.res,
                            quote: item.quote
                        }, null, 8 /* PROPS */, ["response", "quote"])
                    ]));
                }), 128 /* KEYED_FRAGMENT */))
            ]))
            : _createCommentVNode("v-if", true),
        (_ctx.quotesMap.failedItems?.length)
            ? (_openBlock(), _createElementBlock("section", _hoisted_6, [
                _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.$t('trip.bookingError')), 1 /* TEXT */),
                (_ctx.quotesMap.failedItems?.[0]?.quote)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('common.failedItems')), 1 /* TEXT */))
                    : (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t('common.failedItemsPartial')), 1 /* TEXT */)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quotesMap.failedItems, (item) => {
                    return (_openBlock(), _createElementBlock("section", {
                        key: item.res.quoteId,
                        class: "my-3 preview-container"
                    }, [
                        _createVNode(_component_booking_response_preview, {
                            response: item.res,
                            quote: item.quote
                        }, null, 8 /* PROPS */, ["response", "quote"])
                    ]));
                }), 128 /* KEYED_FRAGMENT */))
            ]))
            : _createCommentVNode("v-if", true)
    ]));
}
