import { format, getTime, addMinutes, subMinutes } from 'date-fns';
import * as flightConsts from '@/modules/products/flight/models/consts';
import { flightService } from '@/modules/products/flight/services/flight.service';
import { utilService } from '@/services/util.service';
import { tripService } from '@/services/trip.service';
import { reminderService } from '@/services/reminder.service';
import { $t } from '@/plugins/i18n';
import { FlightStops, SortByTypes } from '@/modules/products/flight/models/consts';
import { ActionTypes, PolicyViolationCodes, Products } from '@/types/consts';
import { router, RoutesNames } from '@/router';
import { loggerService } from '@/services/logger.service';
import { eventBus } from '@/services/event-bus.service';
import { LocationType } from '../../hotels/models/consts';
import { alertService } from '@/services/alert.service';
const initialFilter = () => {
    return {
        page: 1,
        q: '',
        stops: [],
        inPolicy: false,
        sortBy: 'best',
        sortByOptions: {},
        priceRange: [0, 0],
        priceMinMax: [0, 0],
        emissionsAverage: 0,
        departTimeRange: {},
        departTimeMinMax: {},
        arriveTimeRange: {},
        arriveTimeMinMax: {},
        durationRange: {},
        durationMinMax: {},
        stopsDurationRange: {},
        stopsDurationMinMax: {},
        agreementTypes: [],
        availableAgreementTypes: [],
        availableCarriers: [],
        carriers: [],
        availableAdvisories: {},
        advisories: {},
        availableAirports: {},
        airports: {},
    };
};
export const initialSearchOptions = () => ({
    flightRows: [
        {
            departureDate: '',
            arrivalDate: '',
            origin: {},
            connection: {},
            returnConnection: {},
            destination: {},
            via: false,
            class: 'Y',
            returnClass: 'Y',
            returnVia: null,
            dateType: flightConsts.DateType.Departure,
            returnDateType: flightConsts.DateType.Departure,
            flightTime: flightConsts.FlightTimeOptions.Anytime.text,
            returnFlightTime: flightConsts.FlightTimeOptions.Anytime.text,
        },
    ],
    numberOFTravelers: 1,
    travelerTypeInfo: [],
    noOfStops: flightConsts.NoOfStops['Up to one stop'],
    allowNearbyAirports: true,
    dontAllowAnytime: false,
    tripType: 1,
    searchFor: 'completeTrip',
    isAdvancedSearch: false,
});
const initialState = () => ({
    flightsRes: null,
    euRailOptionsRes: null,
    searchOptions: initialSearchOptions(),
    filteredFlights: [],
    filterBy: initialFilter(),
    comparableFilters: null,
    activeFiltersAmount: 0,
    resultMap: {},
    flightSeatMap: {},
    selectedSeats: [],
    cacheSelectedSeats: {},
    parallelSearchData: [],
    parallelHotelSearchesOptions: [],
    parallelCarSearchesOptions: {},
    isHotelParallelSearchActive: false,
    isCarParallelSearchActive: false,
    parallelSearchCacheKeys: [],
    addedQuoteId: null,
    searchData: {},
    cityAirportMap: {},
    allAirports: {},
    allCarriers: [],
    checkPriceResult: {},
});
//localStorage keys
const localStoragePrefix = 'flight.store';
const localStorageFilters = `${localStoragePrefix}.filters`;
const localStorageSearchOptions = `${localStoragePrefix}.searchOptions`;
// TODO: maybe better to put in flight and not tripService
function saveFlightSearchOptions(searchOptions) {
    tripService.saveTripOptions(localStorageSearchOptions, searchOptions);
}
function saveFlightFilter(filter) {
    tripService.saveTripOptions(localStorageFilters, filter);
}
function getFlightSearchOptionsFromStorage() {
    return tripService.loadFromStorage()?.[localStorageSearchOptions] || null;
}
function calculateActiveFilters(filterBy, comparableFilters) {
    const filter = filterBy;
    let activeFiltersCounter = 0;
    if (filter.q) {
        activeFiltersCounter++;
    }
    if (comparableFilters !== null) {
        if (comparableFilters.initialPriceRange[0] !== filter.priceRange[0] ||
            comparableFilters.initialPriceRange[1] !== filter.priceRange[1]) {
            activeFiltersCounter++;
        }
    }
    return activeFiltersCounter;
}
function initializeFilterByAirports(airportsFilter, availableAirports, searchOptions) {
    const isBySchedule = searchOptions?.searchFor === flightConsts.FlightSearchTypes.bySchedule;
    const airports = isBySchedule ? {} : utilService.deepClone(airportsFilter);
    Object.keys(availableAirports)?.forEach((index) => {
        if (!airports[index]) {
            airports[index] = {};
        }
    });
    return airports;
}
export const flightStore = {
    namespaced: true,
    state: initialState(),
    getters: {
        resultMap(state) {
            return state.resultMap;
        },
        flightsRes(state) {
            return state.flightsRes;
        },
        cacheKey: (state) => (resultsIndex = 0) => {
            return state.flightsRes?.[resultsIndex]?.cacheKey;
        },
        filteredFlights(state) {
            return state.filteredFlights;
        },
        page(state) {
            return state.filterBy.page;
        },
        searchOptions(state) {
            return state.searchOptions;
        },
        flightFilter(state) {
            return state.filterBy;
        },
        cityAirportMap(state) {
            return state.cityAirportMap;
        },
        comparableFilters(state) {
            return state.comparableFilters;
        },
        activeFiltersAmount(state) {
            return state.activeFiltersAmount;
        },
        flightSeatMap(state) {
            return state.flightSeatMap;
        },
        selectedSeats(state) {
            return state.selectedSeats;
        },
        cacheSelectedSeats(state) {
            return state.cacheSelectedSeats;
        },
        storageSearchOptions() {
            return getFlightSearchOptionsFromStorage();
        },
        parallelSearchData(state) {
            return state.parallelSearchData;
        },
        parallelHotelSearchesOptions(state) {
            return state.parallelHotelSearchesOptions;
        },
        isHotelParallelSearchActive(state) {
            return state.isHotelParallelSearchActive;
        },
        parallelCarSearchesOptions(state) {
            return state.parallelCarSearchesOptions;
        },
        isCarParallelSearchActive(state) {
            return state.isCarParallelSearchActive;
        },
        searchData(state) {
            return state.searchData;
        },
        allAirports(state) {
            return state.allAirports;
        },
        allCarriers(state) {
            return state.allCarriers;
        },
        checkPriceResult(state) {
            return state.checkPriceResult;
        },
    },
    mutations: {
        setFlightsRes(state, { flightsRes, resultMap }) {
            state.flightsRes = flightsRes;
            state.resultMap = resultMap;
        },
        setResultMap(state, { resultMap }) {
            state.resultMap = resultMap;
        },
        setSearchOptions(state, { searchOptions }) {
            state.searchOptions = searchOptions;
            saveFlightSearchOptions(searchOptions);
        },
        setFlightSearchSearchData(state, { searchData }) {
            state.searchData = searchData;
        },
        setFilteredFlights(state, { filteredFlights }) {
            state.filteredFlights = filteredFlights;
        },
        setAddedQuoteId(state, { quoteId }) {
            state.addedQuoteId = quoteId;
        },
        setFlightParallelSearchData(state, { parallelSearchRes }) {
            state.parallelSearchData = parallelSearchRes;
        },
        setHotelParallelSearchesOptions(state, { parallelHotelSearchesOptions }) {
            state.parallelHotelSearchesOptions = parallelHotelSearchesOptions;
        },
        setIsHotelParallelSearchActive(state, { isHotelParallelSearchActive }) {
            state.isHotelParallelSearchActive = isHotelParallelSearchActive;
        },
        setCarParallelSearchOptions(state, { parallelCarSearchesOptions }) {
            state.parallelCarSearchesOptions = parallelCarSearchesOptions;
        },
        setIsCarParallelSearchActive(state, { isCarParallelSearchActive }) {
            state.isCarParallelSearchActive = isCarParallelSearchActive;
        },
        setParallelSearchCacheKeys(state, { parallelSearchCacheKeys }) {
            state.parallelSearchCacheKeys = parallelSearchCacheKeys;
        },
        setPage(state, { page }) {
            state.filterBy.page = page;
            saveFlightFilter(state.filterBy);
        },
        resetFilter(state) {
            state.filterBy = { ...state.filterBy, ...initialFilter() };
            state.filterBy.airports = initializeFilterByAirports(state.filterBy.airports, state.filterBy.availableAirports, state.searchOptions);
            saveFlightFilter(state.filterBy);
        },
        setFilter(state, { filterBy }) {
            state.filterBy = { ...state.filterBy, ...filterBy, page: 1 };
            saveFlightFilter(state.filterBy);
            const activeFiltersAmount = calculateActiveFilters(state.filterBy, state.comparableFilters);
            state.activeFiltersAmount = activeFiltersAmount;
        },
        setCityAirportMap(state, { cityNamesMap }) {
            state.cityAirportMap = cityNamesMap;
        },
        setComparableFilters(state, { comparableFilters }) {
            state.comparableFilters = comparableFilters;
            // set activeFiltersAmount:
            const activeFiltersAmount = calculateActiveFilters(state.filterBy, comparableFilters);
            state.activeFiltersAmount = activeFiltersAmount;
        },
        setFlightSeatMap(state, { flightSeatMapRes, key }) {
            state.flightSeatMap[key] = flightSeatMapRes;
        },
        setSelectedSeats(state, { selectedSeats, cacheSelectedSeats, deletedCached, packageKey = null, quoteId = null, isClosing = false }) {
            state.selectedSeats = selectedSeats;
            const key = quoteId || packageKey;
            if (deletedCached && key) {
                const updatedCachedSelectedSeats = state.cacheSelectedSeats;
                delete updatedCachedSelectedSeats[key];
                state.cacheSelectedSeats = updatedCachedSelectedSeats;
                return;
            }
            if (key) {
                state.cacheSelectedSeats[key] = cacheSelectedSeats;
            }
            if (isClosing) {
                state.cacheSelectedSeats = [];
            }
        },
        setInitialState(state) {
            Object.assign(state, initialState());
        },
        setAllAirports(state, { allAirports }) {
            state.allAirports = allAirports;
        },
        setFlightCarriers(state, { carriers }) {
            state.allCarriers = carriers;
        },
        setCheckPriceResult(state, { checkPriceResult }) {
            state.checkPriceResult = checkPriceResult;
        },
        // update a flight item with both in the res obj and in the filtered flights array
        updateFlightPackage(state, { flightPackage, resultsIndex }) {
            if (flightPackage) {
                const idx = state.flightsRes[resultsIndex].results.findIndex((f) => f.packageKey === flightPackage.packageKey);
                state.flightsRes[resultsIndex].results.splice(idx, 1, flightPackage);
                const filteredIdx = state.filteredFlights.findIndex((f) => f.packageKey === flightPackage.packageKey);
                state.filteredFlights.splice(filteredIdx, 1, flightPackage);
            }
        },
        updateFlightViolations(state, { flightPackage, resultsIndex, violation, quoteViolations }) {
            // update the violations in both res obj and in the filtered flights array
            if (flightPackage) {
                const idx = state.flightsRes[resultsIndex].results.findIndex((f) => f.packageKey === flightPackage.packageKey);
                const filteredIdx = state.filteredFlights.findIndex((f) => f.packageKey === flightPackage.packageKey);
                // not found (e.g. pricing options)
                if (idx < 0 || filteredIdx < 0) {
                    return;
                }
                // edit the items
                const flightItem = state.flightsRes[resultsIndex].results[idx];
                const filteredFlightItem = state.filteredFlights[filteredIdx];
                flightItem.isRestricTravelPolicyViolation = violation.ActionType === ActionTypes.RestrictSelection;
                filteredFlightItem.isRestricTravelPolicyViolation = violation.ActionType === ActionTypes.RestrictSelection;
                flightItem.travelPolicyViolations = [];
                filteredFlightItem.travelPolicyViolations = [];
                quoteViolations.forEach((violation) => {
                    flightItem.travelPolicyViolations.push(violation.TravelPolicyViolationExplanation.split(';')[0]);
                    filteredFlightItem.travelPolicyViolations.push(violation.TravelPolicyViolationExplanation.split(';')[0]);
                });
                flightItem.capacityRestrictionViolation = violation;
                filteredFlightItem.capacityRestrictionViolation = violation;
            }
        },
    },
    actions: {
        async handleFlightTravelPolicy({ rootState, commit, dispatch }, { flightResult, searchCacheKey, resultsIndex = 0 }) {
            // Check if the passenger has the flight capacity violation flag
            if (rootState.tripStore.trip?.passengers?.find((p) => p.isPrimary && p.hasFlightCapacityPolicy)) {
                // this function checks for restrict flight travel violations, if found it returns the data for it
                const selectedFlightResultCopy = utilService.deepClone(flightResult);
                const flightTravelPolicyRequest = {
                    packageCacheKey: flightResult.packageKey,
                    searchCacheKey,
                    quoteId: 0,
                    tripId: rootState.tripStore.trip.id,
                };
                try {
                    const quoteViolations = await flightService.flightTravelPolicy(flightTravelPolicyRequest);
                    if (quoteViolations?.length) {
                        const violation = quoteViolations.find((v) => v.TravelPolicyParameters.some((p) => p === PolicyViolationCodes.FlightCapacityPolicy));
                        if (violation) {
                            commit({
                                type: 'updateFlightViolations',
                                flightPackage: flightResult,
                                resultsIndex,
                                violation,
                                quoteViolations,
                            });
                            const violationData = {
                                violations: violation,
                                flightPackage: selectedFlightResultCopy,
                            };
                            return violationData;
                        }
                    }
                }
                catch (err) {
                    // should throw here and catch in the cmp to present error to user, not in scope atm but needed.
                    loggerService.error(err);
                }
            }
            //  if we dont have any violations
            return null;
        },
        async setSelectedSeats({ commit }, { selectedSeats, flightKey, deletedCached, packageKey, quoteId, isClosing }) {
            selectedSeats = utilService.deepClone(selectedSeats);
            if (selectedSeats.length) {
                const selectedSeatsArray = selectedSeats.map((segment) => {
                    if (segment) {
                        return segment.map((pax) => {
                            return {
                                paxId: pax.passengerId,
                                passengerIndex: pax.passengerIdx,
                                seat: pax.rowNumber + pax.seatNumber,
                                flightNumber: pax.flight.flightNumber,
                                carrier: pax.flight.carrier,
                                origin: pax.flight.origin,
                                seatMapCacheKey: pax.seatMapCacheKey,
                                oopReason: pax.oopReason,
                                isOOP: pax.isOOP,
                                isOOPRestricted: pax.isOOPRestricted,
                            };
                        });
                    }
                });
                commit({
                    type: 'setSelectedSeats',
                    selectedSeats: selectedSeatsArray,
                    cacheSelectedSeats: selectedSeats,
                    deletedCached,
                    packageKey,
                    quoteId,
                    isClosing,
                });
            }
            else {
                commit({ type: 'setSelectedSeats', selectedSeats: [], cacheSelectedSeats: [], deletedCached, isClosing });
            }
        },
        async getSelectedSeats({ commit, getters }, { quoteId }) {
            const res = await flightService.getFlightSeats(quoteId);
            return res;
        },
        async getFlightCarriers({ commit }) {
            const carriers = await flightService.getFlightCarriers();
            const res = [];
            for (const code in carriers) {
                const airline = {
                    code: code,
                    name: carriers[code],
                };
                res.push(airline);
            }
            res.shift(); // remove first element - $type string
            commit({ type: 'setFlightCarriers', carriers: res });
        },
        async getFlightParallelSearch({ commit }, { destinationCodes, productName }) {
            const parallelSearchRes = await flightService.getFlightParallelSearch(destinationCodes, productName);
            commit({ type: 'setFlightParallelSearchData', parallelSearchRes });
        },
        async getFlightSeatMap({ commit, getters, rootState }, { leg, packageKey, isCart = false, quoteId = null, resultsIndex = 0, pnr = null }) {
            const cacheKey = getters.cacheKey(resultsIndex);
            let seatMapReq = {};
            if (isCart) {
                seatMapReq = {
                    quoteId: quoteId,
                    tripId: rootState.tripStore.trip.id,
                    flightNumber: leg.flightNumber,
                    carrier: leg.carrier,
                    destination: leg.destCode,
                    origin: leg.origin,
                };
            }
            else {
                seatMapReq = {
                    searchCacheKey: cacheKey,
                    packageKey: packageKey,
                    tripId: rootState.tripStore.trip.id,
                    flightNumber: leg.flightNumber,
                    carrier: leg.carrier,
                    destination: leg.destCode,
                    origin: leg.origin,
                };
            }
            const flightKey = `${pnr}-${leg.carrier}-${leg.flightNumber}-${leg.origin}-${leg.destCode}-${leg.flightClass}-${leg.depDate.split('T')[0]}`;
            try {
                const seatMapRes = await flightService.getSeatMap(seatMapReq);
                if (seatMapRes.success) {
                    commit({ type: 'setFlightSeatMap', flightSeatMapRes: seatMapRes, key: flightKey });
                }
                return seatMapRes;
            }
            catch (err) {
                loggerService.error(err);
                commit({ type: 'setFlightSeatMap', flightSeatMapRes: null, key: flightKey });
            }
        },
        async saveSeatsFromCart({ commit, rootState }, { selectedSeats, flight, quoteId }) {
            const segmentsSelectedSeatsArray = [];
            selectedSeats.forEach((segment, segmentIdx) => {
                if (segment.length) {
                    const seats = [];
                    segment.forEach((pax, paxIdx) => {
                        seats.push({
                            seatNumber: pax.rowNumber + pax.seatNumber,
                            paxId: pax.passengerId,
                            passengerIndex: paxIdx,
                            seatMapCacheKey: pax.seatMapCacheKey,
                        });
                    });
                    segmentsSelectedSeatsArray.push({
                        quoteId: quoteId,
                        tripId: rootState.tripStore.trip.id,
                        seatMapCacheKey: selectedSeats[segmentIdx][0]?.seatMapCacheKey,
                        seats: seats,
                        segmentNumber: segmentIdx + 1,
                        flightNumber: selectedSeats[segmentIdx][0]?.flight?.flightNumber,
                        carrier: selectedSeats[segmentIdx][0]?.flight?.carrier,
                    });
                }
            });
            try {
                commit({
                    type: 'setSelectedSeats',
                    selectedSeats: segmentsSelectedSeatsArray,
                    cacheSelectedSeats: selectedSeats,
                    deletedCached: false,
                });
                const res = await flightService.saveSeatsFromCart(segmentsSelectedSeatsArray);
                return res;
            }
            catch (err) {
                loggerService.error(err);
                return null;
            }
        },
        async recalculateQuickSortBy({ commit, getters }, { filterBy, isRecalculateSortByOnFilteredFlights = false, cFlights }) {
            const { filteredFlights } = getters;
            const flightsForQuickSort = isRecalculateSortByOnFilteredFlights
                ? utilService.deepClone(filteredFlights)
                : cFlights;
            // Calculate quicksort - Best
            const bestFlight = flightsForQuickSort.reduce((acc, flightResult) => {
                if (!acc.joy || acc.joy < flightResult.joy) {
                    acc.joy = flightResult.joy;
                    acc.price = flightResult.totalPriceDisplayCurrency;
                    acc.currency = flightResult.displayCurrency;
                    acc.duration = flightResult.segments.reduce((acc2, segment) => {
                        acc2 += segment.segmentDuration.totalMinutes;
                        return acc2;
                    }, 0);
                }
                return acc;
            }, { joy: 0, price: 0, duration: 0, currency: '' });
            filterBy.sortByOptions.best = {
                label: $t('filter.best'),
                value: 'best',
                price: bestFlight.price.toFixed(2),
                currency: bestFlight.currency,
                duration: bestFlight.duration,
            };
            // Calculate quicksort - Cheapest
            const cheapestFlight = flightsForQuickSort.reduce((acc, flightResult) => {
                if (!acc.price || acc.price > flightResult.totalPriceDisplayCurrency) {
                    acc.price = flightResult.totalPriceDisplayCurrency;
                    acc.currency = flightResult.displayCurrency;
                    acc.duration = flightResult.segments.reduce((acc2, segment) => {
                        acc2 += segment.segmentDuration.totalMinutes;
                        return acc2;
                    }, 0);
                }
                return acc;
            }, { price: 0, duration: 0, currency: '' });
            filterBy.sortByOptions.cheapest = {
                label: $t('filter.cheapest'),
                value: 'cheapest',
                price: cheapestFlight.price.toFixed(2),
                currency: cheapestFlight.currency,
                duration: cheapestFlight.duration,
            };
            // Calculate quicksort - Quickest
            const quickestFlight = flightsForQuickSort.reduce((acc, flightResult) => {
                const segmentsDuration = flightResult.segments.reduce((acc2, segment) => {
                    acc2 += segment.segmentDuration.totalMinutes;
                    return acc2;
                }, 0);
                if (!acc.duration || acc.duration > segmentsDuration) {
                    acc.duration = segmentsDuration;
                    acc.price = flightResult.totalPriceDisplayCurrency;
                    acc.currency = flightResult.displayCurrency;
                }
                return acc;
            }, { price: 0, duration: 0, currency: '' });
            filterBy.sortByOptions.quickest = {
                label: $t('filter.quickest'),
                value: 'quickest',
                price: quickestFlight.price.toFixed(2),
                currency: quickestFlight.currency,
                duration: quickestFlight.duration,
            };
            if (isRecalculateSortByOnFilteredFlights) {
                commit({ type: 'setFilter', filterBy });
                return;
            }
            else {
                return filterBy;
            }
        },
        async recalculateFilters({ dispatch, commit, getters }, { applyFilter = true, isRecalculateSortByOnFilteredFlights = false, modifiedFilterBy, resultsIndex = 0, recalculateCountFunction = false, isReset = false, }) {
            // The applyFilter parameter states if we want to apply the recalculate filter right away (as setting it in the store)
            // or just return the recalculated filter (for example flight filter in mobile that wants to show the recalculated filter but not apply it before the
            // apply btn is pressed)
            let filterBy = isRecalculateSortByOnFilteredFlights
                ? utilService.deepClone(modifiedFilterBy)
                : utilService.deepClone(initialFilter());
            const flightResults = getters.flightsRes[resultsIndex].results;
            if (flightResults !== null && flightResults.length > 0) {
                const cFlights = utilService.deepClone(flightResults);
                if (isRecalculateSortByOnFilteredFlights) {
                    await dispatch({ type: 'recalculateQuickSortBy', filterBy, isRecalculateSortByOnFilteredFlights });
                    return;
                }
                else {
                    filterBy = await dispatch({
                        type: 'recalculateQuickSortBy',
                        filterBy,
                        isRecalculateSortByOnFilteredFlights,
                        cFlights,
                    });
                }
                const isBySchedule = getters.searchOptions?.searchFor === flightConsts.FlightSearchTypes.bySchedule;
                const filterData = isBySchedule ? getters.flightsRes[resultsIndex].filter : getters.flightsRes[0].filter;
                if (isBySchedule) {
                    filterBy.sortBy = SortByTypes.DepartureAscending;
                }
                // Co2 emissions average
                filterBy.emissionsAverage = isBySchedule
                    ? getters.flightsRes[resultsIndex].co2EmissionAVG
                    : getters.flightsRes[0].co2EmissionAVG;
                // only in bySchedule will need to access the relevant index
                const airportFilterData = filterData.cityPair;
                // create  a names map - if the filters need the name of the dest e.g. : {NYC:'New York' , LGW : 'London'} etc...
                const cityNamesMap = airportFilterData.reduce((acc, flightAirportsData) => {
                    flightAirportsData.destination.forEach((airport) => {
                        if (!acc[airport.code]) {
                            acc[airport.code] = flightAirportsData.destinationMultiName;
                        }
                    });
                    flightAirportsData.origin.forEach((airport) => {
                        if (!acc[airport.code]) {
                            acc[airport.code] = flightAirportsData.originMultiName;
                        }
                    });
                    if (!acc[flightAirportsData.destinationCode]) {
                        acc[flightAirportsData.destinationCode] = flightAirportsData.destinationMultiName;
                    }
                    if (!acc[flightAirportsData.destinationCodeMulti]) {
                        acc[flightAirportsData.destinationCodeMulti] = flightAirportsData.destinationMultiName;
                    }
                    if (!acc[flightAirportsData.originCode]) {
                        acc[flightAirportsData.originCode] = flightAirportsData.originMultiName;
                    }
                    if (!acc[flightAirportsData.originCodeMulti]) {
                        acc[flightAirportsData.originCodeMulti] = flightAirportsData.originMultiName;
                    }
                    return acc;
                }, {});
                commit({ type: 'setCityAirportMap', cityNamesMap });
                //Calculate min/max prices
                const minMaxPrice = utilService.minMax(cFlights, (f) => {
                    return f.totalPriceDisplayCurrency;
                });
                minMaxPrice[1] = Math.round(minMaxPrice[1]) + 1;
                minMaxPrice[0] = Math.max(Math.round(minMaxPrice[0]) - 1, 0);
                filterBy.priceMinMax = minMaxPrice;
                filterBy.priceRange = minMaxPrice;
                // Calculate quicksort - Best
                const bestFlight = cFlights.reduce((acc, flightResult) => {
                    if (!acc.joy || acc.joy < flightResult.joy) {
                        acc.joy = flightResult.joy;
                        acc.price = flightResult.totalPriceDisplayCurrency;
                        acc.currency = flightResult.displayCurrency;
                        acc.duration = flightResult.segments.reduce((acc2, segment) => {
                            acc2 += segment.segmentDuration.totalMinutes;
                            return acc2;
                        }, 0);
                    }
                    return acc;
                }, { joy: 0, price: 0, duration: 0, currency: '' });
                filterBy.sortByOptions.best = {
                    label: $t('filter.best'),
                    value: 'best',
                    price: bestFlight.price.toFixed(2),
                    currency: bestFlight.currency,
                    duration: bestFlight.duration,
                };
                // Calculate quicksort - Cheapest
                const cheapestFlight = cFlights.reduce((acc, flightResult) => {
                    if (!acc.price || acc.price > flightResult.totalPriceDisplayCurrency) {
                        acc.price = flightResult.totalPriceDisplayCurrency;
                        acc.currency = flightResult.displayCurrency;
                        acc.duration = flightResult.segments.reduce((acc2, segment) => {
                            acc2 += segment.segmentDuration.totalMinutes;
                            return acc2;
                        }, 0);
                    }
                    return acc;
                }, { price: 0, duration: 0, currency: '' });
                filterBy.sortByOptions.cheapest = {
                    label: $t('filter.cheapest'),
                    value: 'cheapest',
                    price: cheapestFlight.price.toFixed(2),
                    currency: cheapestFlight.currency,
                    duration: cheapestFlight.duration,
                };
                // Calculate quicksort - Quickest
                const quickestFlight = cFlights.reduce((acc, flightResult) => {
                    const segmentsDuration = flightResult.segments.reduce((acc2, segment) => {
                        acc2 += segment.segmentDuration.totalMinutes;
                        return acc2;
                    }, 0);
                    if (!acc.duration || acc.duration > segmentsDuration) {
                        acc.duration = segmentsDuration;
                        acc.price = flightResult.totalPriceDisplayCurrency;
                        acc.currency = flightResult.displayCurrency;
                    }
                    return acc;
                }, { price: 0, duration: 0, currency: '' });
                filterBy.sortByOptions.quickest = {
                    label: $t('filter.quickest'),
                    value: 'quickest',
                    price: quickestFlight.price.toFixed(2),
                    currency: quickestFlight.currency,
                    duration: quickestFlight.duration,
                };
                //Calculate min/max departure times
                const departureTimesMap = filterData.depart.reduce((acc, departureData) => {
                    const destinationCity = cityNamesMap[departureData.destination];
                    if (!acc[destinationCity]) {
                        const minDate = new Date(departureData.minTime);
                        const maxDate = new Date(departureData.maxTime);
                        const minTime = getTime(minDate);
                        const maxTime = getTime(maxDate);
                        acc[destinationCity] = [minTime, maxTime].sort((a, b) => a - b);
                    }
                    return acc;
                }, {});
                filterBy.departTimeMinMax = utilService.deepClone(departureTimesMap);
                filterBy.departTimeRange = utilService.deepClone(departureTimesMap);
                const arrivalTimesMap = filterData.arrive.reduce((acc, arrivalData) => {
                    const destinationCity = cityNamesMap[arrivalData.destination];
                    if (!acc[destinationCity]) {
                        const minDate = new Date(arrivalData.minTime);
                        const maxDate = new Date(arrivalData.maxTime);
                        const minTime = getTime(minDate);
                        const maxTime = getTime(maxDate);
                        acc[destinationCity] = [minTime, maxTime].sort((a, b) => a - b);
                    }
                    return acc;
                }, {});
                filterBy.arriveTimeMinMax = utilService.deepClone(arrivalTimesMap);
                filterBy.arriveTimeRange = utilService.deepClone(arrivalTimesMap);
                //Calculate min/max flight duration
                const durationTimesMap = filterData.duration.reduce((acc, durationData) => {
                    if (!acc[cityNamesMap[durationData.origin]]) {
                        acc[cityNamesMap[durationData.origin]] = [durationData.minMinuteTime, durationData.maxMinuteTime];
                    }
                    return acc;
                }, {});
                filterBy.durationMinMax = durationTimesMap;
                filterBy.durationRange = durationTimesMap;
                //Calculate min/max stops duration
                const randomFlight = cFlights.find((x) => x.layoverDuration?.length > 1 && x.layoverDuration[1] > 0 && x.layoverDuration[0] > 0);
                let maxValue = randomFlight?.layoverDuration[1];
                let minValue = randomFlight?.layoverDuration[0];
                for (let i = 0; i < cFlights.length; i++) {
                    if (cFlights[i].layoverDuration[1] > maxValue) {
                        maxValue = cFlights[i].layoverDuration[1];
                    }
                    if (cFlights[i].layoverDuration[0] < minValue && cFlights[i].layoverDuration[0] > 0) {
                        minValue = cFlights[i].layoverDuration[0];
                    }
                }
                filterBy.stopDurationMinMax = [minValue, maxValue];
                filterBy.stopsDurationRange = [minValue, maxValue];
                //Calculate available carriers
                const availableCarriers = [];
                cFlights.forEach((flightResult) => {
                    // First flight of each segment must be of the carrier in order to be in the filter
                    const carrierName = flightResult.segments[0].flights[0].carrierName;
                    const isSameCarrier = flightResult.segments.every((flightSegment) => carrierName === flightSegment.flights[0].carrierName);
                    if (isSameCarrier) {
                        availableCarriers.push(carrierName);
                    }
                });
                filterBy.availableCarriers = [...new Set(availableCarriers)];
                //Calculate available agreement Types
                const availableAgreementTypes = [
                    ...new Set(cFlights.map((flightResult) => {
                        if (flightResult.isMarine || flightResult.isHasMarineAlternative) {
                            return 'Marine fare';
                        }
                        else {
                            return flightConsts.AgreementType[flightResult.agreementType];
                        }
                    })),
                ];
                filterBy.availableAgreementTypes = availableAgreementTypes;
                //Calculate available advisories
                const advisoriesMap = filterData.advisory.reduce((acc, advisoryData) => {
                    // segmentCaption looks like 'LON to NYC'
                    const segmentCaptions = advisoryData.segmentCaption ? advisoryData.segmentCaption.split(' ') : [''];
                    const origin = cityNamesMap[segmentCaptions[0]];
                    const destination = cityNamesMap[segmentCaptions[segmentCaptions.length - 1]];
                    // const title = `${origin} to ${destination}`;
                    const title = origin;
                    if (!acc[title]) {
                        acc[title] = advisoryData.advisoryList.map((advisory) => advisory.iconClass);
                    }
                    return acc;
                }, {});
                filterBy.availableAdvisories = advisoriesMap;
                // Calculate available airports per segment
                // create availableAirportsMap according to filters coming from backend
                const availableAirportsMap = airportFilterData.reduce((acc, flightAirportsData, segmentIndex) => {
                    const segmentKey = segmentIndex.toString();
                    if (!acc[segmentKey]) {
                        acc[segmentKey] = {
                            [flightAirportsData.originMultiName]: flightAirportsData.origin.map((airport) => {
                                return `${airport.code}`;
                            }),
                            [flightAirportsData.destinationMultiName]: flightAirportsData.destination.map((airport) => {
                                return `${airport.code}`;
                            }),
                        };
                    }
                    return acc;
                }, {});
                filterBy.availableAirports = availableAirportsMap;
                //calculate advanced search filters
                if (getters.searchOptions?.isAdvancedSearch && !isReset) {
                    // calculate number of stops filter
                    if (typeof getters.searchOptions?.noOfStops === 'number') {
                        const flightStops = Array.from({
                            length: getters.searchOptions?.noOfStops + 1,
                        }, (_, index) => FlightStops[index]);
                        filterBy.stops = flightStops;
                    }
                    if (getters.searchOptions?.carrierName) {
                        const carriersMap = utilService.deepClone(getters.allCarriers).reduce((map, item) => {
                            map[item.code] = item.name;
                            return map;
                        }, {});
                        filterBy.carriers = getters.searchOptions?.carrierName.map((carrierKey) => carriersMap[carrierKey]);
                    }
                }
                filterBy.airports = initializeFilterByAirports(filterBy.airports, filterBy.availableAirports, getters.searchOptions);
                if (!applyFilter) {
                    return filterBy;
                }
                commit({ type: 'setFilter', filterBy });
                if (recalculateCountFunction) {
                    const { resultMap } = flightService.getResultMap(flightResults);
                    commit({ type: 'setResultMap', resultMap });
                }
            }
            await dispatch({ type: 'filterFlights', filterBy: null, resultsIndex }); //call to activate the filters
        },
        async filterFlights({ getters, commit, state }, { filterBy, resultsIndex = 0 }) {
            if (filterBy) {
                commit({ type: 'setFilter', filterBy });
            }
            try {
                const flightResults = getters.flightsRes[resultsIndex].results;
                const filteredFlights = flightService.filterFlights(flightResults, filterBy || state.filterBy);
                commit({ type: 'setFilteredFlights', filteredFlights });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async saveSearchOptions({ commit }, { searchOptions }) {
            commit({ type: 'setSearchOptions', searchOptions });
        },
        async resetFilter({ dispatch, commit, state }) {
            commit({ type: 'resetFilter', state });
            dispatch({ type: 'recalculateFilters', isReset: true });
            dispatch({ type: 'filterFlights', commit, state });
        },
        async saveComparableFilters({ commit }, { comparableFilters }) {
            commit({ type: 'setComparableFilters', comparableFilters });
        },
        async searchSegmentForBySchedule({ commit, dispatch, state }, { segmentToModifyIndex, editedSegment }) {
            // reminder: by schedule is not allowed in "one way", only round trip and multi city
            const searchOptions = utilService.deepClone(state.searchOptions);
            if (searchOptions.tripType === 1) {
                // check if this is a round trip. if it is - we will change it to multi city because any modification of one "round trip" segment will make it illogical to remain "round trip"
                searchOptions.tripType = 2; // change trip type to multi city
                searchOptions.flightRows.push(utilService.deepClone(searchOptions.flightRows[0])); // pushing a second segment to represent multi city in our search options. reminder- searching options for round trip contain only one value
                searchOptions.flightRows[1].origin = searchOptions.flightRows[0].destination;
                searchOptions.flightRows[1].destination = searchOptions.flightRows[0].origin;
                searchOptions.flightRows[1].departureDate = searchOptions.flightRows[0].arrivalDate;
                searchOptions.flightRows[0].arrivalDate = ''; // no arrival date in multi city
                searchOptions.flightRows[1].arrivalDate = ''; // no arrival date in multi city
            }
            searchOptions.flightRows[segmentToModifyIndex].origin = editedSegment.options.origin;
            searchOptions.flightRows[segmentToModifyIndex].destination = editedSegment.options.destination;
            searchOptions.flightRows[segmentToModifyIndex].departureDate = editedSegment.options.departureDate;
            commit({ type: 'setSearchOptions', searchOptions });
            const searchData = state.searchData;
            searchData.isBySchedule = false;
            searchData.tripType = 0; // one way. in order to search for one segment in "bySchedule" mode, we need to search it as one way trip
            searchData.segments = searchData.segments.splice(0, 1);
            searchData.segments[0].origin = editedSegment.options.origin.Code;
            searchData.segments[0].destination = editedSegment.options.destination.Code;
            searchData.segments[0].departDateAt = `${editedSegment.options.departureDate}T00:00:00`;
            commit({ type: 'setFlightSearchSearchData', searchData });
            const { singleSegmentFlightsRes, resultMap } = await flightService.searchSingleSegmentForBySchedule(searchData);
            const flightsRes = state.flightsRes;
            flightsRes[segmentToModifyIndex] = singleSegmentFlightsRes;
            commit({ type: 'setFlightsRes', flightsRes, resultMap });
            commit({ type: 'setFilteredFlights', filteredFlights: singleSegmentFlightsRes.results });
            await dispatch({
                type: 'recalculateFilters',
                resultsIndex: segmentToModifyIndex,
                recalculateCountFunction: true,
            });
        },
        async loadFlights({ commit, dispatch, state, rootState }, { isSearchEuRailOptions, resultsIndex = 0, preventReSearch = false, cacheKey = '', isGoStraightToSearch = false, }) {
            commit({ type: 'euRailStore/clearEuRailAnswerRes' }, { root: true });
            commit({ type: 'euRailStore/setRailsAnswer', railsAnswer: null }, { root: true });
            if (isGoStraightToSearch) {
                // use for price check(search alternatives)
                try {
                    const { flightsRes, resultMap } = await flightService.searchFlight(state.searchData, cacheKey);
                    commit({ type: 'setFlightsRes', flightsRes, resultMap });
                    commit({ type: 'setFilteredFlights', filteredFlights: flightsRes?.[resultsIndex]?.results });
                    dispatch({ type: 'recalculateFilters' });
                }
                catch (err) {
                    loggerService.error(err);
                    commit({ type: 'setFlightsRes', flightsRes: null });
                }
                return;
            }
            const { agencyId, id, corporateUserGroupId, corporationId, preferences } = rootState.authStore.user;
            const opts = state.searchOptions;
            const searchData = {
                tripId: rootState.tripStore.trip.id,
                noOfStops: opts.noOfStops,
                carrierName: opts.carrierName,
                isNearByAirports: opts.allowNearbyAirports,
                isSearchMyTmcOnly: false,
                isBySchedule: opts.searchFor === 'bySchedule' ? true : false,
                isPTCBreakdown: true,
                totalPassengers: opts.numberOFTravelers,
                tripType: opts.tripType,
                travelerTypeInfo: opts.travelerTypeInfo,
                segments: [],
                product: Products.Flight,
                comments: null,
                commentsChange: false,
                timeout: 0,
                maxResults: 0,
                isAdvancedSearch: opts.isAdvancedSearch,
                // branchId: 16572, // from userInfo ????????
                agencyId,
                user: {
                    // from userInfo
                    id: id,
                    corporateId: corporationId,
                    corporateGroupId: corporateUserGroupId, // from userInfo
                },
                userCurrency: preferences.currency,
                passengers: rootState.tripStore.trip.passengers,
            };
            opts.flightRows.forEach((row) => {
                searchData.segments.push({
                    origin: row.origin.Code,
                    destination: row.destination.Code,
                    connectionAt: row.connection?.Code || null,
                    departDateAt: `${row.departureDate}T00:00:00`,
                    cabin: row.class,
                    dateType: row.dateType,
                    flightTime: flightConsts.FlightTimeOptions[row.flightTime],
                });
            });
            // if searching for roundtrip - need to add the way back
            if (opts.tripType === flightConsts.TripType.roundTrip) {
                searchData.segments.push({
                    origin: opts.flightRows[0].destination.Code,
                    destination: opts.flightRows[0].origin.Code,
                    connectionAt: opts.flightRows[0].returnConnection?.Code || null,
                    departDateAt: `${opts.flightRows[0].arrivalDate}T00:00:00`,
                    cabin: !searchData.isAdvancedSearch ? opts.flightRows[0].class : opts.flightRows[0].returnClass,
                    dateType: opts.flightRows[0].returnDateType,
                    flightTime: flightConsts.FlightTimeOptions[opts.flightRows[0].returnFlightTime],
                });
            }
            commit({ type: 'setFlightSearchSearchData', searchData });
            try {
                if (state.isHotelParallelSearchActive) {
                    dispatch({ type: 'searchHotels' });
                }
                const { flightsRes, resultMap } = await flightService.searchFlight(searchData, cacheKey);
                // When there are no results on the flight, change it to Two Stops on the search options, and reset connections
                if (!preventReSearch && !flightsRes?.[resultsIndex]?.results.length) {
                    alertService.warning('common.noResultFoundFilterReset', null);
                    const searchOptions = utilService.deepClone(state.searchOptions);
                    searchOptions.noOfStops = flightConsts.NoOfStops['Up to two stops'];
                    searchOptions.flightRows.forEach((row) => {
                        row.connection = null;
                        row.returnConnection = null;
                    });
                    commit({ type: 'setSearchOptions', searchOptions });
                    await dispatch({ type: 'loadFlights', isSearchEuRailOptions, resultsIndex, preventReSearch: true });
                    return;
                }
                //Initializing search options is case we searched with cacheKey
                if (cacheKey && flightsRes && flightsRes[0].flightInfoBar) {
                    const options = flightsRes[0].flightInfoBar;
                    options.flightRows.forEach((row) => {
                        if (row.destination) {
                            row.destination.Name = row.destination.name;
                            row.destination.MultiCode = row.destination.multiCode;
                            row.destination.CityName = row.destination.cityName;
                            row.destination.CityAirportName = row.destination.cityAirportName;
                            row.destination.CountryCode = row.destination.countryCode;
                            row.destination.Index = row.destination.index;
                            row.destination.Code = row.destination.code;
                        }
                        if (row.origin) {
                            row.origin.Name = row.origin.name;
                            row.origin.MultiCode = row.origin.multiCode;
                            row.origin.CityName = row.origin.cityName;
                            row.origin.CityAirportName = row.origin.cityAirportName;
                            row.origin.CountryCode = row.origin.countryCode;
                            row.origin.Index = row.origin.index;
                            row.origin.Code = row.origin.code;
                        }
                    });
                    commit({ type: 'setSearchOptions', searchOptions: options });
                }
                commit({ type: 'setFlightsRes', flightsRes, resultMap });
                commit({ type: 'setFilteredFlights', filteredFlights: flightsRes?.[resultsIndex]?.results });
                dispatch({ type: 'recalculateFilters' });
                reminderService.getNotificationsFlightSearch(searchData);
                if (isSearchEuRailOptions) {
                    dispatch({ type: 'searchEuRailOptions', searchData });
                }
            }
            catch (err) {
                loggerService.error(err);
                commit({ type: 'setFlightsRes', flightsRes: null });
            }
        },
        async searchEuRailOptions({ commit }, { searchData }) {
            try {
                const euRailOptionsRes = await flightService.searchEuRailOptions(searchData);
                if (euRailOptionsRes?.success) {
                    commit({ type: 'euRailStore/setRailsAnswer', railsAnswer: euRailOptionsRes }, { root: true });
                    commit({ type: 'euRailStore/saveEuRailAnswerRes', railsAnswer: euRailOptionsRes }, { root: true });
                }
                else {
                    commit({ type: 'euRailStore/setRailsAnswer', railsAnswer: null }, { root: true });
                }
            }
            catch (error) {
                loggerService.error(error);
                commit({ type: 'euRailStore/setRailsAnswer', railsAnswer: null }, { root: true });
            }
        },
        async checkPrice({ commit, state, rootState }, { dataForComparison, quote }) {
            try {
                commit({ type: 'setCheckPriceResult', checkPriceResult: null });
                const { agencyId, id, corporateUserGroupId, corporationId, preferences } = rootState.authStore.user;
                const searchData = {
                    tripId: rootState.tripStore.trip.id,
                    noOfStops: quote.segments.reduce((maxStops, segment) => {
                        const stopsInSegment = segment.stops ? segment.stops.length : 0;
                        return Math.max(maxStops, stopsInSegment);
                    }, 0),
                    carrierName: [
                        ...new Set(quote.segments.flatMap((segment) => segment.flights.map((flight) => flight.carrier))),
                    ],
                    isNearByAirports: false,
                    isSearchMyTmcOnly: false,
                    isBySchedule: false,
                    isPTCBreakdown: false,
                    totalPassengers: rootState.tripStore.trip.passengers.length,
                    tripType: state.searchOptions.tripType,
                    travelerTypeInfo: rootState.tripStore.trip.passengers.map((p) => 0),
                    segments: [],
                    product: Products.Flight,
                    comments: null,
                    commentsChange: false,
                    timeout: 0,
                    maxResults: 0,
                    isAdvancedSearch: false,
                    agencyId: agencyId,
                    user: {
                        id,
                        corporateId: corporationId,
                        corporateGroupId: corporateUserGroupId,
                    },
                    userCurrency: preferences.currency,
                    passengers: rootState.tripStore.trip.passengers,
                };
                quote.segments.forEach((segment) => {
                    const mainFlight = segment.flights[0];
                    const lastFlight = segment.flights[segment.flights.length - 1];
                    searchData.segments.push({
                        origin: mainFlight.origin,
                        destination: lastFlight.destCode,
                        departDateAt: mainFlight.depDate,
                        cabin: mainFlight.cabin,
                        dateType: 0,
                        flightTime: flightService.getFlightTimeOption(mainFlight.depDate),
                    });
                });
                if (searchData.tripType === flightConsts.TripType.roundTrip && quote.segments.length > 1) {
                    const returnSegment = quote.segments[1];
                    const returnMainFlight = returnSegment.flights[0];
                    const returnLastFlight = returnSegment.flights[returnSegment.flights.length - 1];
                    searchData.segments[1].origin = returnMainFlight.origin;
                    searchData.segments[1].destination = returnLastFlight.destCode;
                    searchData.segments[1].departDateAt = returnMainFlight.arrDate;
                }
                commit({ type: 'setFlightSearchSearchData', searchData });
                const checkPriceRes = await flightService.checkPrice(dataForComparison, searchData);
                commit({ type: 'setCheckPriceResult', checkPriceResult: checkPriceRes });
            }
            catch (error) {
                loggerService.error(error);
                commit({ type: 'setCheckPriceResult', checkPriceResult: null });
            }
        },
        async selectFlight({ dispatch, state, getters, rootState, commit }, { packageCacheKey, resultsIndex = 0, searchCacheKeyFromCheckPrice = '' }) {
            const tripId = rootState.tripStore.trip.id;
            let selectedSeats = state.selectedSeats;
            // remove segments with no seat selection only when selecting flight
            selectedSeats = selectedSeats.filter((segment) => segment);
            const cacheKey = searchCacheKeyFromCheckPrice || getters.cacheKey(resultsIndex);
            const flightSelectParams = {
                tripId: tripId,
                searchCacheKey: cacheKey,
                packageCacheKey,
                selectedSeats,
            };
            try {
                // select flight
                const addedQuoteId = await flightService.selectFlight(flightSelectParams);
                commit({ type: 'setAddedQuoteId', quoteId: addedQuoteId });
                // after selection success, reload trip
                await dispatch({ type: 'tripStore/loadTrip', tripId }, { root: true });
                // Hotel parallel
                if (!searchCacheKeyFromCheckPrice &&
                    state.isHotelParallelSearchActive &&
                    state.parallelHotelSearchesOptions.length) {
                    //Take the first parallel search option
                    const parallelHotelSearchesOptions = utilService.deepClone(state.parallelHotelSearchesOptions);
                    const parallelSearchCacheKeys = utilService.deepClone(state.parallelSearchCacheKeys);
                    const parallelSearchOptions = parallelHotelSearchesOptions.shift();
                    const parallelSearchCacheKey = parallelSearchCacheKeys.shift();
                    //Save the changes
                    await Promise.all([
                        commit({
                            type: 'setHotelParallelSearchesOptions',
                            parallelHotelSearchesOptions: parallelHotelSearchesOptions,
                        }),
                        commit({
                            type: 'setParallelSearchCacheKeys',
                            parallelSearchCacheKeys: parallelSearchCacheKeys,
                        }),
                    ]);
                    await dispatch({
                        type: 'hotelStore/searchHotels',
                        searchOptions: parallelSearchOptions,
                        searchCacheKey: parallelSearchCacheKey,
                    }, { root: true });
                    await dispatch({ type: 'tripStore/loadTrip', tripId }, { root: true });
                    router.push({
                        name: RoutesNames.hotelResults,
                        params: { tripId: tripId + '', searchCacheKey: parallelSearchCacheKey },
                    });
                    return;
                    // Car parallel
                }
                else if (!searchCacheKeyFromCheckPrice &&
                    state.isCarParallelSearchActive &&
                    state.parallelCarSearchesOptions.pickUpDate) {
                    await dispatch({ type: 'setParallelCar', selectedFlight: flightSelectParams });
                    router.push({ name: RoutesNames.carResults, params: { tripId: tripId + '' } });
                    return;
                }
                // redirect to cart
                router.push({ name: RoutesNames.tripCart, params: tripId });
            }
            catch (err) {
                loggerService.error(err);
                throw err;
            }
        },
        async selectMultiFlights({ dispatch, state, getters, rootState }, { packageCacheKeys, resultsIndex = 0 }) {
            const tripId = rootState.tripStore.trip.id;
            let selectedSeats = utilService.deepClone(state.selectedSeats);
            // remove segments with no seat selection only when selecting flight
            selectedSeats = selectedSeats.filter((segment) => segment);
            const cacheKey = getters.cacheKey(resultsIndex);
            const flightSelectParams = packageCacheKeys.map((packageKey) => {
                return {
                    tripId: tripId,
                    searchCacheKey: cacheKey,
                    packageCacheKey: packageKey,
                    selectedSeats,
                };
            });
            try {
                await flightService.selectMultiFlight(flightSelectParams);
                // after selection success, reload trip
                await dispatch({ type: 'tripStore/loadTrip', tripId }, { root: true });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async setParallelCar({ getters, state, rootState, commit }, { selectedFlight }) {
            const tripId = rootState.tripStore.trip.id;
            // get added quote Id, then clear it
            const quoteId = state.addedQuoteId;
            commit({ type: 'setAddedQuoteId', quoteId: null });
            const suggestion = await tripService.getTripSearchSuggestions(tripId, Products.Car, quoteId);
            const searchOptions = utilService.deepClone(getters.parallelCarSearchesOptions);
            // if the user didnt manually edit the search
            if (!searchOptions.isEdited) {
                searchOptions.pickUpLocation = suggestion?.destination;
                // if I use the suggestion in parallel - it should always be airport - if not - blame Alex
                searchOptions.pickUpLocation.locationType = LocationType.AIRPORT;
                // match selectedFlight cache key with the flight from our flights
                let matchedFlight;
                if (selectedFlight) {
                    matchedFlight = getters.filteredFlights.find((f) => f.packageKey === selectedFlight.packageCacheKey);
                }
                else {
                    const quotes = await tripService.getQuotes(tripId);
                    matchedFlight = quotes.flightQuotes.find((f) => f.quoteId === quoteId);
                }
                // If parallel car search wasnt edited, use the date and time from the selected flight in car search
                const firstSegmentArrival = new Date(matchedFlight.segments[0].flights[matchedFlight.segments[0].flights.length - 1].arrDate);
                // adding & subtracting 30 minutes to both the date and the time in arrival and departure:
                // to make up for time it takes to complete the flight
                searchOptions.pickUpDate = format(addMinutes(firstSegmentArrival, 30), 'yyyy-MM-dd');
                searchOptions.pickUpTime = format(addMinutes(firstSegmentArrival, 30), 'HH:mm');
                const lastSegmentDeparture = new Date(matchedFlight.segments[1].flights[0].depDate);
                searchOptions.dropOffDate = format(subMinutes(lastSegmentDeparture, 30), 'yyyy-MM-dd');
                searchOptions.dropOffTime = format(subMinutes(lastSegmentDeparture, 30), 'HH:mm');
            }
            commit({ type: 'carStore/setSearchOptions', searchOptions }, { root: true });
            eventBus.$emit('productSearchLoading', {
                productName: Products.Car,
                destinationName: searchOptions.pickUpLocation.name,
            });
            searchOptions.pickUpTime = searchOptions.pickUpTime || suggestion?.startTime;
            searchOptions.dropOffTime = searchOptions.dropOffTime || suggestion?.endTime;
            commit({ type: 'setCarParallelSearchOptions', parallelCarSearchesOptions: searchOptions });
        },
        async loadAllAirports({ commit }) {
            const allAirports = await flightService.getAllAirports();
            commit({ type: 'setAllAirports', allAirports });
        },
        async searchHotels({ commit, state, dispatch }) {
            const parallelHotelSearchesOptions = utilService.deepClone(state.parallelHotelSearchesOptions);
            const parallelSearchCacheKeys = await Promise.all(parallelHotelSearchesOptions.map(async (parallelSearch) => {
                parallelSearch.checkInDate = parallelSearch.startDate;
                parallelSearch.checkOutDate = parallelSearch.endDate;
                //Save the changes
                await commit({
                    type: 'setHotelParallelSearchesOptions',
                    parallelHotelSearchesOptions: parallelHotelSearchesOptions,
                });
                return await dispatch('hotelStore/getHotelResCacheKey', { parallelSearchOptions: parallelSearch }, { root: true });
            }));
            commit({ type: 'setParallelSearchCacheKeys', parallelSearchCacheKeys });
        },
    },
};
