import { defineComponent } from 'vue';
import ticketLayout from '@/components/ticket-layout.vue';
import { RoutesNames } from '@/router';
import { Products, TripState } from '@/types/consts';
import { utilService } from '@/services/util.service';
import { differenceInCalendarDays } from 'date-fns';
import { TripType } from '@/modules/products/flight/models/consts';
import { loggerService } from '@/services/logger.service';
export default defineComponent({
    components: { ticketLayout },
    name: 'GeneralProductCard',
    props: {
        quote: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            isDrawerOpen: false,
        };
    },
    methods: {
        goToSearch() {
            const routeName = this.quote.productName + 'Results';
            const searchOptions = this.buildSearchOptions();
            if (this.isHotel) {
                utilService.buildSearchQueryParams(searchOptions, true, ['$type', 'priority']);
                this.$store.dispatch({ type: 'hotelStore/saveSearchOptions', searchOptions });
            }
            else if (this.isCar) {
                this.$store.commit('carStore/setSearchOptions', { searchOptions });
            }
            else {
                //TODO : ???
                loggerService.warn('Flight is under construction');
            }
            this.$router.push({ name: RoutesNames[routeName] });
        },
        buildSearchOptions() {
            if (this.isHotel) {
                const { checkInDate, checkOutDate, numberOfPersons, numberOfRooms, location, locationType, longitude, latitude, } = this.quote;
                const hotelSearchOptions = {
                    checkInDate,
                    checkOutDate,
                    numberOFTravelers: numberOfPersons,
                    roomsCount: numberOfRooms,
                    destination: {
                        Code: '',
                        Name: location,
                        CountryCode: '',
                        Latitude: latitude,
                        Longitude: longitude,
                        Priority: 0,
                        LocationType: locationType,
                    },
                };
                return hotelSearchOptions;
            }
            else if (this.isCar) {
                const { pickUpDate, pickUpTime, dropOffDate, dropOffTime, dropOffDifferentLocation, pickUpLocationParams, vendorCode, } = this.quote;
                let { dropOffLocationParams } = this.quote;
                const formatedPickUpDate = this.$filters?.date(pickUpDate, 'yyyy-MM-dd') + 'T' + pickUpTime;
                const formatedDropOffDate = this.$filters?.date(dropOffDate, 'yyyy-MM-dd') + 'T' + dropOffTime;
                // if same location - set dropOffLocation as PickUp
                if (!dropOffDifferentLocation) {
                    dropOffLocationParams = { ...pickUpLocationParams };
                }
                const carSearchOptions = {
                    pickUpDate: formatedPickUpDate,
                    pickUpTime: pickUpTime,
                    dropOffDate: formatedDropOffDate,
                    dropOffTime: dropOffTime,
                    pickUpLocation: {
                        Code: pickUpLocationParams.code,
                        CountryCode: pickUpLocationParams.countryCode,
                        Name: pickUpLocationParams.name,
                        Latitude: pickUpLocationParams.latitude,
                        Longitude: pickUpLocationParams.longitude,
                    },
                    dropOffLocation: {
                        Code: dropOffLocationParams.code,
                        CountryCode: dropOffLocationParams.countryCode,
                        Name: dropOffLocationParams.name,
                        Latitude: dropOffLocationParams.latitude,
                        Longitude: dropOffLocationParams.longitude,
                    },
                    releatedCorporationId: '',
                    vendorCode,
                    pickUpLocationType: 2,
                    differentLocation: dropOffDifferentLocation,
                };
                return carSearchOptions;
            }
            else {
                loggerService.warn('Flight will come here soon');
            }
        },
    },
    computed: {
        location() {
            const locationByProductMap = {
                hotel: this.quote.location,
                car: this.quote.pickUpLocation || this.quote.pickUpLocationParams?.name,
                flight: this.quote.tripType === TripType.multiCity ? '' : this.quote.waypoints?.at(1).location,
                rail: this.quote.origin,
            };
            return locationByProductMap[this.quote.productName];
        },
        tripState() {
            return this.$store.getters['tripStore/trip'].state;
        },
        isMultiCity() {
            return this.quote.tripType === TripType.multiCity;
        },
        isAssistanceRequested() {
            return this.tripState === TripState.AssistanceRequest;
        },
        isApproved() {
            return this.tripState === TripState.PreTripRequest;
        },
        isDeclined() {
            return this.tripState === TripState.Declined;
        },
        isHotel() {
            return this.quote.productName === Products.Hotel;
        },
        isCar() {
            return this.quote.productName === Products.Car;
        },
        isRail() {
            return this.quote.productName === Products.Rail; // TODO: fix the euRail convention along the app and than use Products enum (Products.Rail => benerail)
        },
        isFlight() {
            return this.quote.productName === Products.Flight;
        },
        statusClass() {
            //TODO: use utilService
            if (!this.quote.productStatusName) {
                return '';
            }
            const name = this.quote.productStatusName;
            return name.toLowerCase().split(' ').join('-');
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        productIconMap() {
            // Need to take it to const file
            const productIconMap = {
                hotel: 'domain',
                car: 'directions_car',
                flight: 'flight',
                rail: 'directions_railway',
            };
            return productIconMap;
        },
        datesDayDiff() {
            let date1 = new Date(this.quote.checkInDate);
            let date2 = new Date(this.quote.checkOutDate);
            if (this.isCar) {
                date1 = new Date(this.quote.pickUpDate);
                date2 = new Date(this.quote.dropOffDate);
            }
            return this.isCar ? differenceInCalendarDays(date2, date1) + 1 : differenceInCalendarDays(date2, date1) || 1; // Cars in days inclusive
        },
    },
});
