import { defineComponent } from 'vue';
export default defineComponent({
    name: 'EcoLabel',
    props: {
        carbonQuantity: {
            type: Number,
            default: 0
        },
        isEstimated: {
            type: Boolean,
            default: false
        },
    },
});
