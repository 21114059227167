import { defineComponent } from 'vue';
import appUnusedTickets from '@/components/app-unused-tickets.vue';
export default defineComponent({
    name: 'UnusedTicketsBanner',
    components: {
        appUnusedTickets,
    },
    props: {
        passengers: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            unusedTicketsVisible: false,
        };
    },
    methods: {
        carrierIcon(carrier) {
            let res = '';
            try {
                res = require(`@/assets/img/flights/airlinesIcons/${carrier}.gif`);
            }
            catch (e) {
                res = require('@/assets/icon/flight.svg');
            }
            return res;
        },
        toggleUnusedTickets() {
            this.unusedTicketsVisible = !this.unusedTicketsVisible;
        },
    },
    computed: {
        isDisabled() {
            return this.passengers.length > 1;
        },
        uniqueCarriers() {
            // Initialize a set to store unique carriers
            const uniqueCarriers = new Set();
            // Iterate through passengers to find the first one with unused tickets
            for (const passenger of this.passengers) {
                // Check if the passenger has unused tickets and is not null or undefined
                if (passenger && passenger.unusedTickets) {
                    // Iterate through the unused tickets of the passenger
                    for (const item of passenger.unusedTickets) {
                        // If the carrier code is not already in the set, add it
                        if (!uniqueCarriers.has(item.CarrierCode)) {
                            uniqueCarriers.add(item.CarrierCode);
                            // If we have found three unique carriers, return them
                            if (uniqueCarriers.size === 3) {
                                return Array.from(uniqueCarriers);
                            }
                        }
                    }
                }
            }
            // If no passenger has unused tickets or there are fewer than 3 unique carriers
            // return all unique carriers found
            return Array.from(uniqueCarriers);
        }
    },
});
