import { defineComponent } from 'vue';
import { AddTravelerType, PhoneFormat } from '@/types/consts';
import { RoutesNames } from '@/router';
import { helpers, required, email, requiredIf } from '@vuelidate/validators';
import { loggerService } from '@/services/logger.service';
import { utilService } from '@/services/util.service';
import { sub } from 'date-fns';
import { useVuelidate } from '@vuelidate/core';
import appSelect from '@/components/app-select.vue';
import appDatepicker from '@/components/form/app-datepicker.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
export default defineComponent({
    components: { appSelect, appDatepicker, formValidationMsg },
    props: {
        isPrimary: {
            type: Boolean,
            default: false,
        },
        travelerIdx: {
            type: Number,
            required: true,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            formData: {
                settingsType: 'profile',
                title: '',
                firstName: '',
                middleName: '',
                lastName: '',
                gender: '',
                birthDate: null,
                travelerType: 0,
                nationality: '',
                email: '',
                phone: '',
                phoneType: '',
                userGroupId: null,
            },
            markFields: false,
            isLoadingBtn: false,
        };
    },
    async created() {
        await this.loadData();
    },
    methods: {
        async loadData() {
            if (!this.nationalities && !this.userGroups) {
                await Promise.all([this.$store.dispatch('getNationalities'), this.$store.dispatch('getUserGroups')]);
            }
            else {
                if (!this.nationalities) {
                    await this.$store.dispatch('getNationalities');
                }
                if (!this.userGroups) {
                    await this.$store.dispatch('getUserGroups');
                }
            }
        },
        validateBirthDateField(e) {
            if (this.v$.formData?.birthDate?.$invalid) {
                this.v$.formData.birthDate.$touch();
            }
        },
        async handleSubmit() {
            this.markFields = true;
            if (this.v$.$invalid) {
                return;
            }
            try {
                const passengerInfo = {
                    passenger: {
                        TripId: +this.trip.id,
                        TravelerType: +this.formData.travelerType,
                        IsPrimary: false,
                        PaxId: 0,
                        CorporationId: this.loggedinUser?.corporationId,
                        BirthDate: this.formData.birthDate,
                        FirstName: utilService.capitalizeFirstLetter(this.formData.firstName),
                        Title: this.getTitle(),
                        LastName: utilService.capitalizeFirstLetter(this.formData.lastName),
                        MiddleName: this.formData.middleName,
                        PhoneNumber: this.formData.phone,
                        CorporateUserGroupId: this.formData.settingsType === 'profile' ? null : this.formData.userGroupId,
                        IsMyProfile: this.formData.settingsType === 'profile' ? true : false,
                        Gender: this.formData.gender,
                        Nationality: this.formData.nationality || null,
                        PhoneType: this.formData.phoneType,
                        Email: this.formData.email,
                    },
                    addTravellerType: AddTravelerType.Guest,
                    updatePrimary: this.isPrimary,
                    idx: this.travelerIdx,
                };
                await this.$emit('selectUser', passengerInfo);
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.resetForm();
                this.$emit('unsetGuestMode');
                this.$emit('close');
            }
        },
        getTitle() {
            if (this.formData.title) {
                return this.formData.title;
            }
            if (!this.formData.gender) {
                return 'Mr';
            }
            else {
                if (this.formData.gender === 'Male') {
                    return 'Mr';
                }
                else {
                    return 'Ms';
                }
            }
        },
        resetDate() {
            this.formData.birthDate = null;
        },
        resetForm() {
            this.formData = {
                settingsType: 'profile',
                title: '',
                firstName: '',
                middleName: '',
                lastName: '',
                gender: '',
                birthDate: null,
                travelerType: 0,
                nationality: '',
                email: '',
                phone: '',
                phoneType: '',
                userGroupId: null,
            };
        },
        handleGenderChange(gender) {
            const maleTitles = ['Mr', 'Dr', 'Prof'];
            const femaleTitles = ['Ms', 'Miss', 'Mrs', 'Dr', 'Prof'];
            if (gender === 'Male' && !maleTitles.includes(this.formData.title)) {
                this.formData.title = maleTitles[0];
            }
            else if (gender === 'Female' && !femaleTitles.includes(this.formData.title)) {
                this.formData.title = femaleTitles[0];
            }
        },
        handleTitleChange(title) {
            const maleTitles = ['Mr'];
            const femaleTitles = ['Ms', 'Mrs', 'Miss'];
            if (maleTitles.includes(title)) {
                this.formData.gender = 'Male';
            }
            else if (femaleTitles.includes(title)) {
                this.formData.gender = 'Female';
            }
        },
        handleCancel() {
            this.$emit('unsetGuestMode');
        },
        isFieldMarked(fieldName) {
            return ((this.markFields && this.v$.formData[fieldName]?.$invalid));
        },
    },
    computed: {
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        nationalities() {
            return this.$store.getters.nationalities;
        },
        userGroups() {
            return this.$store.getters.userGroups;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isMustFillPassengerPersonalDetails() {
            return this.loggedinUser.permissions.includes('MustFillPassengerPersonalDetails');
        },
        isUSPhoneFormat() {
            return this.loggedinUser.preferences.phoneFormat === PhoneFormat.USFormat;
        },
        minDate() {
            let yearsToSub = 100;
            if (this.formData) {
                switch (this.formData.travelerType) {
                    case 0:
                        yearsToSub = 100;
                        break;
                    case 1:
                        yearsToSub = 100;
                        break;
                    case 2:
                        yearsToSub = 18;
                        break;
                    case 3:
                        yearsToSub = 12;
                        break;
                    case 4:
                        yearsToSub = 2;
                        break;
                    case 5:
                        yearsToSub = 2;
                        break;
                }
            }
            const now = new Date();
            const daysToSub = this.isMobile ? 0 : 1;
            const minDate = sub(now, { years: yearsToSub, days: daysToSub }).toISOString();
            return minDate;
        },
        maxDate() {
            let yearsToSub = 18;
            if (this.formData) {
                switch (this.formData.travelerType) {
                    case 0:
                        yearsToSub = 18;
                        break;
                    case 1:
                        yearsToSub = 65;
                        break;
                    case 2:
                        yearsToSub = 12;
                        break;
                    case 3:
                        yearsToSub = 2;
                        break;
                    case 4:
                        yearsToSub = 0;
                        break;
                    case 5:
                        yearsToSub = 0;
                        break;
                }
            }
            const now = new Date();
            const maxDate = sub(now, { years: yearsToSub, days: 1 }).toISOString();
            return maxDate;
        },
        titleOpts() {
            return [
                {
                    value: 'Mr',
                    label: `${this.$t('trip.addGuestForm.titles.mr')}`,
                },
                {
                    value: 'Mrs',
                    label: `${this.$t('trip.addGuestForm.titles.mrs')}`,
                },
                {
                    value: 'Ms',
                    label: `${this.$t('trip.addGuestForm.titles.ms')}`,
                },
                {
                    value: 'Miss',
                    label: `${this.$t('trip.addGuestForm.titles.miss')}`,
                },
                {
                    value: 'Dr',
                    label: `${this.$t('trip.addGuestForm.titles.dr')}`,
                },
                {
                    value: 'Prof',
                    label: `${this.$t('trip.addGuestForm.titles.prof')}`,
                },
            ];
        },
        genderOpts() {
            return [
                {
                    value: 'Male',
                    label: `${this.$t('trip.addGuestForm.male')}`,
                },
                {
                    value: 'Female',
                    label: `${this.$t('trip.addGuestForm.female')}`,
                },
            ];
        },
        travelerTypeOpts() {
            const canAddMultiPtc = this.loggedinUser.permissions.includes('CanBookForMultiPtcCorpUser') &&
                this.loggedinUser.permissions.includes('CanUseRequestAssistance') &&
                !this.$route.path.toLowerCase().includes('result') &&
                this.$route.path !== RoutesNames.tripCart;
            if (!canAddMultiPtc) {
                return [
                    {
                        value: 0,
                        label: `${this.$t('trip.addGuestForm.adult')}`,
                    },
                ];
            }
            else {
                return [
                    {
                        value: 0,
                        label: `${this.$t('trip.addGuestForm.adult')}`,
                    },
                    {
                        value: 1,
                        label: `${this.$t('trip.addGuestForm.senior')}`,
                    },
                    {
                        value: 2,
                        label: `${this.$t('trip.addGuestForm.youth')}`,
                    },
                    {
                        value: 3,
                        label: `${this.$t('trip.addGuestForm.child')}`,
                    },
                    {
                        value: 4,
                        label: `${this.$t('trip.addGuestForm.lapInfant')}`,
                    },
                    {
                        value: 5,
                        label: `${this.$t('trip.addGuestForm.seatInfant')}`,
                    },
                ];
            }
        },
        nationalityTypeOpts() {
            if (!this.nationalities) {
                return [];
            }
            return this.nationalities.map((n) => {
                return {
                    value: n.code,
                    label: `${n.name} (${n.code})`,
                };
            });
        },
        phoneTypeOpts() {
            return [
                {
                    value: 'B',
                    label: `${this.$t('trip.addGuestForm.businessPhone')}`,
                },
                {
                    value: 'H',
                    label: `${this.$t('trip.addGuestForm.homePhone')}`,
                },
                {
                    value: 'C',
                    label: `${this.$t('trip.addGuestForm.cellularPhone')}`,
                },
            ];
        },
        groupOpts() {
            if (!this.userGroups) {
                return [];
            }
            return this.userGroups.map((ug) => {
                return {
                    value: ug.id,
                    label: ug.name,
                };
            });
        },
    },
    validations() {
        return {
            formData: {
                firstName: {
                    required,
                    isAlphaAndSpace: (value) => /^[a-zA-Z\s]*$/.test(value),
                },
                lastName: {
                    required,
                    isAlphaAndSpace: (value) => /^[a-zA-Z\s]*$/.test(value),
                },
                email: {
                    required,
                    email,
                },
                gender: { required },
                userGroupId: {
                    required: requiredIf(() => {
                        return this.formData.settingsType === 'group';
                    }),
                },
                phone: {
                    required: requiredIf(() => {
                        return this.isMustFillPassengerPersonalDetails;
                    }),
                    // If there is a value we check if it's all numbers and special characters
                    isValidPhone: (value) => {
                        return !helpers.req(value) || /^[0-9*#+()-]+$/.test(value);
                        // return !helpers.req(value) || /^(\d+-?)+\d+$/.test(value);
                    },
                    // In case we need a US phone format, check if the value is in format [City code] - [3 numbers prefix] - [4 last digits]
                    isValidFormat: (value) => {
                        if (!this.isUSPhoneFormat) {
                            return true;
                        }
                        return !helpers.req(value) || /^[0-9]{1,3}-[0-9]{3}-[0-9]{4}/.test(value);
                    },
                },
                phoneType: {
                    required: requiredIf(() => {
                        return this.isMustFillPassengerPersonalDetails;
                    }),
                },
                birthDate: {
                    required: requiredIf(() => {
                        return this.isMustFillPassengerPersonalDetails || this.formData.travelerType !== 0;
                    }),
                },
            },
        };
    },
});
