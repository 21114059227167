import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7584c4e2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "flights-by-schedule-selected-segments" };
const _hoisted_2 = { class: "user-selected-segments-wrapper" };
const _hoisted_3 = { class: "title" };
const _hoisted_4 = { class: "main-content" };
const _hoisted_5 = { class: "carrier-container is-flex is-align-items-center" };
const _hoisted_6 = ["src"];
const _hoisted_7 = { class: "carrier-name" };
const _hoisted_8 = { class: "flight-info is-flex is-align-items-center" };
const _hoisted_9 = { class: "mr-2" };
const _hoisted_10 = { class: "bottom-bar is-flex is-justify-content-flex-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_btn = _resolveComponent("app-btn");
    const _component_ticket_layout = _resolveComponent("ticket-layout");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(_component_ticket_layout, null, {
            "ticket-details": _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('flight.flights')), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedSegments, (flightSegment, segmentIdx) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: `segment-${segmentIdx}`
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(["segment-legs-wrapper", { 'last-segment': segmentIdx === _ctx.selectedSegments.length - 1 }])
                                }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(flightSegment.segments[0].flights, (flight, flightIdx) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                            key: `flight-${flightIdx}`,
                                            class: "segment-leg is-flex is-justify-content-space-between is-align-items-center"
                                        }, [
                                            _createElementVNode("div", _hoisted_5, [
                                                _createElementVNode("img", {
                                                    class: "carrier-img",
                                                    src: _ctx.carrierIcon(flight.carrier),
                                                    alt: ""
                                                }, null, 8 /* PROPS */, _hoisted_6),
                                                _createElementVNode("div", null, [
                                                    _createElementVNode("p", _hoisted_7, _toDisplayString(flight.carrierName), 1 /* TEXT */),
                                                    _createElementVNode("p", null, _toDisplayString(flight.carrier) + _toDisplayString(flight.flightNumber), 1 /* TEXT */)
                                                ])
                                            ]),
                                            _createElementVNode("div", _hoisted_8, [
                                                _createElementVNode("p", _hoisted_9, [
                                                    _createElementVNode("span", {
                                                        class: _normalizeClass({
                                                            marked: _ctx.getFlightConnectionMarking(segmentIdx, flightIdx, 'origin'),
                                                            markedEdge: _ctx.getFlightMarking(segmentIdx, flightIdx, 'origin'),
                                                        })
                                                    }, _toDisplayString(flight.origin), 3 /* TEXT, CLASS */),
                                                    _createTextVNode(" - "),
                                                    _createElementVNode("span", {
                                                        class: _normalizeClass({
                                                            marked: _ctx.getFlightConnectionMarking(segmentIdx, flightIdx, 'destination'),
                                                            markedEdge: _ctx.getFlightMarking(segmentIdx, flightIdx, 'destination'),
                                                        })
                                                    }, _toDisplayString(flight.destCode), 3 /* TEXT, CLASS */)
                                                ]),
                                                _createElementVNode("p", null, _toDisplayString(_ctx.formattedTime(flight.depDate)) + " - " + _toDisplayString(_ctx.formattedTime(flight.arrDate)), 1 /* TEXT */)
                                            ])
                                        ]));
                                    }), 128 /* KEYED_FRAGMENT */))
                                ], 2 /* CLASS */)
                            ]));
                        }), 128 /* KEYED_FRAGMENT */)),
                        _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_app_btn, {
                                type: "text",
                                class: "more-details-btn mt-2",
                                "has-padding": false,
                                "is-link-style": "",
                                onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.$emit('flightSelected')), ["stop"]))
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('flight.flightDetails')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                            })
                        ])
                    ])
                ])
            ]),
            _: 1 /* STABLE */
        })
    ]));
}
