import * as flightConsts from '@/modules/products/flight/models/consts';

let flightmixins = {
  methods: {
    getFlightAdvisoryIconName(amenity) {
      return flightConsts.FlightAdvisoriesIconNames[amenity];
    },
    getFlightAdvisoriesDisplayName(amenity) {
      return flightConsts.FlightAdvisoriesDisplayNames[amenity];
    },
    getAgreementName(agreementType) {
      return flightConsts.AgreementType[agreementType];
    },
  },
};

export default flightmixins;
