import { defineComponent } from 'vue';
export default defineComponent({
    name: 'FlightDetailsTooltip',
    props: {
        contents: {
            type: Array,
            default: () => []
        },
        text: {
            type: String,
            default: '',
        },
        tooltipType: {
            type: String,
            default: ''
        }
    },
    methods: {
        formattedContent(content) {
            if (this.tooltipType === 'Baggage') {
                const { quantity, weight, amount, currency } = content;
                return `${quantity} ${quantity === '1' ? 'Bag' : 'Bags'} ${weight} KG - ${amount} ${currency}`;
            }
            else {
                const { type, amount, currency } = content;
                return `${type} - ${amount} ${currency}`;
            }
        }
    },
});
