import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
import isPast from 'date-fns/isPast';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import ticketLayout from '@/components/ticket-layout.vue';
import flightRibbon from '@/modules/products/flight/components/flight-by-schedule-pricing/flight-ribbon/flight-ribbon.vue';
import appTooltipOfficeHoursRestricted from '@/components/app-tooltip-office-hours-restricted/app-tooltip-office-hours-restricted.vue';
import appOopBadge from '@/components/app-oop-badge.vue';
import { utilService } from '@/services/util.service';
export default defineComponent({
    name: 'FlightBySchedulePricingOption',
    components: {
        ticketLayout,
        flightRibbon,
        appTooltipOfficeHoursRestricted,
        appOopBadge,
    },
    props: {
        pricingOption: {
            type: Object,
            required: true,
        },
        subTitle: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        isShowSegmentsRoute: {
            type: Boolean,
            default: false,
        },
        isRibbon: {
            type: Boolean,
            default: false,
        },
        selectedSegments: {
            type: Array,
        },
    },
    async created() {
        eventBus.$on('cancelCartBtnLoadingBySchedule', this.cancelCartBtnLoading);
    },
    data() {
        return {
            isCartBtnLoading: false,
        };
    },
    methods: {
        addToCart() {
            this.isCartBtnLoading = true;
            this.$emit('addToCart');
        },
        cancelCartBtnLoading() {
            this.isCartBtnLoading = false;
        },
        flightClassForDisplay(flight) {
            return `${flight.flightClassDesc} (${flight.flightClass})`;
        },
        getCarrierIcon(carrier) {
            let res = '';
            try {
                res = require(`@/assets/img/flights/airlinesIcons/${carrier}.gif`);
            }
            catch (e) {
                res = require('@/assets/icon/flight.svg');
            }
            return res;
        },
        formattedTime(dateStr) {
            return format(new Date(dateStr), 'HH:mm');
        },
        getDateObject(dateUTC) {
            return utilService.convertUnformattedUTCToLocalDate(dateUTC);
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isExpired() {
            if (!this.pricingOption) {
                return false;
            }
            const dateTime = this.getDateObject(this.pricingOption.lastTktDate);
            return isPast(dateTime);
            // Check if the parsed date and time is in the past
        },
        isExpiredOfficeHours() {
            if (!this.pricingOption) {
                return false;
            }
            const dateTime = this.getDateObject(this.pricingOption.nextOfficeHoursExpiration);
            return isPast(dateTime);
            // Check if the parsed date and time is in the past
        },
        isOfficeHoursExpiration() {
            return !isBefore(new Date(this.pricingOption.lastTktDate), new Date(this.pricingOption.nextOfficeHoursExpiration));
        },
        isAddToCardDisabled() {
            return this.isExpired || this.isExpiredOfficeHours;
        },
    },
    unmounted() {
        eventBus.$off('cancelCartBtnLoadingBySchedule', this.cancelCartBtnLoading);
    },
});
