import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0e96d7da"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "flight-capacity-violation-warning" };
const _hoisted_2 = { class: "violation-msg-content" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 1 };
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { class: "btn-wrapper is-flex is-align-items-center is-justify-content-flex-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_btn = _resolveComponent("app-btn");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_ctx.isSingleFlight)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('trip.capacityViolation.violationWithoutFlights')), 1 /* TEXT */)
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('trip.capacityViolation.violationWithFlights')), 1 /* TEXT */),
                    _createElementVNode("p", {
                        innerHTML: _ctx.flightList,
                        class: "flight-list-capacity"
                    }, null, 8 /* PROPS */, _hoisted_5),
                    _createElementVNode("p", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('trip.capacityViolation.searchForAlternative')) + " ", 1 /* TEXT */),
                        (!_ctx.isRestrictViolation)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('trip.capacityViolation.proceedWithJustification')), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                    ])
                ]))
        ]),
        _createElementVNode("div", _hoisted_7, [
            (!_ctx.isRestrictViolation)
                ? (_openBlock(), _createBlock(_component_app_btn, {
                    key: 0,
                    class: "mr-2",
                    type: "text",
                    onClick: _ctx.proceedAnyway,
                    isLinkStyle: "",
                    hasPadding: false
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('btn.proceedAnyway')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true),
            _createVNode(_component_app_btn, {
                type: "primary",
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close')))
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('btn.goBack')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            })
        ])
    ]));
}
