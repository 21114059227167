import { defineComponent } from 'vue';
import flightPreviewSegment from '@/modules/products/flight/components/flight-preview/flight-preview-segment/flight-preview-segment.vue';
import { airportMarkingsService } from '@/modules/products/flight/services/airport-marking.service';
import { TripType } from '@/modules/products/flight/models/consts';
export default defineComponent({
    name: 'FlightSelectedSegments',
    components: {
        flightPreviewSegment,
    },
    props: {
        selectedSegments: {
            type: Array,
            default: () => [],
        },
        isOnBySchedulePricingPage: {
            type: Boolean,
            default: false,
        },
        flightResult: {
            type: Object,
            default: null,
        },
    },
    methods: {
        isLastShownSegment(index) {
            let isLastShownSegment = true;
            this.selectedSegments.forEach((segment, idx) => {
                if (idx > index && segment.segments) {
                    isLastShownSegment = false;
                }
            });
            return isLastShownSegment;
        },
        isFirstShownSegment(index) {
            let isFirstShownSegment = true;
            this.selectedSegments.forEach((segment, idx) => {
                if (idx < index && segment.segments) {
                    isFirstShownSegment = false;
                }
            });
            return isFirstShownSegment;
        },
    },
    computed: {
        isAnySegmentSelected() {
            return this.selectedSegments.some((s) => s.segments);
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        searchOptions() {
            return this.$store.getters['flightStore/searchOptions'];
        },
        flightMarkings() {
            const strategyOffset = {
                detailed: 0,
                simplified: -1,
            };
            const isValid = (seg) => {
                return JSON.stringify(seg) !== '{}';
            };
            const selected = this.selectedSegments;
            const flightSearchResults = this.flightResult;
            const lastSelectedIdx = selected.filter((s) => isValid(s)).length - 1;
            const offset = this.searchOptions.tripType === TripType.multiCity ? strategyOffset.detailed : strategyOffset.simplified;
            const expectedSelections = this.searchOptions.flightRows.length - offset;
            const everythingSelected = lastSelectedIdx === expectedSelections - 1;
            const selectingLast = lastSelectedIdx === expectedSelections - 2;
            return selected.map((cur, idx) => {
                const marking = {
                    originMarked: false,
                    destinationMarked: false,
                    departureMarked: false,
                    arrivalMarked: false,
                };
                if (!isValid(cur)) {
                    return marking;
                }
                const prvSelected = selected[idx - 1];
                if (prvSelected && isValid(prvSelected)) {
                    marking.originMarked = airportMarkingsService.markIfConnectingToSameCityDiffAirport(prvSelected, cur);
                }
                const nxtSelected = selected[idx + 1];
                if (nxtSelected && isValid(nxtSelected)) {
                    marking.destinationMarked = airportMarkingsService.markIfConnectingToSameCityDiffAirport(cur, nxtSelected);
                }
                const fstSelected = idx === 0 && selected[0];
                if (fstSelected && isValid(fstSelected)) {
                    marking.departureMarked =
                        (selectingLast &&
                            airportMarkingsService.markIfConnectingToSameCityDiffAirport(flightSearchResults, fstSelected)) ||
                            (everythingSelected &&
                                airportMarkingsService.markIfConnectingToSameCityDiffAirport(selected[lastSelectedIdx], fstSelected));
                }
                const lstSelected = idx === lastSelectedIdx && selected[expectedSelections - 1];
                if (lstSelected) {
                    marking.arrivalMarked =
                        everythingSelected &&
                            airportMarkingsService.markIfConnectingToSameCityDiffAirport(selected[lastSelectedIdx], selected[0]);
                }
                const isCurrentPivot = !lstSelected && idx === lastSelectedIdx;
                if (isCurrentPivot) {
                    marking.destinationMarked = airportMarkingsService.markIfConnectingToSameCityDiffAirport(cur, flightSearchResults);
                }
                return marking;
            });
        },
    },
});
