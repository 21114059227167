import { loggerService } from '@/services/logger.service';
export const airportMarkingsService = {
    markIfConnectingToSameCityDiffAirport,
};
function sameCity(origin, destination) {
    const onlyAirports = ![origin.aircraftName, destination.aircraftName].some((n) => n?.toUpperCase() === 'TRAIN');
    const bothMultiCodes = onlyAirports && !!origin.originCodeMulti && !!destination.destinationCodeMulti;
    const originCity = (bothMultiCodes && origin.originCodeMulti) || origin.originCityName;
    const destinationCity = (bothMultiCodes && destination.destinationCodeMulti) || destination.destinationCityName;
    return originCity === destinationCity;
}
function sameAirport(origin, destination) {
    return origin.origin === destination.destCode;
}
function markIfConnectingToSameCityDiffAirport(origin, destination, debug = false) {
    const log = () => {
        if (debug) {
            const o = origins.flatMap((o) => `[${o.origin}]${o.originCityName}`);
            const d = destinations.flatMap((o) => `[${o.destCode}]${o.destinationCityName}`);
            const zip = { o, d };
            loggerService.debug('origins-destinations', zip, 'marked ', marked);
        }
    };
    const origins = destination?.flights?.slice(0, 1) ||
        destination?.segments
            ?.slice(0, 1)
            .flatMap((s) => s.flights)
            .slice(0, 1) ||
        destination
            .flatMap((res) => res.segments.slice(0, 1))
            .flatMap((s) => {
            return s.flights.slice(0, 1);
        });
    const destinations = origin?.flights?.slice(-1) ||
        origin?.segments?.[0].flights.slice(-1) ||
        origin.flatMap((res) => res.segments.slice(-1)).flatMap((s) => s.flights.slice(-1));
    const marked = origins.some((o) => destinations.some((d) => {
        return sameCity(o, d) && !sameAirport(o, d);
    }));
    log();
    return marked;
}
