import { defineAsyncComponent, defineComponent } from 'vue';
import { Products, QuoteCancellationType, ProductStatusName, QuoteStatus } from '@/types/consts';
import { format } from 'date-fns';
import { utilService } from '@/services/util.service';
import { RoomRefundableStatus } from '@/modules/products/hotels/models/consts';
import { SuppliersId } from '@/modules/products/flight/models/consts';
import flightCancelVoid from '@/modules/products/flight/components/flight-cancel-void/flight-cancel-void.vue';
import appProductIcon from '@/components/app-product-icon.vue';
const amtrakCancelQuote = defineAsyncComponent(() => import(
/* webpackChunkName: 'amtrak' */ '@/modules/products/rails/amtrak/components/amtrak-cart/amtrak-cancel-quote/amtrak-cancel-quote.vue'));
const euRailCancelQuote = defineAsyncComponent(() => import(
/* webpackChunkName: 'eu-rail' */ '@/modules/products/rails/eu-rail/components/eu-rail-cancel-quote/eu-rail-cancel-quote.vue'));
export default defineComponent({
    components: {
        amtrakCancelQuote,
        euRailCancelQuote,
        appProductIcon,
        flightCancelVoid
    },
    props: {
        quote: {
            type: Object,
            required: true,
        },
        cancelQuoteRes: {
            type: Object,
            required: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        showResults: {
            type: Boolean,
            default: false,
        },
        isCancellationSuccess: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            Products: Products,
            selectedRefund: QuoteCancellationType.CreditCard,
            isSaving: false,
            isCancelNotAllowed: false,
            transactionId: '',
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        async cancelQuote() {
            this.$emit('cancleQuote', this.quote, this.selectedRefund, this.transactionId);
        },
        onSelectedRefund(val) {
            this.selectedRefund = val;
        },
        onTransactionIdChanged(val) {
            this.transactionId = val;
        },
    },
    computed: {
        hasQuote() {
            return !!Object.keys(this.quote).length;
        },
        QuoteStatus() {
            return QuoteStatus;
        },
        hasPNR() {
            return this.hasQuote && this.quote.pnr !== undefined && this.quote.pnr.length > 0;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        ProductStatusName() {
            return ProductStatusName;
        },
        SuppliersId() {
            return SuppliersId;
        },
        hideDates() {
            return this.quote.product === Products.StaySafe || this.quote.product === Products.Compensation;
        },
        quoteName() {
            if (!this.hasQuote) {
                return '';
            }
            switch (this.quote.product) {
                case Products.Hotel:
                    return this.quote.hotelName;
                case Products.HeathrowExpress:
                    return this.quote.ticketType;
                case Products.Parking:
                    return this.quote.segment.name;
                case Products.Rail:
                case Products.Amtrak:
                    return this.quote.route;
                case Products.GroundService:
                    return this.quote.dropoffAddress.street;
                case Products.Flight:
                case Products.Evolvi:
                case Products.StaySafe:
                case Products.Compensation:
                case Products.Manual:
                    return utilService.getProductTitle(this.quote);
                default:
                    return '';
            }
        },
        quoteDates() {
            if (!this.hasQuote) {
                return '';
            }
            let startDate;
            let endDate;
            switch (this.quote.product) {
                case Products.Hotel:
                    startDate = this.quote.checkinDate;
                    endDate = this.quote.checkoutDate;
                    break;
                case Products.HeathrowExpress:
                case Products.Rail:
                    startDate = this.quote.startDate;
                    break;
                case Products.Parking:
                    startDate = this.quote.segment.arrivalDate;
                    endDate = this.quote.segment.departureDate;
                    break;
                case Products.Car:
                    startDate = this.quote.carSegment.pickUpDate;
                    endDate = this.quote.carSegment.dropOffDate;
                    break;
                case Products.GroundService:
                    startDate = this.quote.pickupDateTime;
                    break;
                case Products.StaySafe:
                case Products.Compensation:
                    startDate = this.quote.startDate;
                    break;
                case Products.Amtrak:
                case Products.Evolvi:
                    startDate = this.quote.journeyStartDate;
                    endDate = this.quote.journeyEndDate;
                    break;
                case Products.Flight:
                    startDate = this.quote.segments[0].flights[0].depDate;
                    endDate =
                        this.quote.segments[this.quote.segments.length - 1].flights[this.quote.segments[this.quote.segments.length - 1].flights.length - 1].arrDate;
                    break;
                case Products.Manual:
                    startDate = this.quote.departureDate;
                    endDate = this.quote.endDate;
                    break;
                default:
                    startDate = '';
                    endDate = '';
                    break;
            }
            return `${format(new Date(startDate), 'EEE, dd MMM')} ${endDate ? '-' : ''} ${endDate ? format(new Date(endDate), 'EEE, dd MMM') : ''}`;
        },
        statusClass() {
            return utilService.getQuoteStatusClass(this.quote);
        },
        RoomRefundableStatus() {
            return RoomRefundableStatus;
        },
    },
});
